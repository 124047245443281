// absolute imports
import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

// relative imports
import Beamer from '../beamer/Beamer';
import ErrorBoundary from '../../error-handling/ErrorBoundary';
import Header from '../header/Header';
import MobileHeader from '../header/MobileHeader';
import { useConfig } from '../../common/providers/ConfigProvider';
import useAlternativeDisplay from '../custom-hooks/useAlternativeDisplay';

const useStyles = makeStyles()((theme, { headerHeight }) => ({
  mainContent: {
    position: 'fixed',
    top: headerHeight || 56,
    height: '100%',
    width: '100%',
    overflowY: 'scroll',
  },
}));

const PrimaryLayout = ({
  children,
  hideSecondaryMenu,
  hideAccountMenu,
  hideHeader,
  hideUpdateButton,
  accountOptional,
}) => {
  const { isMobile } = useAlternativeDisplay();
  const { appConfig } = useConfig();
  const [headerHeight, setHeaderHeight] = useState(() => {
    if (isMobile) return 57;
    if (hideHeader) return 0;
    if (hideSecondaryMenu) return 56;
    return 104;
  });
  const { classes } = useStyles({ isMobile, headerHeight });

  return (
    <>
      {appConfig.enableBeamer && !window.Cypress && <Beamer />}
      {!hideHeader && !isMobile && (
        <Header
          hideAccountMenu={hideAccountMenu}
          hideSecondaryMenu={hideSecondaryMenu}
          hideUpdateButton={hideUpdateButton}
          accountOptional={accountOptional}
          setHeaderHeight={setHeaderHeight}
        />
      )}
      {!hideHeader && isMobile && (
        <MobileHeader
          hideAccountMenu={hideAccountMenu}
          hideUpdateButton={hideUpdateButton}
          setHeaderHeight={setHeaderHeight}
        />
      )}
      <ErrorBoundary
        fallbackType="modal"
        errorMsg="Uncaught error in layout"
        context={{
          component: PrimaryLayout.displayName,
        }}
      >
        <div className={classes.mainContent}>
          <div className="beamerActivePadding" />
          {children}
        </div>
      </ErrorBoundary>
    </>
  );
};;

PrimaryLayout.displayName = 'PrimaryLayout';
export default PrimaryLayout;

PrimaryLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  hideAccountMenu: PropTypes.bool,
  hideSecondaryMenu: PropTypes.bool,
  hideHeader: PropTypes.bool,
  hideUpdateButton: PropTypes.bool,
  accountOptional: PropTypes.bool,
};

PrimaryLayout.defaultProps = {
  hideAccountMenu: false,
  hideSecondaryMenu: false,
  hideHeader: false,
  hideUpdateButton: false,
  accountOptional: false,
};
