// absolute imports
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import {
  Alert,
  AlertTitle,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';

// icon imports
import { Close as CloseIcon } from '@mui/icons-material';

// relative imports
import ErrorFoundIcon from '../../shared/custom-icons/ErrorFoundIcon';
import LedgibleButton from '../../shared/custom-components/buttons/LedgibleButton';
import { openZendeskWidget } from '../../shared/customer-support/zendesk-operations';

const useStyles = makeStyles()((theme) => ({
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  supportLink: {
    color: theme.whiteLabel.Link.color || '#007bff',
    display: 'inline',
    padding: 0,
    border: 0,
    backgroundColor: 'transparent',
    '&:focus': {
      outline: 0,
    },
    cursor: 'pointer',
  },
  secondaryText: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 8px',
  },
  noButtonDialogTitle: {
    color: theme.palette.common.white,
    '& > h6': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.error.dark,
  },
  iconButton: {
    color: theme.palette.grey[200],
  },
  alertTitle: {
    marginBottom: 0,
  },
  alertSvg: {
    fontSize: theme.typography.pxToRem(64),
  },
  errorMessage: {
    fontWeight: 400,
  },
}));

const ModalErrorFallback = ({
  resetError,
  errorMsg = 'An error occurred loading this content',
}) => {
  const { classes } = useStyles();

  const handleHelpClick = () => {
    openZendeskWidget();
  };

  return (
    <Dialog
      maxWidth="sm"
      open
      fullWidth
      disableEscapeKeyDown
      onClose={resetError}
    >
      <DialogTitle
        classes={{
          root: classes.noButtonDialogTitle,
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <span>Error</span>
          <IconButton onClick={resetError} className={classes.iconButton}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <ErrorFoundIcon className={classes.alertSvg} />
          </Grid>
          <Grid item>
            <Alert severity="error" className={classes.alert}>
              <AlertTitle className={classes.alertTitle}>
                <span className={classes.errorMessage}>{errorMsg}</span>
              </AlertTitle>
            </Alert>
            <div className={classes.secondaryText}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                align="center"
              >
                We&apos;re working to fix this, but if you need further help
                please &nbsp;
                <span
                  role="button"
                  tabIndex={0}
                  className={classes.supportLink}
                  onClick={handleHelpClick}
                  onKeyUp={handleHelpClick}
                >
                  contact our support team
                </span>
                &nbsp; or reload the page and try again.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LedgibleButton
          size="small"
          onClick={resetError}
          color="cancel"
          id="error-boundary-modal-fallback-reset-btn"
        >
          Close
        </LedgibleButton>
      </DialogActions>
    </Dialog>
  );
};

export default ModalErrorFallback;

ModalErrorFallback.propTypes = {
  resetError: PropTypes.func.isRequired,
  errorMsg: PropTypes.string,
};

ModalErrorFallback.defaultProps = {
  errorMsg: undefined,
};
