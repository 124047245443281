// absolute imports
import { useCallback, useEffect, useRef, useState } from 'react';
import { useReactiveVar } from '@apollo/client';

// relative imports
import { errorVar } from '../../../data/apollo/cache/reactiveVars';

/* custom hook to get cache error results easily in components for array of specific
operations since ApolloLinkError is a terminating link in network chain */
export const useApolloErrorState = (operationNames) => {
  const [error, setError] = useState(null);
  // get cached errors
  const localError = useReactiveVar(errorVar);
  const errRef = useRef(null);

  // clear cached errors
  const clearError = useCallback(() => {
    setError(null);
    errorVar(null);
    errRef.current = null;
  }, []);

  useEffect(() => {
    /**
     * null check on localError.operation due to errors being able to occur from
     * a missing/unexpected gql schema parameter when trying to perform operation
     */
    if (
      localError?.operation &&
      localError.type !== 'network' &&
      (operationNames.includes(localError.operation) ||
        localError.operation === null)
    ) {
      setError(localError);
      errRef.current = localError;
    }
    if (errRef.current && !localError) {
      setError(null);
      errRef.current = null;
    }
  }, [localError, operationNames]);

  return [error, clearError];
};

export default useApolloErrorState;
