import { gql } from '@apollo/client';

const typeDefs = gql`
  extend type Query {
    accountingTableState: AccountingTableState!
  }

  input TxTableFlags {
    flags: [String]
    operator: String!
  }

  type TxTableState {
    currencies: [String]
    txFlags: TxTableFlags
    exchanges: [String]
    sortColumn: String
    sortOrder: String
    txDateEnd: String
    txDateStart: String
    txDateStatic: String
    types: [String]
    wallets: [String]
    hideFees: Boolean
    contracts: [String]
    taxCategories: [String]
  }

  type AccountingTableState {
    contacts: [String]
    currencies: [String]
    exchanges: [String]
    txDateEnd: String
    txDateStart: String
    txDateStatic: String
    types: [String]
    wallets: [String]
    sortColumn: String
    sortOrder: String
    contracts: [String]
  }

  type ErrorResponse {
    code: Int
    message: String
    operation: String
    modalKey: String
    type: ErrorType
    details: ErrorDetails
    contactSupport: Boolean
  }

  type ErrorDetails {
    planMaximum: String
    transactionCount: String
  }

  enum ErrorType {
    network
    graphql
  }
`;

export default typeDefs;
