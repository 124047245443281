import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { green } from '@mui/material/colors';
import { Box } from '@mui/material';

const useStyles = makeStyles()(
  (theme, { circleSize, marginLeft, hasText, marginRight }) => ({
    activeWrapper: {
      color: theme.palette.primary.dark,
      padding: theme.spacing(0.5, 1),
    },
    activeCircle: {
      backgroundColor: green[400],
      width: circleSize || 10,
      height: circleSize || 10,
      borderRadius: 25,
      margin: 0,
      marginLeft: theme.spacing(marginLeft || 0),
      marginRight: theme.spacing(marginRight || 0),
      boxShadow: theme.shadows[2],
    },
    activeText: {
      display: hasText ? 'inherit' : 'none',
      height: 14,
      fontSize: 12,
      fontWeight: 500,
    },
  }),
);

const ActiveAccountIndicator = ({
  circleSize,
  marginRight,
  marginLeft,
  text,
}) => {
  const { classes } = useStyles({
    circleSize,
    marginRight,
    marginLeft,
    hasText: !!text,
  });
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      className={classes.activeWrapper}
    >
      <div className={classes.activeCircle} />
      <div className={classes.activeText}>CURRENT</div>
    </Box>
  );
};

ActiveAccountIndicator.propTypes = {
  circleSize: PropTypes.number,
  marginRight: PropTypes.number,
  marginLeft: PropTypes.number,
  text: PropTypes.string,
};

ActiveAccountIndicator.defaultProps = {
  circleSize: 10,
  marginRight: 0,
  marginLeft: 0,
  text: null,
};

export default ActiveAccountIndicator;
