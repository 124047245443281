// absolute imports
import { useContext, useReducer, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';

// relative imports
import { useAuth0 } from '../../common/providers/Auth0Provider';
import { useAccount } from '../../common/providers/AccountProvider';
import {
  GET_PREPARER_LINKS,
  GET_CLIENT_ACCOUNTS_UPDATING,
  GET_PREPARER_LINK_STATUS_TOTALS,
} from '../../../data/apollo/queries';
import { tableStateVar } from '../../../data/apollo/cache/reactiveVars';
import { getTableStateId } from '../../shared/utilities/stateUtilities';
import { useAppManagement } from '../../common/providers/AppManagementProvider';
import useUpdatePolling from '../../shared/custom-hooks/useUpdatePolling';

export const ClientSummaryContext = createContext();
export const useClientSummary = () => {
  const context = useContext(ClientSummaryContext);
  if (!context) {
    throw new Error(
      'Client Summary Context must be used under the ClientSummaryProvider',
    );
  }
  return context;
};

function clientTableReducer(state, [type, value]) {
  switch (type) {
    case 'pageSize':
      return { ...state, pageSize: value };
    case 'globalFilter':
      return { ...state, globalFilter: value };
    case 'pageIndex':
      return { ...state, pageIndex: value };
    case 'filters':
      return { ...state, filters: value };
    case 'sortBy':
      return { ...state, sortBy: value };
    default:
      return state;
  }
}

export const ClientSummaryProvider = ({ children }) => {
  const { loadingAuth } = useAuth0();
  const { currentAccount } = useAccount();
  const { appType } = useAppManagement();
  const tableId = getTableStateId('clientTable');

  const initialTableState = tableStateVar()[tableId] || {
    filters: [],
    globalFilter: '',
    pageSize: 10,
    pageIndex: 0,
    sortBy: [
      {
        id: 'createdAt',
        desc: true,
      },
    ],
  };

  const [clientTableState, dispatchClientTableState] = useReducer(
    clientTableReducer,
    initialTableState,
  );

  const { filters, pageSize, pageIndex, sortBy, globalFilter } = useMemo(
    () => clientTableState,
    [clientTableState],
  );

  const {
    previousData: {
      getPreparerLinksByFilter: {
        totalUnfiltered: previousTotalUnfiltered,
        totalFiltered: previousTotalFiltered,
        preparerLinks: previousPreparerLinks,
      } = {},
    } = {},
    data: {
      getPreparerLinksByFilter: {
        totalUnfiltered,
        totalFiltered,
        preparerLinks,
      } = {},
    } = {},
    refetch: refetchPreparerLinks,
    networkStatus,
  } = useQuery(GET_PREPARER_LINKS, {
    variables: {
      accountId: currentAccount.id,
      filters,
      clientAccountName: globalFilter,
      limit: Number(pageSize),
      offset: pageSize * pageIndex,
      sortBy,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    skip:
      loadingAuth ||
      !currentAccount.id ||
      (appType !== 'tax-preparer' && appType !== 'tir-admin'),
  });

  const loadingQuery = networkStatus === 2;

  const {
    data: { getPreparerLinkStatusTotals: statusTotals = {} } = {},
    refetch: refetchStatusTotals,
  } = useQuery(GET_PREPARER_LINK_STATUS_TOTALS, {
    variables: {
      accountId: currentAccount.id,
    },
    fetchPolicy: 'cache-and-network',
    skip:
      loadingAuth ||
      !currentAccount.id ||
      (appType !== 'tax-preparer' && appType !== 'tir-admin'),
  });

  const clientAccountsToPoll = useMemo(
    () =>
      preparerLinks
        ?.filter((link) => link.clientAccount?.updating)
        .map(({ clientAccountId }) => clientAccountId) || [],
    [preparerLinks],
  );

  useUpdatePolling({
    query: GET_CLIENT_ACCOUNTS_UPDATING,
    variables: {
      type: 'TaxPayer',
      accountIds: clientAccountsToPoll,
    },
    updateCondition: clientAccountsToPoll.length > 0,
    queryOptions: {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: (response) => {
        const checkedAccounts = response?.getAccounts || [];
        const updateComplete = checkedAccounts.filter(
          (checkedAccount) => !checkedAccount.updating,
        );
        if (updateComplete.length > 0) {
          refetchPreparerLinks();
          enqueueSnackbar(`Client account updates complete`, {
            key: `client-account-updates-finished-${Date.now()}`,
            preventDuplicate: true,
            autoHideDuration: 3000,
            variant: 'success',
          });
        }
      },
    },
  });

  const clientSummaryValue = useMemo(
    () => ({
      loadingInitial:
        networkStatus === 1 &&
        preparerLinks?.length === 0 &&
        !previousPreparerLinks,
      loadingQuery,
      preparerLinks:
        filters.length === 0 && !globalFilter
          ? preparerLinks ?? previousPreparerLinks
          : preparerLinks,
      totalFiltered:
        filters.length === 0 && !globalFilter
          ? totalFiltered ?? previousTotalFiltered
          : totalFiltered,
      totalUnfiltered: totalUnfiltered ?? previousTotalUnfiltered,
      clientTableState,
      statusTotals,
      dispatchClientTableState,
      refetchPreparerLinks,
      refetchStatusTotals,
    }),
    [
      networkStatus,
      preparerLinks,
      previousPreparerLinks,
      loadingQuery,
      filters.length,
      globalFilter,
      totalFiltered,
      previousTotalFiltered,
      totalUnfiltered,
      previousTotalUnfiltered,
      clientTableState,
      statusTotals,
      refetchPreparerLinks,
      refetchStatusTotals,
    ],
  );

  return (
    <ClientSummaryContext.Provider value={clientSummaryValue}>
      {children}
    </ClientSummaryContext.Provider>
  );
};

ClientSummaryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ClientSummaryConsumer = ClientSummaryContext.Consumer;
