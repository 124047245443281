import * as colors  from '@mui/material/colors';

// A theme for Ledgible:
// All of this is optional and can be extended to include whichever elements
// of material-ui theme need adjustment globally
// see https://material-ui.com/customization/themes/ for full set of options
// whiteLabel property used for specific assignments of theme colors/effects through JSON file ingest

const ledgibleDefaultThemeOptions = {
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      main: '#5FB7D1',
    },
    secondary: {
      main: '#143945',
    },
    success: {
      main: colors.green[500],
    },
    error: {
      main: colors.red[500],
    },
    warning: {
      main: colors.amber[700],
    },
    ...colors,
  },
  whiteLabel: {
    AccountBox: {},
    AccountWindow: {},
    AppLoader: {},
    Button: {},
    CardHeader: {},
    DialogTitle: {},
    Header: {},
    HeaderText: {},
    Help: {},
    Indicator: {},
    IndicatorRoot: {},
    Link: {},
    Navigation: {},
    PageBody: {},
    PageHeader: {},
    ProductName: {},
    SecondaryToolbar: {},
    TxTable: {},
    UpdateButton: {},
  },
  components: {
    MuiButton: {
      root: {
        borderRadius: 28,
        '&:focus': {
          outline: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:focus': {
            outline: 'none',
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.common.white,
          '&:focus': {
            outline: 'none',
          },
          '&:disabled': {
            color: theme.palette.common.white,
          },
        }),
      },
    },
    MuiPickersToolbarText: {
      styleOverrides: {
        toolbarTxt: ({ theme }) => ({
          color: theme.palette.common.white,
        }),
        toolbarBtnSelected: ({ theme }) => ({
          color: theme.palette.common.white,
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        action: {
          alignItems: 'center',
        },
      },
    },
    Tooltip: {
      defaultProps: {
        arrow: true,
      },
    },
  },
};

export default ledgibleDefaultThemeOptions;
