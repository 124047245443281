/** POSSIBLE VALUES
  fieldId: String
  fieldType: String (text, select)
  fieldLabel: String
  fieldValidators: validator function with arg (val)
  fieldNormalizer: normalizing function with arg (val)
  defaultValue: default field value
  ariaLabel: String
  format: func to format display
  parse: func to parse stored value
  options: for select/radio/etc -> static array of objects
  valueDisplayReference: object for mapping option values to display values
*/
import { Field } from 'react-final-form';
import { MenuItem, TextField } from '@mui/material';

import {
  requiredField,
  email,
  maxLength40,
  composeValidators,
} from '../../../components/shared/utilities/formValidations';
import {
  upperCase,
  lowerCase,
} from '../../../components/shared/utilities/stringUtilities';
import { g20Currencies } from '../appDetails';
import {
  FormEmailField,
  FormTextField,
} from '../../../components/shared/final-form-field-wrappers';

export const subtypeFieldDefs = {
  /**
   * US BANK SUB TYPE FIELDS
   */
  reportingPlatform: {
    fieldType: 'select',
    fieldId: 'reportingPlatform',
    fieldLabel: 'Reporting Platform',
    ariaLabel: 'Reporting Platform',
    placeholder: 'Select a reporting platform...',
    validators: [requiredField.generic],
    options: [
      {
        key: 1,
        value: 'geneva',
        label: 'Geneva',
      },
      {
        key: 2,
        value: 'eagle',
        label: 'Eagle',
      },
      {
        key: 3,
        value: 'sei',
        label: 'SEI',
      },
    ],
    valueDisplayReference: {
      geneva: 'Geneva',
      eagle: 'Eagle',
      sei: 'SEI',
    },
  },
  fundId: {
    fieldType: 'text',
    fieldId: 'fundId',
    fieldLabel: 'Fund ID',
    ariaLabel: 'Fund ID',
    placeholder: 'Enter the fund ID',
    validators: [
      requiredField.generic,
      (val) =>
        val && !/^[a-zA-Z]{5}$/i.test(val)
          ? 'Must consist of five alphabetic characters'
          : undefined,
    ],
    format: (val) => upperCase(val || ''),
    parse: (val) => upperCase(val || ''),
  },
  /**
   * FIS GENERAL SUB TYPE FIELDS
   */
  sftpAdminEmail: {
    fieldType: 'email',
    fieldId: 'sftpAdminEmail',
    fieldLabel: 'FIS SFTP Admin Email Address',
    ariaLabel: 'FIS SFTP Admin Email Address',
    placeholder: 'fis-user@fis.com',
    validators: [requiredField.generic, email],
    parse: (val) => lowerCase(val || ''),
  },
  /**
   * FIS INVESTONE SUB TYPE FIELDS
   */
  investOneAccountNumber: {
    fieldType: 'text',
    fieldId: 'portfolio',
    fieldLabel: 'InvestOne Account Number',
    ariaLabel: 'InvestOne Account Number',
    placeholder: 'Enter InvestOne account number',
    validators: [
      requiredField.generic,
      (val) =>
        val && !/^[0-9]{15}$/i.test(val)
          ? 'Must consist of fifteen digits'
          : undefined,
    ],
  },
  investOneUserBank: {
    fieldType: 'text',
    fieldId: 'fundStructure',
    fieldLabel: 'InvestOne User Bank',
    ariaLabel: 'InvestOne User Bank',
    placeholder: 'Enter InvestOne User Bank',
    validators: [requiredField.generic, maxLength40],
  },
  userBankFiatCurrency: {
    fieldType: 'select',
    fieldId: 'fundStructureCurrency',
    fieldLabel: 'User Bank Fiat Currency',
    ariaLabel: 'User Bank Fiat Currency',
    placeholder: 'Select user bank currency',
    options: g20Currencies.map((currency) => ({
      key: currency,
      value: currency,
      label: currency,
    })),
    renderValue: (value) => value,
    validators: [requiredField.generic],
  },
  /**
   * FIS ECM SUB TYPE FIELDS
   */
  ecmClientId: {
    fieldType: 'text',
    fieldId: 'portfolio',
    fieldLabel: 'ECM Client ID',
    ariaLabel: 'ECM Client ID',
    placeholder: 'Enter the ECM client ID',
    validators: [requiredField.generic],
  },
  ecmClientName: {
    fieldType: 'text',
    fieldId: 'fundStructure',
    fieldLabel: 'ECM Client Name',
    ariaLabel: 'ECM Client Name',
    placeholder: 'Enter ECM client name',
    validators: [requiredField.generic],
  },
  /**
   * FIS STREAMCORE SUB TYPE FIELDS
   */
  streamCoreClientId: {
    fieldType: 'text',
    fieldId: 'portfolio',
    fieldLabel: 'Client ID',
    ariaLabel: 'Client ID',
    placeholder: 'Enter StreamCore client ID',
    validators: [requiredField.generic],
  },
  streamCoreClientName: {
    fieldType: 'text',
    fieldId: 'fundStructure',
    fieldLabel: 'Client Name',
    ariaLabel: 'Client Name',
    placeholder: 'Enter StreamCore client name',
    validators: [requiredField.generic],
  },
  streamCoreBrokerName: {
    fieldType: 'select',
    fieldId: 'streamCoreBrokerName',
    fieldLabel: 'Broker Name',
    ariaLabel: 'Broker Name',
    placeholder: 'Select the broker',
    defaultValue: 'paxos',
    options: [
      {
        key: 1,
        value: 'paxos',
        label: 'PAXOS',
      },
    ],
    valueDisplayReference: {
      paxos: 'PAXOS',
    },
    validators: [requiredField.generic],
  },
  /**
   * FIS VPM SUB TYPE FIELDS
   */
  vpmClientId: {
    fieldType: 'text',
    fieldId: 'portfolio',
    fieldLabel: 'VPM Client ID',
    ariaLabel: 'VPM Client ID',
    placeholder: 'Enter VPM client ID',
    validators: [requiredField.generic],
  },
  vpmClientName: {
    fieldType: 'text',
    fieldId: 'fundStructure',
    fieldLabel: 'VPM Client Name',
    ariaLabel: 'VPM Client Name',
    placeholder: 'Enter VPM client name',
    validators: [requiredField.generic, maxLength40],
  },
};

export const getSubtypeInputFieldFromConfig = (
  fieldId,
  formId,
  fieldProps = {},
) => {
  const fieldConfig = subtypeFieldDefs[fieldId];

  switch (fieldConfig.fieldType) {
    case 'select':
      return (
        <Field
          id={`${formId}-${fieldId}`}
          data-cy={`${formId}-${fieldId}`}
          name={fieldId}
          component={FormTextField}
          fullWidth
          label={fieldConfig.fieldLabel}
          aria-label={fieldConfig.ariaLabel}
          validate={
            fieldConfig.validators
              ? composeValidators(...fieldConfig.validators)
              : null
          }
          defaultValue={fieldConfig.defaultValue}
          select
          InputLabelProps={{
            shrink: true,
          }}
          margin="dense"
          SelectProps={{
            displayEmpty: true,
            renderValue: (value) => {
              const { renderValue, valueDisplayReference, placeholder } =
                fieldConfig;
              if (renderValue && renderValue(value)) {
                return renderValue(value);
              }
              return (
                valueDisplayReference?.[value] || (
                  <span style={{ color: 'rgba(0, 0, 0, 0.35)' }}>
                    {placeholder}
                  </span>
                )
              );
            },
          }}
          {...fieldProps}
        >
          {fieldConfig.options.map((option) => (
            <MenuItem key={option.key} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Field>
      );
    case 'text':
      return (
        <Field
          id={`${formId}-${fieldId}`}
          data-cy={`${formId}-${fieldId}`}
          name={fieldId}
          component={FormTextField}
          fullWidth
          margin="dense"
          InputLabelProps={{ shrink: true }}
          label={fieldConfig.fieldLabel}
          placeholder={fieldConfig.placeholder}
          validate={composeValidators(...fieldConfig.validators)}
          variant="outlined"
          aria-label={fieldConfig.ariaLabel}
          format={fieldConfig.format}
          parse={fieldConfig.parse}
          {...fieldProps}
        />
      );
    case 'email':
      return (
        <Field
          id={`${formId}-${fieldId}`}
          data-cy={`${formId}-${fieldId}`}
          name={fieldId}
          component={FormEmailField}
          fullWidth
          margin="dense"
          InputLabelProps={{ shrink: true }}
          label={fieldConfig.fieldLabel}
          placeholder={fieldConfig.placeholder}
          validate={composeValidators(...fieldConfig.validators)}
          variant="outlined"
          aria-label={fieldConfig.ariaLabel}
          format={fieldConfig.format}
          parse={fieldConfig.parse}
          {...fieldProps}
        />
      );
    default:
      return null;
  }
};

export const getSubtypeFilterFieldFromConfig = (fieldId, fieldProps = {}) => {
  const fieldConfig = subtypeFieldDefs[fieldId];

  switch (fieldConfig.fieldType) {
    case 'select':
      return (
        <TextField
          id={`${fieldId}-filter`}
          fullWidth
          label={fieldConfig.fieldLabel}
          aria-label={fieldConfig.ariaLabel}
          select
          margin="dense"
          {...fieldProps}
        >
          <MenuItem value="all">All</MenuItem>
          {fieldConfig.options.map((option) => (
            <MenuItem key={option.key} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      );
    case 'text':
      return (
        <TextField
          id={`${fieldId}-filter`}
          name={fieldId}
          fullWidth
          margin="dense"
          InputLabelProps={{ shrink: true }}
          label={fieldConfig.fieldLabel}
          placeholder={fieldConfig.placeholder}
          variant="outlined"
          aria-label={fieldConfig.ariaLabel}
          {...fieldProps}
        />
      );
    default:
      return null;
  }
};

export const getFormFieldIds = (values) => {
  const indexRef = {};
  Object.keys(values).forEach((key, index) => {
    indexRef[key] = index;
  });
  return indexRef;
};

export const getSecondaryAccountLabel = (account, secondaryLabelFieldId) => {
  if (secondaryLabelFieldId === 'subtype') {
    return account.subtype.displayName;
  }
  const valueRef =
    subtypeFieldDefs[secondaryLabelFieldId]?.valueDisplayReference;
  const fieldValue = account.subtypeFields?.[secondaryLabelFieldId];

  if (valueRef) {
    return valueRef[fieldValue];
  }
  return subtypeFieldDefs[fieldValue].fieldLabel;
};

export const getDbIdFromClientFieldId = (clientFieldId) =>
  subtypeFieldDefs[clientFieldId].fieldId;
