import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IconButton, Tooltip, Typography } from '@mui/material';

// icon imports
import {
  FileCopy as FileCopyIcon,
  FileCopyOutlined as FileCopyOutlineIcon,
} from '@mui/icons-material';

const useStyles = makeStyles()(
  (theme, { flexDisplay, inheritDisplayOff, iconSize }) => {
    const base = {
      copyWrapper: {
        width: '100%',
        display: 'inherit',
      },
      copyTooltip: {
        background: theme.palette.grey[200],
        color: theme.palette.text.secondary,
      },
      copyButton: {
        marginLeft: theme.spacing(1),
      },
      copyIcon: {
        fontSize: theme.typography.pxToRem(iconSize),
      },
    };

    if (flexDisplay) base.copyWrapper.display = 'flex';
    if (inheritDisplayOff) base.copyWrapper.display = null;

    return base;
  },
);

const CopyText = ({
  copyVal,
  className,
  outlineIcon,
  linkText,
  copiedText,
  TypographyProps,
  TooltipProps,
  iconMarginOff,
  iconOnly,
  iconSize,
  flexDisplay,
  inheritDisplayOff,
  isLink,
  linkStyle,
  messageDuration,
  IconButtonProps,
  displayText,
}) => {
  const { classes, cx } = useStyles({
    iconSize,
    flexDisplay,
    inheritDisplayOff,
  });
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
  };

  const getTextDisplay = () => {
    if (displayText) return displayText;
    if (!isLink) return copyVal;
    return (
      <a
        className={linkStyle}
        href={copyVal}
        target="_blank"
        rel="noopener noreferrer"
      >
        {linkText || copyVal}
      </a>
    );
  };

  useEffect(() => {
    let copiedTimeout;

    if (copied) {
      copiedTimeout = setTimeout(() => {
        setCopied(false);
      }, messageDuration);
    }

    return () => clearTimeout(copiedTimeout);
  }, [copied, messageDuration]);

  return (
    <span className={cx(classes.copyWrapper, className)}>
      {!iconOnly && (
        <Typography {...TypographyProps}>{getTextDisplay()}</Typography>
      )}
      <Tooltip
        title={copiedText || 'copied'}
        open={copied}
        leaveDelay={2500}
        enterDelay={2500}
        placement="right-start"
        classes={{
          tooltip: classes.copyTooltip,
        }}
        {...TooltipProps}
      >
        <span>
          <CopyToClipboard
            text={copyVal}
            onCopy={handleCopy}
            className={iconMarginOff ? null : classes.copyButton}
          >
            <IconButton {...IconButtonProps}>
              {outlineIcon && (
                <FileCopyOutlineIcon className={classes.copyIcon} />
              )}
              {!outlineIcon && <FileCopyIcon className={classes.copyIcon} />}
            </IconButton>
          </CopyToClipboard>
        </span>
      </Tooltip>
    </span>
  );
};

export default CopyText;

CopyText.propTypes = {
  className: PropTypes.string,
  copyVal: PropTypes.string.isRequired,
  outlineIcon: PropTypes.bool,
  TypographyProps: PropTypes.shape(),
  TooltipProps: PropTypes.shape(),
  iconMarginOff: PropTypes.bool,
  copiedText: PropTypes.string,
  iconSize: PropTypes.number,
  flexDisplay: PropTypes.bool,
  isLink: PropTypes.bool,
  linkStyle: PropTypes.string,
  linkText: PropTypes.string,
  messageDuration: PropTypes.number,
  iconOnly: PropTypes.bool,
  inheritDisplayOff: PropTypes.bool,
  IconButtonProps: PropTypes.shape(),
  displayText: PropTypes.string,
};

CopyText.defaultProps = {
  className: null,
  TypographyProps: {},
  outlineIcon: false,
  TooltipProps: {},
  iconMarginOff: false,
  copiedText: null,
  iconSize: 14,
  flexDisplay: false,
  isLink: false,
  linkStyle: null,
  linkText: null,
  messageDuration: 750,
  iconOnly: false,
  inheritDisplayOff: false,
  IconButtonProps: {
    size: 'small',
    color: 'secondary',
  },
  displayText: null,
};
