// absolute imports
import { lazy } from 'react';
import { nanoid } from 'nanoid';

// relative imports
import PrimaryLayout from '../../shared/layouts/PrimaryLayout';
import ErrorPage from '../../error-handling/ErrorPage';
import FullScreenLayout from '../../shared/layouts/FullScreenLayout';

// import public route pages
const LogInRouter = lazy(() =>
  import('../../shared/authentication/LogInRouter'),
);
const EnterpriseLoginRouter = lazy(() =>
  import('../../shared/authentication/EnterpriseLoginRouter'),
);
const LogOutRouter = lazy(() =>
  import('../../shared/authentication/LogOutRouter'),
);
const SignUpRouter = lazy(() =>
  import('../../shared/authentication/SignUpRouter'),
);
const TokenSignUpRouter = lazy(() =>
  import('../../shared/authentication/TokenSignUpRouter'),
);
const UnverifiedEmailPage = lazy(() =>
  import('../../shared/authentication/UnverifiedEmailPage'),
);
const ConfirmRedirectPage = lazy(() =>
  import('../../shared/authentication/OAuthConfirmRedirectPage'),
);
const InvalidRequestPage = lazy(() =>
  import('../../error-handling/InvalidRequestPage'),
);
const AppSelectionPage = lazy(() =>
  import('../../shared/authentication/AppSelectionPage'),
);
const WalletPage = lazy(() => import('../wallets/wallet-page/WalletPage'));
const AccountDashboard = lazy(() =>
  import('../../accounting/accounting-dashboard/AccountDashboard'),
);
const MonitoringPage = lazy(() =>
  import('../../accounting/monitoring/MonitoringPage'),
);
const ExchangePage = lazy(() =>
  import('../exchanges/exchange-page/ExchangePage'),
);
const SourcesPage = lazy(() => import('../sources/sources-page/SourcesPage'));
const AddSourcesPage = lazy(() =>
  import('../sources/add-sources-page/AddSourcesPage'),
);
const NoAccessPage = lazy(() => import('../../shared/no-access/NoAccessPage'));
const UserProfilePage = lazy(() => import('../user-profile/UserProfilePage'));
const ReportsPage = lazy(() => import('../reports/ReportsPageContainer'));
const NftsPage = lazy(() => import('../nfts/NftsPageContainer'));

// tax payer pages
const TaxOverviewPage = lazy(() =>
  import('../../tax/tax-overview/TaxOverviewPageContainer'),
);
const TaxCompletePage = lazy(() =>
  import('../../tax/tax-complete/TaxCompletePage'),
);
const TaxTransactionsPage = lazy(() =>
  import('../../tax/tax-transactions/TaxTransactionsPage'),
);
const CheckoutPage = lazy(() => import('../../tax/billing/CheckoutPage'));
const TaxPlanningPage = lazy(() =>
  import('../../tax/tax-planning/TaxPlanningPageContainer'),
);
// tax pages
const PreparerOverview = lazy(
  () => import('../../tax-preparer/preparer-overview/PreparerOverviewPage'),
);
const PreparerTeam = lazy(() =>
  import('../../tax-preparer/preparer-team/PreparerTeamPage'),
);
const PreparerSettings = lazy(() =>
  import('../../tax-preparer/preparer-settings/PreparerSettingsPage'),
);
const IntegrationsPage = lazy(() =>
  import('../../accounting/integrations/IntegrationsPage'),
);
const IntegrationPage = lazy(() =>
  import('../../accounting/integrations/IntegrationPageContainer'),
);
const ContactsPage = lazy(() =>
  import('../../accounting/contacts/ContactsPage'),
);
const AccountDisabledPage = lazy(() =>
  import('../../accounting/accounts/account-disabled-page/AccountDisabledPage'),
);
const TaxPreparerAccountDisabledPage = lazy(() =>
  import('../../tax-preparer/account-disabled-page/AccountDisabledPage'),
);
const TransactionsPage = lazy(() =>
  import('../../accounting/transaction-table/TransactionTablePage'),
);
const AccountSettingsPage = lazy(() =>
  import('../../accounting/account-settings/AccountSettingsPage'),
);
const TrialEndedPage = lazy(() =>
  import('../../accounting/accounts/trial-ended-page/TrialEndedPage'),
);
const TaxPayerSettingsPage = lazy(() =>
  import('../../tax/tax-settings/PayerSettingsPage'),
);
const PostLoginPasswordChange = lazy(() =>
  import('../../shared/authentication/PostLoginPasswordChange'),
);
// tir pages
const TirOverview = lazy(() =>
  import('../../tir-admin/tir-overview/TirOverview'),
);
const TirTeam = lazy(() => import('../../tir-admin/tir-team/TirTeamPage'));
const TirFiles = lazy(() => import('../../tir-admin/tir-files/TirFilesPage'));
const TirSettings = lazy(
  () => import('../../tir-admin/tir-settings/TirAdminSettingsPage'),
);
// partner specific pages
const USBankWaitlistPage = lazy(
  () =>
    import(
      '../../shared/authentication/client-specific-pages/USBankWaitlistPage'
    ),
);

// reference for route object:
/**
  id - unique identifier for dom ops
  path - pathname of the route, can use vars similar to express route params
  exact - boolean for whether route should be matched exactly or relatively (pathname plus w/e)
  main: - component to render for the route
  layout - page layout to wrap the renderd component with
  LayoutProps: - optional props object to pass to rendered layout component,
  public - whether route requires authentication (false) or not (true)
  apps - apps that this route can be rendered for
  disabled - secondary filter if route should not be rendered based on predetermined condition
  helpLabels - array of key words to drive contextual help articles on the respective page (to be implemented)
 */

// for subroutes, can put property routes with array of route objects

// define route config
const generateRouteConfig = (mapUrlAlias = (path) => path) => [
  /* PUBLIC AND PRIVATE ROUTES USED ACROSS APPLICATIONS */
  {
    id: nanoid(10),
    path: '/login',
    exact: true,
    main: LogInRouter,
    layout: FullScreenLayout,
    public: true,
    apps: ['none', 'tax', 'tax-preparer', 'accounting', 'tir-admin'],
  },
  {
    id: nanoid(10),
    path: '/start-login',
    exact: true,
    main: EnterpriseLoginRouter,
    layout: FullScreenLayout,
    public: true,
    apps: ['none', 'tax', 'tax-preparer', 'accounting', 'tir-admin'],
  },
  {
    id: nanoid(10),
    path: '/logout',
    exact: true,
    main: LogOutRouter,
    layout: FullScreenLayout,
    public: true,
    apps: ['none', 'tax', 'tax-preparer', 'accounting', 'tir-admin'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias(
      '/:app(accounting|tax|tax-preparer|tir-admin)',
    )}/logout`,
    exact: true,
    main: LogOutRouter,
    layout: FullScreenLayout,
    public: true,
    apps: ['none', 'tax', 'tax-preparer', 'accounting', 'tir-admin'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias(
      '/:app(accounting|tax|tax-preparer|tir-admin)',
    )}/login`,
    exact: true,
    main: LogInRouter,
    layout: FullScreenLayout,
    public: true,
    apps: ['none', 'tax', 'tax-preparer', 'accounting', 'tir-admin'],
  },
  {
    id: nanoid(10),
    path: '/signup',
    exact: true,
    main: SignUpRouter,
    layout: FullScreenLayout,
    public: true,
    apps: ['none', 'tax', 'tax-preparer', 'accounting', 'tir-admin'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias(
      '/:app(accounting|tax|tax-preparer|tir-admin)',
    )}/signup`,
    exact: true,
    main: SignUpRouter,
    layout: FullScreenLayout,
    public: true,
    apps: ['none', 'tax', 'tax-preparer', 'accounting', 'tir-admin'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias(
      '/:app(accounting|tax|tax-preparer|tir-admin)',
    )}/signup/:token?`,
    exact: true,
    main: TokenSignUpRouter,
    layout: FullScreenLayout,
    public: true,
    apps: ['none', 'tax', 'tax-preparer', 'accounting', 'tir-admin'],
  },
  {
    id: nanoid(10),
    path: `/us-bank-waitlist`,
    exact: true,
    main: USBankWaitlistPage,
    layout: FullScreenLayout,
    public: true,
    apps: ['none', 'tax', 'tax-preparer', 'accounting', 'tir-admin'],
  },
  {
    id: nanoid(10),
    path: '/invalid-auth-request',
    exact: true,
    main: InvalidRequestPage,
    layout: FullScreenLayout,
    public: true,
    apps: ['none', 'tax', 'accounting'],
  },
  {
    id: nanoid(10),
    path: '/confirm-oauth-redirect',
    exact: true,
    main: ConfirmRedirectPage,
    layout: FullScreenLayout,
    public: true,
    apps: ['none', 'tax', 'accounting'],
  },
  {
    id: nanoid(10),
    path: '/auth-error',
    exact: false,
    main: ErrorPage,
    layout: FullScreenLayout,
    public: true,
    apps: ['none', 'tax', 'tax-preparer', 'accounting', 'tir-admin'],
  },
  {
    id: nanoid(10),
    path: '/verify-email',
    exact: true,
    main: UnverifiedEmailPage,
    layout: FullScreenLayout,
    public: true,
    apps: ['none', 'tax', 'tax-preparer', 'accounting', 'tir-admin'],
  },
  {
    id: nanoid(10),
    path: '/pw-change-required',
    exact: true,
    main: PostLoginPasswordChange,
    layout: FullScreenLayout,
    public: true,
    apps: ['none', 'tax', 'tax-preparer', 'accounting', 'tir-admin'],
  },
  {
    id: nanoid(10),
    path: '/app-select',
    exact: true,
    main: AppSelectionPage,
    layout: FullScreenLayout,
    public: true,
    apps: ['none', 'tax', 'tax-preparer', 'accounting', 'tir-admin'],
    // helpLabels: ['applications'],
  },
  {
    id: nanoid(10),
    path: '/user-settings',
    exact: true,
    main: UserProfilePage,
    layout: PrimaryLayout,
    LayoutProps: {
      hideSecondaryMenu: true,
      hideAccountMenu: true,
    },
    public: false,
    accountOptional: true,
    apps: ['tax', 'tax-preparer', 'accounting', 'tir-admin'],
    // helpLabels: ['profile', 'security', 'password-change', 'password'],
  },
  {
    id: nanoid(10),
    path: '/unauthorized',
    exact: true,
    main: NoAccessPage,
    layout: PrimaryLayout,
    LayoutProps: {
      hideAccountMenu: true,
      hideSecondaryMenu: true,
    },
    public: false,
    accountOptional: true,
    apps: ['none', 'tax', 'tax-preparer', 'accounting', 'tir-admin'],
  },
  // APP-CENTRIC SHARED ROUTES
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/:app(accounting|tax)')}/wallets/:walletId?`,
    exact: true,
    main: WalletPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax', 'accounting'],
    // helpLabels: ['wallet', 'file-import', 'manual-entry'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias(
      '/:app(accounting|tax)',
    )}/wallets/:walletId?/:subPage?`,
    exact: true,
    main: WalletPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax', 'accounting'],
    // helpLabels: ['wallet', 'file-import', 'manual-entry'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias(
      '/:app(accounting|tax)',
    )}/wallets/:walletId?/:subPage?/:importId?`,
    exact: true,
    main: WalletPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax', 'accounting'],
    // helpLabels: ['wallet', 'file-import', 'manual-entry'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/:app(accounting|tax)')}/sources`,
    exact: true,
    main: SourcesPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax', 'accounting'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/:app(accounting|tax)')}/sources/add`,
    exact: true,
    main: AddSourcesPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax', 'accounting'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/:app(accounting|tax)')}/exchanges/:exchangeId?`,
    exact: true,
    main: ExchangePage,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax', 'accounting'],
    // helpLabels: ['exchange', 'file-import', 'manual-entry'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias(
      '/:app(accounting|tax)',
    )}/exchanges/:exchangeId?/:subPage?`,
    exact: true,
    main: ExchangePage,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax', 'accounting'],
    // helpLabels: ['exchange', 'file-import', 'manual-entry'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias(
      '/:app(accounting|tax)',
    )}/exchanges/:exchangeId?/:subPage?/:importId?`,
    exact: true,
    main: ExchangePage,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax', 'accounting'],
    // helpLabels: ['exchange', 'file-import', 'manual-entry'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/:app(accounting|tax)')}/nfts`,
    exact: true,
    main: NftsPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax', 'accounting'],
    // helpLabels: ['nft',],
  },
  /* TAX APP AND SPECIFICALLY TAX PAYER ROUTES */
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/tax')}`,
    exact: true,
    main: TaxOverviewPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax'],
    // helpLabels: ['tax', 'tax-summary'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/:app(accounting|tax)')}/reports`,
    exact: true,
    main: ReportsPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax', 'accounting'],
    // helpLabels: ['tax', 'reports'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/tax')}/transactions`,
    exact: true,
    main: TaxTransactionsPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax'],
    // helpLabels: [
    //   'tax',
    //   'tx-table',
    //   'transfer-in',
    //   'transfer-out',
    //   'capital-gain',
    //   'capital-loss',
    // ],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/tax')}/settings`,
    exact: true,
    main: TaxPayerSettingsPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/tax')}/settings/:subPage(general|logs)`,
    exact: true,
    main: TaxPayerSettingsPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/tax')}/tax-complete`,
    exact: true,
    main: TaxCompletePage,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax'],
    // helpLabels: ['tax', 'tax-summary'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/tax')}/tax-planning`,
    exact: true,
    main: TaxPlanningPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax'],
    // helpLabels: ['tax', 'capital-gain', 'capital-loss'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/tax')}/tax-checkout`,
    exact: true,
    main: CheckoutPage,
    layout: PrimaryLayout,
    LayoutProps: {
      hideAccountMenu: true,
      hideSecondaryMenu: true,
    },
    accountOptional: true,
    public: false,
    apps: ['tax'],
    // helpLabels: ['tax', 'billing'],
  },
  /* tax app routes for preparers only */
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/tax-preparer')}`,
    exact: true,
    main: PreparerOverview,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax-preparer'],
    // helpLabels: ['tax-pro', 'tax-client-management'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/tax-preparer')}/team`,
    exact: true,
    main: PreparerTeam,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax-preparer'],
    // helpLabels: ['tax-pro', 'users'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/tax-preparer')}/reports`,
    exact: true,
    main: ReportsPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax-preparer'],
    // helpLabels: ['tax-pro', 'reports'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/tax-preparer')}/administration`,
    exact: true,
    main: PreparerSettings,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax-preparer'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias(
      '/tax-preparer',
    )}/administration/:subPage(general|logs)`,
    exact: true,
    main: PreparerSettings,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax-preparer'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/tax-preparer')}/disabled`,
    exact: true,
    main: TaxPreparerAccountDisabledPage,
    layout: PrimaryLayout,
    LayoutProps: {
      hideSecondaryMenu: true,
      hideAccountMenu: false,
    },
    public: false,
    apps: ['tax-preparer'],
  },
  /* ACCOUNTING-ONLY ROUTES */
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/accounting')}`,
    exact: true,
    main: AccountDashboard,
    layout: PrimaryLayout,
    public: false,
    apps: ['accounting'],
    // helpLabels: ['accounting', 'dashboard'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/accounting')}/disabled`,
    exact: true,
    main: AccountDisabledPage,
    layout: PrimaryLayout,
    LayoutProps: {
      hideSecondaryMenu: true,
      hideUpdateButton: true,
    },
    public: false,
    apps: ['accounting'],
    // helpLabels: ['accounting', 'billing'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/accounting')}/trial-expired`,
    exact: true,
    main: TrialEndedPage,
    layout: PrimaryLayout,
    LayoutProps: {
      hideSecondaryMenu: true,
      hideAccountMenu: false,
      hideUpdateButton: true,
    },
    public: false,
    apps: ['accounting'],
    // helpLabels: ['accounting', 'billing'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/accounting')}/integrations`,
    exact: true,
    main: IntegrationsPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['accounting'],
    // helpLabels: ['accounting', 'integrations', 'reconnect', 'disconnecting'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/accounting')}/integrations/:subPage(xero|qbo)`,
    exact: true,
    main: IntegrationPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['accounting'],
    // helpLabels: [
    //   'accounting',
    //   'integrations',
    //   'xero',
    //   'qbo',
    //   'journal',
    //   'sync',
    //   'reconnect',
    //   'disconnecting',
    // ],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/accounting')}/contacts`,
    exact: true,
    main: ContactsPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['accounting'],
    // helpLabels: ['accounting', 'contacts'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/accounting')}/transactions`,
    exact: true,
    main: TransactionsPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['accounting'],
    // helpLabels: ['accounting', 'tx-table'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/accounting')}/transactions/:viewId?`,
    exact: false,
    main: TransactionsPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['accounting'],
    // helpLabels: ['accounting', 'tx-table'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/accounting')}/monitoring`,
    exact: true,
    main: MonitoringPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['accounting'],
    // helpLabels: ['monitoring',],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/accounting')}/administration`,
    exact: true,
    main: AccountSettingsPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['accounting'],
    // helpLabels: ['accounting', 'settings', 'billing', 'users'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias(
      '/accounting',
    )}/administration/:subPage(general|users|usage|logs)`,
    exact: true,
    main: AccountSettingsPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['accounting'],
    // helplabels: ['accounting', 'settings', 'billing', 'users'],
  },
  /* TIR-ADMIN-ONLY ROUTES */
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/tir-admin')}`,
    exact: true,
    main: TirOverview,
    layout: PrimaryLayout,
    public: false,
    apps: ['tir-admin'],
    // helpLabels: ['tir-admin', 'tir-client-management'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/tir-admin')}/team`,
    exact: true,
    main: TirTeam,
    layout: PrimaryLayout,
    public: false,
    apps: ['tir-admin'],
    // helpLabels: ['tir-admin', 'team'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/tir-admin')}/files`,
    exact: true,
    main: TirFiles,
    layout: PrimaryLayout,
    public: false,
    apps: ['tir-admin'],
    // helpLabels: ['tir-admin', 'files'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/tir-admin')}/files/:subPage?`,
    exact: true,
    main: TirFiles,
    layout: PrimaryLayout,
    public: false,
    apps: ['tir-admin'],
    // helpLabels: ['tir-admin', 'files'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/tir-admin')}/administration`,
    exact: true,
    main: TirSettings,
    layout: PrimaryLayout,
    public: false,
    apps: ['tir-admin'],
    // helpLabels: ['tir-admin', 'administration'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/tir-admin')}/administration/:subPage(general|logs)`,
    exact: true,
    main: TirSettings,
    layout: PrimaryLayout,
    public: false,
    apps: ['tir-admin'],
    // helpLabels: ['tir-admin', 'administration'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/tir-admin/client/:accountId')}`,
    exact: true,
    main: TaxOverviewPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax'],
    // helpLabels: ['tax', 'tax-summary'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/tir-admin')}/client/:accountId/transactions`,
    exact: true,
    main: TaxTransactionsPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax'],
    // helpLabels: [
    //   'tax',
    //   'tx-table',
    //   'transfer-in',
    //   'transfer-out',
    //   'capital-gain',
    //   'capital-loss',
    // ],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias('/tir-admin')}/client/:accountId/settings`,
    exact: true,
    main: TaxPayerSettingsPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax'],
  },
  {
    id: nanoid(10),
    path: `${mapUrlAlias(
      '/tir-admin',
    )}/client/:accountId/settings/:subPage(general|logs)`,
    exact: true,
    main: TaxPayerSettingsPage,
    layout: PrimaryLayout,
    public: false,
    apps: ['tax'],
  },
];

export default generateRouteConfig;
