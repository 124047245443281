// absolute imports
import { RestLink } from 'apollo-link-rest';

/* Create Apollo Link to call non-grahpql routes via REST,
  * MUST come before terminating link (eg HTTP link)
  */
const restLink = new RestLink({
  uri: `${import.meta.env.REACT_APP_API_URL}/`,
  credentials: 'same-origin',
});

export default restLink;
