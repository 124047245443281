// absolute imports
import { useReactiveVar } from '@apollo/client';

// relative imports
import { creatingAccountVar } from '../../../data/apollo/cache/reactiveVars';
import { useConfig } from '../../common/providers/ConfigProvider';

// hook used to translate setupRequired flag to appropriate step flow ID for SelfSelect component
const useSetupFlow = (currentAccount = {}) => {
  const { accountConfig } = useConfig();
  const creatingAccount = useReactiveVar(creatingAccountVar);
  const { type, setupRequired } = currentAccount;
  let setupFlow = null;

  if (accountConfig.enableSetupFlow) {
    if (creatingAccount) {
      setupFlow = 'createAccountingAccount';
    }

    if (type === 'TaxPayer' && setupRequired) {
      setupFlow = 'taxAccountSetup';
    }
  }

  return setupFlow;
};

export default useSetupFlow;
