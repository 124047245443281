// absolute imports
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// icon imports
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';

// relative imports
import LedgibleButton from '../../../../../shared/custom-components/buttons/LedgibleButton';
import { useAccount } from '../../../../providers/AccountProvider';

const useStyles = makeStyles()((theme) => ({
  headerText: {
    margin: theme.spacing(2, 1),
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  selectField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  modalIcon: {
    margin: theme.spacing(1),
    fontSize: theme.typography.pxToRem(36),
    color: theme.palette.success.main,
  },
}));

const CompletedStep = ({ createdAccountId }) => {
  const { classes } = useStyles();
  const { currentAccount, accounts } = useAccount();
  const newAccount = useMemo(() => {
    if (createdAccountId) {
      return accounts.find((account) => account.id === createdAccountId);
    }
    return {};
  }, [createdAccountId, accounts]);

  return (
    <>
      <CardContent
        classes={{
          root: classes.cardContent,
        }}
      >
        <Typography
          className={classes.headerText}
          color="secondary"
          gutterBottom
          variant="h6"
          align="center"
          data-cy="account-create-complete-message"
        >
          Account Setup Complete!
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item className={classes.contentContainer}>
            <CheckCircleIcon className={classes.modalIcon} />
          </Grid>
        </Grid>
        <Typography color="textSecondary" gutterBottom variant="subtitle1">
          Click the button below to open the new account.
        </Typography>
        {currentAccount.id && (
          <Typography color="textSecondary" gutterBottom variant="subtitle1">
            If you would like to return to your current account, click Exit
            Account Setup.
          </Typography>
        )}
      </CardContent>
      <CardActions className={classes.actionRow} disableSpacing>
        <LedgibleButton
          variant="contained"
          size="large"
          color="success"
          id="new-acct-complete-confirm"
          disabled={!createdAccountId}
          textTransformOff
          type="submit"
        >
          Go To {newAccount?.name}
        </LedgibleButton>
      </CardActions>
    </>
  );
};

export default CompletedStep;

CompletedStep.propTypes = {
  createdAccountId: PropTypes.string,
};

CompletedStep.defaultProps = {
  createdAccountId: undefined,
};
