import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { darken } from '@mui/material/styles';
import { Button } from '@mui/material';

// relative imports
import { enableWhiteLabelStyling } from '../../utilities/styleUtilities';

const useStyles = makeStyles()(
  (theme, { textTransformOff, margin, ignoreWhiteLabel, disableHover }) => ({
    root: {
      whiteSpace: 'nowrap',
      alignItems: 'center',
      textTransform: textTransformOff ? 'none' : null,
      margin,
      borderRadius: enableWhiteLabelStyling({
        ignoreWhiteLabel,
        whiteLabelStyle: theme.whiteLabel.Button.borderRadius,
        defaultStyle: 4,
      }),
    },
    primaryContained: {
      color: theme.palette.common.white,
      '&:hover': {
        color: theme.palette.common.white,
      },
    },
    primaryOutlined: {
      color: enableWhiteLabelStyling({
        ignoreWhiteLabel,
        whiteLabelStyle: theme.whiteLabel.Button.textColor,
        defaultStyle: theme.palette.primary.main,
      }),
      border: enableWhiteLabelStyling({
        ignoreWhiteLabel,
        whiteLabelStyle: `${
          theme.whiteLabel.Button.borderWidth || '1px'
        } solid ${theme.whiteLabel.Button.borderColor}`,
        defaultStyle: `${theme.whiteLabel.Button.borderWidth || '1px'} solid ${
          theme.palette.primary.main
        }`,
      }),
      '&:hover': {
        color: enableWhiteLabelStyling({
          ignoreWhiteLabel,
          whiteLabelStyle: theme.whiteLabel.Button.textColor,
          defaultStyle: theme.palette.primary.main,
        }),
        border: enableWhiteLabelStyling({
          ignoreWhiteLabel,
          whiteLabelStyle: `${
            theme.whiteLabel.Button.borderWidth || '1px'
          } solid ${theme.whiteLabel.Button.borderColor}`,
          defaultStyle: `${
            theme.whiteLabel.Button.borderWidth || '1px'
          } solid ${theme.palette.primary.main}`,
        }),
      },
    },
    secondaryOutlined: {
      color: enableWhiteLabelStyling({
        ignoreWhiteLabel,
        whiteLabelStyle: theme.whiteLabel.Button.textColor,
        defaultStyle: theme.palette.secondary.main,
      }),
      border: enableWhiteLabelStyling({
        ignoreWhiteLabel,
        whiteLabelStyle: `${
          theme.whiteLabel.Button.borderWidth || '1px'
        } solid ${theme.whiteLabel.Button.borderColor}`,
        defaultStyle: `${theme.whiteLabel.Button.borderWidth || '1px'} solid ${
          theme.palette.secondary.main
        }`,
      }),
      '&:hover': {
        color: enableWhiteLabelStyling({
          ignoreWhiteLabel,
          whiteLabelStyle: theme.whiteLabel.Button.textColor,
          defaultStyle: theme.palette.secondary.main,
        }),
        border: enableWhiteLabelStyling({
          ignoreWhiteLabel,
          whiteLabelStyle: `${
            theme.whiteLabel.Button.borderWidth || '1px'
          } solid ${theme.whiteLabel.Button.borderColor}`,
          defaultStyle: `${
            theme.whiteLabel.Button.borderWidth || '1px'
          } solid ${theme.palette.secondary.main}`,
        }),
      },
    },
    primaryDarkContained: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: darken(
          theme.palette.primary.dark,
          theme.palette.action.hoverOpacity,
        ),
        color: theme.palette.common.white,
      },
    },
    primaryDarkOutlined: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.dark,
      '&:hover': {
        backgroundColor: darken(
          theme.palette.common.white,
          theme.palette.action.hoverOpacity,
        ),
        color: theme.palette.primary.dark,
      },
    },
    successDarkContained: {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: darken(
          theme.palette.primary.dark,
          theme.palette.action.hoverOpacity,
        ),
        color: theme.palette.common.white,
      },
    },
    secondaryContained: {
      backgroundColor: enableWhiteLabelStyling({
        ignoreWhiteLabel,
        whiteLabelStyle: theme.whiteLabel.Button.color,
        defaultStyle: 'default',
      }),
      color: theme.palette.common.white,
      '&:hover': {
        color: theme.palette.common.white,
        '&:hover': {
          color: theme.palette.common.white,
        },
      },
    },
    cancelText: {
      backgroundColor: 'transparent',
      color: 'rgb(241, 10, 10)',
      '&:hover': {
        backgroundColor: disableHover
          ? 'transparent'
          : 'rgba(241, 10, 10, .08)',
        color: 'rgb(241, 10, 10)',
      },
    },
    cancelContained: {
      backgroundColor: 'rgb(241, 10, 10)',
      color: theme.palette.common.white,
      '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: 'rgb(191, 8, 8)',
      },
    },
    cancelOutlined: {
      backgroundColor: 'transparent',
      color: 'rgb(241, 10, 10)',
      border: `${
        theme.whiteLabel.Button.borderWidth || '1px'
      }  solid rgba(241, 10, 10, .5)`,
      '&:hover': {
        backgroundColor: 'rgba(241, 10, 10, .08)',
        color: 'rgb(241, 10, 10)',
        border: `${
          theme.whiteLabel.Button.borderWidth || '1px'
        }  solid rgb(241, 10, 10)`,
      },
    },
    cancelDisabled: {
      borderColor: 'rgba(0, 0, 0, 0.26)',
    },
    successText: {
      backgroundColor: 'transparent',
      color: 'rgb(24, 165, 56)',
      '&:hover': {
        backgroundColor: 'rgba(24, 165, 56, .08)',
        color: 'rgb(24, 165, 56)',
      },
    },
    successContained: {
      backgroundColor: 'rgb(24, 165, 56)',
      color: theme.palette.common.white,
      '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: 'rgb(19, 134, 45)',
      },
    },
    successOutlined: {
      backgroundColor: 'transparent',
      color: 'rgb(24, 165, 56)',
      border: `${
        theme.whiteLabel.Button.borderWidth || '1px'
      }  solid rgba(24, 165, 56, .5)`,
      '&:hover': {
        backgroundColor: 'rgba(24, 165, 56, .08)',
        color: 'rgb(24, 165, 56)',
        border: `${
          theme.whiteLabel.Button.borderWidth || '1px'
        }  solid rgb(24, 165, 56)`,
      },
    },
    successDisabled: {
      borderColor: 'rgba(0, 0, 0, 0.26)',
    },
    whiteContained: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
      border: `2px solid ${theme.palette.secondary.main}`,
      '&:hover': {
        backgroundColor: '1px solid rgba(255, 255, 255, .8)',
        border: `2px solid ${theme.palette.secondary.main}`,
      },
    },
    whiteOutlined: {
      backgroundColor: 'transparent',
      color: theme.palette.common.white,
      border: `${
        theme.whiteLabel.Button.borderWidth || '1px'
      }  solid rgba(255, 255, 255, 1)`,
      '&:hover': {
        border: `${
          theme.whiteLabel.Button.borderWidth || '1px'
        }  solid rgba(255, 255, 255, 1)`,
      },
    },
    whiteText: {
      backgroundColor: 'transparent',
      color: theme.palette.common.white,
      '&:hover': {},
    },
  }),
);

const customColors = [
  'success',
  'successDark',
  'cancel',
  'white',
  'primaryDark',
];

const LedgibleButton = forwardRef((props, ref) => {
  const {
    buttonClasses,
    children,
    color,
    disableHover,
    id,
    marginLeft,
    marginTop,
    marginBottom,
    marginRight,
    onClick,
    textTransformOff,
    ignoreWhiteLabel,
    ...rest
  } = props;

  const margin = `${marginTop || 0}px ${marginRight || 0}px 
    ${marginBottom || 0}px ${marginLeft || 0}px`;

  const { classes, cx } = useStyles({
    disableHover,
    textTransformOff,
    margin,
    ignoreWhiteLabel,
  });

  return (
    <Button
      classes={{
        root: cx(classes.root, buttonClasses.root),
        contained: cx(classes.root, classes[`${color}Contained`]),
        text: cx(classes.root, classes[`${color}Text`]),
        outlined: cx(classes.root, classes[`${color}Outlined`]),
        disabled: cx(classes.root, classes[`${color}Disabled`]),
        ...buttonClasses,
      }}
      onClick={onClick}
      color={customColors.includes(color) ? 'primary' : color}
      id={id}
      ref={ref}
      data-cy={rest?.['data-cy'] || `cypress-${id}`}
      {...rest}
    >
      {children}
    </Button>
  );
});

LedgibleButton.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf([
    'primary',
    'primaryDark',
    'secondary',
    'cancel',
    'success',
    'successDark',
    'inherit',
    'default',
    'white',
  ]),
  marginTop: PropTypes.number,
  marginRight: PropTypes.number,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  disableHover: PropTypes.bool,
  buttonClasses: PropTypes.shape(),
  textTransformOff: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  ignoreWhiteLabel: PropTypes.bool,
};

LedgibleButton.defaultProps = {
  children: null,
  color: 'inherit',
  marginTop: 0,
  marginRight: 0,
  marginBottom: 0,
  marginLeft: 0,
  buttonClasses: {},
  textTransformOff: false,
  disableHover: false,
  onClick: () => null,
  title: '',
  ignoreWhiteLabel: false,
};

LedgibleButton.displayName = 'LedgibleButton';

export default LedgibleButton;
