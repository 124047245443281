// absolute imports
import { useReactiveVar } from '@apollo/client';
import { useMediaQuery } from '@mui/material';

// relative imports
import { browserCheckVar } from '../../../data/apollo/cache/reactiveVars';

// supposed to be used to help with displaying on different screen sizes but currently
// only applies to mobile
const useAlternativeDisplay = (mobileBreakpoint = 600) => {
  const isMobileScreenSize = useMediaQuery(`(max-width:${mobileBreakpoint}px)`);
  const { confirmedMobile } = useReactiveVar(browserCheckVar);
  const isMobile = confirmedMobile || isMobileScreenSize;

  return {
    isMobile,
  };
};

export default useAlternativeDisplay;