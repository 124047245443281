// absolute imports
import PropTypes from 'prop-types';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { enqueueSnackbar } from 'notistack';
import { Grid, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useMutation } from '@apollo/client';

// relative imports
import {
  LedgibleDialog,
  LedgibleDialogTitle,
  LedgibleDialogActions,
  LedgibleDialogContent,
  LedgibleDialogContentText,
} from '../../../shared/modals';
import LedgibleButton from '../../../shared/custom-components/buttons/LedgibleButton';
import useAlternativeDisplay from '../../../shared/custom-hooks/useAlternativeDisplay';
import { UPDATE_TIR_CONFIG } from '../../../../data/apollo/mutations';
import { useAccount } from '../../../common/providers/AccountProvider';

const useStyles = makeStyles()((theme) => ({
  noButtonDialogTitle: {
    paddingBottom: theme.spacing(2),
  },
  modalContentRow: {
    minHeight: '6rem',
  },
  textRoot: {
    marginBottom: theme.spacing(1),
  },
}));

const GenerateReportingFileModal = ({ taxYears, hideModal }) => {
  const { isMobile } = useAlternativeDisplay();
  const { classes } = useStyles({ isMobile });
  const [selectedReportType, setSelectedReportType] = useState('');
  const [selectedTaxYear, setSelectedTaxYear] = useState(taxYears[0]);
  const { currentAccount } = useAccount();

  const handleReportTypeSelectChange = (event) => {
    setSelectedReportType(event.target.value);
  };

  const handleTaxYearSelectChange = (event) => {
    setSelectedTaxYear(event.target.value);
  };

  const [updateTirConfig, { loading }] = useMutation(UPDATE_TIR_CONFIG, {
    onCompleted: () => {
      hideModal();
      enqueueSnackbar('Report generation request sent', {
        key: `tir-report-generate-request-${Date.now()}`,
        preventDuplicate: true,
        persist: false,
        autoHideDuration: 5000,
        variant: 'success',
      });
    },
  });

  const handleConfirm = async () => {
    if (selectedReportType === '' || selectedTaxYear === '') {
      return;
    }

    await updateTirConfig({
      variables: {
        accountId: currentAccount.id,
        tirConfig: {
          reportGenerating: true,
        },
      },
    });
  };

  return (
    <LedgibleDialog maxWidth="sm" fullWidth disableEscapeKeyDown>
      <LedgibleDialogTitle
        titleText="Generate Reporting File"
        classes={{
          root: classes.noButtonDialogTitle,
        }}
      />
      <LedgibleDialogContent dividers>
        <Grid
          container
          direction="row"
          gap={1}
          className={classes.modalContentRow}
        >
          <LedgibleDialogContentText className={classes.textRoot}>
            Select the tax year and type of report you want to generate:
          </LedgibleDialogContentText>
          <Grid item xs={12} sm={4} className={classes.textRoot}>
            <FormControl fullWidth>
              <InputLabel id="tir-tax-year-select-label" size="small">
                Tax Year
              </InputLabel>
              <Select
                labelId="tir-tax-year-select-label"
                id="tir-tax-year-select"
                name="tir-tax-year-select"
                value={selectedTaxYear}
                label="Tax Year"
                onChange={handleTaxYearSelectChange}
                size="small"
              >
                {taxYears.length > 0 &&
                  taxYears.map((taxYear) => (
                    <MenuItem key={taxYear} value={taxYear}>
                      {taxYear}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.textRoot}>
            <FormControl fullWidth>
              <InputLabel id="tir-type-select-label" size="small">
                Report
              </InputLabel>
              <Select
                labelId="tir-type-select-label"
                id="tir-type-select"
                name="tir-type-select"
                value={selectedReportType}
                label="Report"
                onChange={handleReportTypeSelectChange}
                size="small"
              >
                <MenuItem value="1099-DA">1099-DA</MenuItem>
                <MenuItem value="1099-MISC">1099-MISC</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <LedgibleDialogContentText className={classes.textRoot}>
            Once you confirm your selection, the report generation request will
            be forwarded to our team. We will send an email notification when
            the report is ready to download.
          </LedgibleDialogContentText>
        </Grid>
      </LedgibleDialogContent>
      <LedgibleDialogActions>
        <LedgibleButton
          size="small"
          color="cancel"
          id="tir-generate-first-step-cancel-button"
          onClick={hideModal}
        >
          Cancel
        </LedgibleButton>
        <LedgibleButton
          size="small"
          color="primary"
          id="tir-generate-first-step-ok-button"
          onClick={handleConfirm}
          disabled={!selectedReportType || !selectedTaxYear || loading}
        >
          Confirm
        </LedgibleButton>
      </LedgibleDialogActions>
    </LedgibleDialog>
  );
};

export default GenerateReportingFileModal;

GenerateReportingFileModal.propTypes = {
  taxYears: PropTypes.arrayOf(PropTypes.string).isRequired,
  hideModal: PropTypes.func.isRequired,
};
