// absolute imports
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Toolbar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// icon imports
import {
  ArrowLeft as LeftIcon,
  ArrowRight as RightIcon,
} from '@mui/icons-material';

// relative imports
import { useAuth0 } from '../../common/providers/Auth0Provider';
import { useAccount } from '../../common/providers/AccountProvider';
import { useAppManagement } from '../../common/providers/AppManagementProvider';
import { useModalActions } from '../../common/providers/ModalProvider';
import { getAppUrlPathFromId } from '../utilities/appUtilities';
import LedgibleButton from '../custom-components/buttons/LedgibleButton';
import useAlternativeDisplay from '../custom-hooks/useAlternativeDisplay';

const useStyles = makeStyles()((theme) => ({
  preparerBar: {
    height: 40,
    minHeight: '0px !important',
    backgroundColor: 'green',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    color: theme.palette.common.white,
    boxSizing: 'content-box',
    transition: 'all 0.3s ease-in-out',
  },
  preparerBarText: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 16,
    },
  },
  preparerBarIcon: {
    marginRight: 8,
    width: 18,
  },
  preparerBarName: {
    fontWeight: theme.typography.fontWeightMedium,
    paddingLeft: 4,
  },
  backButton: {
    color: '#fff',
    borderColor: '#fff',
    backgroundColor: 'transparent',
    padding: theme.spacing(0.15, 1),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    position: 'absolute',
    right: 8,

    '&:hover': {
      color: '#fff',
      backgroundColor: 'transparent',
      borderColor: '#fff',
    },
  },
  preparerModalTrigger: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ManageClientsButton = ({ btnClass, goToManageClients }) => (
  <LedgibleButton
    onClick={goToManageClients}
    onFocusVisible={(e) => e.stopPropagation()}
    color="primary"
    variant="outlined"
    buttonClasses={{
      outlinedPrimary: btnClass,
    }}
    size="small"
    textTransformOff
    startIcon={<LeftIcon fontSize="small" />}
    id="header-preparer-manage-clients-button"
  >
    Client Dashboard
  </LedgibleButton>
);

const PreparerBar = () => {
  const { classes, cx } = useStyles();
  const { setApplication } = useAppManagement();
  const { user } = useAuth0();
  const { selectAccount, currentAccount } = useAccount();
  const { permittedApplications } = useAuth0();
  const { isMobile } = useAlternativeDisplay();
  const { showModal } = useModalActions();

  const goToManageClients = useCallback(() => {
    const { accountPermissions } = user;
    const preparerPermission = accountPermissions.find(
      ({ type }) => type === 'TaxPreparer',
    );

    if (permittedApplications.includes('tax-preparer')) {
      setApplication('tax-preparer', `/${getAppUrlPathFromId('tax-preparer')}`);
    } else if (permittedApplications.includes('tir-admin')) {
      setApplication('tir-admin', `/${getAppUrlPathFromId('tir-admin')}`);
    }
    selectAccount(preparerPermission.accountId);
  }, [permittedApplications, selectAccount, setApplication, user]);

  ManageClientsButton.propTypes = {
    btnClass: PropTypes.string,
  };

  ManageClientsButton.defaultProps = {
    btnClass: '',
  };

  const showPreparerModal = () => {
    showModal('PREPARER_MODAL', { ManageClientsButton });
  };

  return (
    <Toolbar className={cx(classes.preparerBar, 'beamerSpacingPreparerBar')}>
      {!isMobile ? (
        <>
          <div className={classes.preparerBarText}>
            Viewing client account{' '}
            <strong className={classes.preparerBarName}>
              {currentAccount.name}
            </strong>
          </div>
          <ManageClientsButton
            btnClass={classes.backButton}
            goToManageClients={goToManageClients}
          />
        </>
      ) : (
        <div
          className={classes.preparerModalTrigger}
          onClick={showPreparerModal}
          onKeyDown={showPreparerModal}
          role="button"
          tabIndex={-1}
        >
          Viewing Client
          <RightIcon />
        </div>
      )}
    </Toolbar>
  );
};

export default PreparerBar;

ManageClientsButton.propTypes = {
  goToManageClients: PropTypes.func.isRequired,
};
