import { makeVar } from '@apollo/client';
import makePersistentVar from './utilities/makePersistentVar';
import { accountingTableStateDefault } from './defaultValues';
import { getAppConfig } from '../../app-config/configs/configUtilities';

/**
 * Reactive Vars (for purpose of local, global State) ->
 * use makeVar for non-persistant, use makeVarPersistent to persist in
 * local storage with session/local designation
 */

export const numberOfTIRClients = makeVar(236);

// global reference for currently active appType
export const appTypeVar = makePersistentVar(
  `${getAppConfig().staticAppType || 'none'}`,
  'appType',
  'local',
);
// tracks an app requested by the user but not yet switched to
export const requestedAppTypeVar = makePersistentVar(null, 'requestedApp');

// reference for custom configs on certain screens (populated downstream from Auth0, in places where config may be unavailable)
export const customLogoUrlVar = makeVar(null);

// for account persistance on referesh/app-switching
export const persistedAccountIdsVar = makePersistentVar(
  { accounting: null, tax: null, 'tax-preparer': null },
  'persistedAccountIds',
  'local',
);

// persisted reference for the currently selected account, for global reference
export const currentAccountIdVar = makePersistentVar(null, 'currentAccountId');

// global reference for the timezone associated with the currently active account
export const currentAccountTimezoneVar = makeVar(null);

// indicates if the user is using the help widget or not
export const helpWidgetActiveVar = makeVar(false);

// global reference for application error(s)
export const errorVar = makeVar(null);

// global reference for check performed on login for browser compatibility
export const browserCheckVar = makePersistentVar(
  {
    confirmedBadBrowser: false,
    confirmedBadOS: false,
    currentBrowser: null,
    currentOS: null,
    confirmedMobile: false,
  },
  'browserCheck',
);

// global var for reference to current (in-memory only) accessToken
export const accessTokenVar = makeVar(null);

// variable to track user preference for hiding/showing global help widget
export const showHelpWidgetVar = makePersistentVar(true, 'showHelpWidget');

// Common object for persisting table page sizes
export const tableStateVar = makePersistentVar({}, `tableState`);

// used to make app aware that account generation is in progress
export const creatingAccountVar = makeVar(false);

export const dialogStateVar = makeVar({
  pristine: true,
  confirmExit: false,
});

// Accounting transaction table state management
export const accountingTableStateVar = makePersistentVar(
  accountingTableStateDefault,
  'accountingTableState',
);

// global var for current tax year of the account
export const currentAccountTaxYearVar = makePersistentVar(
  null,
  'currentAccountTaxYearVar',
);

// manages the Tax tx table state variables
export const costBasisAssignmentTableStateVar = makePersistentVar(
  {
    default: {
      sortColumn: 'date',
      sortOrder: 'desc',
      pageSize: 5,
    },
  },
  'costBasisAssignmentTableState',
);

// For Form State persistence (only 1 form's values can be saved at a time)
export const formStateVar = makePersistentVar({}, 'formState');

export const queuedTxTableFilterUpdateVar = makeVar(null);
export const isTaxPreparerVar = makeVar(false);
export const switchingApplicationsVar = makeVar(false);
