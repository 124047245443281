// absolute imports
import { useMemo, useContext, createContext, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useQuery, useMutation } from '@apollo/client';
import {
  PAYMENT_SOURCE_QUERY,
  CUSTOMER_SUBSCRIPTION,
  PLANS,
} from '../../../data/apollo/queries';

// relative imports
import { useAccount } from './AccountProvider';
import { useAppManagement } from './AppManagementProvider';
import {
  handleOneTimeCheckout,
  handlePlanCheckoutSession,
  handlePlanUpgradeSession,
  handlePortalOpenSection,
} from '../chargebee/chargebeeClient';
import { useAuth0 } from './Auth0Provider';
import {
  CUSTOMER_PORTAL_SESSION,
  CUSTOMER_CHECKOUT_SESSION,
  CUSTOMER_ONE_TIME_CHECKOUT,
  CUSTOMER_UPGRADE_SESSION,
} from '../../../data/apollo/mutations';

// helper functions
export function getRemainingWallets(usageWallets, plan, addons) {
  const realUsage = usageWallets;
  const addonWallets = addons && addons.extraWallets ? addons.extraWallets : 0;
  const validWallets = addonWallets + plan.numberOfWallets;
  return validWallets - realUsage;
}

export function getRemainingExchanges(usageExchanges, plan, addons) {
  const realUsage = usageExchanges;
  const addonExchanges =
    addons && addons.extraExchanges ? addons.extraExchanges : 0;
  const validExchanges = addonExchanges + plan.numberOfExchangeAccounts;
  return validExchanges - realUsage;
}

// create context for provider
export const BillingContext = createContext();
export const BillingActionsContext = createContext();
export const useBilling = () => useContext(BillingContext);
export const useBillingActions = () => useContext(BillingActionsContext);

export const BillingProvider = ({ children }) => {
  const { user, loadingAuth } = useAuth0();
  const { appType, loadingApp } = useAppManagement();
  const { currentAccount, clientPermissions: permissions } = useAccount();
  const {
    id: accountId,
    preparerLink,
    plan,
    disabled = false,
    usage,
  } = currentAccount;

  const { data: { getPlans: plans = [] } = {}, loading: loadingPlans } =
    useQuery(PLANS, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: loadingAuth || !user || loadingApp,
    });

  const [makeCustomerPortal] = useMutation(CUSTOMER_PORTAL_SESSION);

  // mutations, functions should be memoized by apollo client
  const [makeCheckoutPortal] = useMutation(CUSTOMER_CHECKOUT_SESSION);
  const [makeUpgradePortal] = useMutation(CUSTOMER_UPGRADE_SESSION);
  const [makeOneTimeCheckout] = useMutation(CUSTOMER_ONE_TIME_CHECKOUT);

  // queries
  const {
    data: { getChargeBeePaymentSource: paymentSource } = {},
    loading: loadingPaymentSource,
    refetch: refetchPaymentSource, // should be memoized by apollo client
  } = useQuery(PAYMENT_SOURCE_QUERY, {
    variables: {
      accountId,
    },
    fetchPolicy: 'network-only',
    // skip for non-direct due to acct owner being used for payment source
    skip:
      !accountId ||
      permissions.isPreparerViewingClient ||
      permissions.isTirAdminViewingClient ||
      disabled ||
      (preparerLink?.clientActive &&
        preparerLink?.billingEntity === 'preparer') ||
      (appType && appType === 'tax-preparer'),
  });

  const {
    data: { getChargeBeeSubscription: currentSubscription } = {},
    loading: loadingSubscription,
    refetch: refetchSubscription,
  } = useQuery(CUSTOMER_SUBSCRIPTION, {
    variables: {
      accountId,
    },
    fetchPolicy: 'network-only',
    skip: !accountId,
  });

  const openChargebeePortal = useCallback(
    (portalType, ...args) => {
      const portalTypes = {
        openSection: {
          openPortal: handlePortalOpenSection,
          baseArgs: [makeCustomerPortal, accountId],
        },
        planCheckout: {
          openPortal: handlePlanCheckoutSession,
          baseArgs: [makeCheckoutPortal],
        },
        planUpgrade: {
          openPortal: handlePlanUpgradeSession,
          baseArgs: [makeUpgradePortal, accountId],
        },
        oneTimeCheckout: {
          openPortal: handleOneTimeCheckout,
          baseArgs: [makeOneTimeCheckout],
        },
      };
      const { openPortal, baseArgs } = portalTypes[portalType];
      openPortal(...baseArgs, ...args);
    },
    [
      accountId,
      makeCheckoutPortal,
      makeCustomerPortal,
      makeOneTimeCheckout,
      makeUpgradePortal,
    ],
  );

  const loadingBilling = useMemo(
    () => loadingPlans || loadingPaymentSource || loadingSubscription,
    [loadingPlans, loadingPaymentSource, loadingSubscription],
  );

  const accountingBilling = useMemo(() => {
    if (appType === 'accounting' && usage) {
      const hasRemainingExchanges =
        getRemainingExchanges(usage.numberOfExchangeAccounts, plan) > 0;
      const hasRemainingWallets =
        getRemainingWallets(usage.numberOfWallets, plan) > 0;

      return {
        hasRemainingExchanges,
        hasRemainingWallets,
      };
    }
    return {};
  }, [appType, usage, plan]);

  const billingValue = useMemo(
    () => ({
      plans: plans || [],
      loadingBilling,
      paymentSource: paymentSource || {},
      currentSubscription,
      accountingBilling,
    }),
    [
      plans,
      loadingBilling,
      paymentSource,
      currentSubscription,
      accountingBilling,
    ],
  );

  const billingActionsValue = useMemo(
    () => ({
      openChargebeePortal,
      refetchPaymentSource,
      refetchSubscription,
    }),
    [openChargebeePortal, refetchPaymentSource, refetchSubscription],
  );

  return (
    <BillingContext.Provider value={billingValue}>
      <BillingActionsContext.Provider value={billingActionsValue}>
        {children}
      </BillingActionsContext.Provider>
    </BillingContext.Provider>
  );
};

export const BillingConsumer = BillingContext.Consumer;

BillingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
