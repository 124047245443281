// absolute imports
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardMedia,
  Grid,
  LinearProgress,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// relative imports
import LedgibleButton from '../../../../shared/custom-components/buttons/LedgibleButton';
import { getAppLogoSrc } from '../../../../shared/utilities/appUtilities';
import { useAccount } from '../../../providers/AccountProvider';
import { useAppManagement } from '../../../providers/AppManagementProvider';
import { creatingAccountVar } from '../../../../../data/apollo/cache/reactiveVars';

const useStyles = makeStyles()((theme) => ({
  mainGrid: {
    height: '100%',
  },
  logoImg: {
    margin: theme.spacing(0, 'auto', 2),
    width: 220,
  },
  stepCard: {
    maxWidth: 600,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 8,
  },
  progressRoot: {
    height: 8,
    borderRadius: 5,
    backgroundColor:
      theme.whiteLabel.IndicatorRoot.color || theme.palette.secondary.main,
  },
  progressBar: {
    borderRadius: 5,
    backgroundColor:
      theme.whiteLabel.Indicator.color || theme.palette.secondary.main,
  },
  textButton: {
    padding: 1,
    fontSize: theme.typography.body1,
    color: theme.whiteLabel.Navigation.color || theme.palette.common.white,
  },
}));

const CreateAccountTemplate = ({ progress, children }) => {
  const { classes } = useStyles();
  const { appType } = useAppManagement();
  const { currentAccount, accounts } = useAccount();
  const displayOfframp =
    !!currentAccount.id && !(accounts?.length === 1 && progress === 100);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.mainGrid}
      spacing={4}
      wrap="nowrap"
    >
      <Grid item>
        <CardMedia
          component="img"
          alt="Ledgible Tax Logo"
          image={getAppLogoSrc(appType, 'dark')}
          title="Ledgible Tax Logo"
          className={classes.logoImg}
        />
      </Grid>
      <Grid item>
        <Card className={classes.stepCard} raised>
          <Box width="60%">
            <LinearProgress
              variant="determinate"
              value={progress}
              classes={{
                root: classes.progressRoot,
                bar: classes.progressBar,
              }}
            />
          </Box>
          {children}
        </Card>
      </Grid>
      {displayOfframp && (
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <LedgibleButton
            variant="text"
            size="medium"
            color="white"
            textTransformOff
            onClick={() => creatingAccountVar(false)}
            id="account-create-setup-flow-step-exit"
            marginLeft={8}
            className={classes.textButton}
          >
            Exit Account Setup
          </LedgibleButton>
        </Grid>
      )}
    </Grid>
  );
};

export default CreateAccountTemplate;

CreateAccountTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.shape()]).isRequired,
  progress: PropTypes.number.isRequired,
};
