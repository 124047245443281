// absolute imports
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

// relative imports
import { getAppIdFromUrl } from '../shared/utilities/appUtilities';
import DefaultErrorFallback from './fallback-components/DefaultErrorFallback';
import ModalErrorFallback from './fallback-components/ModalErrorFallback';
import PageErrorFallback from './fallback-components/PageErrorFallback';
import RowErrorFallback from './fallback-components/RowErrorFallback';
import TableErrorFallback from './fallback-components/TableErrorFallback';
import { useAuth0 } from '../common/providers/Auth0Provider';
import { useAccount } from '../common/providers/AccountProvider';
import { useAppManagement } from '../common/providers/AppManagementProvider';

const getFallbackComponent = (fallbackType) => {
  switch (fallbackType) {
    // render an error modal display with passed error details
    case 'modal':
      return ModalErrorFallback;
    // render placeholder page for top level errors within pages
    case 'page':
      return PageErrorFallback;
    // render an error table row
    case 'row':
      return RowErrorFallback;
    // render an error display for a table component
    case 'table':
      return TableErrorFallback;
    default:
      return DefaultErrorFallback;
  }
};

const ErrorBoundary = ({
  children,
  fallbackType,
  fallbackProps,
  onReset,
  errorMsg,
  context,
}) => {
  const { user } = useAuth0() || {};
  const { currentAccount } = useAccount() || {};
  const { appType } = useAppManagement() || {};
  const Fallback = getFallbackComponent(fallbackType);
  const appId = appType === 'none' ? getAppIdFromUrl() : appType;

  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setUser({
          id: user?.id || 'unavailable',
        });
        scope.setTransactionName(appId || 'unavailable');
        scope.setTag('accountId', currentAccount?.id || 'unavailable');
        scope.setTag('pathname', window.location.pathname);
        scope.setTag('userAgent', navigator.userAgent);
        scope.setTag('context', JSON.stringify(context));
      }}
      fallback={({ resetError }) => (
        <Fallback
          errorMsg={errorMsg}
          resetError={() => {
            resetError();
            if (onReset) {
              onReset();
            }
          }}
          fallbackProps={fallbackProps}
        />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  fallbackType: PropTypes.oneOf(['modal', 'page', 'row', 'table', 'default']),
  fallbackProps: PropTypes.shape(),
  errorMsg: PropTypes.string,
  context: PropTypes.shape(),
  onReset: PropTypes.func,
};

ErrorBoundary.defaultProps = {
  fallbackProps: {},
  fallbackType: 'default',
  errorMsg: undefined,
  context: {},
  onReset: undefined,
};
