// absolute imports
import { isFunction, isPlainObject } from 'lodash';

// relative imports
import { currentAccountIdVar } from '../../../data/apollo/cache/reactiveVars';

const ObjectPropertyType = {
  Generic: 'generic',
  Function: 'function',
  Object: 'object',
};

const serializeStateFunction = (func) => func.toString();

const deserializeStateFunction = (func) =>
  // eslint-disable-next-line no-new-func
  new Function(`return ${func}`)();

export const serializeStateObject = (obj) => {
  if (!isPlainObject(obj)) {
    return obj;
  }
  return Object.keys(obj).reduce((result, key) => {
    const value = obj[key];
    if (isFunction(value)) {
      result[key] = {
        type: ObjectPropertyType.Function,
        value: serializeStateFunction(() => value()),
      };
    } else if (isPlainObject(value)) {
      result[key] = {
        type: ObjectPropertyType.Object,
        value: serializeStateObject(value),
      };
    } else {
      result[key] = {
        type: ObjectPropertyType.Generic,
        value,
      };
    }
    return result;
  }, {});
};

export const deserializeStateObject = (obj) => {
  if (!isPlainObject(obj)) {
    return obj;
  }
  return Object.keys(obj).reduce((result, key) => {
    const { type, value } = obj[key];
    if (type === ObjectPropertyType.Function) {
      result[key] = deserializeStateFunction(value);
    } else if (type === ObjectPropertyType.Object) {
      result[key] = deserializeStateObject(value);
    } else {
      result[key] = value;
    }
    return result;
  }, {});
};

/** Returns unique table ID to match required tableId prop type based on tableName, accountId and any additional taxonomy
 * @param {String} tableName - a name for the table being displayed, must end with 'Table'
 * @param {Array.<String | Number>} secondaryTaxonomy - array of terms to append to tableId to enhance specificity, values must be primatives of type string or number
 */
export const getTableStateId = (tableName, secondaryTaxonomy = []) => {
  if (!tableName.endsWith('Table')) {
    throw new Error('tableName argument must end with \'Table\'');
  }

  let tableId = `${tableName}-${currentAccountIdVar()}`;

  try {
    if (secondaryTaxonomy.length && Array.isArray(secondaryTaxonomy)) {
      secondaryTaxonomy.forEach((term) => {
        if (typeof term === 'string' || typeof term === 'number') {
          tableId += `-${term}`;
        } else {
          throw new Error('Invalid value for tableId taxonomy. Must be string or number');
        }
      });
    }
    return tableId;
  } catch(e) {
    throw new Error(`Error generating table ID: ${e.message}`);
  }
}
