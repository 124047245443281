// absolute imports
import { useContext, useMemo, createContext } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';

// relative imports
import { useAccount } from './AccountProvider';
import { useAuth0 } from './Auth0Provider';
import { useAppManagement } from './AppManagementProvider';
import {
  EXCHANGES_QUERY,
  EXCHANGE_QUERY,
  EXCHANGES_LIST_QUERY,
} from '../../../data/apollo/queries';
import useUpdatePolling from '../../shared/custom-hooks/useUpdatePolling';
import { getDate } from '../../shared/utilities/dateUtilities';

export const ExchangeContext = createContext();
export const useExchange = () => useContext(ExchangeContext);

// MAIN COMPONENT
export const ExchangeProvider = ({ children }) => {
  const { appType, displayAppType } = useAppManagement();
  const match = useRouteMatch(`/${displayAppType}/exchanges/:exchangeId`);
  const params = match ? match.params : {};

  const { isAuthenticated } = useAuth0();
  const { currentAccount } = useAccount();

  const {
    data: { eSources: exchanges = [] } = {},
    loading: loadingExchanges,
    refetch: refetchExchanges,
    networkStatus: exchangesNetworkStatus,
  } = useQuery(EXCHANGES_QUERY, {
    variables: {
      accountId: currentAccount.id,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip:
      !currentAccount.id ||
      appType === 'tax-preparer' ||
      currentAccount?.disabled,
  });

  const exchangesUpdating = exchanges?.some((exchange) => exchange.updating);

  // Reference var for page requests with invalid exchange id params
  const exchangeUrlInvalid = useMemo(() => {
    if (
      params.exchangeId &&
      exchanges?.length > 0 &&
      !exchanges.map(({ id }) => id).includes(params.exchangeId)
    ) {
      return true;
    }
    return false;
  }, [params.exchangeId, exchanges]);

  const {
    data: { eSource: currentExchange = {} } = {},
    networkStatus: exchangeNetworkStatus,
    refetch: refetchExchange,
  } = useQuery(EXCHANGE_QUERY, {
    variables: {
      accountId: currentAccount.id,
      eSourceId: params.exchangeId, // must have exchange ID provided from URL
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip:
      !currentAccount.id ||
      !params.exchangeId ||
      exchanges?.length === 0 ||
      exchangeUrlInvalid,
  });

  // manage updating query for single exchange
  useUpdatePolling({
    preset: 'exchange',
    updateCondition: params.exchangeId && currentExchange?.updating,
    disabled: !params.exchangeId,
    variables: {
      eSourceId: currentExchange?.id,
    },
    onUpdateStart: () => {
      enqueueSnackbar(`${currentExchange?.nickname} update in progress`, {
        key: `${currentExchange?.id}-started`,
        preventDuplicate: true,
        autoHideDuration: 10000,
        variant: 'info',
      });
    },
    onUpdateComplete: () => {
      refetchExchange();
      enqueueSnackbar(`${currentExchange?.nickname} update complete`, {
        key: `${currentExchange?.id}-finished`,
        preventDuplicate: true,
        autoHideDuration: 3000,
        variant: 'success',
      });
    },
  });

  useUpdatePolling({
    preset: 'exchanges',
    updateCondition: exchangesUpdating && !params.exchangeId,
    disabled: !!params.exchangeId,
    onUpdateComplete: () => {
      const hasRecentlyUpdated = exchanges.some(({ lastUpdated }) => {
        const minutesSinceUpdated = getDate(new Date(lastUpdated), {
          parseMethod: 'JSDate',
        }).diffNow('minutes').minutes;

        return minutesSinceUpdated > -10;
      });

      if (hasRecentlyUpdated) {
        refetchExchanges();
        enqueueSnackbar('Exchange updates complete', {
          key: 'all-exchanges-update-status',
          preventDuplicate: true,
          autoHideDuration: 3000,
          variant: 'success',
        });
      }
    },
  });

  const {
    data: { eSourceList: exchangeList = [] } = {},
    loading: loadingExchangeList,
  } = useQuery(EXCHANGES_LIST_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !isAuthenticated || appType === 'none' || !currentAccount.id,
  });

  const loadingExchange =
    [1, 4].includes(exchangeNetworkStatus) && !currentExchange?.id;

  const exchangeProviderValue = useMemo(
    () => ({
      currentExchange: currentExchange || {},
      exchanges: exchanges || [],
      exchangeList: exchangeList || [],
      loadingExchange,
      exchangeNetworkStatus,
      loadingExchanges,
      exchangesNetworkStatus,
      loadingExchangeList,
      exchangeUrlInvalid,
      exchangesUpdating,
      refetchExchange,
      refetchExchanges,
    }),
    [
      currentExchange,
      exchangeList,
      exchangeNetworkStatus,
      exchangeUrlInvalid,
      exchanges,
      exchangesNetworkStatus,
      loadingExchange,
      loadingExchangeList,
      loadingExchanges,
      refetchExchange,
      refetchExchanges,
      exchangesUpdating,
    ],
  );

  return (
    <ExchangeContext.Provider value={exchangeProviderValue}>
      {children}
    </ExchangeContext.Provider>
  );
};

export const ExchangeConsumer = ExchangeContext.Consumer;

ExchangeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
