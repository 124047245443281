// absolute imports
import { useMemo, useCallback, Suspense } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

// relative imports
import MODALS from './ModalLibrary';
import ErrorBoundary from '../../error-handling/ErrorBoundary';
import { useAuth0 } from '../../common/providers/Auth0Provider';
import { useAppManagement } from '../../common/providers/AppManagementProvider';
import {
  useModal,
  useModalActions,
} from '../../common/providers/ModalProvider';
import useDialogState from '../custom-hooks/useDialogState';

const ModalFallback = () => (
  <Backdrop
    open
    sx={{
      zIndex: (theme) => theme.zIndex.drawer + 1,
      color: '#fff',
    }}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
);

const ModalContainer = () => {
  const { modals } = useModal();
  const { hideModal, showModal } = useModalActions();
  const { loadingAuth } = useAuth0();
  const { loadingApp } = useAppManagement();
  const { dialogState, updateDialogState, resetDialogState } = useDialogState();

  const closeModalWindow = useCallback(
    (uuid, skipConfirmation) => {
      if (dialogState.pristine || skipConfirmation) {
        resetDialogState();
        hideModal(uuid, skipConfirmation);
      } else {
        updateDialogState('confirmExit', true);
      }
    },
    [dialogState, hideModal, updateDialogState, resetDialogState],
  );

  const modalList = useMemo(
    () =>
      modals.map(({ key, props, uuid }) => {
        const ModalComponent = MODALS[key];

        return (
          <Suspense key={uuid} fallback={<ModalFallback />}>
            <ModalComponent
              hideModal={(skipConfirmation) => closeModalWindow(uuid, skipConfirmation === true)}
              modalKey={key}
              showModal={showModal}
              {...props}
            />
          </Suspense>
        );
      }),
    [modals, closeModalWindow, showModal],
  );

  if (loadingAuth || loadingApp) {
    return null;
  }

  return (
    <ErrorBoundary
      context={{
        component: ModalContainer.displayName,
      }}
    >
      {modalList}
    </ErrorBoundary>
  );
};

ModalContainer.displayName = 'ModalContainer';
export default ModalContainer;
