// absolute imports
import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Box, CardActions, CardContent, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';

// icon imports
import {
  ArrowRightRounded as ArrowRightIcon,
  ArrowLeftRounded as ArrowLeftIcon,
  CheckCircle as CheckCircleIcon
} from '@mui/icons-material';

// relative imports
import LedgibleButton from '../../../../../shared/custom-components/buttons/LedgibleButton';
import { UPDATE_ACCOUNT } from '../../../../../../data/apollo/mutations';
import { useAccount } from '../../../../providers/AccountProvider';
import Loader from '../../../../../shared/custom-components/Loader';

const useStyles = makeStyles()((theme) => ({
  headerText: {
    marginBottom: theme.spacing(2),
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  selectField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  modalIcon: {
    margin: theme.spacing(2),
    fontSize: theme.typography.pxToRem(40),
  },
}));

const CompletedStep = ({ handlePrevious }) => {
  const { classes } = useStyles();
  const { currentAccount, refetchCurrentAccount } = useAccount();
  const [exiting, setExiting] = useState(false);

  const [completeSetup] = useMutation(UPDATE_ACCOUNT, {
    variables: {
      accountId: currentAccount.id,
      updateData: {
        setupRequired: false,
      },
    },
    onCompleted() {
      refetchCurrentAccount();
    },
  });

  const handleExit = () => {
    setExiting(true);
    completeSetup();
  };

  return (
    <>
      {exiting && (
        // overlay loader for table portion of card, less standard table footer height
        <Box
          position="absolute"
          top="0"
          left="0"
          height="100%"
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          bgcolor="rgba(235,235,235,0.3)"
          borderRadius="4px"
        >
          <Loader />
        </Box>
      )}
      <CardContent>
        <Typography
          className={classes.headerText}
          color="secondary"
          gutterBottom
          variant="h6"
          align="center"
        >
          Setup Complete!
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          color="success.dark"
        >
          <CheckCircleIcon className={classes.modalIcon} />
        </Box>
        <Typography color="textSecondary" gutterBottom variant="subtitle1">
          Note that you can update your settings at any time under your
          account&apos;s Settings page.
        </Typography>
      </CardContent>
      <CardActions className={classes.actionRow}>
        <LedgibleButton
          variant="outlined"
          size="large"
          color="secondary"
          textTransformOff
          onClick={handlePrevious}
          startIcon={<ArrowLeftIcon />}
          id="tax-setup-flow-year-step-back"
        >
          Go Back
        </LedgibleButton>
        <LedgibleButton
          variant="contained"
          size="large"
          color="success"
          textTransformOff
          onClick={handleExit}
          endIcon={<ArrowRightIcon />}
          id="tax-setup-flow-year-step-continue"
        >
          Go To App
        </LedgibleButton>
      </CardActions>
    </>
  );
};

export default CompletedStep;

CompletedStep.propTypes = {
  handlePrevious: PropTypes.func.isRequired,
};
