import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';

// import local modules
import { checkBrowser } from '../../../data/apollo/cache/operations';
import { browserCheckVar } from '../../../data/apollo/cache/reactiveVars';
import { useModalActions } from '../../common/providers/ModalProvider';
import { useAuth0 } from '../../common/providers/Auth0Provider';

// options come from 'detect-browser' library
const acceptedBrowsers = ['chrome', 'firefox', 'edge', 'brave', 'safari'];
const mobileBrowsers = ['android', 'edge-ios', 'ios', 'ios-webview', 'chromium-webview', 'samsung', 'fxios', 'crios', 'bb10', 'facebook', 'instagram', 'silk', 'miui'];
const unsupportedOSes = ['BlackBerry OS', 'Windows Mobile', 'Amazon OS'];

const BrowserCheck = () => {
  const {
    confirmedBadBrowser,
    currentBrowser,
    OSConfirmed,
    currentOS,
  } = useReactiveVar(browserCheckVar);

  const { showModal } = useModalActions();
  const { loadingAuth, isAuthenticated } = useAuth0();
  const isMobile = mobileBrowsers.includes(currentBrowser) || unsupportedOSes.includes(currentOS);
  const isAccepted = acceptedBrowsers.includes(currentBrowser);

  useEffect(() => {
    if (loadingAuth || !isAuthenticated || (currentBrowser && isAccepted && !isMobile)) return;

    // otherwise
    if (!currentBrowser && !confirmedBadBrowser) {
      checkBrowser();
    }
    if (currentBrowser && !confirmedBadBrowser && !isAccepted && !isMobile) {
      showModal('BROWSER_UNSUPPORTED', {
        currentBrowser,
        noHash: true, // disable modal close with back button
      });
    }
  }, [
    confirmedBadBrowser,
    showModal,
    currentBrowser,
    currentOS,
    OSConfirmed,
    isAccepted,
    isMobile,
    loadingAuth,
    isAuthenticated,
  ]);

  return null;
};

export default BrowserCheck;
