// absolute imports
import {
  forwardRef,
  cloneElement,
  createContext,
  useContext,
  useRef,
  useEffect,
  Children,
  isValidElement,
} from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import { Autocomplete, ListSubheader, TextField, useMediaQuery } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';

// relative imports
import PopperVirtualized from './LedgiblePopper';

// setup sub-component for virtualizing options list if needed
const LISTBOX_PADDING = 8; // px

function VirtualizedRow(props) {
  const { data, index, style, totalItems, onLoadMore, currentLimit } = props;

  useEffect(() => {
    if (!onLoadMore) return;
    if (onLoadMore && data.length < totalItems && index === data.length - 1) {
      onLoadMore(data.length);
    }
  }, [currentLimit, data.length, index, totalItems, onLoadMore]);

  return cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetListCache(data) {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
export const VirtualizedListboxComponent = forwardRef((props = {}, ref) => {
  const { children, VirtualizedProps = {}, ...other } = props;
  const { overscanCount, ...virtualizedRowProps } = VirtualizedProps || {};
  const itemData = Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetListCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={overscanCount || 3}
          itemCount={itemCount}
        >
          {(rowProps) => {
            const { data, style, index } = rowProps;
            return (
              <VirtualizedRow
                data={data}
                index={index}
                style={style}
                {...virtualizedRowProps}
              />
            );
          }}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

VirtualizedListboxComponent.propTypes = {
  children: PropTypes.node.isRequired,
  VirtualizedProps: PropTypes.shape(),
};

VirtualizedListboxComponent.defaultProps = {
  VirtualizedProps: {},
};

export const defaultTextFieldProps = {
  placeholder: 'Filter...',
  margin: 'dense',
  label: null,
  error: false,
  variant: 'outlined',
  fullWidth: true,
};

const useVirtualizedStyles = makeStyles()({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const useStyles = makeStyles()({
  textFieldRoot: {
    marginTop: 4,
  },
});

// Custom AutoComplete Component
const AutoCompleteTextField = ({
  optionsList,
  handleChange,
  handleInputChange,
  multiple,
  value,
  disabled,
  shrink,
  noOptionsText,
  sort,
  getOptionLabel,
  isOptionEqualToValue,
  sortBy,
  filterLimit,
  matchType,
  autoSelect,
  freeSolo,
  inputProps,
  InputProps,
  renderOption,
  TextFieldProps,
  virtualize,
  inputValue,
  manualFilterOptions,
  VirtualizedProps,
  ...rest
}) => {
  const { classes } = useStyles();
  const { classes: virtualizedClasses } = useVirtualizedStyles();
  let sortedList = optionsList;
  if (sort) sortedList = [...optionsList].sort(sortBy);

  const filterOptions = createFilterOptions({
    matchFrom: matchType,
    limit: filterLimit,
  });

  // conditionalize application of virtualized Listbox based on prop
  const virtualizeProps = virtualize
    ? {
        disableListWrap: true,
        ListboxComponent: VirtualizedListboxComponent,
        ListboxProps: {
          VirtualizedProps,
        },
        classes: virtualizedClasses,
        PopperComponent: PopperVirtualized,
      }
    : {};

  const isEmptyActiveField =
    !!inputValue?.length || !!TextFieldProps?.placeholder?.length || shrink;

  return (
    <Autocomplete
      autoSelect={autoSelect}
      multiple={multiple}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={sortedList}
      value={value}
      disabled={disabled}
      filterOptions={manualFilterOptions ? (options) => options : filterOptions}
      filterSelectedOptions
      freeSolo={freeSolo}
      noOptionsText={noOptionsText}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          classes={{
            root: classes.textFieldRoot,
          }}
          {...params}
          {...defaultTextFieldProps}
          {...TextFieldProps}
          InputLabelProps={{
            ...TextFieldProps.InputLabelProps,
            shrink: isEmptyActiveField,
          }}
          InputProps={{
            ...params.InputProps,
            ...TextFieldProps.InputProps,
            inputProps: {
              ...params.inputProps,
              ...inputProps,
            },
          }}
        />
      )}
      {...virtualizeProps}
      {...rest}
    />
  );
};

export default AutoCompleteTextField;

AutoCompleteTextField.propTypes = {
  filterLimit: PropTypes.number,
  optionsList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  shrink: PropTypes.bool,
  noOptionsText: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  getOptionLabel: PropTypes.func,
  isOptionEqualToValue: PropTypes.func,
  handleChange: PropTypes.func,
  handleInputChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]),
  matchType: PropTypes.oneOf(['any', 'start']),
  renderOption: PropTypes.func,
  sort: PropTypes.bool,
  sortBy: PropTypes.func,
  autoSelect: PropTypes.bool,
  freeSolo: PropTypes.bool,
  TextFieldProps: PropTypes.shape(),
  inputProps: PropTypes.shape(),
  InputProps: PropTypes.shape(),
  virtualize: PropTypes.bool,
  inputValue: PropTypes.string,
  VirtualizedProps: PropTypes.shape(),
  manualFilterOptions: PropTypes.bool,
};

AutoCompleteTextField.defaultProps = {
  TextFieldProps: {},
  shrink: true,
  value: undefined,
  noOptionsText: 'No options',
  sort: true,
  sortBy: (a, b) => (a > b ? 1 : -1),
  getOptionLabel: (label) => label,
  isOptionEqualToValue: (option, value) => option[value],
  filterLimit: 500,
  matchType: 'any',
  autoSelect: false,
  freeSolo: false,
  inputProps: {},
  InputProps: {},
  multiple: false,
  disabled: false,
  handleChange: (val) => val,
  handleInputChange: undefined,
  virtualize: false,
  renderOption: undefined,
  inputValue: '',
  VirtualizedProps: null,
  manualFilterOptions: false,
};
