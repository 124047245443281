// absolute imports
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { FormHelperText, InputBase, InputLabel } from '@mui/material';

// relative imports
import TransferList from '../custom-components/TransferList';

const useStyles = makeStyles()((theme, { active, touched, error }) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  inputLabelRoot: {
    marginBottom: 0,
    marginTop: theme.spacing(2),
    color:
      !active && touched && error
        ? theme.palette.error.main
        : theme.palette.text.secondary,
  },
}));

const FormTransferListField = ({ input, label, meta, transferListProps }) => {
  const { touched, error, active } = meta;
  const { classes } = useStyles({ active, touched, error });
  const handleChange = (newValArr) => {
    input.onChange(newValArr);
  };

  return (
    <div>
      <InputLabel
        disableAnimation
        htmlFor={`transfer-list-${label}`}
        className={classes.inputLabelRoot}
      >
        {label}
      </InputLabel>
      <InputBase
        type="hidden"
        name="transfer-component"
        id={`transfer-list-${label}`}
        value={input.value}
        onChange={handleChange}
      />
      <TransferList
        {...transferListProps}
        updateSelectedValues={handleChange}
      />
      {!active && touched && error && (
        <FormHelperText error margin="dense">
          {error}
        </FormHelperText>
      )}
    </div>
  );
};

export default FormTransferListField;

FormTransferListField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape().isRequired,
  transferListProps: PropTypes.shape(),
};

FormTransferListField.defaultProps = {
  transferListProps: {},
};
