// absolute imports
import { useState, useEffect, useRef, useCallback } from 'react';
import { darken } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { Badge, CircularProgress, Fab, Tooltip, lighten } from '@mui/material';
import { red, green } from '@mui/material/colors';
import clsx from 'clsx';

// icon imports
import {
  Check as CheckIcon,
  Refresh as RefreshIcon,
  PriorityHigh,
  SyncProblem as SyncProblemIcon,
} from '@mui/icons-material';

// relative imports
import { useAccount } from '../providers/AccountProvider';
import { useAppManagement } from '../providers/AppManagementProvider';
import { useModalActions } from '../providers/ModalProvider';
import useAlternativeDisplay from '../../shared/custom-hooks/useAlternativeDisplay';

const useStyles = makeStyles()((theme, { isMobile }) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonMain: {
    zIndex: 2,
    width: isMobile ? 28 : 26,
    height: isMobile ? 28 : 26,
    minHeight: isMobile ? 28 : 26,
    color: theme.whiteLabel.UpdateButton.color || theme.palette.common.white,
    backgroundColor:
      theme.whiteLabel.UpdateButton.backgroundColor ||
      'rgba(255, 255, 255, 0.2)',
    '&:hover': {
      backgroundColor: theme.whiteLabel.UpdateButton.backgroundColor
        ? darken(theme.whiteLabel.UpdateButton.backgroundColor, 0.3)
        : 'rgba(255, 255, 255, 0.3)',
    },
    marginLeft: theme.spacing(1),
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
    border: `2px solid ${
      theme.whiteLabel.UpdateButton.backgroundColor ||
      theme.palette.common.white
    }`,
  },
  buttonError: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
    border: `2px solid ${theme.palette.common.white}`,
  },
  buttonLoading: {
    backgroundColor: theme.whiteLabel.UpdateButton.backgroundColor
      ? lighten(theme.whiteLabel.UpdateButton.backgroundColor, 0.2)
      : 'rgba(255, 255, 255, 0.4)',
    '&:hover': {
      backgroundColor: theme.whiteLabel.UpdateButton.backgroundColor
        ? lighten(theme.whiteLabel.UpdateButton.backgroundColor, 0.3)
        : 'rgba(255, 255, 255, 0.4)',
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: isMobile ? 3.1 : -4,
    left: isMobile ? 4.2 : 4,
    zIndex: 1,
    opacity: 0.8,
  },
  statusBadge: {
    width: 14,
    height: 14,
    minWidth: 12,
    transform: 'scale(1) translate(60%, -30%)',
    boxShadow: theme.shadows[4],
    zIndex: 2,
  },
  warningStatus: {
    backgroundColor: theme.palette.warning.main,
  },
  errorStatus: {
    backgroundColor: theme.palette.error.main,
  },
  badgeIcon: {
    fontSize: 10,
    color: theme.palette.common.white,
  },
}));

const UpdateAccountDataButton = () => {
  const { isMobile } = useAlternativeDisplay();
  const { classes } = useStyles({ isMobile });
  const { appType } = useAppManagement();
  const { currentAccount, refetchCurrentAccount } = useAccount();
  const { showModal } = useModalActions();
  const { updating, taxUpdater, usage } = currentAccount;
  const { taxUpdateFailed, taxUpdateInProgress, taxUpdateNeedsRerun } =
    taxUpdater || {};
  const noSources =
    usage?.numberOfExchangeAccounts === 0 && usage?.numberOfWallets === 0;
  const [loading, setLoading] = useState(updating);
  const [completeStatus, setCompleteStatus] = useState();

  // set refs
  const updatingRef = useRef(updating);
  const timerRef = useRef(null);

  const buttonClassname = clsx({
    [classes.buttonMain]: true,
    [classes.buttonSuccess]: completeStatus === 'success' && !loading,
    [classes.buttonError]: completeStatus === 'error' && !loading,
    [classes.buttonLoading]: loading,
  });

  const badgeClassname = clsx({
    [classes.statusBadge]: true,
    [classes.errorStatus]: taxUpdateFailed,
    [classes.warningStatus]: taxUpdateNeedsRerun,
  });

  // handle modal opening
  const handleOpenClick = () => {
    showModal('TRIGGER_ACCOUNT_UPDATE', {
      updateType: appType === 'accounting' ? 'sources' : 'both',
    });
  };

  const handleUpdateFinished = useCallback(() => {
    if (taxUpdateFailed) {
      setCompleteStatus('error');
    } else {
      setCompleteStatus('success');
      timerRef.current = window.setTimeout(() => {
        setCompleteStatus(null);
      }, 1500);
    }
  }, [taxUpdateFailed]);

  useEffect(() => () => clearTimeout(timerRef.current), []);

  // handle update loader toggling based on update state
  useEffect(() => {
    let backupRefetchTimeout;

    if ((updating || updatingRef.current) && updating !== updatingRef.current) {
      updatingRef.current = updating;
      setLoading(updating);
      if (updating) {
        backupRefetchTimeout = window.setTimeout(() => {
          refetchCurrentAccount();
        }, 3000);
      }
      if (!updating && !taxUpdateInProgress) {
        handleUpdateFinished();
      }
    }

    return () => clearTimeout(backupRefetchTimeout);
  }, [
    updating,
    handleUpdateFinished,
    refetchCurrentAccount,
    taxUpdateInProgress,
  ]);

  return (
    <span>
      <div className={classes.wrapper}>
        <Tooltip
          title="Account Refresh Options"
          enterDelay={800}
          leaveDelay={200}
          placement="right"
          enterTouchDelay={10}
        >
          <Badge
            color="secondary"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            badgeContent={<PriorityHigh className={classes.badgeIcon} />}
            classes={{
              anchorOriginTopRightRectangular: badgeClassname,
            }}
            invisible={
              appType === 'accounting' ||
              completeStatus === 'error' ||
              completeStatus === 'success' ||
              loading ||
              updating ||
              (!taxUpdateFailed && !taxUpdateNeedsRerun) ||
              noSources
            }
          >
            <Fab
              aria-label="update all data sources"
              className={buttonClassname}
              onClick={handleOpenClick}
              id="update-all-sources-icon-button"
            >
              {!completeStatus && <RefreshIcon fontSize="small" />}
              {completeStatus === 'success' && <CheckIcon fontSize="small" />}
              {completeStatus === 'error' && (
                <SyncProblemIcon fontSize="small" />
              )}
            </Fab>
          </Badge>
        </Tooltip>
        {loading && (
          <CircularProgress
            size={isMobile ? 36 : 34}
            thickness={5}
            className={classes.fabProgress}
          />
        )}
      </div>
    </span>
  );
};

export default UpdateAccountDataButton;
