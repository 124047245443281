// tax setup flow
import {
  TaxSetupWelcomeStep,
  TaxSetupTimezoneStep,
  TaxSetupAlgorithmStep,
  TaxSetupTaxYearStep,
  TaxSetupCostBasisModeStep,
  TaxSetupCompletedStep,
} from './tax-account-setup';
import TaxSetupTemplate from './tax-account-setup/TaxSetupTemplate';
// accounting account creation flow
import {
  CreateAccountWelcomeStep,
  CreateAccountTimezoneStep,
  CreateAccountSubtypeSelectStep,
  CreateAccountSubtypeFieldsStep,
  CreateAccountReviewStep,
  CreateAccountCompletedStep,
} from './create-accounting-account';
import CreateAccountFormWrapper from './create-accounting-account/CreateAccountFormWrapper';
import CreateAccountTemplate from './create-accounting-account/CreateAccountTemplate';

// format flows with easily-mapped properties for convenience
export default {
  taxAccountSetup: {
    templateComponent: TaxSetupTemplate,
    hasCompleteStep: true,
    steps: [
      { stepNumber: 0, Component: TaxSetupWelcomeStep },
      { stepNumber: 1, Component: TaxSetupTaxYearStep },
      { stepNumber: 2, Component: TaxSetupTimezoneStep },
      { stepNumber: 3, Component: TaxSetupAlgorithmStep },
      { stepNumber: 4, Component: TaxSetupCostBasisModeStep },
      { stepNumber: 5, Component: TaxSetupCompletedStep },
    ],
  },
  createAccountingAccount: {
    templateComponent: CreateAccountTemplate,
    hasCompleteStep: true,
    formComponent: CreateAccountFormWrapper,
    submitStepKey: 'caConfirm',
    steps: [
      {
        key: 'caWelcome',
        stepNumber: 0,
        Component: CreateAccountWelcomeStep,
      },
      {
        key: 'caTimezone',
        stepNumber: 1,
        Component: CreateAccountTimezoneStep,
      },
      {
        key: 'caSubtypeSelect',
        stepNumber: 2,
        Component: CreateAccountSubtypeSelectStep,
      },
      {
        key: 'caSubtypeFields',
        stepNumber: 3,
        Component: CreateAccountSubtypeFieldsStep,
      },
      {
        key: 'caConfirm',
        stepNumber: 4,
        Component: CreateAccountReviewStep,
      },
      {
        key: 'caCompleted',
        stepNumber: 5,
        Component: CreateAccountCompletedStep,
      },
    ],
  },
};
