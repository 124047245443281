export default {
  id: 'default',
  enableSetupFlow: true,
  enableIntegrations: true,
  enableReports: true,
  enableTaxTransactions: true,
  enableAccountingTransactions: true,
  enableAccountingDashboard: true,
  enableAccountingSettings: true,
  enableTaxPaywall: true,
  enableContacts: true,
  enableNFTSuite: true,
  enableProConnection: true,
  enablePlanning: true,
  enableSources: true,
  enableMonitoring: true,
  enableTaxProClientInvites: true,
  enableTaxProAppClientSummary: true,
  enableTaxProConnection: true,
  additionalReports: [],
  excludedReports: [],
  disclaimerMessage: null,
  accountSetup: {
    enableAccountAdd: false,
    enableInitialAccountingAccountCreation: false,
    subtypeFields: [],
  },
  accountSettings: {
    defaultFieldLabels: {
      name: 'Account Name',
    },
    subtypeFieldConfig: [],
  },
  accountSelection: {
    enableFiltering: false,
    availableFilters: [],
  },
  hideTaxLabels: false,
  taxProAppClientAccountSubtype: null,
  taxProTIRConfig: false,
  accountLimits: {
    orders: {
      process: 250000,
      import: 1000,
    },
    transactions: {
      process: 250000,
      import: 1000,
    },
  },
};
