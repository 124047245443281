// absolute imports
import { useReactiveVar } from '@apollo/client';

// relative imports
import { dialogStateVar } from '../../../data/apollo/cache/reactiveVars';

/**
 * useDialogState
 * */
export const useDialogState = () => {
	const dialogState = useReactiveVar(dialogStateVar);
	/**
	 *
	 * @param {*} key String - [confirmExit, pristine]
	 * @param {*} value Boolean
	 */
	const updateDialogState = (key, value) => {
		if (!dialogState[key] === value) {
      dialogStateVar({
        ...dialogState,
        [key]: value,
      });
		}
	}

	const resetDialogState = () => {
		dialogStateVar({
			pristine: true,
			confirmExit: false,
		});
	}

	const ignoreDialogState = () => {
		dialogStateVar({
			pristine: false,
			confirmExit: false,
		});
	}

	return {
    dialogState,
    updateDialogState,
    resetDialogState,
    ignoreDialogState,
  };
};

export default useDialogState;
