/* CHARGEBEE API */
const { Chargebee = {} } = window;

if (!Chargebee && document.readyState === 'complete' && !window.Cypress) {
  window.Chargebee.init({
    site: import.meta.env.REACT_APP_CB_SITE,
    enableGATracking: true,
  });
}

export const cb = Chargebee;

/** open portal session for customer to manage billing info through ChargeBee with optional config
 * SEE HERE FOR DETAILS:
 * https://www.chargebee.com/checkout-portal-docs/api.html#chargebee-portal-instance-object
 * @param {Function} makeCustomerPortal -> mutation function to return portal promise
 * @param {String} accountId -> id of subscription/account to open portal for
 * @param {Object} Chargebee -> chargebee instance to use cb methods
 * @param {Object} section -> string of section to open
 * @param {Object} onClose -> callback to run on close of portal
 * @param {Object} onComplete -> callback for successful action in portal (card add/edit)
 */
export const handlePortalOpenSection = (
  makeCustomerPortal, accountId, section,
  onClose, onComplete,
) => {
  const chargebeeInstance = cb.getInstance();

  chargebeeInstance.setPortalSession((async () => {
    const mutationResult = await makeCustomerPortal();
    return mutationResult.data.generateCustomerPortal;
  }));
  chargebeeInstance.createChargebeePortal().openSection(
    {
      sectionType: Chargebee.getPortalSections()[section],
      params: {
        subscriptionId: accountId,
      },
    },
    {
      close() {
        chargebeeInstance.logout();
        if (onClose) {
          onclose();
        }
      },
      paymentSourceAdd(status) {
        if (status === 'success' && onComplete) {
          onComplete();
          chargebeeInstance.closeAll();
          chargebeeInstance.logout();
        }
      },
      paymentSourceUpdate(status) {
        if (status === 'success') {
          onComplete();
          chargebeeInstance.closeAll();
          chargebeeInstance.logout();
        }
      },
    },
  );
};

/** Prompt user with portal to checkout the plan they've selected, optionally
 * can disable trial for the plan
 * @param {Function} makeCheckoutPortal -> mutation function to return portal promise
 * @param {String} planIdToPurchase -> Id of CB add-on for one-time checkout
 * (must be associated with user's plan)
 * @param {Boolean} disableTrial -> boolean indicating if trial should not be applied to plan
 * (only works if it's a trial-enabled plan)
 * @param {Function} successCallback -> callback function to call on successful checkout
 */
export const handlePlanCheckoutSession = async (
  makeCheckoutPortal, planIdToPurchase, disableTrial, successCallback,
) => {
  const chargebeeInstance = cb.getInstance();
  // generate portal
  chargebeeInstance.openCheckout({
    hostedPage: async () => {
      const mutationResult = await makeCheckoutPortal({
        variables: {
          planId: planIdToPurchase,
          disableTrial,
        },
      });
      return mutationResult.data.generateCheckoutPortal;
    },
    success: async (hostedPageId) => {
      if (successCallback) {
        await successCallback(hostedPageId); // supply hosted page id to callback
      }
      chargebeeInstance.closeAll(); // close all cb instance windows
    },
    close: () => {
      chargebeeInstance.logout(); // end cb session on close
    },
  });
};

/** Prompt user with portal to upgrade their existing plan to a new one
 * @param {Function} makeUpgradePortal -> mutation function to return portal promise
 * @param {String} accountId -> id of subscription/account to open portal for
 * @param {String} newPlanId -> Id of CB add-on for one-time checkout
 * (must be associated with user's plan)
 * @param {Function} successCallback -> callback function to call on successful checkout
 */
export const handlePlanUpgradeSession = async (
  makeUpgradePortal, accountId, newPlanId, successCallback,
) => {
  const chargebeeInstance = cb.getInstance();
  // generate portal
  chargebeeInstance.openCheckout({
    hostedPage: async () => {
      const mutationResult = await makeUpgradePortal({
        variables: {
          planId: newPlanId,
          accountId,
        },
      });
      return mutationResult.data.generateUpgradePortal;
    },
    success: async (hostedPageId) => {
      if (successCallback) {
        successCallback(hostedPageId); // supply hosted page id to callback
      }
      chargebeeInstance.closeAll(); // close all cb instance windows
    },
    close: () => {
      chargebeeInstance.logout(); // end cb session on close
    },
  });
};

/** Prompt user with portal to upgrade their existing plan to a new one
 * @param {Function} makeOneTimeCheckout -> mutation function to return portal promise
 * @param {String} accountId -> id of subscription/account to open portal for
 * @param {String} addOnId -> Id of CB add-on for one-time checkout
 * (must be associated with user's plan)
 * @param {Function} successCallback -> callback function to call on successful checkout
 */
export const handleOneTimeCheckout = async (
  makeOneTimeCheckout, accountId, addOnId, successCallback,
) => {
  const chargebeeInstance = cb.getInstance();

  chargebeeInstance.openCheckout({
    hostedPage: async () => {
      const mutationResult = await makeOneTimeCheckout({
        variables: {
          addOnId,
          accountId,
        },
      });
      return mutationResult.data.generateCheckoutAddonPortal;
    },
    success: async (hostedPageId) => {
      if (successCallback) {
        successCallback(hostedPageId); // supply hosted page id to callback
      }
      chargebeeInstance.closeAll(); // close all cb instance windows
    },
    close: () => {
      chargebeeInstance.logout(); // end cb session on close
    },
  });
};
