import PropTypes from 'prop-types';
import { DialogTitle, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { maxWidthSize }) => ({
  root: {
    backgroundColor:
      theme.whiteLabel.DialogTitle.color || theme.palette.primary.main,
    borderRadius: '4px 4px 0 0',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  ledgibleHeaderFlex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  ledgibleHeaderLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'auto',
  },
  ledgibleHeaderRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 'auto',
  },
  titleText: {
    color: theme.whiteLabel.DialogTitle.textColor || theme.palette.common.white,
    maxWidth: maxWidthSize,
  },
}));

const getMaxWidth = (maxWidth, isMobile) => {
  if (isMobile) return 300;
  if (maxWidth === 'sm') return 500;
  if (maxWidth === 'lg') return 1000;
  return 750;
}

const LedgibleDialogTitle = ({
  titleText,
  children,
  className,
  isMobile,
  ...muiTitleProps
}) => {
  const { classes, cx } = useStyles({
    maxWidthSize: getMaxWidth(muiTitleProps?.maxWidth || 'sm', isMobile),
  });

  return (
    <DialogTitle
      id="draggable-dialog-title"
      className={cx(
        classes.root,
        className,
        children ? classes.ledgibleHeaderFlex : classes.ledgibleHeaderLeft,
      )}
      {...muiTitleProps}
    >
      <div className={classes.ledgibleHeaderLeft}>
        <Typography
          variant="h6"
          color="textSecondary"
          className={classes.titleText}
          noWrap
        >
          {titleText}
        </Typography>
      </div>
      <div className={classes.ledgibleHeaderRight}>
        {children}{' '}
        {/* include any buttons, etc here to render in upper right of modal */}
      </div>
    </DialogTitle>
  );
};

export default LedgibleDialogTitle;

LedgibleDialogTitle.propTypes = {
  className: PropTypes.string,
  titleText: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

LedgibleDialogTitle.defaultProps = {
  className: null,
  children: null,
  isMobile: false,
};
