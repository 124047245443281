// absolute imports
import { useMemo } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { useReactiveVar } from '@apollo/client';

// relative imports
import { useAuth0 } from './common/providers/Auth0Provider';
import PrivateRoute from './common/routing/route-wrappers/PrivateRoute';
import PublicRoute from './common/routing/route-wrappers/PublicRoute';
import generateRouteConfig from './common/routing/RouteConfig';
import BrowserCheck from './shared/browser-detection/BrowserCheck';
import CustomZendeskLauncher from './shared/customer-support/CustomZendeskLauncher';
import { BillingProvider } from './common/providers/BillingProvider';
import { ExchangeProvider } from './common/providers/ExchangeProvider';
import { WalletProvider } from './common/providers/WalletProvider';
import { useAppManagement } from './common/providers/AppManagementProvider';
import { useConfig } from './common/providers/ConfigProvider';
import LoadingPage from './shared/page-components/LoadingPage';
import HelpWidgetOverlay from './shared/customer-support/HelpWidgetOverlay';
import AccountDisclaimer from './shared/custom-components/AccountDisclaimer';
import ModalContainer from './shared/modals/ModalContainer';
import IdleTimer from './shared/authentication/IdleTimer';
import { mapAliasToPath } from './shared/utilities/appUtilities';
import { MonitoringAlertsProvider } from './common/providers/MonitoringAlertsProvider';
import { switchingApplicationsVar } from '../data/apollo/cache/reactiveVars';
import { ReportProvider } from './common/providers/ReportProvider';
import { ClientSummaryProvider } from './tax-preparer/preparer-overview/ClientSummaryProvider';

const App = () => {
  const { appConfig } = useConfig();
  const {
    taxAppUrlAlias,
    accountingAppUrlAlias,
    taxProAppUrlAlias,
    tirAdminAppUrlAlias,
  } = appConfig;
  const isSwitchingApplications = useReactiveVar(switchingApplicationsVar);
  const { loadingAuth } = useAuth0();
  const { appType, displayAppType, loadingApp } = useAppManagement();
  const hasActiveApp = appType !== 'none';

  const routeConfig = useMemo(
    () =>
      generateRouteConfig((path) =>
        mapAliasToPath(path, {
          taxAppUrlAlias,
          taxProAppUrlAlias,
          accountingAppUrlAlias,
          tirAdminAppUrlAlias,
        }),
      ),
    [
      accountingAppUrlAlias,
      taxAppUrlAlias,
      taxProAppUrlAlias,
      tirAdminAppUrlAlias,
    ],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <ClientSummaryProvider>
        <BillingProvider>
          <WalletProvider>
            <ExchangeProvider>
              <MonitoringAlertsProvider>
                <ReportProvider>
                  <ModalContainer />
                  <IdleTimer />
                  <BrowserCheck />
                  <AccountDisclaimer />
                  <HelpWidgetOverlay />
                  {!window.Cypress && <CustomZendeskLauncher />}
                  {(loadingApp || loadingAuth) && (
                    <LoadingPage
                      loadingApplication={!isSwitchingApplications}
                    />
                  )}
                  {!loadingApp && !loadingAuth && (
                    <Switch>
                      {routeConfig
                        .filter((route) => route.apps.includes(appType))
                        .map((route) =>
                          route.public ? (
                            <PublicRoute
                              key={route.id}
                              route={route}
                              path={route.path}
                              exact={route.exact}
                            />
                          ) : (
                            <PrivateRoute
                              key={route.id}
                              route={route}
                              path={route.path}
                              exact={route.exact}
                            />
                          ),
                        )}

                      <Route
                        path="/"
                        render={() =>
                          hasActiveApp ? (
                            <Redirect
                              to={
                                appConfig.alternateBaseAppPath ||
                                `/${displayAppType}`
                              }
                            />
                          ) : (
                            <Redirect to="/app-select" />
                          )
                        }
                      />
                    </Switch>
                  )}
                </ReportProvider>
              </MonitoringAlertsProvider>
            </ExchangeProvider>
          </WalletProvider>
        </BillingProvider>
      </ClientSummaryProvider>
    </LocalizationProvider>
  );
};

export default App;
