// absolute imports
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';

// asset imports
import ErrorTableIcon from '../../shared/custom-icons/ErrorTableIcon';
import { openZendeskWidget } from '../../shared/customer-support/zendesk-operations';

const useStyles = makeStyles()((theme) => ({
  supportLink: {
    color: theme.whiteLabel.Link.color || '#007bff',
    display: 'inline',
    padding: 0,
    border: 0,
    backgroundColor: 'transparent',
    '&:focus': {
      outline: 0,
    },
    cursor: 'pointer',
  },
  resetButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  tableRow: {
    height: 5 * 53,
    width: '100%',
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  errorDataImg: {
    marginTop: theme.spacing(1),
    width: 120,
    height: 80,
    opacity: 0.7,
  },
  noDataText: {
    opacity: 0.8,
  },
  textSpace: {
    marginTop: theme.spacing(1),
  },
}));

const ErrorBoundaryFallbackTable = ({
  errorMsg = 'An error ocurred trying to display this table',
}) => {
  const { classes, cx } = useStyles();

  return (
    <Table>
      <TableBody>
        <TableRow className={classes.tableRow}>
          <TableCell colSpan={11} className={classes.tableCell} align="center">
            <ErrorTableIcon
              className={classes.errorDataImg}
              viewBox="0 0 340 250"
            />
            <Typography
              color="textSecondary"
              variant="subtitle1"
              className={cx(classes.noDataText, classes.textSpace)}
            >
              <i>{errorMsg}</i>
            </Typography>
            <Typography
              color="textSecondary"
              variant="subtitle2"
              paragraph
              className={classes.noDataText}
            >
              We&apos;re working to fix this, but in the meantime, you can
              &nbsp;
              <span
                role="button"
                onKeyDown={() => openZendeskWidget()}
                onClick={() => openZendeskWidget()}
                tabIndex={0}
                className={classes.supportLink}
              >
                contact our support team
              </span>
              &nbsp; or try to reload this table.
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default ErrorBoundaryFallbackTable;

ErrorBoundaryFallbackTable.propTypes = {
  errorMsg: PropTypes.string,
};

ErrorBoundaryFallbackTable.defaultProps = {
  errorMsg: undefined,
};
