
const required = (msg) => (value) => (
  value ? undefined : msg
);

const requiredBool = (msg) => (value) => (
  value === true ? undefined : msg
);

const requiredSelection = (msg) => (value) => (
  !['', 'none', null, undefined].includes(value) ? undefined : msg
);

const requiredArray = (msg) => (value) => (
  Array.isArray(value) && value.length > 0 ? undefined : msg
);

export const requiredField = {
  connectionName: required('Connection name cannot be blank'),
  apiCredential: required('Credential required'),
  clientAccountName: required('Enter a name to display for this client'),
  clientContactName: required(
    'Enter a name for the primary contact on this client account',
  ),
  contactName: required('Contact name cannot be blank'),
  walletName: required('Wallet name cannot be blank'),
  integrationName: required('Integration name cannot be blank'),
  accountName: required('Account name cannot be blank'),
  address: required('Address cannot be blank'),
  password: required('Password cannot be blank'),
  permission: required('Select a permission level for this user'),
  email: required('Enter an email address'),
  contactEmail: required('Email for this contact is required'),
  clientEmail: required('Email is required to add a client.'),
  xPub: required('Key required'),
  mOfN: required('Required number of signatures'),
  moneroSecretViewKey: required('Monero Secret View Key required'),
  generic: required('This field is required'),
  signupTerms: requiredBool('You must accept the terms of service to sign up'),
  asset: requiredBool('You must enter an asset'),
  key: required('No blank keys'),
  tableView: required('Enter a name for this view'),
  sourceName: required('Source name cannot be blank'),
  selectOption: requiredSelection('An option must be selected'),
  files: requiredArray('A file must be uploaded'),
};

const maxLength = (max) => (value) => (value && value.length > max ? `Must be ${max} characters or fewer` : undefined);

export const maxLength200 = maxLength(200);
export const maxLength40 = maxLength(40);
export const maxLength30 = maxLength(30);
export const maxLength20 = maxLength(20);
export const maxLength15 = maxLength(15);

const minLength = (min) => (value) => (value && value.length < min ? `Must be ${min} characters or more` : undefined);

export const minLength8 = minLength(8);

export const number = (value) => (value && !Number.isNaN(parseFloat(value)) ? 'Must be a number' : undefined);

export const usdAmount = (value) => (value && !/^[+]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$/i.test(value)
  ? 'Enter a valid USD amount'
  : undefined);

export const minValue = (min) => (value) => (value && value < min ? `Must be at least ${min}` : undefined);
export const maxValue = (max) => (value) => (value && value > max ? `Must not be more than ${max}` : undefined);

export const minValueQuantity = minValue(0.000000000000000001);
export const minValueZero = minValue(0);
export const minValue13 = minValue(13);
export const nonZeroPositive = minValue(1);
export const maxValue100 = maxValue(100);

export const email = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)
  ? 'Invalid email address'
  : undefined);

export const preparerPermissionList = (value) => (!value || (value && value.length === 0)
  ? 'Select at least one client for this Team Member to access'
  : undefined);

export const matchFormValue = (value, allValues, meta, other) =>
  value === allValues?.[other] ? undefined : 'Values do not match';

export const noEmptySpace = (value) =>
  typeof value === 'string' && value?.trim() === ''
    ? 'Empty space is not valid'
    : undefined;

export const alphaNumeric = (value) => (value && /[^a-zA-Z0-9 ]/i.test(value)
  ? 'Only alphanumeric characters allowed'
  : undefined);

export const alphaNumericAndDash = (value) => (value && /[^a-zA-Z0-9- ]/i.test(value)
  ? 'Only alphanumeric characters and dashes allowed'
  : undefined);

export const alphaNumericAndSpecial = (value) => (value && /[^a-zA-Z0-9 &,.`'":*()-]/i.test(value)
  ? 'Only alphanumeric characters and certain special characters allowed'
  : undefined);

export const invalidSelection = (val) => val === 'none' ? 'A selection is required' : undefined;

export const goSystemLocator = (value) => (/^[A-Z0-9]{6}$/g.test(value)
  ? undefined
  : 'Locator must be alphanumeric, exactly 6 characters and have no lowercase letters');

export const goodPasswordStrength = (value) => (value && !/^(((?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]))|((?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*]))|((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]))|((?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])))(?=.{8,})/i.test(value)
  ? `Password should be at least 8 characters and have at least 3 of the 4 following character types:

      - Special characters (!@#$%^&*)
      - Lower case (a-z)
      - Upper case (A-Z)
      - Numbers (0-9)`
  : undefined);

// eslint-disable-next-line max-len
export const composeValidators = (...validators) => (value, allValues, fieldState) => (validators.reduce((error, validator) => error || validator(value, allValues, fieldState), undefined));

// normalize currency
export const normalizeCurrency = (value) => {
  if (typeof value === 'string') return value.toUpperCase();
  return value;
};

