// absolute imports
import { Field } from 'react-final-form';
import { CardActions, CardContent, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// icon imports
import { ArrowRightRounded as ArrowRightIcon } from '@mui/icons-material';

// relative imports
import { FormTextField } from '../../../../../shared/final-form-field-wrappers';
import {
  requiredField,
  alphaNumericAndDash,
  maxLength40,
  composeValidators,
} from '../../../../../shared/utilities/formValidations';
import LedgibleButton from '../../../../../shared/custom-components/buttons/LedgibleButton';
import { useConfig } from '../../../../providers/ConfigProvider';

const useStyles = makeStyles()((theme) => ({
  cardContent: {
    margin: theme.spacing(2, 1),
  },
  headerText: {
    marginBottom: theme.spacing(2),
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
}));

const WelcomeStep = () => {
  const { accountConfig } = useConfig();
  const { accountSettings } = accountConfig;
  const { classes } = useStyles();

  return (
    <>
      <CardContent
        classes={{
          root: classes.cardContent,
        }}
      >
        <Typography
          className={classes.headerText}
          color="secondary"
          gutterBottom
          variant="h6"
          align="center"
        >
          New Account Setup
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="subtitle1">
          The following steps will guide you through creating a new account.
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="subtitle1">
          Enter a name for this account and click the button below to continue.
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Field
              autoFocus
              id="account-name"
              data-cy="account-create-account-name"
              name="name"
              component={FormTextField}
              fullWidth
              InputLabelProps={{ shrink: true }}
              margin="dense"
              label={accountSettings.defaultFieldLabels?.name || 'Account Name'}
              placeholder="Enter a name for this account"
              validate={composeValidators(
                requiredField.accountName,
                maxLength40,
                alphaNumericAndDash,
              )}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.actionRow} disableSpacing>
        <LedgibleButton
          variant="contained"
          size="large"
          color="success"
          textTransformOff
          endIcon={<ArrowRightIcon />}
          id="create-account-welcome-step-continue"
          data-cy="create-account-welcome-step-continue"
          type="submit"
        >
          Continue
        </LedgibleButton>
      </CardActions>
    </>
  );
};

export default WelcomeStep;
