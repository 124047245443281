// absolute imports
import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { CardActions, CardContent, MenuItem, TextField, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';

// icon imports
import {
  ArrowRightRounded as ArrowRightIcon,
  ArrowLeftRounded as ArrowLeftIcon
} from '@mui/icons-material';

// relative imports
import LedgibleButton from '../../../../../shared/custom-components/buttons/LedgibleButton';
import { useAccount } from '../../../../providers/AccountProvider';
import { UPDATE_TAX_YEAR } from '../../../../../../data/apollo/mutations';
import { taxYears } from '../../../../../../data/app-config/appDetails';
import useApolloErrorState from '../../../../../shared/custom-hooks/useApolloErrorState';
import { currentAccountTaxYearVar } from '../../../../../../data/apollo/cache/reactiveVars';

const useStyles = makeStyles()((theme) => ({
  headerText: {
    marginBottom: theme.spacing(2),
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  selectField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
}));

const TaxYearStep = ({ handleNext, handlePrevious }) => {
  const { classes } = useStyles();
  const { currentAccount } = useAccount();
  const [newYear, setNewYear] = useState(
    currentAccount.taxPayerDetails.taxYear,
  );
  const [changeTaxYearError, clearError] = useApolloErrorState([
    'updateTaxYear',
  ]);

  const {
    taxPayerDetails: { taxYear },
  } = currentAccount;

  const [updateTaxYear] = useMutation(UPDATE_TAX_YEAR, {
    variables: {
      accountId: currentAccount.id,
      taxYear: newYear,
    },
    onCompleted: (res = {}) => {
      if (res.updateTaxYear && !changeTaxYearError) {
        currentAccountTaxYearVar(res.updateTaxYear?.taxPayerDetails?.taxYear);
      }
    },
  });

  const handleContinue = () => {
    if (changeTaxYearError) {
      clearError();
    }
    if (taxYear !== newYear) {
      updateTaxYear();
    }
    handleNext();
  };

  return (
    <>
      <CardContent>
        <Typography
          className={classes.headerText}
          color="secondary"
          gutterBottom
          variant="h6"
          align="center"
        >
          Confirm Tax Year
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="subtitle1">
          Ledgible allows you to calculate taxes for whichever tax year you
          require. The default is the current tax year, but you can start with a
          different year if needed.
        </Typography>
        <TextField
          fullWidth
          select
          value={newYear}
          color="primary"
          size="small"
          label="Tax Year"
          onChange={(e) => setNewYear(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!changeTaxYearError}
          variant="outlined"
          helperText={!!changeTaxYearError && 'Error setting tax year'}
          className={classes.selectField}
        >
          {taxYears.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </TextField>
      </CardContent>
      <CardActions className={classes.actionRow}>
        <LedgibleButton
          variant="outlined"
          size="large"
          color="secondary"
          textTransformOff
          onClick={handlePrevious}
          startIcon={<ArrowLeftIcon />}
          id="tax-setup-flow-year-step-back"
        >
          Go Back
        </LedgibleButton>
        <LedgibleButton
          variant="contained"
          size="large"
          color="success"
          textTransformOff
          onClick={handleContinue}
          endIcon={<ArrowRightIcon />}
          id="tax-setup-flow-year-step-continue"
        >
          Continue
        </LedgibleButton>
      </CardActions>
    </>
  );
};

export default TaxYearStep;

TaxYearStep.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
};
