// absolute imports
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { useState, useMemo, useContext, createContext } from 'react';

// relative imports
import ErrorPage from './ErrorPage';

// ----- SET UP CONTEXT -----
export const AppErrorBoundaryContext = createContext();
export const useAppErrorBoundary = () => useContext(AppErrorBoundaryContext);

const AppErrorBoundary = ({ children, fallbackProps, errorMsg, onReset }) => {
  const [state, setState] = useState({
    error: null,
    hasError: false,
  });
  const errorBoundaryValue = useMemo(
    () => ({
      showBoundary: (error) => {
        setState({
          error,
          hasError: true,
        });
      },
    }),
    [],
  );

  return (
    <AppErrorBoundaryContext.Provider value={errorBoundaryValue}>
      <Sentry.ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTransactionName('app main view');
          scope.setTag('pathname', window.location.pathname);
          scope.setTag('userAgent', navigator.userAgent);
        }}
        fallback={({ error, resetError }) => (
          <ErrorPage
            error={error}
            resetError={() => {
              resetError();
              if (onReset) {
                onReset();
              }
            }}
            fallbackProps={fallbackProps}
            errorMsg={errorMsg}
          />
        )}
      >
        {state.hasError && (
          <ErrorPage
            error={state.error}
            resetError={onReset}
            fallbackProps={fallbackProps}
            errorMsg={errorMsg}
          />
        )}
        {!state.hasError && [children]}
      </Sentry.ErrorBoundary>
    </AppErrorBoundaryContext.Provider>
  );
};

export default AppErrorBoundary;

AppErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  fallbackProps: PropTypes.shape(),
  errorMsg: PropTypes.string,
  onReset: PropTypes.func,
};

AppErrorBoundary.defaultProps = {
  fallbackProps: {},
  errorMsg: undefined,
  onReset: undefined,
};
