// absolute imports
import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Avatar, ButtonBase, MenuItem, MenuList } from '@mui/material';

// icon imports
import {
  ExitToApp as ExitToAppIcon,
  Person as PersonIcon
} from '@mui/icons-material';

// relative imports
import { useAuth0 } from '../../common/providers/Auth0Provider';
import ExtendedPopper from '../custom-components/ExtendedPopper';
import { getComponentTestKey } from '../utilities/testUtilities';

const useStyles = makeStyles()((theme) => ({
  avatar: {
    width: 35,
    height: 35,
  },
  menuList: {
    padding: theme.spacing(0),
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: theme.typography.pxToRem(14),
    '&:hover': {
      color: '#000',
      textDecoration: 'none',
    },
    minWidth: 120,
  },
  linkItem: {
    '&:hover': {
      color: 'inherit',
    },
  },
  menuIcon: {
    marginRight: theme.spacing(1),
  },
}));

const ProfileNavbox = () => {
  const { classes, cx } = useStyles();
  const { user, logout } = useAuth0();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggleMenu = (event) => {
    setOpen((prevOpen) => !prevOpen);
    anchorRef.current = event.target;
  };

  const handleClose = (event) => {
    if (
      anchorRef.current &&
      event &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  return (
    <ExtendedPopper
      content={
        <MenuList disableListWrap className={classes.menuList}>
          <MenuItem
            component={Link}
            to={{
              pathname: '/user-settings',
              state: {
                redirect: window.location.pathname,
                redirectLabel: 'Back',
              },
            }}
            onClick={handleClose}
            className={cx(classes.menuItem, classes.linkItem)}
            divider
          >
            <PersonIcon className={classes.menuIcon} fontSize="small" />
            User Settings
          </MenuItem>
          <MenuItem
            onClick={handleLogout}
            className={classes.menuItem}
            data-cy={getComponentTestKey({
              componentName: 'ProfileNavbox',
              elementType: 'button',
              elementName: 'logout',
            })}
          >
            <ExitToAppIcon className={classes.menuIcon} />
            Logout
          </MenuItem>
        </MenuList>
      }
      arrow
      open={open}
      placement="bottom-start"
      clickAwayHandler={handleClose}
      padContent={false}
    >
      <ButtonBase
        disableRipple
        ref={anchorRef}
        aria-controls="menu-list-grow"
        aria-haspopup="true"
        onClick={handleToggleMenu}
        id="ProfileNavBoxButton"
        title={`${user.profile.username || 'profile'} user menu`}
      >
        <Avatar
          alt="Profile Picture"
          src={user.profile.picture}
          className={cx(classes.avatar, classes.bigAvatar)}
          data-cy="profile-menu-avatar"
        />
      </ButtonBase>
    </ExtendedPopper>
  );
};

export default ProfileNavbox;
