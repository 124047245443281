import { makeStyles } from 'tss-react/mui';

// import local modules
import LedgibleButton from '../../shared/custom-components/buttons/LedgibleButton';
import { useAccount } from '../../common/providers/AccountProvider';
import { useBilling } from '../../common/providers/BillingProvider';
import { useModalActions } from '../../common/providers/ModalProvider';
import useAlternativeDisplay from '../../shared/custom-hooks/useAlternativeDisplay';

import { getDate } from '../../shared/utilities/dateUtilities';

const useStyles = makeStyles()((theme, { isMobile }) => ({
  buttonText: {
    color: theme.palette.grey[isMobile ? 700 : 300],
  },
}));

const TrialUpgradeButton = ({ ...ButtonProps }) => {
  const { isMobile } = useAlternativeDisplay();
  const { classes } = useStyles({ isMobile });
  const { currentAccount } = useAccount();
  const { currentSubscription: subscription } = useBilling();
  const { showModal } = useModalActions();

  if (
    subscription &&
    subscription.status === 'in_trial' &&
    currentAccount.inTrial
  ) {
    const trialEndDate = getDate(new Date(subscription.trial_end), {
      parseMethod: 'JSDate',
    }).endOf('day');

    const daysRemaining = trialEndDate.diffNow('days').days;

    return (
      <LedgibleButton
        id="days-left-header-btn"
        className={classes.buttonText}
        color="primary"
        onClick={() => showModal('CONVERT_FROM_ACCOUNTING_TRIAL')}
        {...ButtonProps}
      >
        {Math.round(daysRemaining) > 30 ? 30 : Math.round(daysRemaining)}
        &nbsp;
        {Math.round(daysRemaining) === 1 ? 'Day ' : 'Days '}
        Left In Trial
      </LedgibleButton>
    );
  }
  return null;
};

export default TrialUpgradeButton;
