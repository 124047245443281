import { SvgIcon } from '@mui/material';

const ErrorTableSVG = (props) => (
  <SvgIcon {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(-0.000000, -0.000000)" fillRule="nonzero">
        <path d="M215.833333,239.166667 C186.666667,239.166667 163.333333,215.833333 163.333333,186.666667 C163.333333,157.5 186.666667,134.166667 215.833333,134.166667 C245,134.166667 268.333333,157.5 268.333333,186.666667 C268.333333,215.833333 245,239.166667 215.833333,239.166667 Z M215.833333,122.5 C180.249431,122.5 151.666667,151.082764 151.666667,186.666667 C151.666667,222.250569 180.249431,250.833333 215.833333,250.833333 C251.417236,250.833333 280,222.250569 280,186.666667 C280,151.082764 251.417236,122.5 215.833333,122.5 Z" id="Shape" fill="#B5B6B8" />
        <path d="M11.6666667,169.166667 L11.6666667,11.6666667 L315,11.6666667 L315,169.166667 C315,172.667236 312.667236,175 309.166667,175 L291.666667,175 L291.666667,186.666667 L309.166667,186.666667 C319.084472,186.666667 326.666667,179.084472 326.666667,169.166667 L326.666667,-2.84217094e-14 L0,-2.84217094e-14 L0,169.166667 C0,179.084472 7.58219438,186.666667 17.5,186.666667 L140,186.666667 L140,175 L17.5,175 C13.9994305,175 11.6666667,172.667236 11.6666667,169.166667 Z" id="Path" fill="#B5B6B8" />
        <path d="M338.333333,17.5 L338.333333,192.5 C338.333333,196.000569 336.000569,198.333333 332.5,198.333333 L291.666667,198.333333 L291.666667,210 L332.5,210 C342.417806,210 350,202.417806 350,192.5 L350,17.5 L338.333333,17.5 Z" id="Path" fill="#E57373" />
        <polygon id="Path" fill="#E57373" points="23.3333333 198.333333 140 198.333333 140 210 23.3333333 210" />
        <polygon id="Path" fill="#B5B6B8" points="23.3333333 29.1666667 46.6666667 29.1666667 46.6666667 40.8333333 23.3333333 40.8333333" />
        <polygon id="Path" fill="#B5B6B8" points="23.3333333 52.5 46.6666667 52.5 46.6666667 64.1666667 23.3333333 64.1666667" />
        <polygon id="Path" fill="#B5B6B8" points="23.3333333 75.8333333 46.6666667 75.8333333 46.6666667 87.5 23.3333333 87.5" />
        <polygon id="Path" fill="#B5B6B8" points="23.3333333 99.1666667 46.6666667 99.1666667 46.6666667 110.833333 23.3333333 110.833333" />
        <polygon id="Path" fill="#B5B6B8" points="23.3333333 122.5 46.6666667 122.5 46.6666667 134.166667 23.3333333 134.166667" />
        <polygon id="Path" fill="#E57373" points="64.1666667 29.1666667 105 29.1666667 105 40.8333333 64.1666667 40.8333333" />
        <polygon id="Path" fill="#E57373" points="64.1666667 52.5 105 52.5 105 64.1666667 64.1666667 64.1666667" />
        <polygon id="Path" fill="#E57373" points="64.1666667 75.8333333 105 75.8333333 105 87.5 64.1666667 87.5" />
        <polygon id="Path" fill="#E57373" points="64.1666667 99.1666667 105 99.1666667 105 110.833333 64.1666667 110.833333" />
        <polygon id="Path" fill="#E57373" points="64.1666667 122.5 105 122.5 105 134.166667 64.1666667 134.166667" />
        <polygon id="Path" fill="#B5B6B8" points="122.5 52.5 215.833333 52.5 215.833333 64.1666667 122.5 64.1666667" />
        <polygon id="Path" fill="#B5B6B8" points="122.5 30.5 215.833333 30.5 215.833333 42.1666667 122.5 42.1666667" />
        <polygon id="Path" fill="#B5B6B8" points="122.5 75.8333333 215.833333 75.8333333 215.833333 87.5 122.5 87.5" />
        <polygon id="Path" fill="#B5B6B8" points="122.5 99.1666667 215.833333 99.1666667 215.833333 110.833333 122.5 110.833333" />
        <polygon id="Path" fill="#B5B6B8" points="122.5 122.5 157.5 122.5 157.5 134.166667 122.5 134.166667" />
        <polygon id="Path" fill="#E57373" points="233.333333 29.1666667 274.166667 29.1666667 274.166667 40.8333333 233.333333 40.8333333" />
        <polygon id="Path" fill="#E57373" points="233.333333 52.5 274.166667 52.5 274.166667 64.1666667 233.333333 64.1666667" />
        <polygon id="Path" fill="#E57373" points="233.333333 75.8333333 274.166667 75.8333333 274.166667 87.5 233.333333 87.5" />
        <polygon id="Path" fill="#E57373" points="291.666667 29.1666667 303.333333 29.1666667 303.333333 40.8333333 291.666667 40.8333333" />
        <polygon id="Path" fill="#B5B6B8" points="291.666667 52.5 303.333333 52.5 303.333333 64.1666667 291.666667 64.1666667" />
        <polygon id="Path" fill="#B5B6B8" points="291.666667 75.8333333 303.333333 75.8333333 303.333333 87.5 291.666667 87.5" />
        <polygon id="Path" fill="#E57373" points="291.666667 99.1666667 303.333333 99.1666667 303.333333 110.833333 291.666667 110.833333" />
        <polygon id="Path" fill="#E57373" points="233.333333 99.1666667 274.166667 99.1666667 274.166667 110.833333 233.333333 110.833333" />
        <polygon id="Path" fill="#B5B6B8" points="291.666667 145.833333 303.333333 145.833333 303.333333 157.5 291.666667 157.5" />
        <polygon id="Path" fill="#B5B6B8" points="291.666667 122.5 303.333333 122.5 303.333333 134.166667 291.666667 134.166667" />
        <path d="M208,208 L223,208 L223,223 L208,223 L208,208 Z M208,148 L223,148 L223,193 L208,193 L208,148 Z" id="Shape" fill="#E57373" />
      </g>
    </g>
  </SvgIcon>
);

export default ErrorTableSVG;
