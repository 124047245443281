import {
  getAppUrlPathFromId,
  mapAliasToPath,
} from '../../shared/utilities/appUtilities';

const getDynamicRouteProps = (
  currentAccount,
  accountConfig,
  appConfig,
  path,
) => {
  const { taxAppUrlAlias, accountingAppUrlAlias } = appConfig;

  const dynamicRef = {
    [`/${getAppUrlPathFromId('accounting')}/monitoring`]: {
      disabled:
        !currentAccount?.chainalysisIntegrationEnabled
        && currentAccount?.chainalysisIntegrationActive !== undefined
        && !currentAccount?.chainalysisIntegrationActive,
    },
    [`/${getAppUrlPathFromId('accounting')}/integrations`]: {
      disabled: !accountConfig.enableIntegrations,
    },
    [`/${getAppUrlPathFromId('accounting')}/integrations/xero`]: {
      disabled: !accountConfig.enableIntegrations,
    },
    [`/${getAppUrlPathFromId('accounting')}/integrations/qbo`]: {
      disabled: !accountConfig.enableIntegrations,
    },
    [`/${getAppUrlPathFromId('accounting')}/transactions`]: {
      disabled:
        !accountConfig.enableAccountingTransactions || currentAccount?.audit,
    },
    [`/${getAppUrlPathFromId('tax-preparer')}/reports`]: {
      disabled: !accountConfig.enableReports,
    },
    [`${mapAliasToPath('/:app(accounting|tax)', {
      taxAppUrlAlias,
      accountingAppUrlAlias,
    })}/reports`]: {
      disabled: !accountConfig.enableReports,
    },
    [`${mapAliasToPath('/:app(accounting|tax)', {
      taxAppUrlAlias,
      accountingAppUrlAlias,
    })}/sources`]: {
      disabled: !accountConfig.enableSources,
    },
    [`/${getAppUrlPathFromId('tax')}/transactions`]: {
      disabled: !accountConfig.enableTaxTransactions,
    },
    [`/${getAppUrlPathFromId('tax')}/planning`]: {
      disabled: !accountConfig.enablePlanning,
    },
    [`/${getAppUrlPathFromId('accounting')}/contacts`]: {
      disabled: !accountConfig.enableContacts,
    },
    [`/${getAppUrlPathFromId('accounting')}/administration`]: {
      disabled: !accountConfig.enableAccountingSettings,
    },
    [`/${getAppUrlPathFromId('accounting')}`]: {
      disabled: !accountConfig.enableAccountingDashboard,
    },
  };

  return dynamicRef[path] || {};
};

export default getDynamicRouteProps;
