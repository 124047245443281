// absolute imports
import PropTypes from 'prop-types';

import { Form, Field } from 'react-final-form';

import { makeStyles } from 'tss-react/mui';

import { Alert, AlertTitle, IconButton, Typography } from '@mui/material';

// import icons
import {
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

// relative imports
import Loader from '../../../shared/custom-components/Loader';
import LedgibleButton from '../../../shared/custom-components/buttons/LedgibleButton';
import CopyText from '../../../shared/custom-components/CopyText';
import { FormEmailField } from '../../../shared/final-form-field-wrappers';
import { getAppUrlPathFromId } from '../../../shared/utilities/appUtilities';

import {
  LedgibleDialog,
  LedgibleDialogTitle,
  LedgibleDialogContent,
  LedgibleDialogActions,
  LedgibleDialogContentText,
} from '../../../shared/modals';

import {
  composeValidators,
  email,
  requiredField,
} from '../../../shared/utilities/formValidations';

import useApolloErrorState from '../../../shared/custom-hooks/useApolloErrorState';
import useAlternativeDisplay from '../../../shared/custom-hooks/useAlternativeDisplay';

const useStyles = makeStyles()((theme) => ({
  modalText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'green',
  },
  modalIcon: {
    margin: theme.spacing(2),
    fontSize: theme.typography.pxToRem(40),
  },
  iconButton: {
    color: theme.palette.grey[200],
  },
  formRow: {
    width: '100%',
    paddingBottom: theme.spacing(1),
  },
  alertContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  errorAlert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  linkBox: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: '8p',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '.5rem',
  },
}));

const AddTirAdminModal = ({
  submitUser,
  hideModal,
  loading,
  newUserAdded,
  newUserToken,
  goesOver,
}) => {
  const { isMobile } = useAlternativeDisplay();
  const { classes } = useStyles({ isMobile });
  const [addUserError, clearError] = useApolloErrorState(['createTaxPreparer']);

  const onClose = () => {
    if (addUserError) clearError();
  };

  const handleClearAndSubmit = (values) => {
    if (addUserError) {
      clearError();
    }
    submitUser(values);
  };

  return (
    <LedgibleDialog onClose={onClose} scroll="body" fullWidth>
      <LedgibleDialogTitle titleText="Add Team Member">
        {!loading && (
          <IconButton onClick={hideModal} className={classes.iconButton}>
            <CloseIcon />
          </IconButton>
        )}
      </LedgibleDialogTitle>
      <Form
        initialValues={{
          email: '',
        }}
        onSubmit={handleClearAndSubmit}
      >
        {({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            autoComplete="off"
            id="UserMgmt_AddPreparer"
          >
            <LedgibleDialogContent dividers>
              {loading && <Loader />}
              {!loading && newUserAdded && !addUserError && (
                <>
                  <LedgibleDialogContentText
                    className={classes.modalText}
                    gutterBottom
                  >
                    <CheckCircleIcon className={classes.modalIcon} />
                    {newUserAdded === 'existing'
                      ? 'Team Member Added!'
                      : 'New Team Member Added!'}
                  </LedgibleDialogContentText>
                  {newUserAdded === 'existing' && (
                    <LedgibleDialogContentText
                      color="textSecondary"
                      gutterBottom
                    >
                      You have added an existing Ledgible user as a Team Member.
                      They can access this account by logging in with their
                      current credentials.
                    </LedgibleDialogContentText>
                  )}
                  {newUserAdded === 'new' && (
                    <>
                      <LedgibleDialogContentText
                        color="textSecondary"
                        gutterBottom
                      >
                        You have added a new Ledgible user as a Team Member.
                        They will receive an email prompting them to create an
                        account. You can also provide the sign up link below to
                        the new team member directly if you have a preferred
                        communication method.
                      </LedgibleDialogContentText>
                      <LedgibleDialogContentText
                        color="textSecondary"
                        gutterBottom
                      >
                        This link is also available in the Team Members table.
                      </LedgibleDialogContentText>
                      <div className={classes.linkBox}>
                        <CopyText
                          copyVal={`${
                            import.meta.env.REACT_APP_AUTH0_CALLBACK
                          }/${getAppUrlPathFromId(
                            'tax-preparer',
                          )}/signup/${newUserToken}`}
                          TypographyProps={{
                            color: 'secondary',
                            variant: 'body2',
                            display: 'inline',
                          }}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
              {!loading && !newUserAdded && !addUserError && (
                <>
                  {goesOver && (
                    <div className={classes.formRow}>
                      <Alert severity="warning">
                        <AlertTitle>
                          <Typography variant="subtitle1">
                            Plan Limit Reached
                          </Typography>
                        </AlertTitle>
                        <Typography variant="body2">
                          You have reached the limit of users on this Account
                          Plan.
                        </Typography>
                      </Alert>
                    </div>
                  )}
                  <div className={classes.formRow}>
                    <Field
                      id="email"
                      name="email"
                      component={FormEmailField}
                      fullWidth
                      variant="standard"
                      label="Team Member Email"
                      validate={composeValidators(requiredField.email, email)}
                    />
                  </div>
                  <Alert severity="info" className={classes.alertContainer}>
                    <Typography variant="body2">
                      You are adding a new team member to this account. Note
                      that team members can view, manage, and modify all active
                      clients.
                    </Typography>
                  </Alert>
                </>
              )}
              {addUserError && (
                <Alert severity="error" className={classes.errorAlert}>
                  <AlertTitle>Add Preparer Error</AlertTitle>
                  {addUserError.message}
                </Alert>
              )}
            </LedgibleDialogContent>
            {!loading && (
              <LedgibleDialogActions>
                {addUserError && (
                  <LedgibleButton
                    size="small"
                    onClick={clearError}
                    color="cancel"
                    id="preparer-add-preparer-modal-clear-err-button"
                  >
                    Back
                  </LedgibleButton>
                )}
                <LedgibleButton
                  size="small"
                  onClick={hideModal}
                  color="cancel"
                  id="preparer-add-preparer-modal-close/cancel-button"
                >
                  {newUserAdded || addUserError ? 'Close' : 'Cancel'}
                </LedgibleButton>
                {!newUserAdded && !addUserError && (
                  <LedgibleButton
                    size="small"
                    type="submit"
                    color="primary"
                    id="preparer-add-preparer-modal-add-button"
                  >
                    Add
                  </LedgibleButton>
                )}
              </LedgibleDialogActions>
            )}
          </form>
        )}
      </Form>
    </LedgibleDialog>
  );
};

export default AddTirAdminModal;

AddTirAdminModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  newUserAdded: PropTypes.string,
  newUserToken: PropTypes.string,
  goesOver: PropTypes.bool.isRequired,
  submitUser: PropTypes.func.isRequired,
  // clientAccounts: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

AddTirAdminModal.defaultProps = {
  newUserAdded: null,
  newUserToken: null,
};
