/**
 *  WILL RETURN FULL EXPONENT STR
 */
export const convertExpToString = (num) => {
  const data = String(num).split(/[eE]/);
  if (data.length === 1) return data[0];

  let z = '';
  const sign = num < 0 ? '-' : '';
  const str = data[0].replace('.', '');
  let mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = `${sign}0.`;
    while (mag < 0) {
      z += '0';
      mag += 1;
    }
    return z + str.replace(/^-/, '');
  }
  mag -= str.length;
  while (mag > 0) {
    z += '0';
    mag -= 1;
  }
  return str + z;
}

/**
 * 
 */
export const checkIfExponential = (num) => {
  const evaluate = parseFloat(num)?.toString()?.toLowerCase();
  return evaluate && evaluate.includes('e');
}

export const countDecimals = (value) => {
  if (!value) return 0;
  const text = typeof value === 'number' ? value.toString() : value;
  // verify if number 0.000005 is represented as "5e-6"
  if (text.indexOf('e-') > -1) {
    const [, trail] = text.split('e-');
    return parseInt(trail, 10);
  }
  // count decimals for number in representation like "0.123456"
  if (Math.floor(value) !== value) {
    return value.toString().split(".")[1].length || 0;
  }
  return 0;
}

/**
 * Prints string value for number that is formatted with scientific notation 
*/
export const getBigNumberString = (num, options = {}) => {
  const {
    decimals = 8,
    useAmountDecimals = false,
    useRawFormat = false,
  } = options;

  const countAmountDecimals = useAmountDecimals || useRawFormat;
  let toDecimals = countAmountDecimals ? undefined : decimals;
  const str = parseFloat(num).toPrecision(toDecimals);
  const numString = convertExpToString(str);

  // converting here to get the correct number of decimals places
  if (useAmountDecimals) toDecimals = countDecimals(numString);
  const [wholeNums, decimalNums] = numString.split('.');

  // if no decimals, no need to continue
  if (!wholeNums || !decimalNums) return numString;

  const supportedDecimals = decimalNums.slice(0, toDecimals);
  const greaterThan0 = parseFloat(wholeNums) > 0;

  if (greaterThan0 && !useRawFormat) {
    const wholeNumberString = wholeNums.toLocaleString('en-US');
    return `${wholeNumberString}.${supportedDecimals}`
  }
  // useRawFormat
  if (greaterThan0) return `${wholeNums}.${supportedDecimals}`
  return `0.${supportedDecimals}`;
};

export const getRandomNumberInRange = (min, max) => {
  const minimum = Math.ceil(min);
  const maximum = Math.floor(max);
  // The maximum is exclusive and the minimum is inclusive
  return Math.floor(Math.random() * (maximum - minimum) + minimum);
};

export const checkForZeroCurrencyValue = (val, toFixed = 2) => {
  if (Math.abs((val || 0).toFixed(toFixed)) === 0) return 0;
  return val;
};

export const abbreviateNumbers = (num, compact = 10000) => {
  if (num >= compact) {
    return Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1,
    }).format(num);
  }
  return num;
};
