// absolute imports
import {
  createContext,
  useLayoutEffect,
  useContext,
  useReducer,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { nanoid } from 'nanoid';

// relative imports
import {
  serializeStateObject,
  deserializeStateObject,
} from '../../shared/utilities/stateUtilities';
import { dispatchToDataLayer } from '../../shared/google-tag-manager/GTMContainer';

export const ModalContext = createContext();
export const ModalActionsContext = createContext();

// I create a useModal custom hook which will be used to use this context
export const useModal = () => useContext(ModalContext);
export const useModalActions = () => useContext(ModalActionsContext);

function modalReducer(state, action) {
  switch (action.type) {
    case 'hideLastModal':
      return state.slice(0, -1);
    case 'hideModal':
      return state.filter(({ uuid }) => uuid !== action.uuid);
    case 'showModal':
      return [
        ...state,
        {
          key: action.key,
          props: action.props,
          uuid: action.uuid,
        },
      ];
    default:
      return state;
  }
}

const initialState = [];

export const ModalContextProvider = ({ children }) => {
  const { listen, push, replace } = useHistory();
  const { state } = useLocation();
  const prevStateRef = useRef();
  const [modals, dispatch] = useReducer(modalReducer, initialState);

  const hideModal = useCallback(
    (uuid, shouldPushHistory = false) => {
      const historyAction = shouldPushHistory ? push : replace;
      const prevState = prevStateRef.current;

      dispatch({
        type: 'hideModal',
        uuid,
      });

      historyAction({
        state: {
          ...prevState,
          modals: prevState?.modals?.filter(
            ({ uuid: prevStateUuid }) => prevStateUuid !== uuid,
          ),
        },
      });
    },
    [push, replace],
  );

  const hideLastModal = useCallback(
    () => hideModal(modals?.at(-1).uuid),
    [hideModal, modals],
  );

  const showModal = useCallback(
    (key, props, uuid = nanoid(10), shouldUpdateHistory = true) => {
      const dataLayerArgs = {
        dataLayer: {
          event: 'ModalOpen',
          modalName: key,
        },
      };
      const prevState = prevStateRef.current;

      dispatch({
        type: 'showModal',
        key,
        props,
        uuid,
      });

      // dispatch modal opening event on modal open
      if (!window.Cypress) {
        dispatchToDataLayer(dataLayerArgs);
      }

      // eslint-disable-next-line react/prop-types
      if (shouldUpdateHistory && !props?.noHash) {
        if (prevState?.modals?.length > 0) {
          replace({
            state: {
              ...prevState,
              modals: [
                ...(prevState?.modals || []),
                {
                  key,
                  props: serializeStateObject(props),
                  uuid,
                },
              ],
            },
          });
          return;
        }

        push({
          state: {
            ...prevState,
            modals: [
              {
                key,
                props: serializeStateObject(props),
                uuid,
              },
            ],
          },
        });
      }
    },
    [push, replace],
  );

  useLayoutEffect(() => {
    listen((location, action) => {
      const prevState = prevStateRef.current;
      if (action === 'POP' && prevState?.modals?.length > 0) {
        hideModal(prevState.modals.at(-1).uuid, true);
        return;
      }
      prevStateRef.current = location.state;
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const stateModalKeys = useMemo(() => {
    if (!state?.modals?.length) return [];
    return state?.modals?.map(({ key }) => key).sort();
  }, [state?.modals]);

  const displayedModalKeys = useMemo(() => {
    if (!modals?.length) return [];
    return modals.map(({ key }) => key).sort();
  }, [modals]);

  useEffect(() => {
    if (stateModalKeys.join(',') !== displayedModalKeys.join(',') && state?.modals) {
      state.modals.forEach(({ key, props, uuid }) =>
        showModal(key, deserializeStateObject(props), uuid, false),
      );
      prevStateRef.current = state;
    }
  }, [displayedModalKeys, showModal, state, stateModalKeys]);

  const modalValue = useMemo(() => ({ modals }), [modals]);

  const modalActionsValue = useMemo(
    () => ({
      dispatch,
      hideModal,
      hideLastModal,
      showModal,
    }),
    [hideModal, hideLastModal, showModal],
  );

  return (
    <ModalContext.Provider value={modalValue}>
      <ModalActionsContext.Provider value={modalActionsValue}>
        {children}
      </ModalActionsContext.Provider>
    </ModalContext.Provider>
  );
};

export const ModalContextConsumer = ModalContext.Consumer;

ModalContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.shape()]).isRequired,
};
