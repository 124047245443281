// absolute imports
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

// GTM -> initialize tag manager
const tagManagerBase = {
  gtmId: import.meta.env.REACT_APP_GTM_TRACKING_ID,
  auth: import.meta.env.REACT_APP_GTM_AUTH,
  preview: import.meta.env.REACT_APP_GTM_PREVIEW,
  dataLayer: {
    application: 'none',
  },
};
TagManager.initialize(tagManagerBase);

export const dispatchToDataLayer = (dlArgs) => {
  if (!window.Cypress) {
    TagManager.dataLayer(dlArgs);
  }
};

export const GoogleTagManager = () => {
  const { pathname, state: locationState } = useLocation();

  useEffect(() => {
    // produce DL event on history changes
    const dataLayerArgs = {
      dataLayer: {
        event: 'Pageview',
        pagePath: pathname,
        activeModal: locationState && locationState.activeModal,
      },
    };
    dispatchToDataLayer(dataLayerArgs);
  }, [pathname, locationState]);

  return null;
};
