// absolute imports
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { CardActions, CardContent, Typography } from '@mui/material';

// icon imports
import { ArrowRightRounded as ArrowRightIcon } from '@mui/icons-material';

// relative imports
import LedgibleButton from '../../../../../shared/custom-components/buttons/LedgibleButton';

const useStyles = makeStyles()((theme) => ({
  headerText: {
    marginBottom: theme.spacing(2),
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
}));

const WelcomeStep = ({ handleNext }) => {
  const { classes } = useStyles();

  return (
    <>
      <CardContent>
        <Typography
          className={classes.headerText}
          color="secondary"
          gutterBottom
          variant="h6"
          align="center"
        >
          Welcome to Ledgible Crypto Tax!
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="subtitle1">
          Let&apos;s confirm some account settings to make sure your crypto tax
          results are as accurate as possible.
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="subtitle1">
          If you need to exit this setup for any reason, you can modify these
          settings later under your account settings page.
        </Typography>
      </CardContent>
      <CardActions className={classes.actionRow} disableSpacing>
        <LedgibleButton
          variant="contained"
          size="large"
          color="success"
          textTransformOff
          onClick={handleNext}
          endIcon={<ArrowRightIcon />}
          id="tax-setup-flow-step-1-get-started"
        >
          Start Account Setup
        </LedgibleButton>
      </CardActions>
    </>
  );
};

export default WelcomeStep;

WelcomeStep.propTypes = {
  handleNext: PropTypes.func.isRequired,
};
