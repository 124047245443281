
    import { getRemoteAppConfig } from '/src/data/app-config/configs/configUtilities';

    const setTitleAndFavicon = async () => {
      const appConfig = await getRemoteAppConfig();
      const favicons = document.querySelectorAll('link[rel*="icon"]');
      const { defaultDocumentTitle, favicon16Href, favicon32Href, favicon96Href } = appConfig;

      // set favicons
      favicons.forEach((icon) => {
        if (icon.id === 'fav16') icon.href = favicon16Href;
        if (icon.id === 'fav32') icon.href = favicon32Href;
        if (icon.id === 'fav96') icon.href = favicon96Href;
      });
      // set document title
      document.title = defaultDocumentTitle;
    };
    setTitleAndFavicon();
  