import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material';

const useStyles = makeStyles()(() => ({
  tooltip: {
    visibility: 'visible',
    marginTop: -10,
    maxWidth: 200,

    '& .MuiTooltip-arrow': {
      left: '12px !important',
    },
  },
}));

const FormRadioGroup = ({
  input: { onChange, value },
  options,
  RadioProps,
  defaultValue,
  fullWidth,
  label,
  FormControlProps,
  FormLabelProps,
  FormControlLabelProps,
  ...rest
}) => {
  const { classes } = useStyles();

  return (
    <FormControl
      margin="dense"
      size="small"
      fullWidth={fullWidth}
      component="fieldset"
      {...FormControlProps}
    >
      {label && (
        <FormLabel component="legend" {...FormLabelProps}>
          {label}
        </FormLabel>
      )}
      <RadioGroup
        value={value === '' ? defaultValue : value}
        onChange={onChange}
        {...rest}
      >
        {options.map((option) => (
          <Tooltip
            key={option.key}
            classes={{
              popper: option.tooltip ? classes.tooltip : null,
            }}
            title={option.tooltip || ''}
            placement="bottom-start"
            enterTouchDelay={10}
            arrow
          >
            <FormControlLabel
              value={option.value}
              control={<Radio disabled={!!option.disabled} {...RadioProps} />}
              label={option.label}
              classes={classes && { ...classes }}
              {...FormControlLabelProps}
            />
          </Tooltip>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default FormRadioGroup;

// Radio Group prop types
FormRadioGroup.propTypes = {
  RadioProps: PropTypes.shape(),
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.bool.isRequired,
    ]).isRequired,
  }).isRequired,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  classes: PropTypes.shape(),
  defaultValue: PropTypes.string,
  FormControlProps: PropTypes.shape(),
  FormLabelProps: PropTypes.shape(),
  FormControlLabelProps: PropTypes.shape(),
};

FormRadioGroup.defaultProps = {
  label: null,
  fullWidth: true,
  RadioProps: {},
  classes: {},
  defaultValue: null,
  FormControlProps: null,
  FormLabelProps: null,
  FormControlLabelProps: null,
};
