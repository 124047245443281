// absolute imports
import { useState, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useMutation } from '@apollo/client';
import { Alert, Snackbar } from '@mui/material';

// relative imports
import { useAccount } from '../../common/providers/AccountProvider';
import { UPDATE_ACCOUNT } from '../../../data/apollo/mutations';
import LedgibleButton from './buttons/LedgibleButton';
import { useConfig } from '../../common/providers/ConfigProvider';
import { useAuth0 } from '../../common/providers/Auth0Provider';

const useStyles = makeStyles()((theme) => ({
  alertContainer: {
    border: `1px solid ${theme.palette.secondary}`,
  },
}));

const AccountDisclaimer = () => {
  const { accountConfig } = useConfig();
  const { classes } = useStyles();
  const [hide, setHide] = useState(false);
  const { user } = useAuth0();
  const { currentAccount, loadingAccountInfo } = useAccount();
  const { subtypeFields } = currentAccount;
  const [updateAccount] = useMutation(UPDATE_ACCOUNT);

  const showDisclaimer = useMemo(
    () =>
      !loadingAccountInfo &&
      accountConfig.disclaimerMessage &&
      !hide &&
      !subtypeFields?.disclaimerAcknowledged &&
      user?.profile.email_verified,
    [
      loadingAccountInfo,
      accountConfig.disclaimerMessage,
      hide,
      subtypeFields?.disclaimerAcknowledged,
      user?.profile.email_verified,
    ],
  );

  const handleAcknowledge = (event, reason) => {
    if (reason === 'clickaway') return;
    if (reason !== 'timeout') {
      updateAccount({
        variables: {
          accountId: currentAccount.id,
          updateData: {
            subtypeFields: {
              disclaimerAcknowledged: true,
            },
          },
        },
      });
    }
    setHide(true);
  };

  return (
    <Snackbar
      open={showDisclaimer}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={12000}
      onClose={handleAcknowledge}
    >
      <Alert
        variant="standard"
        severity="warning"
        closeText="Got It"
        className={classes.alertContainer}
        action={
          <LedgibleButton
            size="small"
            variant="outlined"
            color="secondary"
            onClick={handleAcknowledge}
            id="account-disclaimer-acknowledger-btn"
          >
            Okay
          </LedgibleButton>
        }
      >
        {accountConfig.disclaimerMessage}
      </Alert>
    </Snackbar>
  );
};

export default AccountDisclaimer;
