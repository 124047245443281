export const roleDisplayRef = {
  owner: 'Owner',
  admin: 'Admin',
  full: 'Full',
  read_only: 'Read Only',
};

export const disabledText = {
  accountSettingsView:
    'You cannot view Account Settings with this permission level',
  accountAdd: 'You are not authorized to add new Accounts',
  accountDelete: 'You are not authorized to delete this Account',
  accountEdit: 'You cannot edit Account details with this permission level',
  sourceView: 'You cannot view Wallets with this permission level',
  sourceAdd: 'You cannot add Wallets with this permission level',
  sourceDelete: 'You cannot delete Wallets with this permission level',
  sourceEdit: 'You cannot edit Wallet details with this permission level',
  custodianDelete:
    'You cannot delete custodian connections with this permission level',
  addressView: 'You cannot view Wallet Addresses with this permission level',
  addressAdd: 'You cannot add Addresses with this permission level',
  addressDelete:
    'You cannot delete Wallet Addresses with this permission level',
  reportsView: 'You cannot view Reports with this permission level',
  reportCreate: 'You cannot create Reports with this permission level',
  reportDelete: 'You cannot delete Reports with this permission level',
  reportCancel: 'You cannot cancel Reports with this permission level',
  contactView: 'You cannot view Contacts with this permission level',
  contactAdd: 'You cannot add Contacts with this permission level',
  contactDelete: 'You cannot delete Contacts with this permission level',
  contactEdit: 'You cannot edit Contact details with this permission level',
  userView: 'You cannot view Account Users with this permission level',
  userAdd: 'You cannot add Account Users with this permission level',
  userEdit: 'You are not authorized to change User Permission levels',
  userDelete: 'You are not authorized to remove Account Users',
  tmpUserView: 'You cannot view Requested Users with this permission level',
  tmpUserResend: 'You cannot resend User invites with this permission level',
  tmpUserDelete: 'You cannot cancel User invites with this permission level',
  billingView: 'You do not have permission to manage billing',
  usageView: 'You cannot view account usage with this permission level',
  txSearchView:
    'You cannot view transaction search features with this permission level',
  integrationsEdit: 'You cannot edit integrations with this permission level',
  integrationsView: 'You cannot view integrations with this permission level',
  teamEdit: 'You do not have permissions to manage Team Members',
  teamView: 'You cannot view Team Members with this permission level',
  editClient: 'You cannot edit client details with this permission level',
  xPubAdd: 'You cannot add Addresses with this permission level',
};

// Permissions object creation function
const rolePermissions = (
  addAccount,
  isPreparerViewingClient,
  isTirAdminViewingClient,
) => ({
  owner: {
    role: 'Owner',
    isPreparerViewingClient,
    isTirAdminViewingClient,
    accountSettingsView: true,
    accountAdd: addAccount,
    accountDelete: true,
    accountEdit: true,
    billingView: true,
    usageView: true,
    sourceView: true,
    sourceAdd: true,
    sourceDelete: true,
    sourceEdit: true,
    custodianDelete: true,
    addressView: true,
    addressAdd: true,
    addressDelete: true,
    reportsView: true,
    reportCreate: true,
    reportDelete: true,
    contactView: true,
    contactAdd: true,
    contactDelete: true,
    contactEdit: true,
    userView: true,
    userAdd: true,
    userEdit: true,
    userDelete: true,
    tmpUserView: true,
    tmpUserResend: true,
    tmpUserDelete: true,
    tmpUserUrls: true,
    txSearchView: true,
    integrationsEdit: true,
    integrationsDelete: true,
    integrationsView: true,
    teamView: true,
    teamEdit: true,
    editClient: true,
    clientDelete: true,
    xPubAdd: true,
    disabledText,
    logsView: true,
  },
  admin: {
    role: 'Admin',
    isPreparerViewingClient,
    isTirAdminViewingClient,
    accountSettingsView: true,
    accountAdd: addAccount,
    accountDelete: false,
    accountEdit: true,
    billingView: false,
    usageView: true,
    sourceView: true,
    sourceAdd: true,
    sourceDelete: true,
    sourceEdit: true,
    custodianDelete: true,
    addressView: true,
    addressAdd: true,
    addressDelete: true,
    reportsView: true,
    reportCreate: true,
    reportDelete: true,
    contactView: true,
    contactAdd: true,
    contactDelete: true,
    contactEdit: true,
    userView: true,
    userAdd: true,
    userEdit: true,
    userDelete: true,
    tmpUserView: true,
    tmpUserResend: true,
    tmpUserDelete: true,
    tmpUserUrls: true,
    txSearchView: true,
    integrationsEdit: true,
    integrationsDelete: true,
    integrationsView: true,
    teamView: true,
    teamEdit: true,
    editClient: true,
    clientDelete: true,
    xPubAdd: true,
    disabledText,
    logsView: true,
  },
  full: {
    role: 'Full',
    isPreparerViewingClient,
    isTirAdminViewingClient,
    accountSettingsView: true,
    accountAdd: addAccount,
    accountDelete: false,
    accountEdit: false,
    billingView: false,
    usageView: true,
    sourceView: true,
    sourceAdd: true,
    sourceDelete: true,
    sourceEdit: true,
    custodianDelete: true,
    bitgoCxDelete: true,
    addressView: true,
    addressAdd: true,
    addressDelete: true,
    reportsView: true,
    reportCreate: true,
    reportDelete: true,
    contactView: true,
    contactAdd: true,
    contactDelete: true,
    contactEdit: true,
    userView: false,
    userAdd: false,
    userEdit: false,
    userDelete: false,
    tmpUserView: false,
    tmpUserResend: false,
    tmpUserDelete: false,
    tmpUserUrls: false,
    txSearchView: true,
    integrationsEdit: true,
    integrationsDelete: true,
    integrationsView: true,
    teamView: true,
    teamEdit: false,
    editClient: true,
    clientDelete: false,
    xPubAdd: true,
    disabledText,
    logsView: true,
  },
  read_only: {
    role: 'Read Only',
    isPreparerViewingClient,
    isTirAdminViewingClient,
    accountSettingsView: true,
    accountAdd: addAccount,
    accountDelete: false,
    accountEdit: false,
    billingView: false,
    usageView: true,
    sourceView: true,
    sourceAdd: false,
    sourceDelete: false,
    sourceEdit: false,
    custodianDelete: false,
    addressView: true,
    addressAdd: false,
    addressDelete: false,
    reportsView: true,
    reportCreate: true,
    reportDelete: false,
    contactView: true,
    contactAdd: false,
    contactDelete: false,
    contactEdit: false,
    userView: false,
    userAdd: false,
    userEdit: false,
    userDelete: false,
    tmpUserView: false,
    tmpUserResend: false,
    tmpUserDelete: false,
    tmpUserUrls: false,
    txSearchView: true,
    integrationsEdit: false,
    integrationsDelete: false,
    integrationsView: true,
    teamView: false,
    teamEdit: false,
    editClient: false,
    clientDelete: false,
    xPubAdd: false,
    disabledText,
    logsView: false,
  },
});

export const generateClientPermissions = (
  addAccount,
  isPreparerViewingClient,
  isTirAdminViewingClient,
  role,
  isOwner,
) => {
  if (isOwner) {
    return rolePermissions(
      addAccount,
      isPreparerViewingClient,
      isTirAdminViewingClient,
    ).owner;
  }
  return rolePermissions(
    addAccount,
    isPreparerViewingClient,
    isTirAdminViewingClient,
  )[role];
};
