// absolute imports
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useReactiveVar } from '@apollo/client';

// relative imports
import {
  formStateVar,
} from '../../../data/apollo/cache/reactiveVars';
import FormPristineSynchronizer from '../final-form-field-wrappers/FormPristineSynchronizer';

const MultiPageForm = ({
  persist,
  children,
  goToPage,
  validate,
  formSubmit,
  formOptions,
  setGoToPage,
  renderHeader,
  initialValues,
  formPageSubmissions,
  formPageValidations,
  persistFieldsExcluded,
  submitError,
  ...extraProps
}) => {
  const formState = useReactiveVar(formStateVar);

  const [page, setPage] = useState(goToPage);
  const [values, setValues] = useState(initialValues);

  const activePage = React.Children.toArray(children)[page];
  const isLastPage = page === React.Children.count(children) - 1;

  /**
   * {} - options param
   * valid options
   * customPage: number - takes number to set for page if should skip some pages
   */

  const defaultOptions = {
    customPage: null,
  };

  const nextPage = (vals, options = defaultOptions) => {
    const { customPage } = options;
    const pageIndex = Math.min(page + 1, children.length - 1);

    setPage(customPage || customPage === 0 ? customPage : pageIndex);

    if (setGoToPage) {
      setGoToPage(customPage || customPage === 0 ? customPage : pageIndex);
    }
    setValues(vals);
  };

  const previousPage = (options = defaultOptions) => {
    const { customPage } = options;
    const pageIndex = Math.max(page - 1, 0);
    setPage(customPage || customPage === 0 ? customPage : pageIndex);

    if (setGoToPage) {
      setGoToPage(customPage || customPage === 0 ? customPage : pageIndex);
    }
  };

  const handleValidation = (vals) => {
    if (formPageValidations && formPageValidations[page]) {
      const currentPageValidate = formPageValidations[page];
      return currentPageValidate(vals);
    }
    if (validate) {
      return validate(vals);
    }
    return {};
  };

  const handlePageSubmit = (vals) => {
    // get submission values
    const submissionValues = persist ? { ...formState, ...vals } : vals;

    if (persist && persistFieldsExcluded?.length) {
      persistFieldsExcluded.forEach((excludeField) => {
        delete submissionValues[excludeField];
      });
    }

    // persist form values
    if (persist) {
      formStateVar(submissionValues);
    }
    if (isLastPage) {
      // reset form on submit
      if (!persist) formStateVar({});
      return formSubmit(submissionValues);
    }

    // page specific form submissions
    if (formPageSubmissions && formPageSubmissions[page]) {
      const currentPageSubmit = formPageSubmissions[page];
      return currentPageSubmit(vals, (options) => nextPage(vals, options));
    }

    return nextPage(vals);
  };

  return (
    <>
      {renderHeader && renderHeader({ values, page, setPage })}
      <Form
        initialValues={values}
        validate={!handleValidation}
        onSubmit={handlePageSubmit}
        {...formOptions}
      >
        {({ handleSubmit, pristine, ...rest }) => (
          <form onSubmit={handleSubmit}>
            <FormPristineSynchronizer
              pristine={
                page === 0 || (!submitError && isLastPage) ? pristine : false
              }
              submitError={submitError}
            />
            {React.cloneElement(activePage, {
              nextPage,
              previousPage,
              setPage,
              pristine,
              updateMultiPageFormState: setValues,
              currentPage: page,
              setFormState: formStateVar,
              ...rest,
              ...extraProps,
            })}
          </form>
        )}
      </Form>
    </>
  );
};

export default MultiPageForm;

MultiPageForm.propTypes = {
  persist: PropTypes.bool,
  validate: PropTypes.func,
  goToPage: PropTypes.number,
  setGoToPage: PropTypes.func,
  renderHeader: PropTypes.func,
  formOptions: PropTypes.shape(),
  initialValues: PropTypes.shape(),
  children: PropTypes.node.isRequired,
  formSubmit: PropTypes.func.isRequired,
  formPageSubmissions: PropTypes.shape(),
  formPageValidations: PropTypes.shape(),
  persistFieldsExcluded: PropTypes.arrayOf(PropTypes.string),
  submitError: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
    PropTypes.bool,
  ]),
};

MultiPageForm.defaultProps = {
  goToPage: 0,
  persist: false,
  persistFieldsExcluded: null,
  validate: () => {},
  formOptions: {},
  setGoToPage: null,
  initialValues: {},
  renderHeader: null,
  formPageSubmissions: null,
  formPageValidations: null,
  submitError: null,
};
