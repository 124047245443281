// absolute imports
import PropTypes from 'prop-types';
import { Divider, Grid, Typography, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// relative imports
import useAlternativeDisplay from '../custom-hooks/useAlternativeDisplay';

const useStyles = makeStyles()((theme) => ({
  fieldText: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.secondary,
  },
  divider: {
    width: '100%',
    margin: theme.spacing(1, 0),
  },
}));

const FormReviewField = ({
  input: { value },
  displayName,
  nullValue,
  hideDivider,
}) => {
  const { classes } = useStyles();
  const { isMobile } = useAlternativeDisplay();
  const displayValue =
    typeof value === 'boolean' ? value.toString() : value || nullValue;

  return value || nullValue ? (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        wrap={isMobile ? 'wrap' : 'nowrap'}
      >
        <Grid item xs={4}>
          <Typography display="inline" variant="body1" color="secondary" noWrap>
            {displayName}:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Tooltip
            followCursor
            title={displayValue?.length > 35 ? displayValue : null}
            placement="top"
            arrow
          >
            <Typography
              variant="body1"
              color="textSecondary"
              noWrap
              align="right"
              className={classes.fieldText}
            >
              {displayValue}
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>
      {!hideDivider && (
        <div className={classes.divider}>
          <Divider light />
        </div>
      )}
    </>
  ) : null;
};

export default FormReviewField;

FormReviewField.propTypes = {
  input: PropTypes.shape().isRequired,
  displayName: PropTypes.string.isRequired,
  nullValue: PropTypes.string,
  hideDivider: PropTypes.bool,
};

FormReviewField.defaultProps = {
  nullValue: null,
  hideDivider: null,
};
