// absolute imports
import { useEffect, useRef } from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import {
  ACCOUNT_UPDATE,
  ACCOUNTS_UPDATE,
  EXCHANGE_UPDATE,
  EXCHANGES_UPDATE,
  WALLET_UPDATE,
  WALLETS_UPDATE,
} from '../../../data/apollo/queries';

// relative imports
import {
  currentAccountIdVar,
  appTypeVar,
} from '../../../data/apollo/cache/reactiveVars';

// available preset queries
const getPollingQueryPresets = () => ({
  account: {
    id: 'account',
    query: ACCOUNT_UPDATE,
    defaultVariables: {
      accountId: currentAccountIdVar(),
      defaultType: appTypeVar(),
    },
  },
  accounts: {
    id: 'accounts',
    query: ACCOUNTS_UPDATE,
    defaultVariables: {
      type: appTypeVar(),
    },
  },
  exchange: {
    id: 'exchange',
    query: EXCHANGE_UPDATE,
    defaultVariables: {
      accountId: currentAccountIdVar(),
    },
  },
  exchanges: {
    id: 'exchanges',
    query: EXCHANGES_UPDATE,
    defaultVariables: {
      accountId: currentAccountIdVar(),
    },
  },
  wallet: {
    id: 'wallet',
    query: WALLET_UPDATE,
    defaultVariables: {
      accountId: currentAccountIdVar(),
    },
  },
  wallets: {
    id: 'wallets',
    query: WALLETS_UPDATE,
    defaultVariables: {
      accountId: currentAccountIdVar(),
    },
  },
});

/* track update state of specified resource */
const useUpdatePolling = ({
  preset,
  query,
  variables,
  queryOptions = {},
  updateCondition = false,
  onUpdateComplete,
  onUpdateStart,
  disabled,
  pollInterval = 5000,
}) => {
  const queryPreset = getPollingQueryPresets()[preset];
  const apolloClient = useApolloClient();
  const queryDoc = queryPreset?.query || query;
  const queryVariables = queryPreset
    ? { ...queryPreset.defaultVariables, ...variables }
    : variables;

  const queryName = queryDoc.definitions[0].name.value;
  const activeQueriesMap = updateCondition
    ? apolloClient.getObservableQueries([queryName])
    : [];

  const pollingInProgress = !![...activeQueriesMap.values()].find(
    (entry) => !!entry.pollingInfo,
  );

  // throw err if hook is called without preset or query
  if (!queryPreset && !query) {
    throw new Error(
      `useUpdatePolling hook requires a query argument or preset argument of one of [${Object.keys(
        getPollingQueryPresets(),
      ).join(', ')}]`,
    );
  }

  // Ref for tracking updateCondition change
  const wasUpdatingRef = useRef(false);

  const { startPolling, stopPolling } = useQuery(queryDoc, {
    variables: queryVariables,
    fetchPolicy: pollingInProgress ? 'cache-first' : 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !updateCondition,
    ...queryOptions,
  });

  // Handle updates
  useEffect(() => {
    // poll if condition turns true
    if (updateCondition && !wasUpdatingRef.current) {
      if (!pollingInProgress) {
        startPolling(pollInterval);
      }
      if (onUpdateStart && !disabled) {
        onUpdateStart();
      }
      wasUpdatingRef.current = true;
    }

    // stop poll if condition turns false or poll returns false
    if (!updateCondition && wasUpdatingRef.current) {
      if (pollingInProgress) {
        stopPolling();
      }
      if (onUpdateComplete && !disabled) {
        onUpdateComplete();
      }
      wasUpdatingRef.current = false;
    }
  }, [
    startPolling,
    pollInterval,
    stopPolling,
    updateCondition,
    onUpdateStart,
    onUpdateComplete,
    pollingInProgress,
    disabled,
    preset,
  ]);
};

export default useUpdatePolling;
