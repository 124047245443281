// absolute imports
import { useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { IconButton, MenuItem, MenuList } from '@mui/material';
import { useReactiveVar } from '@apollo/client';

// icon imports
import {
  HelpOutline as HelpIcon,
  SchoolOutlined as KnowledgeBaseIcon,
  ContactSupportOutlined as ContactUsIcon,
  VisibilityOutlined as ShowIcon,
  VisibilityOffOutlined as HideIcon
} from '@mui/icons-material';


// relative imports
import ExtendedPopper from '../custom-components/ExtendedPopper';
import { showHelpWidgetVar } from '../../../data/apollo/cache/reactiveVars';
import { openZendeskWidget } from './zendesk-operations';
import { useAppManagement } from '../../common/providers/AppManagementProvider';



const useStyles = makeStyles()((theme) => ({
  supportButton: {
    color: theme.whiteLabel.Navigation.color || theme.palette.common.white,
    marginRight: theme.spacing(2),
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.2)',
    },
  },
  helpIcon: {
    fontSize: theme.typography.pxToRem(24),
  },
  menuList: {
    padding: theme.spacing(0),
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: theme.typography.pxToRem(14),
    '&:hover': {
      color: '#000',
      textDecoration: 'none',
    },
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  menuIcon: {
    marginRight: theme.spacing(1),
  },
}));

function getKbLinkFromContext(appType) {
  switch (appType) {
    case 'tax':
      return 'https://support.ledgible.io/hc/en-us/categories/360002561431-Ledgible-Tax';
    case 'tax-preparer':
      return 'https://support.ledgible.io/hc/en-us/categories/360005508671-Ledgible-Tax-Pro';
    case 'accounting':
      return 'https://support.ledgible.io/hc/en-us/categories/360002561451-Ledgible-Accounting';
    default:
      return 'https://support.ledgible.io/hc/en-us/categories/360005867032-About-Ledgible-by-Verady';
  }
}

const HelpMenu = () => {
  const { classes } = useStyles();
  const showHelpWidget = useReactiveVar(showHelpWidgetVar);
  const { appType } = useAppManagement();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const kbLink = getKbLinkFromContext(appType);

  const handleClose = (event) => {
    if (
      anchorRef.current &&
      event &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleToggleMenu = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const toggleHelpWidgetVisibility = () => {
    showHelpWidgetVar(!showHelpWidget);
  };

  return (
    <ExtendedPopper
      content={
        <MenuList disableListWrap className={classes.menuList}>
          <MenuItem
            component="a"
            onClick={handleClose}
            href={kbLink}
            className={classes.menuItem}
            target="_blank"
            id="support-menu-item-help"
            data-cy="support-menu-item-product-guide-link"
            divider
          >
            <KnowledgeBaseIcon className={classes.menuIcon} fontSize="small" />
            Product Guide
          </MenuItem>
          <MenuItem
            onClick={() => {
              openZendeskWidget();
              handleClose();
            }}
            className={classes.menuItem}
            id="support-menu-item-contact-us"
            divider
          >
            <ContactUsIcon className={classes.menuIcon} fontSize="small" />
            Contact Support
          </MenuItem>
          <MenuItem
            onClick={toggleHelpWidgetVisibility}
            className={classes.menuItem}
            id="support-menu-item-contact-us"
          >
            {showHelpWidget ? (
              <HideIcon className={classes.menuIcon} fontSize="small" />
            ) : (
              <ShowIcon className={classes.menuIcon} fontSize="small" />
            )}
            {showHelpWidget ? 'Hide Help Widget' : 'Show Help Widget'}
          </MenuItem>
        </MenuList>
      }
      arrow
      open={open}
      placement="bottom-end"
      clickAwayHandler={handleClose}
      padContent={false}
    >
      <IconButton
        aria-label="toggle help menu"
        onClick={handleToggleMenu}
        className={classes.supportButton}
        size="small"
        aria-controls="menu-list-grow"
        aria-haspopup="true"
        ref={anchorRef}
        id="top-nav-help-menu-button"
        data-cy="top-nav-help-menu-button"
      >
        <HelpIcon className={classes.helpIcon} />
      </IconButton>
    </ExtendedPopper>
  );
};

export default HelpMenu;
