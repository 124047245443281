// absolute imports
import PropTypes from 'prop-types';
import {
  ApolloClient,
  ApolloProvider,
  ApolloLink,
} from '@apollo/client';
import { useHistory } from 'react-router-dom';

// relative imports
import errorLink from '../../../data/apollo/client/errorLink';
import { authLink, skipAuthLink } from '../../../data/apollo/client/authLink';
import retryLink from '../../../data/apollo/client/retryLink';
import { ledgibleAPILink, ledgibleCMSLink } from '../../../data/apollo/client/httpLink';
import restLink from '../../../data/apollo/client/restLink';
import clientTypeDefs from '../../../data/apollo/client/clientTypeDefs';

const AuthorizedApolloProvider = ({
  cache,
  auth0Client,
  persistor,
  children,
}) => {
  const { push } = useHistory();

  /* Create Apollo Link array to pass to Apollo Client */
  const link = ApolloLink.from([
    errorLink(auth0Client, push),
    ApolloLink.split(
      operation => operation.getContext().client === 'ledgible-cms',
      skipAuthLink,
      authLink
    ),
    restLink,
    retryLink,
    ApolloLink.split(
      operation => operation.getContext().client === 'ledgible-cms',
      ledgibleCMSLink,
      ledgibleAPILink
    ),
  ]);

  /* Create Apollo Client */
  const client = new ApolloClient({
    link,
    cache,
    typeDefs: clientTypeDefs,
    defaultOptions: {
      watchQuery: { errorPolicy: 'none' },
      query: { errorPolicy: 'none' },
      mutate: { errorPolicy: 'none' },
    },
    connectToDevTools: import.meta.env.DEV,
  });

  // callback for when apollo store is cleared (eg for logout);
  client.onClearStore(async () => {
    await persistor.purge();
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;

AuthorizedApolloProvider.propTypes = {
  children: PropTypes.node.isRequired,
  auth0Client: PropTypes.shape().isRequired,
  persistor: PropTypes.shape().isRequired,
  cache: PropTypes.shape().isRequired,
};
