import {
  showHelpWidgetVar,
  helpWidgetActiveVar,
} from '../../../data/apollo/cache/reactiveVars';

/**
 * opens zendesk widget with optional prefill data
 * prefill data includes { name, email, phone } and each value takes
 * { value, readOnly } with value as string and readOnly as boolean option.
 * Second arg is optional callback for execution on open
 */
export const openZendeskWidget = (prefillData, cb) => {
  const { zE } = window;

  if (prefillData) {
    zE('webWidget', 'prefill', prefillData);
  }
  zE('webWidget', 'show');
  zE('webWidget', 'open');
  helpWidgetActiveVar(true);
  if (showHelpWidgetVar()) {
    document.querySelector('#ledgible-zendesk-launcher').style.opacity = 0;
  }
  if (cb) {
    cb();
  }
};

export const closeZendeskWidget = () => {
  const { zE } = window;
  zE('webWidget', 'hide');
  helpWidgetActiveVar(false);
  if (showHelpWidgetVar()) {
    document.querySelector('#ledgible-zendesk-launcher').style.opacity = 1;
  }
};

export const setZendeskHelpContext = (suggestionParams) => {
  const { zE } = window;
  zE('webWidget', 'helpCenter:setSuggestions', { ...suggestionParams });
};
