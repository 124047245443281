/* eslint-disable camelcase */
/* eslint-disable no-unreachable */
// absolute imports
import {
  useState,
  useEffect,
  useContext,
  createContext,
  useMemo,
  useCallback,
} from 'react';
import { setUser } from '@sentry/react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useApolloClient, useQuery, useReactiveVar } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

// relative imports
import {
  formatAppType,
  getAppUrlPathFromId,
  getAuth0ClientId,
} from '../../shared/utilities/appUtilities';
import { primaryStoreKey } from '../../../data/apollo/cache/utilities/browserStorage';
import {
  GET_LEDGIBLE_USER,
  GET_USER_OAUTH_CODE,
} from '../../../data/apollo/queries';
import {
  UPDATE_LEDGIBLE_USER,
  CREATE_USER,
  UPDATE_USER_OAUTH_CLIENT,
} from '../../../data/apollo/mutations';
import {
  appTypeVar,
  customLogoUrlVar,
  isTaxPreparerVar,
} from '../../../data/apollo/cache/reactiveVars';
import { useAppErrorBoundary } from '../../error-handling/AppErrorBoundary';
import { captureSentryGenericError } from '../../error-handling/sentry';
import { useConfig } from './ConfigProvider';

/* custom hook to specifically capture auth0 url err response */
const useAuth0UrlErr = () => {
  const location = useLocation();
  const { search } = location;
  const searchObj = queryString.parse(search);
  const { error, error_description } = searchObj;
  const [loginErr, setLoginErr] = useState(null);

  useEffect(() => {
    /**
     * check for url query params of error & error_description returned from auth0
     */
    if (error) {
      setLoginErr({
        error,
        message: error_description,
      });
    }
  }, [error, error_description, loginErr]);

  return [loginErr];
};

/* Format claims from auth0 to standard Auth0User Type fields */
export const formatAuth0User = (auth0User) => {
  const formattedUser = { ...auth0User };
  // get name-spaced property vals from oidc compliant return from auth0
  const auth0MetaData =
    auth0User['https://dashboard.ledgible.io/user_metadata'];
  const userCreatedDate = auth0User['https://dashboard.ledgible.io/created_at'];
  const userLastPwReset =
    auth0User['https://dashboard.ledgible.io/last_password_reset'];
  // assign vals to copied obj
  formattedUser.user_metadata = auth0MetaData;
  formattedUser.created_at = userCreatedDate;
  formattedUser.last_password_reset = userLastPwReset;
  // delete namespaced properties from copied obj to avoid GQL query conflicts
  delete formattedUser['https://dashboard.ledgible.io/user_metadata'];
  delete formattedUser['https://dashboard.ledgible.io/created_at'];
  delete formattedUser['https://dashboard.ledgible.io/last_password_reset'];
  delete formattedUser['https://dashboard.ledgible.io/custom_logo_url'];

  return formattedUser;
};

/* Create default callback function for Auth0Provider to utilize with Auth0 */
const onRedirectCallback = (appState, push) => {
  const params =
    appState && appState.targetUrlParams
      ? `?${Object.keys(appState.targetUrlParams)
          .map((key) => `${key}=${appState.targetUrlParams[key]}`)
          .join('&')}`
      : '';
  const state = appState?.targetLocationState || {};

  push(
    appState && appState.returnTo ? `${appState.returnTo}${params}` : '/',
    state,
  );
};

// implement context
export const Auth0Context = createContext();

export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = ({ children, auth0Client }) => {
  const { push } = useHistory();
  const apolloClient = useApolloClient();
  const [authErr] = useAuth0UrlErr();
  const isTaxPreparer = useReactiveVar(isTaxPreparerVar);
  const { showBoundary } = useAppErrorBoundary();
  const { accountConfig } = useConfig();

  // kick to ErrorBoundary if authErr
  useEffect(() => {
    if (authErr) {
      captureSentryGenericError(authErr, {
        file: 'Auth0Provider.jsx',
        warn: 'check auth0 client instance',
      });
      showBoundary(authErr);
    }
  }, [authErr, showBoundary]);

  // auth state
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(true);
  const [tokenClientId, setTokenClientId] = useState(null);

  const {
    data: { user } = {},
    loading: loadingUser,
    refetch: refetchUser,
  } = useQuery(GET_LEDGIBLE_USER, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ user: result }) => {
      if (
        result?.accountPermissions.find(({ type }) => type === 'TaxPreparer') &&
        !isTaxPreparer
      ) {
        isTaxPreparerVar(true);
      }
    },
    skip: !isAuthenticated,
  });

  // applications the user already has at least one account for
  const permittedApplications = useMemo(
    () =>
      user
        ? [
            ...new Set(
              user.accountPermissions
                .map((permission) =>
                  permission.tirAdmin ? 'TirAdmin' : permission.type,
                )
                .map((backendApp) => formatAppType(backendApp, 'frontend')),
            ),
          ]
        : [],
    [user],
  );

  const allowedApplications = useMemo(
    () =>
      user
        ? user.allowedApplications.map((app) => formatAppType(app, 'frontend'))
        : [],
    [user],
  );

  // update auth on changes
  useEffect(() => {
    const initAuth0 = async () => {
      let redirectParams = {};
      const parsedSearch = window.location.search
        ? queryString.parse(window.location.search)
        : null;

      if (
        parsedSearch &&
        parsedSearch.code &&
        parsedSearch.state &&
        auth0Client?.transactionManager?.get()
      ) {
        const { appState } = await auth0Client.handleRedirectCallback();
        redirectParams = { ...appState };
        onRedirectCallback(appState, push);
      }
      const authed = await auth0Client.isAuthenticated();

      if (authed && !parsedSearch?.session_token) {
        let auth0User = await auth0Client.getUser();
        setUser({ id: auth0User?.sub });
        /**
         * ensure cache is in sync with auth server by ignoring cache on first load
         * (particularly for page refresh / new tab in valid session / EMAIL VERIFICATION CONFIRM)
         * Exception -> cypress instrumented build for CI runs as it will mess up e2e tests with cy.visit
         */
        try {
          await auth0Client.getTokenSilently({
            // -> ensure fresh token fetch on first load, unless testing
            cacheMode:
              !window.Cypress && import.meta.env.MODE !== 'ci' ? 'off' : 'on',
            detailedResponse: true,
          });
          auth0User = await auth0Client.getUser();
        } catch (e) {
          // handle pw change on login if set
          if (e.message === 'Password change required.') {
            push('pw-change-required?unauthorized_error=true');
          } else if (
            e.error === 'missing_refresh_token' ||
            e.error === 'invalid_grant'
          ) {
            auth0Client.loginWithRedirect();
          } else {
            captureSentryGenericError(e, {
              file: 'Auth0Provider.jsx',
              warn: 'check auth0 client instance',
            });
            showBoundary(e);
          }
        }

        const formattedUser = formatAuth0User(auth0User);
        const tokenClaims = await auth0Client.getIdTokenClaims();

        // set custom logo if present for application
        if (tokenClaims?.['https://dashboard.ledgible.io/custom_logo_url']) {
          customLogoUrlVar(
            tokenClaims['https://dashboard.ledgible.io/custom_logo_url'],
          );
        }
        // set/update org ref for logout redirect (if present)
        // to allow org users direct login to org after logout
        if (tokenClaims?.org_id) {
          sessionStorage.setItem(
            'ledgible_org_logout_ref',
            tokenClaims?.org_id,
          );
        } else {
          sessionStorage.removeItem('ledgible_org_logout_ref');
        }

        // swap user for ledgible user
        const {
          data: { updateLedgibleUser },
        } = await apolloClient.mutate({
          mutation: UPDATE_LEDGIBLE_USER,
          variables: {
            auth0User: formattedUser,
          },
        });

        /**
         * Handle redirect for OAuth registration/login if present
         * called after updateLedgibleUser since user obj is not always populated
         */
        const redirectClientId =
          updateLedgibleUser?.redirectToClient || user?.redirectToClient;

        if (redirectClientId && redirectClientId !== 'false') {
          const {
            data: {
              getUserOAuthCode: { authorizationCode, redirectUri, state } = {},
            } = {},
          } = await apolloClient.query({
            query: GET_USER_OAUTH_CODE,
            variables: {
              clientId: redirectClientId,
            },
          });

          if (authorizationCode) {
            // create full redirect uri
            let queryParams = '';
            if (state) queryParams += `&state=${state}`;
            const oauthFullRedirectUri = `${redirectUri}?code=${authorizationCode}${queryParams}`;

            // update user to no longer require redirect
            await apolloClient.mutate({ mutation: UPDATE_USER_OAUTH_CLIENT });
            refetchUser();

            // perform redirect
            window.location.replace(oauthFullRedirectUri);
            return;
          }
        }

        if (!updateLedgibleUser) {
          const createUserParams = {
            auth0User: formattedUser,
            token:
              redirectParams.tmpUserToken || formattedUser.ledgibleTmpUserToken,
            enableInitialAccountingAccountCreation:
              accountConfig.accountSetup
                ?.enableInitialAccountingAccountCreation,
          };

          // create new user and refetch on success
          const { data: { createUser: newUser } = {} } =
            await apolloClient.mutate({
              mutation: CREATE_USER,
              variables: createUserParams,
            });
          if (newUser) {
            refetchUser();
          }
        }
        const claims = await auth0Client.getIdTokenClaims();
        setTokenClientId(claims?.aud);
        setIsAuthenticated(true);
      }
      // stop auth loading
      setLoadingAuth(false);
    };

    if (!isAuthenticated) {
      initAuth0();
    }
    if (user) {
      setLoadingAuth(false);
    }
  }, [
    auth0Client,
    apolloClient,
    refetchUser,
    isAuthenticated,
    push,
    user,
    showBoundary,
    accountConfig,
  ]);

  /**
   * local logout function to handle logout call to auth0 plus session cleanup
   * @param {Object} logoutOptions - query string options to pass to Logout Router on auth0 redirect
   * * appType (Enum)
   * * fromAutoLogout (Boolean)
   * @param {Object} auth0Args - optional pass through object for auth0 logout function arguments
   */
  const logoutCallback = useCallback(
    async (
      queryOptions = { authClientId: getAuth0ClientId(appTypeVar()) },
      auth0Args = {},
    ) => {
      const appType = queryOptions.appType ?? appTypeVar();
      // determines if preparer redirect is required
      const preparerOverride =
        appType === 'tax' &&
        user &&
        allowedApplications.includes('tax-preparer');
      const tirAdminOverride =
        appType === 'tax' && user && allowedApplications.includes('tir-admin');

      // reset local storage
      sessionStorage.removeItem(primaryStoreKey);

      // remove all data with table/filter state
      sessionStorage.removeItem('appState');

      // stop and clear apollo store
      apolloClient.stop();
      await apolloClient.clearStore();

      if (preparerOverride) {
        queryOptions.appType = getAppUrlPathFromId('tax-preparer');
        queryOptions.authClientId = getAuth0ClientId('tax-preparer');
      }
      if (tirAdminOverride) {
        queryOptions.appType = getAppUrlPathFromId('tir-admin');
        queryOptions.authClientId = getAuth0ClientId('tir-admin');
      }

      // construct return URL/search to include custom params
      const logoutCallbackUrl = queryString.stringifyUrl({
        url: `${import.meta.env.REACT_APP_AUTH0_CALLBACK}/logout`,
        query: { ...queryOptions },
      });

      // call auth0 logout with any supplied auth0 args and/or custom query params
      auth0Client.logout({
        clientId: getAuth0ClientId(appType),
        logoutParams: {
          returnTo: logoutCallbackUrl,
          ...auth0Args,
        },
      });
    },
    [user, auth0Client, apolloClient, allowedApplications],
  );

  // memoize values
  const authValues = useMemo(
    () => ({
      auth0Client,
      user,
      refetchUser,
      loadingAuth,
      isAuthenticated,
      permittedApplications,
      allowedApplications,
      loadingUser,
      tokenClientId,
      checkSession: (...p) => auth0Client.checkSession(...p),
      getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
      loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
      getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
      getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
      logout: (...p) => logoutCallback(...p),
    }),
    [
      auth0Client,
      user,
      loadingAuth,
      isAuthenticated,
      logoutCallback,
      refetchUser,
      loadingUser,
      permittedApplications,
      allowedApplications,
      tokenClientId,
    ],
  );
  return (
    <Auth0Context.Provider value={authValues}>{children}</Auth0Context.Provider>
  );
};

export const AuthenticationConsumer = Auth0Context.Consumer;

Auth0Provider.propTypes = {
  children: PropTypes.node.isRequired,
  auth0Client: PropTypes.shape().isRequired,
};
