// import external modules
import { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import {
  AppBar,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';

// import icons
import {
  AccountBox as AccountBoxIcon,
  ArrowLeft as LeftIcon,
  ArrowDropDown as ExpandMore,
  ArrowDropUp as ExpandLess,
  Chat as ChatIcon,
  ExitToApp as ExitToAppIcon,
  HelpOutline as HelpOutlineIcon,
  LiveHelp as LiveHelpIcon,
  Menu as MenuIcon,
  ScreenShareOutlined as SwitchAppIcon,
  Notifications as NotificationsIcon,
} from '@mui/icons-material';

// relative imports
import { useConfig } from '../../common/providers/ConfigProvider';
import { useAuth0 } from '../../common/providers/Auth0Provider';
import { useAccount } from '../../common/providers/AccountProvider';
import { useAppManagement } from '../../common/providers/AppManagementProvider';
import { appTabs } from './SecondaryToolbar';
import TrialUpgradeButton from '../../accounting/billing/TrialUpgradeButton';
import AccountWindow from '../../accounting/accounts/account-window/AccountWindow';
import TaxPayerAccountWindow from '../../tax/payer-components/TaxPayerAccountWindow';
import PreparerAccountWindow from '../../tax-preparer/preparer-components/PreparerAccountWindow';
import PreparerClientAccountWindow from '../../tax-preparer/preparer-components/PreparerClientAccountWindowVirtualized';
import UpdateDataSourcesButton from '../../common/account-updates/UpdateAccountDataButton';
import TirAdminAccountWindow from '../../tir-admin/tir-components/TirAdminAccountWindow';
import TirAdminClientAccountWindow from '../../tir-admin/tir-components/TirAdminClientAccountWindow';
import PendingCodeInviteButton from '../../tax/payer-components/PendingCodeInviteButton';
import { openZendeskWidget } from '../customer-support/zendesk-operations';
import {
  getAppUrlPathFromId,
  getAppLogoSrc,
  mapAliasToPath,
} from '../utilities/appUtilities';

const useStyles = makeStyles()((theme) => ({
  appBar: {
    backgroundColor:
      theme.whiteLabel.Header.color || theme.palette.secondary.main,
  },
  toolbarMain: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    lineHeight: '40px',
    minHeight: '40px',
    width: '100%',
  },
  backButton: {
    color: theme.palette.grey[100],
    marginLeft: theme.spacing(2),
    '&:hover': {
      color: theme.palette.grey[300],
      backgroundColor: 'transparent',
    },
  },
  tabIcon: {
    fontSize: '1.15rem',
  },
  moreBtnIcon: {
    fontSize: theme.typography.pxToRem(26),
    color: '#fff',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  menuItem: {
    padding: 0,
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  menuList: {
    width: '225px',
  },
  accountWindowListItem: {
    justifyContent: 'flex-end',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  boxGrid: {
    minHeight: 56,
  },
  notificationsButton: {
    color: theme.palette.common.white,
    padding: 5,
    marginRight: theme.spacing(1),
    marginTop: -1,
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    boxShadow:
      '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.3)',
    },
    '& .beamer_icon.active, & #beamerIcon.active': {
      width: 15,
      height: 15,
      lineHeight: '15px',
      marginTop: -5,
      marginRight: -5,
    },
  },
  notificationsIcon: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.common.white,
  },
  updateDataButton: {
    marginTop: -3,
  },
  productName: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: '30px',
    fontWeight: 800,
    color: theme.whiteLabel.ProductName.color || 'inherit',
  },
  productNameDivider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: '2px',
    height: '30px',
    backgroundColor: theme.palette.grey[700],
  },
  listItem: {
    justifyContent: 'center',
    color: theme.whiteLabel.SecondaryToolbar.textColor || 'inherit',
  },
  listItemIcon: {
    color: theme.whiteLabel.SecondaryToolbar.textColor || 'inherit',
  },
}));

const supportListItem = [
  {
    icon: <LiveHelpIcon fontSize="small" />,
    title: 'Help',
    link: 'https://verady.zendesk.com/hc/en-us#360002561431',
  },
  {
    icon: <ChatIcon fontSize="small" />,
    title: 'Contact Us',
  },
];

const validTrialUrls = [
  `/${getAppUrlPathFromId('accounting')}/trial-expired`,
  `/${getAppUrlPathFromId('accounting')}/administration/change-plan`,
];

const MobileHeader = ({
  hideAccountMenu,
  hideUpdateButton,
  setHeaderHeight,
}) => {
  const { appConfig, accountConfig } = useConfig();
  const {
    staticAppType,
    taxAppUrlAlias,
    taxProAppUrlAlias,
    accountingAppUrlAlias,
    tirAdminAppUrlAlias,
  } = appConfig;
  const { classes, cx } = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const headerRef = useRef();

  useEffect(() => {
    setHeaderHeight(headerRef.current.clientHeight);
  });

  const [supportListOpen, setSupportListOpen] = useState(false);

  const handleSupportListClick = () => setSupportListOpen(!supportListOpen);

  const { state: locationState, pathname } = useLocation();
  const history = useHistory();
  const { user, logout } = useAuth0();
  const { appType, displayAppType } = useAppManagement();
  const { clientPermissions: permissions, currentAccount } = useAccount();
  const { audit } = currentAccount;

  const mapPathAlias = useCallback(
    (path) =>
      mapAliasToPath(path, {
        taxAppUrlAlias,
        taxProAppUrlAlias,
        accountingAppUrlAlias,
        tirAdminAppUrlAlias,
      }),
    [
      accountingAppUrlAlias,
      taxAppUrlAlias,
      taxProAppUrlAlias,
      tirAdminAppUrlAlias,
    ],
  );

  // generate array from appTabs and filter out any tabs with hidden property based on
  // any conditions applied, can pass args to appTabs
  const visibleListItems = appType
    ? appTabs({
        audit,
        tabIconClass: classes.tabIcon,
        accountConfig,
        mapPathAlias,
        permissions,
      })[appType].filter((tab) => !tab.hidden)
    : [];

  let shouldHideAccountMenu = hideAccountMenu;

  if (appType === 'accounting' && hideAccountMenu) {
    const userAccessibleAccountingAccounts =
      (user.accountPermissions &&
        user.accountPermissions.filter((acc) => acc.type === 'Accounting')) ||
      [];
    shouldHideAccountMenu =
      userAccessibleAccountingAccounts.length > 1 ? false : hideAccountMenu;
  }

  const handleMenuClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleLogout = () => {
    logout();
    handleClose();
  };

  return (
    <AppBar
      className={cx(classes.appBar, 'beamerActivePosition')}
      position="fixed"
      ref={headerRef}
    >
      <div className={classes.toolbarMain}>
        <Box display="flex">
          <Box
            display="flex"
            flexGrow={1}
            alignItems="center"
            paddingLeft={1}
            className={classes.boxGrid}
            onClick={() =>
              history.push(
                appConfig.alternateBaseAppPath || `/${displayAppType}`,
              )
            }
          >
            <img src={getAppLogoSrc(appType)} alt="Ledgible" height="35px" />
            {appConfig.productName && (
              <>
                <Divider
                  className={classes.productNameDivider}
                  orientation="vertical"
                />
                <Typography className={classes.productName}>
                  {appConfig.productName}
                </Typography>
              </>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            paddingRight={1}
            className={classes.boxGrid}
          >
            {hideAccountMenu &&
              currentAccount.id &&
              locationState &&
              locationState.redirect && (
                <Button
                  component={Link}
                  to={locationState.redirect}
                  className={classes.backButton}
                  variant="text"
                  id="TopNav-BackToDashboardWithRedirectBtn"
                >
                  <LeftIcon />
                  {locationState.redirectLabel}
                </Button>
              )}
            {!hideAccountMenu &&
              !hideUpdateButton &&
              currentAccount.id &&
              appType !== 'tax-preparer' && (
                <div className={classes.updateDataButton}>
                  <UpdateDataSourcesButton />
                </div>
              )}
            <IconButton
              className={classes.notificationsButton}
              aria-label="toggle notifications menu"
              size="small"
              aria-controls="menu-list-grow"
              aria-haspopup="true"
              id="top-nav-notifications-menu-button"
            >
              <NotificationsIcon className={classes.notificationsIcon} />
            </IconButton>
            <IconButton
              size="small"
              color="primary"
              aria-label="header menu"
              aria-controls="header-menu"
              aria-haspopup="true"
              onClick={handleMenuClick}
              className={classes.menuButton}
            >
              <MenuIcon className={classes.moreBtnIcon} />
            </IconButton>
            <Menu
              id="action-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <MenuItem className={classes.menuItem} disableRipple>
                <List
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  dense
                  disablePadding
                  className={classes.menuList}
                >
                  {!shouldHideAccountMenu && currentAccount.id && (
                    <>
                      {appType === 'tax' &&
                        !permissions.isPreparerViewingClient &&
                        !permissions.isTirAdminViewingClient && (
                          <TaxPayerAccountWindow />
                        )}
                      {appType === 'tax' &&
                        permissions.isPreparerViewingClient && (
                          <PreparerClientAccountWindow />
                        )}
                      {appType === 'tax' &&
                        permissions.isTirAdminViewingClient && (
                          <TirAdminClientAccountWindow />
                        )}
                      {appType === 'tax-preparer' && <PreparerAccountWindow />}
                      {appType === 'tir-admin' && <TirAdminAccountWindow />}
                      {appType === 'accounting' && (
                        // only account window that isn't a native list element
                        <ListItem className={classes.accountWindowListItem}>
                          <AccountWindow />
                        </ListItem>
                      )}
                    </>
                  )}
                  {appType === 'accounting' &&
                    !hideAccountMenu &&
                    !user.achEnabled &&
                    currentAccount.inTrial &&
                    !currentAccount.trialEnded && (
                      <ListItem style={{ justifyContent: 'center' }}>
                        <TrialUpgradeButton
                          variant="outlined"
                          size="small"
                          // marginLeft={8}
                        />
                      </ListItem>
                    )}
                  {appType === 'tax' &&
                    !permissions.isPreparerViewingClient && (
                      <ListItem>
                        <PendingCodeInviteButton
                          currentAccount={currentAccount}
                        />
                      </ListItem>
                    )}
                  {currentAccount &&
                    currentAccount.id &&
                    !currentAccount.disabled &&
                    !currentAccount.trialEnded && (
                      <>
                        {visibleListItems.map((tab) => (
                          <ListItem
                            button
                            key={tab.key}
                            className={classes.listItem}
                            onClick={() => {
                              if (tab.link) history.push(tab.link);
                              handleClose();
                            }}
                          >
                            <ListItemIcon className={classes.listItemIcon}>
                              {tab.icon}
                            </ListItemIcon>
                            <ListItemText primary={tab.name} />
                          </ListItem>
                        ))}
                      </>
                    )}
                  {user && (
                    <>
                      <ListItem
                        button
                        className={classes.listItem}
                        onClick={() => history.push('/user-settings')}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <AccountBoxIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="User Settings" />
                      </ListItem>
                      {!staticAppType && (
                        <ListItem
                          button
                          className={classes.listItem}
                          onClick={() => history.push('/app-select')}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <SwitchAppIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Switch Application" />
                        </ListItem>
                      )}
                    </>
                  )}
                  <ListItem
                    button
                    onClick={handleSupportListClick}
                    className={classes.listItem}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <HelpOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Support" />
                    {supportListOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={supportListOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                      {supportListItem.map((item) => (
                        <ListItem
                          button
                          href={item.link}
                          component={item.link ? 'a' : 'div'}
                          target="_blank"
                          className={classes.nested}
                          onClick={() => {
                            if (!item.link) openZendeskWidget();
                            handleClose();
                          }}
                          key={item.title}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText primary={item.title} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                  {user && (
                    <ListItem
                      button
                      onClick={handleLogout}
                      className={classes.listItem}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <ExitToAppIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItem>
                  )}
                </List>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </div>
      {currentAccount &&
        !validTrialUrls.includes(pathname) &&
        currentAccount.trialEnded && (
          <Redirect
            to={`/${getAppUrlPathFromId('accounting')}/trial-expired`}
          />
        )}
    </AppBar>
  );
};

export default MobileHeader;

MobileHeader.propTypes = {
  hideAccountMenu: PropTypes.bool.isRequired,
  hideUpdateButton: PropTypes.bool.isRequired,
  setHeaderHeight: PropTypes.func.isRequired,
};
