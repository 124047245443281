export const manualTransferTypeRef = {
  transferIn: 'transfer-in',
  'transfer-in': 'transfer-in',
  transferOut: 'transfer-out',
  'transfer-out': 'transfer-out',
};

export const manualNFTTypeRef = {
  nftBuy: 'nft-buy',
  'nft-buy': 'nft-buy',
  nftMint: 'nft-mint',
  'nft-mint': 'nft-mint',
  nftSell: 'nft-sell',
  'nft-sell': 'nft-sell',
  nftTransferOut: 'nft-transfer-out',
  'nft-transfer-out': 'nft-transfer-out',
  nftTransferIn: 'nft-transfer-in',
  'nft-transfer-in': 'nft-transfer-in',
};

export const manualDefiTypeRef = {
  liquidityDeposit: 'liquidity-deposit',
  'liquidity-deposit': 'liquidity-deposit',
  liquidityWithdrawal: 'liquidity-withdrawal',
  'liquidity-withdrawal': 'liquidity-withdrawal',
  stakeDeposit: 'stake-deposit',
  'stake-deposit': 'stake-deposit',
  stakeReward: 'stake-reward',
  'stake-reward': 'stake-reward',
  stakeWithdrawal: 'stake-withdrawal',
  'stake-withdrawal': 'stake-withdrawal',
  swap: 'swap',
};

export const manualLiquidityPoolTypes = [
  manualDefiTypeRef.liquidityDeposit,
  manualDefiTypeRef.liquidityWithdrawal,
];

export const manualDefiFormatTypeRef = {
  liquidityDeposit: true,
  liquidityWithdrawal: true,
  'liquidity-deposit': true,
  'liquidity-withdrawal': true,
  swap: true,
};

// Should match activeDefiProviders object keys except manual
export const manualDefiProviders = [
  'aave',
  'curve',
  'dydx',
  'maker',
  'pancakeswap',
  'sushi',
  'uniswap',
  'vesper',
  'yearn',
  '1inch',
  'generic',
];

export const manualTxEntryDescriptionRef = {
  standard:
    'This option allows for capturing general transfer activities of a cryptocurrency wallet.',
  defi: `Decentralized Finance refers to interactions with smart contracts on blockchains to 
  perform various financial transactions.`,
  nft: `NFTs (Non-Fungible Tokens) are unique, irreplaceable units of data stored on a blockchain. 
  NFTs can represent digital goods such as photos, videos, audio and more.`,
};

export const manualOrderTypeRef = {
  buy: 'buy',
  sell: 'sell',
  deposit: 'deposit',
  withdrawal: 'withdrawal',
  crypto: 'crypto-crypto',
};

export const contextTitleRef = {
  standard: 'Standard',
  defi: 'DeFi Activity',
  nft: 'NFT',
};

export const categoryTypeMappings = {
  liquidityPoolDeposit_defi: {
    incoming: {},
    outgoing: {
      exchange: null,
      wallet: manualDefiTypeRef.liquidityDeposit,
    },
  },
  liquidityPoolWithdrawal_defi: {
    incoming: {
      exchange: null,
      wallet: manualDefiTypeRef.liquidityWithdrawal,
    },
    outgoing: {},
  },
  stakingDeposit_defi: {
    incoming: {},
    outgoing: {
      exchange: null,
      wallet: manualDefiTypeRef.stakeDeposit,
    },
  },
  stakingRewards_defi: {
    incoming: {
      exchange: null,
      wallet: manualDefiTypeRef.stakeReward,
    },
    outgoing: {},
  },
  stakingWithdrawal_defi: {
    incoming: {
      exchange: null,
      wallet: manualDefiTypeRef.stakeWithdrawal,
    },
    outgoing: {},
  },
  swap_defi: {
    incoming: {
      exchange: null,
      wallet: manualDefiTypeRef.swap,
    },
    outgoing: {
      exchange: null,
      wallet: manualDefiTypeRef.swap,
    },
  },
  buy_nft: {
    incoming: {
      exchange: null,
      wallet: manualNFTTypeRef.nftBuy,
    },
    outgoing: {},
  },
  mint_nft: {
    incoming: {
      exchange: null,
      wallet: manualNFTTypeRef.nftMint,
    },
    outgoing: {},
  },
  sell_nft: {
    incoming: {},
    outgoing: {
      exchange: null,
      wallet: manualNFTTypeRef.nftSell,
    },
  },
  transfer_nft: {
    incoming: {
      exchange: null,
      wallet: manualNFTTypeRef.nftTransferIn,
    },
    outgoing: {
      exchange: null,
      wallet: manualNFTTypeRef.nftTransferOut,
    },
  },
  acquisition_standard: {
    incoming: {
      exchange: manualOrderTypeRef.deposit,
      wallet: manualTransferTypeRef.transferIn,
    },
    outgoing: {},
  },
  airdrop_standard: {
    incoming: {
      exchange: null,
      wallet: manualTransferTypeRef.transferIn,
    },
    outgoing: {},
  },
  buy_standard: {
    incoming: {
      exchange: manualOrderTypeRef.buy,
      wallet: manualTransferTypeRef.transferIn,
    },
    outgoing: {},
  },
  collateralLiquidation_standard: {
    incoming: {},
    outgoing: {
      exchange: manualOrderTypeRef.withdrawal,
      wallet: manualTransferTypeRef.transferOut,
    },
  },
  collateralRetrieval_standard: {
    incoming: {
      exchange: manualOrderTypeRef.deposit,
      wallet: manualTransferTypeRef.transferIn,
    },
    outgoing: {},
  },
  collateralRetrievalNonTaxable_standard: {
    incoming: {
      exchange: manualOrderTypeRef.deposit,
      wallet: manualTransferTypeRef.transferIn,
    },
    outgoing: {},
  },
  collateralize_standard: {
    incoming: {},
    outgoing: {
      exchange: null,
      wallet: manualTransferTypeRef.transferOut,
    },
  },
  collateralizeNonTaxable_standard: {
    incoming: {},
    outgoing: {
      exchange: null,
      wallet: manualTransferTypeRef.transferOut,
    },
  },
  donation_standard: {
    incoming: {
      exchange: manualOrderTypeRef.deposit,
      wallet: manualTransferTypeRef.transferIn,
    },
    outgoing: {
      exchange: manualOrderTypeRef.withdrawal,
      wallet: manualTransferTypeRef.transferOut,
    },
  },
  earnRewards_standard: {
    incoming: {
      exchange: manualOrderTypeRef.deposit,
      wallet: manualTransferTypeRef.transferIn,
    },
    outgoing: {},
  },
  fee_standard: {
    incoming: {},
    outgoing: {
      wallet: manualTransferTypeRef.transferOut,
      exchange: null,
    },
  },
  forkIncome_standard: {
    incoming: {
      exchange: manualOrderTypeRef.deposit,
      wallet: manualTransferTypeRef.transferIn,
    },
    outgoing: {},
  },
  gift_standard: {
    incoming: {
      exchange: manualOrderTypeRef.deposit,
      wallet: manualTransferTypeRef.transferIn,
    },
    outgoing: {
      exchange: manualOrderTypeRef.withdrawal,
      wallet: manualTransferTypeRef.transferOut,
    },
  },
  interestIncome_standard: {
    incoming: {
      exchange: manualOrderTypeRef.deposit,
      wallet: manualTransferTypeRef.transferIn,
    },
    outgoing: {},
  },
  interestPayment_standard: {
    incoming: {},
    outgoing: {
      exchange: manualOrderTypeRef.withdrawal,
      wallet: manualTransferTypeRef.transferOut,
    },
  },
  loanDisbursement_standard: {
    incoming: {
      exchange: manualOrderTypeRef.deposit,
      wallet: manualTransferTypeRef.transferIn,
    },
    outgoing: {},
  },
  loanPayment_standard: {
    incoming: {},
    outgoing: {
      exchange: manualOrderTypeRef.withdrawal,
      wallet: manualTransferTypeRef.transferOut,
    },
  },
  miningIncome_standard: {
    incoming: {
      exchange: manualOrderTypeRef.deposit,
      wallet: manualTransferTypeRef.transferIn,
    },
    outgoing: {},
  },
  sell_standard: {
    incoming: {},
    outgoing: {
      exchange: manualOrderTypeRef.sell,
      wallet: manualTransferTypeRef.transferOut,
    },
  },
  stakingDeposit_standard: {
    incoming: {},
    outgoing: {
      exchange: manualOrderTypeRef.withdrawal,
      wallet: manualTransferTypeRef.transferOut,
    },
  },
  stakingRewards_standard: {
    incoming: {
      exchange: manualOrderTypeRef.deposit,
      wallet: manualTransferTypeRef.transferIn,
    },
    outgoing: {},
  },
  stakingWithdrawal_standard: {
    incoming: {
      exchange: manualOrderTypeRef.deposit,
      wallet: manualTransferTypeRef.transferIn,
    },
    outgoing: {},
  },
  transfer_standard: {
    incoming: {
      exchange: manualOrderTypeRef.deposit,
      wallet: manualTransferTypeRef.transferIn,
    },
    outgoing: {
      exchange: manualOrderTypeRef.withdrawal,
      wallet: manualTransferTypeRef.transferOut,
    },
  },
  withdrawal_standard: {
    incoming: {},
    outgoing: {
      exchange: manualOrderTypeRef.withdrawal,
      wallet: manualTransferTypeRef.transferOut,
    },
  },
  workIncome_standard: {
    incoming: {
      exchange: manualOrderTypeRef.deposit,
      wallet: manualTransferTypeRef.transferIn,
    },
    outgoing: {},
  },
};

export const taxTxExceptionManualTypeRef = {
  [manualOrderTypeRef.buy]: 1,
  [manualTransferTypeRef.transferIn]: 1,
  [manualNFTTypeRef.nftBuy]: 1,
  [manualNFTTypeRef.nftTransferIn]: 1,
  [manualNFTTypeRef.nftMint]: 1,
};

export const addedDefiColumnTypeRef = {
  liquidityPoolDeposit_defi: 1,
  liquidityPoolWithdrawal_defi: 1,
  swap_defi: 1,
};

export const liquidityExclusiveColumnTypeRef = {
  liquidityPoolDeposit_defi: 1,
  liquidityPoolWithdrawal_defi: 1,
};

export const sourceTypeRef = {
  wallet: 'wallet',
  exchange: 'exchange',
}

export const manualUIStandardCategories = [
  {
    canCreateManualEntry: true,
    dbValue: 'fee_standard',
    system: false,
    title: 'Fee',
    txSides: ['outgoing'],
    txSourceTypes: ['wallet'],
  },
];

export const manualUIStandardCategoryRef =
  manualUIStandardCategories.reduce((acc, cat) => {
    acc[cat?.dbValue] = cat;
    return acc;
  }, {});
  
export const categoryTypeSideRef = {
  donation_standard: {
    [manualOrderTypeRef.deposit]: 'incoming',
    [manualTransferTypeRef.transferIn]: 'incoming',
    [manualOrderTypeRef.withdrawal]: 'outgoing',
    [manualTransferTypeRef.transferOut]: 'outgoing',
  },
  gift_standard: {
    [manualOrderTypeRef.deposit]: 'incoming',
    [manualTransferTypeRef.transferIn]: 'incoming',
    [manualOrderTypeRef.withdrawal]: 'outgoing',
    [manualTransferTypeRef.transferOut]: 'outgoing',
  },
  transfer_standard: {
    [manualOrderTypeRef.deposit]: 'incoming',
    [manualTransferTypeRef.transferIn]: 'incoming',
    [manualOrderTypeRef.withdrawal]: 'outgoing',
    [manualTransferTypeRef.transferOut]: 'outgoing',
  },
  transfer_nft: {
    [manualNFTTypeRef.nftTransferIn]: 'incoming',
    [manualNFTTypeRef.nftTransferOut]: 'outgoing',
  },
};
