// absolute imports
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// relative imports
import { Redirect } from 'react-router-dom';
import FullScreenLayout from '../../shared/layouts/FullScreenLayout';
import flows from './self-select-flows';

const useStyles = makeStyles()((theme) => ({
  containerRoot: {
    backgroundColor: (theme.whiteLabel.PageHeader.color || theme.palette.primary.main),
    overflowX: 'hidden',
  },
}));

// Component
const SelfSelectFlow = ({ flowId }) => {
  const { classes } = useStyles();
  const [currentStep, setCurrentStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const activeFlow = flows[flowId] || {}; // pre-defined attributes for step-based process
  const [stepsLength, setStepsLength] = useState(activeFlow.steps?.length);

  useEffect(() => {
    setProgress(Math.round(((currentStep + 1) / stepsLength) * 100));
  }, [currentStep, stepsLength]);

  const handleNext = () => {
    if (currentStep < activeFlow.steps.length) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const goToStep = (step) => {
    setCurrentStep(step);
  };

  if (activeFlow?.steps.length === 0) {
    return <Redirect to="/" />;
  }

  return (
    <FullScreenLayout>
      <Container
        disableGutters
        maxWidth={false}
        classes={{
          root: classes.containerRoot,
        }}
      >
        <activeFlow.templateComponent
          progress={progress}
          currentStepIndex={currentStep}
          lastStepIndex={
            activeFlow.hasCompleteStep ? stepsLength - 1 : stepsLength
          }
        >
          {activeFlow.formComponent ? (
            // if using formComponent, map functions inside of formComponent for step control
            <activeFlow.formComponent
              goToStep={goToStep}
              currentStepIndex={currentStep}
              submitStepKey={activeFlow.submitStepKey}
              setStepsLength={setStepsLength}
            >
              {activeFlow.steps.map(({ key, stepNumber, Component }) => (
                <Component key={key || stepNumber} />
              ))}
            </activeFlow.formComponent>
          ) : (
            activeFlow.steps
              .filter((step) =>
                activeFlow.formComponent
                  ? true
                  : step.stepNumber === currentStep,
              )
              .map(({ key, stepNumber, Component }) => (
                // if not using multi-page form, filter to single step and handle page changes in template
                <Component
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                  goToStep={goToStep}
                  currentStep={currentStep}
                  totalSteps={activeFlow.steps.length}
                  key={key || stepNumber}
                  progress={progress}
                />
              ))
          )}
        </activeFlow.templateComponent>
      </Container>
    </FullScreenLayout>
  );
};

export default SelfSelectFlow;

SelfSelectFlow.propTypes = {
  flowId: PropTypes.oneOf(['taxAccountSetup', 'createAccountingAccount'])
    .isRequired,
};
