// absolute imports
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import FileSaver from 'file-saver';
import { enqueueSnackbar } from 'notistack';

// relative imports
import { useAccount } from './AccountProvider';
import { useAuth0 } from './Auth0Provider';
import { useAppManagement } from './AppManagementProvider';
import { getDate } from '../../shared/utilities/dateUtilities';
import { captureSentryError } from '../../error-handling/sentry';
import { formatReportTableFilters } from '../../shared/utilities/reportDataMapping';
import {
  REPORT_TOTAL_QUERY,
  REPORT_TYPES_QUERY,
} from '../../../data/apollo/queries';

export const ReportContext = createContext();
export const useReport = () => useContext(ReportContext);

export const ReportActionsContext = createContext();
export const useReportActions = () => useContext(ReportActionsContext);

export const reportsTableStateDefault = {
  filters: [],
  globalFilter: '',
  sortBy: [
    {
      id: 'timestamp',
      desc: true,
    },
  ],
  pageSize: 10,
  pageIndex: 0,
};

const downloadReportFromServer = async ({
  reportId,
  accountId,
  fileName,
  startLoading,
  stopLoading,
  getTokenSilently,
  onError,
}) => {
  // retrieve file from server
  const token = await getTokenSilently();

  const url = `${
    import.meta.env.REACT_APP_API_URL
  }/api/accounts/${accountId}/transactions/${reportId}`;

  const fetchOpts = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    credentials: 'same-origin',
  };

  startLoading(reportId);

  try {
    const response = await fetch(url, fetchOpts);

    if (response.ok) {
      const blob = await response.blob();
      FileSaver.saveAs(blob, `${fileName}`);
    } else {
      // success status code not found
      onError({ message: response.statusText, code: response.status });
    }
  } catch (error) {
    onError(error);
  }
  stopLoading(reportId);
};

function filterReducer(reportDownloadState, [type, reportId, payload]) {
  switch (type) {
    case 'startLoading':
      return {
        ...reportDownloadState,
        [reportId]: {
          loading: true,
          error: null,
        },
      };
    case 'stopLoading':
      return {
        ...reportDownloadState,
        [reportId]: {
          ...reportDownloadState[reportId],
          loading: false,
        },
      };
    case 'downloadError':
      return {
        ...reportDownloadState,
        [reportId]: {
          loading: false,
          error: payload,
        },
      };
    case 'clearError':
      return {
        ...reportDownloadState,
        [reportId]: {
          loading: false,
          error: null,
        },
      };
    default:
      return reportDownloadState;
  }
}

export const ReportProvider = ({ children }) => {
  const { getTokenSilently, user } = useAuth0();
  const { currentAccount } = useAccount();
  const { appType } = useAppManagement();

  /* State for report downloads */
  const [reportDownloadState, dispatch] = useReducer(filterReducer, {});

  /** Queries */
  const {
    data: { getAccountReportTypes: accountReportTypes = [] } = {},
    loading: loadingTypes,
  } = useQuery(REPORT_TYPES_QUERY, {
    variables: { accountId: currentAccount.id },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !currentAccount.id,
  });

  const {
    data: {
      getReportsByFilter: { totalUnfiltered } = {},
      refetch: refetchTotalReportCount,
    } = {},
  } = useQuery(REPORT_TOTAL_QUERY, {
    variables: {
      accountId: currentAccount.id,
      filters: formatReportTableFilters(reportsTableStateDefault),
      limit: 5,
      offset: 0,
      sortBy: reportsTableStateDefault.sortBy,
      clientAccountName: reportsTableStateDefault.globalFilter,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !currentAccount.id,
  });

  const accountReportTypesObject = useMemo(() => {
    const types = {};
    accountReportTypes.forEach((type) => {
      const { id, ...reportDetails } = type;
      types[id] = reportDetails;
    });
    return types;
  }, [accountReportTypes]);

  const startReportLoading = useCallback(
    (reportId) => {
      dispatch(['startLoading', reportId]);
    },
    [dispatch],
  );

  const stopReportLoading = useCallback(
    (reportId) => {
      dispatch(['stopLoading', reportId]);
    },
    [dispatch],
  );

  const clearDownloadError = useCallback(
    (reportId) => {
      dispatch(['clearError', reportId]);
    },
    [dispatch],
  );

  const onReportDownloadError = useCallback(
    (report, err) => {
      const {
        id: reportId,
        type: reportTypeId,
        requested: reportGeneratedAt,
      } = report;
      const reportType = accountReportTypesObject?.[reportTypeId];
      // set error for reportId
      dispatch(['downloadError', reportId, err]);
      // capture error to send to Sentry
      captureSentryError({
        error: err,
        accountId: currentAccount.id,
        appType,
        userId: user.id,
        context: {
          file: 'ReportProvider.jsx',
        },
      });
      // show error snackbar
      enqueueSnackbar(
        `The ${reportType?.clientParameters.displayName} 
        (created: ${reportGeneratedAt}) could not be downloaded 
        due to an issue: ${err.message}. 
      `,
        {
          key: reportId,
          preventDuplicate: true,
          autoHideDuration: 5000,
          variant: 'error',
        },
      );
    },
    [accountReportTypesObject, appType, currentAccount.id, dispatch, user?.id],
  );

  const downloadReport = useCallback(
    (report) => {
      const { id: reportId, type: reportTypeId, taxDetails } = report;
      const reportType = accountReportTypesObject?.[reportTypeId];
      // format file name
      const downloadName =
        reportType?.clientParameters?.downloadName || 'Ledgible_Report';
      const extension = reportType?.extension || '.csv';

      let fileString = `${downloadName}_${getDate().toFormat('M/d/yyyy')}_`;

      if (taxDetails) {
        fileString += `${taxDetails.contactName || taxDetails.accountName}`;
      } else {
        fileString += `${currentAccount.name}`;
        if (
          reportType?.clientParameters.displayName.includes('Netsuite') &&
          fileString.length > 45
        ) {
          fileString = fileString.substring(0, 45);
        }
      }

      downloadReportFromServer({
        reportId,
        accountId: currentAccount.id,
        fileName: `${fileString}.${extension}`,
        getTokenSilently,
        startLoading: startReportLoading,
        stopLoading: stopReportLoading,
        onError: (err) => onReportDownloadError(report, err),
      });
    },
    [
      accountReportTypesObject,
      currentAccount,
      getTokenSilently,
      startReportLoading,
      stopReportLoading,
      onReportDownloadError,
    ],
  );

  const reportValue = useMemo(
    () => ({
      accountReportTypes,
      accountReportTypesObject,
      reportDownloadStatus: reportDownloadState,
      totalReportCount: totalUnfiltered,
      loadingReportTypes: loadingTypes,
    }),
    [
      accountReportTypes,
      accountReportTypesObject,
      reportDownloadState,
      totalUnfiltered,
      loadingTypes,
    ],
  );

  const reportActionsValue = useMemo(
    () => ({
      downloadReport,
      onReportDownloadError,
      clearDownloadError,
      startReportLoading,
      stopReportLoading,
      refetchTotalReportCount,
    }),
    [
      downloadReport,
      onReportDownloadError,
      clearDownloadError,
      startReportLoading,
      stopReportLoading,
      refetchTotalReportCount,
    ],
  );

  return (
    <ReportContext.Provider value={reportValue}>
      <ReportActionsContext.Provider value={reportActionsValue}>
        {children}
      </ReportActionsContext.Provider>
    </ReportContext.Provider>
  );
};

ReportProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ReportConsumer = ReportContext.Consumer;
export const ReportActionsConsumer = ReportActionsContext.Consumer;
