// absolute imports
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CardActions, CardContent, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// icon imports
import {
  ArrowRightRounded as ArrowRightIcon,
  ArrowLeftRounded as ArrowLeftIcon,
} from '@mui/icons-material';

// relative imports
import LedgibleButton from '../../../../../shared/custom-components/buttons/LedgibleButton';
import { getSubtypeInputFieldFromConfig } from '../../../../../../data/app-config/configs/subtypeField-utilities';
import { getRemoteAccountConfig } from '../../../../../../data/app-config/configs/configUtilities';

const useStyles = makeStyles()((theme) => ({
  cardContent: {
    margin: theme.spacing(2, 1),
  },
  headerText: {
    marginBottom: theme.spacing(2),
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const SubtypeFieldsStep = ({ previousPage, values }) => {
  const { classes } = useStyles();
  const { subtype: selectedSubtype } = values;
  const [subtypeSetupFields, setSubtypeSetupFields] = useState(null);

  useEffect(() => {
    const getSelectedSubtypeFields = async () => {
      const accountConfig = await getRemoteAccountConfig(selectedSubtype);
      if (accountConfig.accountSetup?.subtypeFields) {
        setSubtypeSetupFields(accountConfig.accountSetup?.subtypeFields);
      }
    };
    getSelectedSubtypeFields();
  }, [selectedSubtype]);

  return (
    <>
      <CardContent
        classes={{
          root: classes.cardContent,
        }}
      >
        <Typography
          className={classes.headerText}
          color="secondary"
          gutterBottom
          variant="h6"
          align="center"
        >
          Account Fields
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="subtitle1">
          The following key fields are required for the account type you have
          chosen.
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={3}
        >
          {subtypeSetupFields &&
            subtypeSetupFields.map((fieldId) => (
              <Grid item xs={12} key={fieldId}>
                {getSubtypeInputFieldFromConfig(fieldId, 'account-create', {
                  variant: 'outlined',
                })}
              </Grid>
            ))}
        </Grid>
      </CardContent>
      <CardActions className={classes.actionRow} disableSpacing>
        <LedgibleButton
          variant="outlined"
          size="large"
          color="secondary"
          textTransformOff
          onClick={previousPage}
          startIcon={<ArrowLeftIcon />}
          id="create-account-subtype-fields-step-previous"
        >
          Go Back
        </LedgibleButton>
        <LedgibleButton
          variant="contained"
          size="large"
          color="success"
          textTransformOff
          endIcon={<ArrowRightIcon />}
          id="create-account-subtype-fields-step-continue"
          data-cy="create-account-subtype-fields-step-continue"
          type="submit"
        >
          Continue
        </LedgibleButton>
      </CardActions>
    </>
  );
};

export default SubtypeFieldsStep;

SubtypeFieldsStep.propTypes = {
  previousPage: PropTypes.func,
  values: PropTypes.shape(),
};

SubtypeFieldsStep.defaultProps = {
  previousPage: () => null,
  values: {},
};
