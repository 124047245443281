/* eslint-disable import/prefer-default-export */
/**
 * used to generate consistent data-cy values that can be referenced in cypress
 * @param {*} componentName - name of the component
 * @param {*} elementName - identifier for specific element
 * @param {*} elementType - element type [
  alert,
  button,
  input,
  checkbox,
  radioGroup,
  dropzone,
  dialog,
  paper,
  drawer,
  img,
  grid,
  card,
  graph,
]
 *
 *
 * ex:
 * getComponentTestKey({
 *  componentName: 'AddSourceModal',
 *  elementName: 'sourceName'
 *  elementType: 'input',
 * }) => AddSourceModal-sourceName-input
 */
export function getComponentTestKey({
  componentName,
  elementType,
  elementName,
}) {
  return `${componentName}-${elementName}-${elementType}`;
}
