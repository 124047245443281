// absolute imports
import { HttpLink } from '@apollo/client';

/* Set URI for all Apollo GraphQL requests (backend api) */
export const ledgibleAPILink = new HttpLink({
  uri: `${import.meta.env.REACT_APP_API_URL}/api/graphql`,
  fetchOptions: { credentials: 'same-origin' },
});

export const ledgibleCMSLink = new HttpLink({
  uri: `${import.meta.env.REACT_APP_SANITY_PROJECT_URL}/v2023-08-01/graphql/${
    import.meta.env.REACT_APP_SANITY_DATASET
  }/ledgible-cms`,
});
