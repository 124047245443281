import PropTypes from 'prop-types';
import { DialogContentText } from '@mui/material';

const LedgibleDialogContentText = (props) => {
  const { children, ...muiContentText } = props;

  return <DialogContentText {...muiContentText}>{children}</DialogContentText>;
};

export default LedgibleDialogContentText;

LedgibleDialogContentText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

LedgibleDialogContentText.defaultProps = {
  children: null,
};
