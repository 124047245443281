import { getDate } from './dateUtilities';

export const formatReportTableFilters = (filter) => {
  const currentFilters = Array.isArray(filter) ? filter : [];
  const mappedFilter = [];

  currentFilters.forEach((item) => {
    if (item.id === 'taxType') {
      mappedFilter.push({ id: 'type', value: item.value });
    } else if (item.id === 'timestamp') {
      const timestampFilter = filter.find((elem) => elem.id === 'timestamp');

      if (timestampFilter) {
        const start = timestampFilter?.value[0];
        const end = timestampFilter?.value[1];

        if (start || end) {
          const mappedTimestamp = {
            id: 'timestamp',
            range: {
              startDate: start
                ? getDate(start, {
                    type: 'utc',
                    parseMethod: 'ISO',
                    parseOptions: { zone: 'utc' },
                    timezoneOnly: false,
                  })
                    .startOf('day')
                    .toISO()
                : undefined,
              endDate: end
                ? getDate(end, {
                    type: 'utc',
                    parseMethod: 'ISO',
                    parseOptions: { zone: 'utc' },
                    timezoneOnly: false,
                  })
                    .endOf('day')
                    .toISO()
                : undefined,
            },
          };

          mappedFilter.push(mappedTimestamp);
        }
      }
    } else {
      mappedFilter.push(item);
    }
  });

  return mappedFilter;
};

export const tableIdName = {
  'tax-preparer': 'Preparer',
  tax: 'TaxPayer',
  accounting: 'Accounting',
};
