// root app setup
import { createRoot } from 'react-dom/client';
// Auth0 setup
import { createAuth0Client } from '@auth0/auth0-spa-js';
// Routing
import { BrowserRouter } from 'react-router-dom';
// Apollo Setup
import { CachePersistor, LocalForageWrapper } from 'apollo3-cache-persist';
import localforage from 'localforage';
import { InMemoryCache } from '@apollo/client';
// Sentry setup
import * as Sentry from '@sentry/react';
// material-ui setup
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { merge } from 'lodash';
import { SnackbarProvider } from 'notistack';

// css for root component
import './components/App.css';

// relative imports
import App from './components/App';
import { ConfigProvider } from './components/common/providers/ConfigProvider';
import { AccountProvider } from './components/common/providers/AccountProvider';
import { AppManagementProvider } from './components/common/providers/AppManagementProvider';
import { Auth0Provider } from './components/common/providers/Auth0Provider';
import AuthorizedApolloProvider from './components/common/providers/AuthorizedApolloProvider';
import { ModalContextProvider } from './components/common/providers/ModalProvider';
import { GoogleTagManager } from './components/shared/google-tag-manager/GTMContainer';
import GQLErrorBoundary from './components/error-handling/GQLErrorBoundary';
import apolloTypePolicies from './data/apollo/cache/typePolicies';
import apolloPossibleTypes from './data/apollo/cache/possibleTypes';
import AppErrorBoundary from './components/error-handling/AppErrorBoundary';
import {
  getAuth0DefaultClientOptions,
  getAuth0ClientId,
  getAuth0RedirectUri,
  getUrlAppParam,
} from './components/shared/utilities/appUtilities';
import {
  getRemoteAppTheme,
  getRemoteAppConfig,
  getRemoteAccountConfig,
} from './data/app-config/configs/configUtilities';
import { primaryStoreKey } from './data/apollo/cache/utilities/browserStorage';
import CloseSnackbar from './components/shared/custom-components/CloseSnackbar';
import { captureSentryGenericError } from './components/error-handling/sentry';

// Sentry init
if (
  import.meta.env.PROD &&
  !window.Cypress &&
  import.meta.env.MODE !== 'ci' &&
  import.meta.env.REACT_APP_SENTRY_DSN
) {
  Sentry.init({
    dsn: import.meta.env.REACT_APP_SENTRY_DSN,
    denyUrls: ['localhost'],
    environment: import.meta.env.REACT_APP_SENTRY_ENVIRONMENT,
  });
}

// Emotion cache for customization/override purposes like removing annoying SSR warning
const emotionCache = createCache({
  key: 'css',
  prepend: true,
});
emotionCache.compat = true;

// Apollo -> set up cache
const cache = new InMemoryCache({
  typePolicies: apolloTypePolicies,
  possibleTypes: apolloPossibleTypes,
});

/* Create persistor to handle persisting data from local storage on refresh, etc.
 * Uses CachePersistor instead of automatic persistCache method to enable more granular
 * control (if needed)
 */
const persistor = new CachePersistor({
  cache,
  storage: new LocalForageWrapper(localforage),
});

const cachedAppId = JSON.parse(localStorage.getItem(primaryStoreKey))?.appType;

// async chain for app initialization
(async () => {
  // load default configs;
  const appConfig = await getRemoteAppConfig();
  const appTheme = await getRemoteAppTheme();
  const accountConfig = await getRemoteAccountConfig();

  /* Make sure auth0 client is available before AuthProvider gets assigned */
  const auth0Client = await createAuth0Client(
    merge(getAuth0DefaultClientOptions(), {
      clientId: getAuth0ClientId(cachedAppId),
      authorizationParams: {
        redirect_uri: getAuth0RedirectUri(cachedAppId),
      },
    }),
  );

  const container = document.getElementById('root');
  const root = createRoot(container);

  /* Create root render function */
  const AppComponent = () => (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={appTheme}>
        {/* All nested components have access to Ledgible theme styling */}
        {/* + CssBaseline for Material-UI library. */}
        <CssBaseline />
        <BrowserRouter>
          <AppErrorBoundary
            fallbackProps={{
              auth0Client,
              persistor,
            }}
            onReset={() => {
              window.location.replace(
                `${
                  import.meta.env.REACT_APP_AUTH0_CALLBACK
                }/${getUrlAppParam()}`,
              );
            }}
            context={{
              component: 'renderApp',
            }}
          >
            <SnackbarProvider action={CloseSnackbar} />
            <ConfigProvider appConfig={appConfig} accountConfig={accountConfig}>
              <ModalContextProvider>
                <AuthorizedApolloProvider
                  auth0Client={auth0Client}
                  persistor={persistor}
                  cache={cache}
                >
                  <Auth0Provider auth0Client={auth0Client}>
                    {!window.Cypress && <GoogleTagManager />}
                    <AppManagementProvider>
                      <GQLErrorBoundary>
                        <AccountProvider>
                          <App />
                        </AccountProvider>
                      </GQLErrorBoundary>
                    </AppManagementProvider>
                  </Auth0Provider>
                </AuthorizedApolloProvider>
              </ModalContextProvider>
            </ConfigProvider>
          </AppErrorBoundary>
        </BrowserRouter>
      </ThemeProvider>
    </CacheProvider>
  );

  /* Render React App after restoring persisted cache, enable MSW intercept for testing purposes if needed */
  if (!window.Cypress) {
    await persistor.restore();
  }
  (async () => {
    if (import.meta.env.REACT_APP_USE_MSW === 'true') {
      const { default: worker } = await import(
        `../test/mocks/msw/workers/browser`
      );
      const { activateStoredMocks } = await import(
        `../test/mocks/msw/mock-endpoint`
      );
      await worker.start({
        onUnhandledRequest: 'bypass',
      });
      activateStoredMocks();
    }
    root.render(<AppComponent />);
  })();
})().catch((e) => {
  captureSentryGenericError(e, {
    file: 'index.jsx',
    warn: 'check auth0 client instance',
  });
});
