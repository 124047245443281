import PropTypes from 'prop-types';
import { FormHelperText as HelperText } from '@mui/material';

const FormHelperText = ({ touched, error, passedErr, passedErrText }) => {
  if (touched && error) return <HelperText>{touched && error}</HelperText>;
  if (passedErr) return <HelperText>{passedErrText}</HelperText>;
  return null;
};

export default FormHelperText;

FormHelperText.propTypes = {
  touched: PropTypes.bool.isRequired,
  error: PropTypes.string,
  passedErr: PropTypes.shape(),
  passedErrText: PropTypes.string,
};

FormHelperText.defaultProps = {
  error: null,
  passedErr: null,
  passedErrText: null,
};
