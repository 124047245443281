// absolute imports
import PropTypes from 'prop-types';
import {
  Alert,
  AlertTitle,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// icon imports
import {
  ArrowRightRounded as ArrowRightIcon,
  ArrowLeftRounded as ArrowLeftIcon,
} from '@mui/icons-material';

// relative imports
import LedgibleButton from '../../../../../shared/custom-components/buttons/LedgibleButton';
import EntryProgress from '../../../../importing/modals/shared-components/EntryProgress';
import {
  subtypeFieldDefs,
  getFormFieldIds,
} from '../../../../../../data/app-config/configs/subtypeField-utilities';
import { getTimezoneDisplayPartialString } from '../../../../../shared/utilities/dateUtilities';
import { useConfig } from '../../../../providers/ConfigProvider';
import Loader from '../../../../../shared/custom-components/Loader';

const useStyles = makeStyles()((theme) => ({
  cardContent: {
    margin: theme.spacing(2, 1),
  },
  headerText: {
    marginBottom: theme.spacing(2),
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const AccountDetailsConfirmStep = ({
  values,
  createAccountLoading,
  previousPage,
  createAccountError,
  createdAccountId,
}) => {
  const { accountConfig } = useConfig();
  const { accountSetup } = accountConfig;
  const { classes } = useStyles();
  const formFieldIds = getFormFieldIds(values);

  // define values from form
  const { name: accountName, timezone, subtype, ...subtypeValues } = values;

  // map dynamic subtypeValues to formField structure
  const subtypeFormFields = Object.keys(subtypeValues).map((key) => ({
    id: formFieldIds[key],
    value: key,
    display: subtypeFieldDefs[key].fieldLabel,
    format: () =>
      subtypeFieldDefs[key].valueDisplayReference
        ? subtypeFieldDefs[key].valueDisplayReference[subtypeValues[key]]
        : subtypeValues[key],
  }));

  // define formFields for display
  const formFields = [
    {
      id: formFieldIds.name,
      value: 'name',
      display: accountSetup?.fieldLabels?.name || 'Account Name',
      format: (val) => val,
    },
    {
      id: formFieldIds.timezone,
      value: 'timezone',
      display: accountSetup?.fieldLabels?.timezone || 'Account Timezone',
      format: (val) => getTimezoneDisplayPartialString(val),
    },
    ...subtypeFormFields,
  ];

  const loading = !!createdAccountId || createAccountLoading;

  return (
    <>
      <CardContent
        classes={{
          root: classes.cardContent,
        }}
      >
        <Typography
          className={classes.headerText}
          color="secondary"
          gutterBottom
          variant="h6"
          align="center"
        >
          Review Account Details
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="subtitle1">
          Review account details, then click &#39;Create Account&#39; to create
          this account.
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={12}>
            <EntryProgress
              values={values}
              formFields={formFields}
              showDivider
            />
          </Grid>
          {createAccountError && (
            <Grid item xs={12}>
              <Alert severity="error" style={{ marginTop: 12 }}>
                <AlertTitle>Error</AlertTitle>
                {createAccountError.message}
              </Alert>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <CardActions className={classes.actionRow} disableSpacing>
        <LedgibleButton
          variant="outlined"
          size="large"
          color="secondary"
          textTransformOff
          onClick={previousPage}
          startIcon={<ArrowLeftIcon />}
          id="create-account-review-step-previous"
        >
          Go Back
        </LedgibleButton>
        <LedgibleButton
          variant="contained"
          size="large"
          color="success"
          textTransformOff
          endIcon={<ArrowRightIcon />}
          id="create-account-review-step-submit"
          data-cy="create-account-review-step-submit"
          type="submit"
          startIcon={loading && <Loader size={20} thickness={4} />}
        >
          {loading && 'Loading'}
          {!loading && 'Create Account'}
        </LedgibleButton>
      </CardActions>
    </>
  );
};

export default AccountDetailsConfirmStep;

AccountDetailsConfirmStep.propTypes = {
  values: PropTypes.shape(),
  previousPage: PropTypes.func,
  createAccountLoading: PropTypes.bool,
  createAccountError: PropTypes.shape(),
  createdAccountId: PropTypes.string,
};

AccountDetailsConfirmStep.defaultProps = {
  previousPage: () => null,
  values: {},
  createAccountLoading: false,
  createAccountError: undefined,
  createdAccountId: undefined,
};
