// absolute imports
import { useContext, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';

// relative imports
import { useAccount } from './AccountProvider';
import { useAuth0 } from './Auth0Provider';
import { useAppManagement } from './AppManagementProvider';
import useUpdatePolling from '../../shared/custom-hooks/useUpdatePolling';
import { defaultCursorReturnSizes } from '../../../data/app-config/appDetails';
import {
  WALLETS_QUERY,
  WALLET_QUERY,
  CHAINS_QUERY,
  GET_NFTS_BY_SEARCH,
} from '../../../data/apollo/queries';
import { getDate } from '../../shared/utilities/dateUtilities';

export const WalletContext = createContext();
export const useWallet = () => useContext(WalletContext);

// PROVIDER COMPONENT
export const WalletProvider = ({ children }) => {
  const { appType, displayAppType } = useAppManagement();
  const walletPageMatch = useRouteMatch({
    path: `/${displayAppType}/wallets/:walletId`,
    isExact: false,
  });
  const nftsPageMatch = useRouteMatch({
    path: `/${displayAppType}/nfts`,
    isExact: false,
  });
  const params = walletPageMatch ? walletPageMatch.params : {};
  const { isAuthenticated } = useAuth0();
  const { currentAccount } = useAccount();

  const {
    data: { wallets = [] } = {},
    loading: loadingWallets = false,
    refetch: refetchWallets,
    networkStatus: walletsNetworkStatus,
  } = useQuery(WALLETS_QUERY, {
    variables: {
      accountId: currentAccount.id,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip:
      !currentAccount.id ||
      appType === 'tax-preparer' ||
      currentAccount?.disabled,
  });

  const walletsUpdating = wallets?.some(
    (wallet) => wallet.updating || wallet.sourceUpdating,
  );

  // Reference var for page requests with invalid wallet id params
  const walletUrlInvalid = useMemo(() => {
    if (
      params.walletId &&
      wallets?.length > 0 &&
      !wallets.map(({ id }) => id).includes(params.walletId)
    ) {
      return true;
    }
    return false;
  }, [params.walletId, wallets]);

  const {
    data: { wallet: currentWallet = {} } = {},
    networkStatus: walletNetworkStatus,
    refetch: refetchWallet,
  } = useQuery(WALLET_QUERY, {
    variables: {
      accountId: currentAccount.id,
      walletId: params.walletId, // must have wallet ID provided from URL
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip:
      !currentAccount.id ||
      !params.walletId ||
      wallets?.length === 0 ||
      walletUrlInvalid,
  });

  const { refetch: fetchNfts } = useQuery(GET_NFTS_BY_SEARCH, {
    variables: {
      accountId: currentAccount.id,
      feedType: 'NFT',
      limit: defaultCursorReturnSizes.nfts,
      sortBy: 'nameAsc',
      includePaymentInfo: true,
      nftFilter: {
        name: '',
        contractAddresses: [],
        walletIds: wallets
          .filter(
            (wallet) => wallet.type !== 'manual' && wallet.chain === 'ETH',
          )
          .map((wallet) => wallet.id),
        tokenType: null,
      },
    },
    fetchPolicy: 'network-only',
    skip:
      !currentAccount.id ||
      wallets?.length === 0 ||
      nftsPageMatch?.path === `/${displayAppType}/nfts` ||
      currentAccount?.type === 'TaxPreparer',
  });

  const loadingWallet =
    [4, 1].includes(walletNetworkStatus) && !currentWallet?.id;

  // manage updating queries for different wallet views
  useUpdatePolling({
    preset: 'wallet',
    updateCondition:
      params.walletId &&
      (currentWallet?.updating || currentWallet?.sourceUpdating),
    disabled: !params.walletId,
    variables: {
      walletId: currentWallet?.id,
    },
    onUpdateStart: () => {
      enqueueSnackbar(`${currentWallet?.name} update in progress`, {
        key: `${currentWallet?.id}-started`,
        preventDuplicate: true,
        autoHideDuration: 3000,
        variant: 'info',
      });
    },
    onUpdateComplete: () => {
      refetchWallet();
      fetchNfts();
      enqueueSnackbar(`${currentWallet?.name} update complete`, {
        key: `${currentWallet?.id}-finished`,
        preventDuplicate: true,
        autoHideDuration: 3000,
        variant: 'success',
      });
    },
  });

  useUpdatePolling({
    preset: 'wallets',
    updateCondition: walletsUpdating && !params.walletId,
    disabled: !!params.walletId,
    onUpdateComplete: () => {
      const hasRecentlyUpdated = wallets.some(({ lastUpdated }) => {
        const minutesSinceUpdated = getDate(new Date(lastUpdated), {
          parseMethod: 'JSDate',
        }).diffNow('minutes').minutes;

        return minutesSinceUpdated > -10;
      });

      if (hasRecentlyUpdated) {
        refetchWallets();
        fetchNfts();
        enqueueSnackbar('Wallet updates complete', {
          key: `all-wallets-update-status`,
          preventDuplicate: true,
          autoHideDuration: 3000,
          variant: 'success',
        });
      }
    },
  });

  // query for chain list
  const { data: { chainList = [] } = [], loading: loadingChainList } = useQuery(
    CHAINS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: !isAuthenticated || appType === 'none' || !currentAccount.id,
    },
  );

  const walletProviderValue = useMemo(
    () => ({
      currentWallet: currentWallet || {},
      wallets: wallets || [],
      chainList: chainList || [],
      loadingWallet,
      loadingWallets,
      walletsNetworkStatus,
      loadingChainList,
      walletUrlInvalid,
      walletsUpdating,
      refetchWallet,
      refetchWallets,
      walletNetworkStatus,
    }),
    [
      chainList,
      currentWallet,
      loadingChainList,
      loadingWallet,
      loadingWallets,
      refetchWallet,
      refetchWallets,
      walletsUpdating,
      walletUrlInvalid,
      wallets,
      walletNetworkStatus,
      walletsNetworkStatus,
    ],
  );

  return (
    <WalletContext.Provider value={walletProviderValue}>
      {children}
    </WalletContext.Provider>
  );
};

export const WalletConsumer = WalletContext.Consumer;

WalletProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
