// absolute imports
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Alert, AlertTitle } from '@mui/material';
import clsx from 'clsx';

// icon imports
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';

// relative imports
import useAlternativeDisplay from '../../shared/custom-hooks/useAlternativeDisplay';

const useStyles = makeStyles()((theme) => ({
  alert: {
    color: theme.palette.error.dark,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  mobile: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: {
    color: theme.palette.error.dark,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  alertIcon: {
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(3),
  },
  alertTitleBase: {
    color: theme.palette.error.dark,
    marginTop: 0,
    marginBottom: theme.spacing(2),
    lineHeight: '1.2',
  },
  contactButton: {
    color: theme.palette.error.dark,
  },
}));

const DefaultErrorFallback = ({
  errorMsg = 'An error occurred loading this content',
}) => {
  const { classes } = useStyles();
  const { isMobile } = useAlternativeDisplay();

  const alertClassnames = clsx({
    [classes.alert]: true,
    [classes.mobile]: isMobile,
  });

  return (
    <Alert
      variant="standard"
      severity="error"
      className={alertClassnames}
      classes={{
        icon: classes.alertIcon,
      }}
      icon={<ErrorOutlineIcon />}
    >
      <>
        <AlertTitle className={classes.alertTitleBase}>{errorMsg}</AlertTitle>
        <p className={classes.errorText}>
          Yep, it&apos;s an error. Our team is likely working on a fix already.
          In the meantime, feel free to contact our support team for further
          help.
        </p>
      </>
    </Alert>
  );
};

export default DefaultErrorFallback;

DefaultErrorFallback.propTypes = {
  errorMsg: PropTypes.string,
};

DefaultErrorFallback.defaultProps = {
  errorMsg: undefined,
};
