/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
// absolute imports

import { getTimeZones } from '@vvo/tzdb';
import { DateTime } from 'luxon';

// relative imports
import {
  currentAccountTimezoneVar,
  currentAccountTaxYearVar,
} from '../../../data/apollo/cache/reactiveVars';

const allTimezones = getTimeZones({ includeUtc: true });

/** invocation for timezone getting system timezone - returns timezone value if available */
export const guessTimezone = () => {
  const dt = DateTime.local();
  return dt.zoneName;
};

/** Returns luxon DateTime instance parsed in user's account timezone by default, with options for local or UTC parsing
 * @param {Date} date - DateTime object from luxon
 * @param {String[]} [validFormats] - optional date invalidation if input format doesn't match eg. ['MM/DD/YYY', 'DD/MM/YYYY']
 */
export const getDateFromFormats = (date, validFormats = []) => {
  const formatChecks = [];
  validFormats.forEach((format) => {
    const d = DateTime.fromFormat(date, format);
    formatChecks.push(d.isValid);
  });
  if (formatChecks.includes(true)) {
    return DateTime.fromFormat(date, validFormats[formatChecks.indexOf(true)]);
  }
  return DateTime.invalid('Invalid format string');
};

const luxonParseMethods = [
  'ISO',
  'RFC2822',
  'HTTP',
  'SQL',
  'Millis',
  'Seconds',
  'JSDate',
  'Format',
  'FormatExplain',
  'Object',
];

/** Returns luxon DateTime instance parsed in user's account timezone by default, with options for local or UTC parsing
 * @param {Date} date - date arg, can be anything that parseMethods can parse
 * @param {Object} [options={}] - options object for date formatting and output
 * @param {'local' | 'utc' | 'timezone'} [options.type=timezone] - locality for parsing, affects all subsequent operations
 * @param {'ISO' | 'RFC2822' | 'HTTP' | 'SQL' | 'Millis' | 'Seconds' | 'JSDate' | 'Format' | 'FormatOnly'} [options.parseMethod=ISO] -
 * method for parsing, affects all subsequent operations
 * @param {String} [optons.timezone=undefined] - valid IANA timzone to transform date to, defaults to account.timezone then system timezone, can be set
 * to 'system' for system timezone if account timezone needs to be overwritten
 * @param {String} [optons.parseFormat=undefined] - format to use for parsing a date, eg 'DD/MM/YYYY'
 * @param {String[]} [options.validParseFormats] - array of possible formats to use for parsing date, otherwise will invalidate
 * @param {Object} [optons.parseOptions={}] - options passed to parsing functions -> whatever luxon allows
 * @param {Boolean} [optons.timezoneOnly=false] - flag that applies keepLocalTime on timezone assignment to preserve timestamp
 */
export const getDate = (date = undefined, options = {}) => {
  const {
    type = 'timezone',
    parseMethod = 'ISO',
    parseOptions = {},
    parseFormat = undefined,
    timezone = currentAccountTimezoneVar() || guessTimezone(),
    validFormats = undefined,
    timezoneOnly = false,
  } = options;
  // date arg defaults to current date in timezone
  let newDate = date || DateTime.local().setZone(timezone);

  // ensure valid parse method is queued up
  if (!luxonParseMethods.includes(parseMethod)) newDate = DateTime.invalid();
  // attempt to parse date based on populated args
  if (!DateTime.isDateTime(newDate)) {
    if (validFormats) newDate = getDateFromFormats(date, validFormats);
    else if (parseFormat && ['Format', 'FormatExplain'].includes(parseMethod))
      newDate = DateTime[`from${parseMethod}`](date, parseFormat, parseOptions);
    else newDate = DateTime[`from${parseMethod}`](date, parseOptions);
  }
  // check for valid date before proceeding with parseType conversion
  if (newDate.isValid && type) {
    if (type === 'local') {
      newDate = newDate.setZone('system');
    }
    if (type === 'utc') {
      newDate = newDate.toUTC();
    }
    if (type === 'timezone') {
      if (timezoneOnly)
        newDate = newDate.setZone(timezone, { keepLocalTime: true });
      else {
        newDate = newDate.setZone(timezone);
      }
    }
  }

  // return luxon DateTime instance
  return newDate;
};

export const isDateValid = (date) => DateTime.fromISO(date).isValid;

export const isEpochStart = (date) =>
  DateTime.fromMillis(0).ts === DateTime.fromISO(date).toMillis();

export const dateIsAfter = (date, dateBefore) => {
  const dateToCheck = DateTime.isDateTime(date) ? date : getDate(date);
  const dateToBeAfter = DateTime.isDateTime(dateBefore)
    ? dateBefore
    : getDate(dateBefore);
  if (dateToCheck.isValid && dateToBeAfter.isValid) {
    return dateToCheck > dateToBeAfter;
  }
  throw new Error('Invalid DateTime objects for comparison');
};

export const dateIsBefore = (date, dateAfter) => {
  const dateToCheck = DateTime.isDateTime(date) ? date : getDate(date);
  const dateToBeBefore = DateTime.isDateTime(dateAfter)
    ? dateAfter
    : getDate(dateAfter);
  if (dateToCheck.isValid && dateToBeBefore.isValid) {
    return dateToCheck < dateToBeBefore;
  }
  throw new Error('Invalid DateTime objects for comparison');
};

export const checkForMaxDate = (dt) => {
  const currentTz = currentAccountTimezoneVar();
  const today = currentTz ? DateTime.now().setZone(currentTz) : DateTime.now();
  // is future date
  if (dateIsAfter(dt, today)) {
    // if future date month is past current date, return the parsed date with the previous year
    if (
      dt.month > today.month ||
      (dt.month === today.month && dt.day > today.day)
    ) {
      return dt.set({ year: today.year - 1 });
    }
    // if future date month is before current date, return the parsed date with the current year
    if (
      dt.month < today.month ||
      (dt.month === today.month && dt.day <= today.day)
    ) {
      return dt.set({ year: today.year });
    }
  }
  return dt;
};

// bitcoin genesis block date in account timezone
export const bitcoinGenesisDate = getDate('2009-01-03').startOf('day');

export const checkForMinDate = (dt) => {
  const minimumDate = bitcoinGenesisDate;

  // is past date
  if (dateIsBefore(dt, minimumDate)) {
    // day is either Jan 1st or 2nd, return minimumDate
    if (dt.year === minimumDate.year) {
      return minimumDate;
    }
    // return date with following year
    return dt.set({ year: minimumDate.year });
  }
  return dt;
};

export const convertToStandardTime = (time) =>
  getDate(time).toFormat('hh:mm a');

// formatter for account timezone value string to match selector display
export const getTimezoneDisplayString = (tz) => {
  const currentTz = tz || currentAccountTimezoneVar();
  const tzFull =
    allTimezones.find(
      (timeZone) =>
        currentTz === timeZone.name || timeZone.group.includes(currentTz),
    ) || {};
  return tzFull.currentTimeFormat || null;
};

// formatter for account timezone value string to match selector display
export const getTimezoneDisplayPartialString = (tz, abbr) => {
  const currentTz = tz || currentAccountTimezoneVar();
  const tzFull =
    allTimezones.find(
      (timeZone) =>
        currentTz === timeZone.name || timeZone.group.includes(currentTz),
    ) || {};

  if (
    tzFull.currentTimeFormat &&
    tzFull.currentTimeFormat.split(' ').length > 2
  ) {
    const timezoneStringArr = tzFull.currentTimeFormat.split(' ');
    if (abbr) return `${timezoneStringArr[0]} GMT`;
    return `${timezoneStringArr[1]} ${timezoneStringArr[2]} (${timezoneStringArr[0]} GMT)`;
  }
  return tzFull.currentTimeFormat || null;
};

/** returns the maximum or minimum date of provided dates based provided array and designation
 * @param {'min'| 'max'} action - min or max (desired result)
 * @param {Array} [dates=[]] - array input of luxon dates
 */
export const getMinMax = (action, dates) => {
  try {
    if (action === 'min') {
      const minn = DateTime.min(...dates);
      return minn;
    }
    if (action === 'max') {
      const maxx = DateTime.max(...dates);
      return maxx;
    }
    throw new Error();
  } catch (e) {
    throw new Error('Must specify valid action and dates');
  }
};

/** Convert text string to corresponding start/end date values for filters */
export const getFilterDatesFromString = (dateLabel) => {
  const startDefault = bitcoinGenesisDate;
  const endDefault = getDate();

  // defaults if no matched dateLabel
  let start = startDefault.toUTC(); // default UTC start output
  let end = endDefault.toUTC(); // default UTC end output

  switch (dateLabel) {
    // last 24 hours
    case 'oneDay':
      start = endDefault.minus({ hours: 24 }).toUTC();
      break;
    // last 7 days
    case 'oneWeek':
      start = endDefault.minus({ week: 1 }).toUTC();
      break;
    // month-to-date
    case 'currentMonth':
      start = endDefault.startOf('month').toUTC();
      break;
    // previous calendar month
    case 'pastMonth':
      start = endDefault.minus({ month: 1 }).startOf('month').toUTC();
      end = endDefault.minus({ month: 1 }).endOf('month').toUTC();
      break;
    // tax year
    case 'currentTaxYear': {
      const taxYear = currentAccountTaxYearVar();

      start = endDefault.set({ year: taxYear }).startOf('year').toUTC();
      end = endDefault.set({ year: taxYear }).endOf('year').toUTC();
      break;
    }
    default:
      break;
  }

  return {
    start,
    end,
  };
};

export const checkDateEqual = (d1, d2) => {
  if (d1 && d2) {
    const date1 = DateTime.isDateTime(d1)
      ? d1
      : getDate(new Date(d1).toISOString());
    const date2 = DateTime.isDateTime(d2)
      ? d2
      : getDate(new Date(d2).toISOString());
    if (date1.isValid && date2.isValid) {
      return (
        date1.startOf('day').toString() === date2.startOf('day').toString()
      );
    }
    return false;
  }
  return false;
};

