// absolute imports
import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

// relative imports
import { accessTokenVar, errorVar } from '../cache/reactiveVars';

let isRefreshing = false;
let pendingRequests = [];

// store concurrent requests after err instead of erroring
const addPendingRequest = (pendingRequest) => {
  pendingRequests.push(pendingRequest);
};

// resolve stored concurrent requests
const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const getNewToken = async (auth0Client) => {
  const newToken = await auth0Client.getTokenSilently();
  accessTokenVar(newToken);
};

// FOR ERROR LINK -> returns a value only if you want to retry an operation
const errorLink = (auth0Client) =>
  onError(
    ({
      graphQLErrors,
      networkError,
      operation,
      forward,
      // eslint-disable-next-line consistent-return
    }) => {
      if (networkError) {
        // handle 401 as network err -> maybe consider auth directive for backend resolves in future
        if (networkError.statusCode === 401) {
          // retry request after refreshing token, set auth error if it fails
          if (!isRefreshing) {
            isRefreshing = true;
            // use fromPromise to transform token check promise to observable that
            // can be returned up the chain
            return fromPromise(
              getNewToken(auth0Client).catch(() => {
                // catch error if token refresh fails
                resolvePendingRequests();
                isRefreshing = false;
                // throw up logout prompt due to token being invalid
                errorVar({
                  code: 401,
                  message: 'Invalid Token',
                  modalKey: 'TOKEN_EXPIRED',
                  type: 'network',
                });
                return forward(operation);
              }),
            ).flatMap(() => {
              resolvePendingRequests();
              isRefreshing = false;
              return forward(operation); // retry the op the token failed on
            });
          }
          // if already refreshing, return resolution for concurrent requests instead
          // of multiple failures
          return fromPromise(
            new Promise((resolve) => {
              addPendingRequest(() => resolve());
            }),
          ).flatMap(() => forward(operation));
        }
        // handle 408 timeout error with popup msg to user, otherwise show generic network err message
        if (networkError.statusCode === 408) {
          errorVar({
            code: 408,
            message: 'Request timeout. Please try refreshing the page.',
            modalKey: 'GENERIC_ERROR',
            operation,
            type: 'network',
          });
        } else {
          errorVar({
            code: networkError.statusCode,
            message: networkError.message,
            modalKey: 'GENERIC_ERROR',
            operation,
            type: 'network',
            contactSupport: true,
          });
        }
      }

      if (graphQLErrors) {
        graphQLErrors.forEach((err) => {
          const { code, message, path, details } = err;
          switch (err?.message) {
            case "Wallet's would exceed transaction limit":
              // throw global error for wallet transaction threshold flag
              errorVar({
                code: code || 500,
                message,
                modalKey: 'OVER_TX_LIMIT',
                operation: path ? path[0] : null,
                type: 'graphql',
                details: details
                  ? { ...details, __typename: 'ErrorDetails' }
                  : null,
              });
              break;
            default:
              // set errorVar value for whatever err it is
              errorVar({
                code: graphQLErrors[0].code || 500,
                message: graphQLErrors[0].message,
                details: graphQLErrors[0].details,
                operation: graphQLErrors[0].path
                  ? graphQLErrors[0].path[0]
                  : null,
                type: 'graphql',
              });
              break;
          }
        });
      }
    },
  );

export default errorLink;
