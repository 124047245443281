// absolute imports
import PropTypes from 'prop-types';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Field, Form } from 'react-final-form';
import { FormControl, FormLabel, FormHelperText } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';

// relative imports
import {
  LedgibleDialog,
  LedgibleDialogActions,
  LedgibleDialogContent,
  LedgibleDialogTitle,
} from '../../../shared/modals';
import renderDropzoneInput from './TirDemoDropzoneInput';
import LedgibleButton from '../../../shared/custom-components/buttons/LedgibleButton';
import useAlternativeDisplay from '../../../shared/custom-hooks/useAlternativeDisplay';
import { useAccount } from '../../../common/providers/AccountProvider';
import { ADD_TIR_CORRECTION_FILE } from '../../../../data/apollo/mutations';
import { GET_TIR_CORRECTION_FILES } from '../../../../data/apollo/queries';

const useStyles = makeStyles()((theme) => ({
  noButtonDialogTitle: {
    paddingBottom: theme.spacing(2),
  },
  formHelperText: {
    marginLeft: 0,
  },
}));

const SubmitCorrectionFileModal = ({ hideModal }) => {
  const { isMobile } = useAlternativeDisplay();
  const { classes } = useStyles({ isMobile });
  const { currentAccount } = useAccount();
  const [uploadedFile, setUploadedFile] = useState(null);

  const handleFileUploadChange = (files) => {
    setUploadedFile(files[0]);
  };

  const [addTirCorrectionFile] = useMutation(ADD_TIR_CORRECTION_FILE, {
    refetchQueries: () => [
      {
        query: GET_TIR_CORRECTION_FILES,
        variables: {
          accountId: currentAccount.id,
        },
      },
    ],
    onCompleted: () => {
      hideModal();
      enqueueSnackbar('Correction file submitted', {
        key: `tir-correction-file-${Date.now()}`,
        preventDuplicate: true,
        persist: false,
        autoHideDuration: 5000,
        variant: 'success',
      });
    },
  });

  return (
    <LedgibleDialog maxWidth="sm" fullWidth disableEscapeKeyDown>
      <Form
        onSubmit={() => {
          if (!uploadedFile) {
            return;
          }

          addTirCorrectionFile({
            variables: {
              accountId: currentAccount.id,
              filename: uploadedFile.name.split('.')[0],
            },
          });
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <LedgibleDialogTitle
              titleText="Transaction Categorization"
              classes={{
                root: classes.noButtonDialogTitle,
              }}
            />
            <LedgibleDialogContent dividers>
              <FormControl>
                <FormLabel>Correction File Upload</FormLabel>
                <FormHelperText className={classes.formHelperText}>
                  Please upload a correction file. We will send an email
                  notification when the recategorized file is ready to download.
                </FormHelperText>
                <Field
                  name="file"
                  component={renderDropzoneInput}
                  onChange={handleFileUploadChange}
                  acceptedExtArr={['csv', 'txt']}
                  matchHeader="csv"
                />
              </FormControl>
            </LedgibleDialogContent>
            <LedgibleDialogActions>
              <LedgibleButton
                size="small"
                color="cancel"
                onClick={hideModal}
                id="tir-correction-file-modal-cancel-button"
              >
                Cancel
              </LedgibleButton>
              <LedgibleButton
                size="small"
                color="primary"
                type="submit"
                disabled={!uploadedFile}
                id="tir-correction-file-modal-ok-button"
              >
                Confirm
              </LedgibleButton>
            </LedgibleDialogActions>
          </form>
        )}
      </Form>
    </LedgibleDialog>
  );
};

export default SubmitCorrectionFileModal;

SubmitCorrectionFileModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
};
