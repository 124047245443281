import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { amber } from '@mui/material/colors';
import { Grid, Dialog } from '@mui/material';

// import icons
import { Warning as WarningIcon } from '@mui/icons-material';

// relative imports
import {
  LedgibleDialogActions,
  LedgibleDialogContent,
  LedgibleDialogContentText,
  LedgibleDialogTitle,
} from '../../modals';
import LedgibleButton from '../../custom-components/buttons/LedgibleButton';

const useStyles = makeStyles()((theme) => ({
  noButtonDialogTitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  modalContentRow: {
    marginTop: theme.spacing(1),
  },
  modalIcon: {
    color: amber[700],
    paddingTop: theme.spacing(3),
  },
}));

const SessionTimeoutModal = ({
  initialValue,
  timeRemaining,
  handleStillHere,
  isExpired,
}) => {
  const { classes } = useStyles();

  return (
    <Dialog open maxWidth="sm" fullWidth disableEscapeKeyDown>
      <LedgibleDialogTitle
        titleText={
          timeRemaining > 0 && !isExpired
            ? 'Session Expires Soon'
            : 'Session Expired'
        }
        classes={{
          root: classes.noButtonDialogTitle,
        }}
      />
      <LedgibleDialogContent dividers>
        <Grid
          container
          direction="row"
          alignContent="center"
          spacing={4}
          className={classes.modalContentRow}
        >
          <Grid
            item
            xs={3}
            container
            alignContent="stretch"
            alignItems="center"
            justifyContent="center"
            className={classes.modalIcon}
          >
            <WarningIcon fontSize="large" />
          </Grid>
          <Grid
            item
            xs={9}
            container
            alignContent="stretch"
            alignItems="center"
            justifyContent="flex-start"
          >
            {timeRemaining > 0 && !isExpired && (
              <>
                <LedgibleDialogContentText gutterBottom>
                  Your session will expire in&nbsp;
                  <b>
                    {timeRemaining > initialValue
                      ? initialValue
                      : timeRemaining}
                  </b>
                  &nbsp;seconds due to inactivity.
                </LedgibleDialogContentText>
                <LedgibleDialogContentText>
                  Do you want to continue your session?
                </LedgibleDialogContentText>
              </>
            )}
            {(timeRemaining === 0 || isExpired) && (
              <LedgibleDialogContentText gutterBottom>
                Your session has expired due to inactivity. You will be
                automatically logged out for security.
              </LedgibleDialogContentText>
            )}
          </Grid>
        </Grid>
      </LedgibleDialogContent>
      {timeRemaining > 0 && !isExpired && (
        <LedgibleDialogActions>
          <LedgibleButton
            size="small"
            onClick={handleStillHere}
            color="primary"
            id="session-warning-modal-continue-button"
            marginRight={8}
          >
            Continue
          </LedgibleButton>
        </LedgibleDialogActions>
      )}
    </Dialog>
  );
};

export default SessionTimeoutModal;

SessionTimeoutModal.propTypes = {
  initialValue: PropTypes.number.isRequired,
  timeRemaining: PropTypes.number.isRequired,
  handleStillHere: PropTypes.func.isRequired,
  isExpired: PropTypes.bool.isRequired,
};
