import PropTypes from 'prop-types';
import { DialogContent } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useContentStyles = makeStyles()((theme) => ({
  defaultSize: {
    overflowY: 'auto',
    paddingTop: theme.spacing(2),
  },
}));

const LedgibleDialogContent = (props) => {
  const { children, classes, ...muiContentProps } = props;
  const { classes: customClasses, cx } = useContentStyles();
  const { root } = classes;

  return (
    <DialogContent
      classes={{
        root: cx(customClasses.defaultSize, root),
        ...classes,
      }}
      {...muiContentProps}
    >
      {children}
    </DialogContent>
  );
};

export default LedgibleDialogContent;

LedgibleDialogContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  classes: PropTypes.shape(),
};

LedgibleDialogContent.defaultProps = {
  children: null,
  classes: {},
};
