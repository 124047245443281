import { ApolloLink } from '@apollo/client';
import { accessTokenVar } from '../cache/reactiveVars';

/* Create Apollo Link to supply token in auth header with every gql request */
export const authLink = new ApolloLink((operation, forward) => {
  const accessToken = accessTokenVar();

  operation.setContext(({ headers }) => ({
    headers: {
      ...headers,
      ...accessToken ? { authorization: `Bearer ${accessToken}` } : {},
    },
  }));

  return forward(operation);
});

export const skipAuthLink = new ApolloLink((operation, forward) => forward(operation));

