/* since no support (yet) in apollo-cache-persist to natively persist reactive vars,
 *use this for reactive vars makeVar function to make them persistent in session storage
 */

import { makeVar } from '@apollo/client';
import { getLocalStore, saveLocalStore } from './browserStorage';

const makePersistentVar = (initialValue, storeKey, storageType = 'session') => {
  let value = initialValue;
  const initialStore = getLocalStore(storageType); // unserialize state for ref
  // Try to fetch the value from local storage
  const previousValue = initialStore ? initialStore[storeKey] : null;

  if (previousValue || previousValue === false) {
    value = previousValue;
  }

  // Use apollo makeVar to make reactive var with stored/initial value
  // assumes default val doesn't need to be stored, b/c default
  const reactiveVar = makeVar(value);

  const onNextChangeCb = (newValue) => {
    try {
      // Try to add the value to local storage
      let newStore = getLocalStore(storageType);
      if (!newStore) {
        newStore = {};
      }
      newStore[storeKey] = newValue;
      saveLocalStore(newStore, storageType);
    } catch {
      // ignore
    }

    // Re-register for the next change
    reactiveVar.onNextChange(onNextChangeCb);
  };

  // Register for the first change
  reactiveVar.onNextChange(onNextChangeCb);

  return reactiveVar;
};

export default makePersistentVar;
