// absolute imports
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Alert, AlertTitle, TableCell, TableRow } from '@mui/material';

const useStyles = makeStyles()(() => ({
  alert: {
    width: '100%',
  },
  noPadding: {
    padding: 0,
    margin: 0,
  },
  alertTitleBase: {
    marginBottom: 0,
  },
  errorMessage: {
    fontWeight: 400,
  },
}));

const ErrorBoundaryFallbackRow = ({
  fallbackProps,
  errorMsg = 'An error ocurred trying to display this row',
}) => {
  const { classes } = useStyles();
  const { rowLength } = fallbackProps;

  return (
    <TableRow className={classes.noPadding}>
      <TableCell colSpan={rowLength} style={{ padding: 0, margin: 0 }}>
        <Alert severity="error" className={classes.alert}>
          <AlertTitle className={classes.alertTitleBase}>
            <span className={classes.errorMessage}>{errorMsg}</span>
          </AlertTitle>
        </Alert>
      </TableCell>
    </TableRow>
  );
};

export default ErrorBoundaryFallbackRow;

ErrorBoundaryFallbackRow.propTypes = {
  fallbackProps: PropTypes.shape(),
  errorMsg: PropTypes.string,
};

ErrorBoundaryFallbackRow.defaultProps = {
  fallbackProps: {
    rowLength: 0,
  },
  errorMsg: undefined,
};
