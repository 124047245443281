import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

const FormEmailField = ({ input, meta, ...emailFieldProps }) => {
  const { touched, error, active, pristine } = meta;

  return (
    <TextField
      label={emailFieldProps?.label}
      type="email"
      error={touched && !!error}
      helperText={touched && !!error ? error : null}
      FormHelperTextProps={{
        style: {
          whiteSpace: 'pre-line',
        },
      }}
      InputLabelProps={{
        focused: active,
        shrink:
          (active && pristine) ||
          !pristine ||
          (!!input.value && input.value !== ''),
        ...emailFieldProps.InputLabelProps,
      }}
      inputProps={{
        onFocus: input.onFocus,
        onBlur: !pristine && input.onBlur,
      }}
      onChange={(event) => {
        const { value } = event.target;
        input.onChange(value);
      }}
      value={input.value}
      {...emailFieldProps}
    />
  );
};

export default FormEmailField;

FormEmailField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
};
