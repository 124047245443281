import PropTypes from 'prop-types';
import { DialogActions } from '@mui/material';

const LedgibleDialogActions = (props) => {
  const { children, ...muiActionProps } = props;

  return <DialogActions {...muiActionProps}>{children}</DialogActions>;
};

export default LedgibleDialogActions;

LedgibleDialogActions.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

LedgibleDialogActions.defaultProps = {
  children: null,
};
