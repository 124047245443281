// absolute imports
import { makeStyles } from 'tss-react/mui';

import { useReactiveVar } from '@apollo/client';
import { Backdrop } from '@mui/material';

// relative imports
import { helpWidgetActiveVar } from '../../../data/apollo/cache/reactiveVars';
import { closeZendeskWidget } from './zendesk-operations';

const useStyles = makeStyles()(() => ({
  overlay: {
    backgroundColor: 'rgba(239, 239, 239, 0.6)',
    zIndex: 2000,
  },
}));

const HelpWidgetOverlay = () => {
  const { classes } = useStyles();
  const helpWidgetActive = useReactiveVar(helpWidgetActiveVar);

  return (
    <Backdrop
      open={helpWidgetActive}
      className={classes.overlay}
      onClick={closeZendeskWidget}
    />
  );
};

export default HelpWidgetOverlay;
