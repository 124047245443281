// import external modules
import {
  useState,
  useMemo,
  useReducer,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { useQuery } from '@apollo/client';
import {
  Avatar,
  Box,
  ButtonBase,
  Drawer,
  IconButton,
  InputAdornment,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material';
import { FixedSizeList } from 'react-window';

// import svg icons
import {
  ArrowDropDown,
  ArrowDropUp,
  Search as SearchIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

// relative imports
import { GET_PREPARER_LINKS } from '../../../data/apollo/queries';
import { createInitials } from '../../shared/utilities/stringUtilities';
import { useAccount } from '../../common/providers/AccountProvider';
import useAlternativeDisplay from '../../shared/custom-hooks/useAlternativeDisplay';
import ActiveAccountIndicator from '../../accounting/accounts/account-window/ActiveAccountIndicator';
import { mapColor } from '../../shared/utilities/styleUtilities';
import { getAppUrlPathFromId } from '../../shared/utilities/appUtilities';
import useAsyncDebounce from '../../shared/custom-hooks/useAsyncDebounce_SHIM';

// LIST ITEM COMPONENT
const useListItemStyles = makeStyles()((theme) => ({
  activeAccountButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  displaySet: {
    display: 'block',
  },
  accountListSecondary: {
    fontStyle: 'italic',
  },
  tealText: {
    color: theme.palette.primary.main,
  },
}));

// define row render function for virtualization
const VirtualizedAccountRow = ({
  index,
  style,
  clientList,
  currentAccount,
  setClientListOffset,
  total,
  chooseAccount,
}) => {
  const classes = useListItemStyles();
  const preparerLink = clientList[index];

  useEffect(() => {
    if (clientList.length < total && clientList.length - 1 === index) {
      setClientListOffset(clientList.length);
    }
  }, [clientList.length, index, setClientListOffset, total]);

  return preparerLink ? (
    <ListItemButton
      key={preparerLink.id}
      style={style}
      selected={preparerLink.clientAccountId === currentAccount.id}
      onClick={() => chooseAccount(preparerLink.clientAccountId)}
      component={Link}
      to={`/${getAppUrlPathFromId('tax')}`}
      classes={{
        selected: classes.activeAccountButton,
      }}
      divider
    >
      <Avatar
        style={{
          backgroundColor: mapColor(preparerLink.clientAccountName, 90, 40),
        }}
        sx={{ mr: 1.5 }}
      >
        {createInitials(preparerLink.clientAccountName)}
      </Avatar>
      <ListItemText
        primary={preparerLink.clientAccountName}
        primaryTypographyProps={{
          noWrap: true,
          className: classes.displaySet,
        }}
        secondary={preparerLink.clientTaxDetail.taxYear}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'body2',
          className: classes.accountListSecondary,
        }}
      />
      {preparerLink.clientAccountId === currentAccount.id && (
        <ListItemIcon className={classes.tealText}>
          <ActiveAccountIndicator
            circleSize={10}
            marginRight={0.5}
            text="current"
          />
        </ListItemIcon>
      )}
    </ListItemButton>
  ) : null;
};

VirtualizedAccountRow.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.shape().isRequired,
  clientList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currentAccount: PropTypes.shape().isRequired,
  setClientListOffset: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  chooseAccount: PropTypes.func.isRequired,
};

// LIST COMPONENT

const useStyles = makeStyles()((theme, { isMobile }) => ({
  windowWrapper: {
    display: 'flex',
    height: '100%',
    borderLeft: `${isMobile ? 0 : 1}px solid ${theme.palette.grey[200]}`,
    paddingLeft: isMobile ? 0 : theme.spacing(2),
    marginLeft: isMobile ? 0 : theme.spacing(3),
    alignItems: 'center',
  },
  accountButton: {
    maxWidth: 180,
    minWidth: 100,
    maxHeight: 32,
    borderRadius: 4,
    backgroundColor:
      theme.whiteLabel.AccountBox.color || 'rgba(255, 255, 255, 0.1)',
    color: theme.whiteLabel.AccountBox.textColor || 'inherit',
    padding: theme.spacing(0.25, 1),
  },
  accountButtonListText: {
    color:
      theme.whiteLabel.HeaderText.color || isMobile
        ? undefined
        : theme.palette.common.white,
  },
  dropdownIcon: {
    marginLeft: theme.spacing(0.75),
    color:
      theme.whiteLabel.HeaderText.color || isMobile
        ? undefined
        : theme.palette.common.white,
  },
  headerText: {
    color:
      theme.whiteLabel.AccountWindow.headerTextColor ||
      theme.palette.common.white,
    fontSize: theme.typography.pxToRem(24),
    cursor: 'default',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  drawerHeader: {
    padding: theme.spacing(2),
    background:
      theme.whiteLabel.AccountWindow.color || theme.palette.primary.main,
  },
  selectField: {
    marginTop: 0,
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
    },
  },
  searchAdornment: {
    color: theme.palette.grey[400],
  },
}));

/* reducer default account state */
function heightsReducer(state, [element, value]) {
  switch (element) {
    case 'drawer':
      return { ...state, drawer: value };
    case 'search':
      return { ...state, search: value };
    case 'header':
      return { ...state, header: value };
    default:
      return state;
  }
}

const PreparerClientAccountWindow = () => {
  const { isMobile } = useAlternativeDisplay();
  const { classes, cx } = useStyles({ isMobile });
  const { currentAccount, selectAccount } = useAccount();
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchTermText, setSearchTermText] = useState('');
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [clientListOffset, setClientListOffset] = useState(0);
  const [searchActive, setSearchActive] = useState(false);
  const clientListLimit = 15;
  const [clientList, setClientList] = useState([]);

  // state for ref'ed heights
  const [heights, dispatchHeights] = useReducer(heightsReducer, {
    drawer: 0,
    header: 0,
    filters: 0,
    search: 0,
    addButton: 0,
  });
  // filter state for preparerLinks
  const titleRef = useRef(null);
  // static heights
  const LIST_ITEM_HEIGHT = 75;

  // callback ref for height management
  const measuredRef = useCallback((node) => {
    if (node !== null) {
      let elementId;
      if (node.id === 'account-window-search') {
        elementId = 'search';
      }
      if (node.id === 'account-window-drawer') {
        elementId = 'drawer';
      }
      if (node.id === 'account-window-header') {
        elementId = 'header';
        // set ref for drawer for popper
        titleRef.current = node;
      }
      if (elementId) {
        // set height of element
        dispatchHeights([elementId, node.getBoundingClientRect().height]);
      }
    }
  }, []);

  const toggleDrawer = () => {
    setAccountMenuOpen(!accountMenuOpen);
  };

  const chooseAccount = (accountId) => {
    selectAccount(accountId);
    toggleDrawer();
  };

  const handleSearchTermChange = useAsyncDebounce((val) => {
    setSearchActive(true);
    setSearchTerm(val);
    if (clientListOffset !== 0) {
      setClientListOffset(0);
    }
  }, 200);

  const handleSearchTermTextChange = (e) => {
    setSearchTermText(e.target.value);
    handleSearchTermChange(e.target.value);
  };

  const handleSearchTermTextKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearchTermChange(e);
    }
  };

  const handleClearSearch = useCallback(() => {
    setSearchTermText('');
    handleSearchTermChange('');
  }, [handleSearchTermChange]);

  // set height of list to properly size window for virtualization
  const listHeight = useMemo(() => {
    const nonListHeight = heights.search + heights.header + heights.addButton;
    const heightDifference = heights.drawer - nonListHeight - 1;

    return typeof heightDifference === 'number' ? heightDifference : 0;
  }, [heights]);

  // Get data w/o cache for expected behavior due to reusing paging query
  const {
    previousData: {
      getPreparerLinksByFilter: { totalFiltered: previousTotal } = {},
    } = {},
    data: { getPreparerLinksByFilter: { totalFiltered } = {} } = {},
  } = useQuery(GET_PREPARER_LINKS, {
    variables: {
      accountId: currentAccount.preparerLink?.preparerAccountId,
      filters: [
        {
          id: 'accountLinkStatus',
          arrayValue: ['connected', 'preparer-only'],
        },
      ],
      clientAccountName: searchTerm,
      limit: clientListLimit,
      offset: clientListOffset,
      sortBy: [{ id: 'clientAccountName', desc: false }],
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted(result) {
      const { preparerLinks } = result?.getPreparerLinksByFilter || {};
      const page = Math.floor(totalFiltered / clientListLimit);
      const atLimit = page > 0 && totalFiltered < page * clientListLimit;

      if (searchActive && clientListOffset === 0) {
        setClientList([...preparerLinks]);
        if (!searchTerm) {
          setSearchActive(false);
        }
      } else if (
        !clientList.map(({ id }) => id).includes(preparerLinks[0]?.id) &&
        !atLimit
      ) {
        setClientList((current) => [...current, ...preparerLinks]);
      }
    },
    skip: !currentAccount.preparerLink?.preparerAccountId || !accountMenuOpen,
  });

  // Account List return
  return (
    <div className={classes.windowWrapper}>
      <ButtonBase
        onClick={toggleDrawer}
        className={cx('accounts-window', classes.accountButton)}
      >
        <ActiveAccountIndicator circleSize={8} />
        <ListItemText
          primary={currentAccount.id && currentAccount.name}
          classes={{
            root: classes.accountButtonListText,
          }}
          primaryTypographyProps={{
            variant: 'body1',
            noWrap: true,
            color: 'inherit',
          }}
        />
        {accountMenuOpen && (
          <ArrowDropUp className={classes.dropdownIcon} fontSize="small" />
        )}
        {!accountMenuOpen && (
          <ArrowDropDown className={classes.dropdownIcon} fontSize="small" />
        )}
      </ButtonBase>
      <Drawer
        anchor="left"
        variant="temporary"
        open={accountMenuOpen}
        onClose={toggleDrawer}
        id="account-window-drawer"
        ref={measuredRef}
      >
        <Box
          className={classes.drawerHeader}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          id="account-window-header"
          ref={measuredRef}
        >
          <span className={classes.headerText} aria-label="title">
            Client Accounts
          </span>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="stretch"
          id="account-window-search"
          ref={measuredRef}
        >
          <TextField
            value={searchTermText}
            variant="outlined"
            placeholder="Search Client Accounts..."
            className={classes.selectField}
            margin="none"
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.searchAdornment}
                >
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchTermText && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClearSearch}
                    className={classes.searchIconButton}
                    aria-label="directions"
                    size="small"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handleSearchTermTextChange}
            onKeyDown={handleSearchTermTextKeyDown}
            fullWidth
          />
        </Box>
        {clientList.length > 0 && (
          <div>
            <FixedSizeList
              height={listHeight || 0}
              width={window.innerWidth > 450 ? 450 : window.innerWidth}
              itemSize={LIST_ITEM_HEIGHT}
              itemCount={totalFiltered ?? previousTotal}
            >
              {({ index, style }) => (
                <VirtualizedAccountRow
                  index={index}
                  style={style}
                  clientList={clientList}
                  currentAccount={currentAccount}
                  setClientListOffset={setClientListOffset}
                  total={totalFiltered ?? previousTotal}
                  chooseAccount={chooseAccount}
                />
              )}
            </FixedSizeList>
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default PreparerClientAccountWindow;
