import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Typography,
} from '@mui/material';

const FormSingleCheckbox = forwardRef((props, ref) => {
  const {
    input,
    fieldLabel,
    label,
    meta,
    meta: { error, submitFailed },
    FormControlProps,
    labelProps,
    ...checkboxFieldProps
  } = props;
  let finalLabelProps = labelProps;
  if (typeof labelProps === 'function') {
    finalLabelProps = labelProps(meta, input);
  }

  return (
    <FormControl
      required={checkboxFieldProps.required}
      error={!!(submitFailed && error)}
      ref={ref}
      {...FormControlProps}
    >
      {fieldLabel && (
        <FormLabel component="legend" style={{ paddingBottom: 16 }}>
          {fieldLabel}
        </FormLabel>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <Checkbox
          checked={input.checked}
          value={input.value}
          onChange={input.onChange}
          name={checkboxFieldProps.name}
          {...checkboxFieldProps}
        />
        <Typography color="secondary" variant="body1" {...finalLabelProps}>
          {label}
        </Typography>
      </div>
      {submitFailed && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
});

export default FormSingleCheckbox;

// Checkbox prop types
FormSingleCheckbox.propTypes = {
  input: PropTypes.shape({
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape(),
      PropTypes.bool,
    ]),
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
    submitFailed: PropTypes.bool,
  }).isRequired,
  label: PropTypes.node,
  fieldLabel: PropTypes.string,
  labelProps: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]),
  FormControlProps: PropTypes.shape(),
};

FormSingleCheckbox.defaultProps = {
  fieldLabel: null,
  label: null,
  labelProps: {},
  FormControlProps: {},
};
