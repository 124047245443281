export const accountingTableStateDefault = {
  txDateEnd: null,
  txDateStart: null,
  txDateStatic: 'oneDay',
  wallets: null,
  exchanges: null,
  currencies: null,
  contacts: null,
  types: null,
  sortColumn: null,
  sortOrder: null,
  contracts: null,
};

export const browserCheckDefault = {
  confirmedBadBrowser: false,
  confirmedBadOS: false,
  currentBrowser: null,
  currentOS: null,
  confirmedMobile: false,
};
