// absolute imports
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';

// relative imports
import { useDialogState } from '../custom-hooks/useDialogState';

const FormPristineSynchronizer = ({ pristine, submitError }) => {
  const { updateDialogState } = useDialogState();

  const PristineSynchronizer = () => {
    useEffect(() => {
      updateDialogState('pristine', submitError ? false : pristine);
    }, []);

    return null;
  };

  return (
    <FormSpy subscription={{ pristine: true }}>
      {() => <PristineSynchronizer />}
    </FormSpy>
  );
};

export default FormPristineSynchronizer;

FormPristineSynchronizer.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitError: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
    PropTypes.bool,
  ]),
};

FormPristineSynchronizer.defaultProps = {
  submitError: undefined,
};