// import external modules
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { ListItemIcon, ListItem, ListItemText } from '@mui/material';
import { amber } from '@mui/material/colors';

// import svg icons
import { RemoveRedEye as EyeIcon } from '@mui/icons-material';

// relative imports
import { upperCase } from '../../shared/utilities/stringUtilities';
import { useAccount } from '../../common/providers/AccountProvider';
import useAlternativeDisplay from '../../shared/custom-hooks/useAlternativeDisplay';
import { currentAccountTaxYearVar } from '../../../data/apollo/cache/reactiveVars';

const useStyles = makeStyles()((theme, { isMobile }) => ({
  listItemRoot: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
    marginLeft: isMobile ? 0 : theme.spacing(3),
    paddingTop: 0,
    paddingBottom: 0,
  },
  accountButton: {
    maxWidth: 160,
  },
  greyText: {
    color: theme.palette.grey[isMobile ? 400 : 200],
    display: 'block',
  },
  textPadLeft: {
    color: theme.whiteLabel.HeaderText.color || theme.palette.common.white,
    fontSize: theme.typography.pxToRem(14),
    paddingLeft: '12px',
  },
  textPadLeftSecondary: {
    paddingLeft: '12px',
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[isMobile ? 700 : 400],
  },
  acctButtonIcon: {
    minWidth: 'auto',
    width: 'auto',
  },
  noPad: {
    padding: 0,
  },
  accountList: {
    width: 250,
    maxHeight: '100%',
    overflowY: 'auto',
  },
  buttonRoot: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      background: 'none',
    },
    marginLeft: theme.spacing(3),
  },
  accountAvatar: {
    color: '#fff',
  },
  whiteText: {
    color: theme.palette.common.white,
  },
  tealText: {
    color: theme.palette.primary.main,
  },
  leftBorder: {
    borderLeft: `${isMobile ? 0 : 1}px solid ${theme.palette.grey[200]}`,
  },
  drawerHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    background: theme.palette.primary.main,
  },
  eyeIcon: {
    width: 'auto',
  },
  noMargin: {
    margin: 0,
  },
  editIcon: {
    color: amber[700],
  },
  deleteIcon: {
    color: theme.palette.error.light,
  },
  displaySet: {
    display: 'block',
  },
}));

const TirAdminClientAccountWindow = () => {
  const { isMobile } = useAlternativeDisplay();
  const { classes, cx } = useStyles({ isMobile });
  const { currentAccount } = useAccount();
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const { taxPayerDetails = {} } = currentAccount;
  const currentPreparerLink = currentAccount?.preparerLink;
  const defaultTaxYear = currentAccountTaxYearVar();

  const toggleDrawer = () => {
    setAccountMenuOpen(!accountMenuOpen);
  };

  return (
    <ListItem
      className={classes.leftBorder}
      classes={{
        root: classes.buttonRoot,
        gutters: classes.textPadLeft,
      }}
      onClick={toggleDrawer}
      alignItems="center"
    >
      <ListItemIcon
        classes={{
          root: classes.acctButtonIcon,
        }}
      >
        <EyeIcon className={classes.greyText} />
      </ListItemIcon>
      <ListItemText
        primary={currentPreparerLink?.clientAccountName || currentAccount.name}
        primaryTypographyProps={{
          classes: {
            root: classes.textPadLeft,
            colorPrimary: classes.greyText,
          },
          variant: 'body1',
          noWrap: true,
        }}
        secondary={`Tax Year ${taxPayerDetails.taxYear || defaultTaxYear}
        ${
          taxPayerDetails.taxMethod
            ? ` (${upperCase(taxPayerDetails.taxMethod)})`
            : ''
        }`}
        secondaryTypographyProps={{
          classes: {
            root: classes.textPadLeftSecondary,
            colorPrimary: classes.greyText,
          },
          variant: 'body2',
          noWrap: true,
        }}
        className={cx(classes.noPad, classes.accountButton)}
      />
    </ListItem>
  );
};

export default TirAdminClientAccountWindow;
