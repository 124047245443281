// absolute imports
import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import {
  Alert,
  AlertTitle,
  CardActions,
  CardContent,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { useMutation } from '@apollo/client';

// icon imports
import {
  ArrowRightRounded as ArrowRightIcon,
  ArrowLeftRounded as ArrowLeftIcon
} from '@mui/icons-material';

// relative imports
import { taxMethodRef } from '../../../../../../data/app-config/appDetails';
import { useAccount } from '../../../../providers/AccountProvider';
import LedgibleButton from '../../../../../shared/custom-components/buttons/LedgibleButton';
import useApolloErrorState from '../../../../../shared/custom-hooks/useApolloErrorState';
import { UPDATE_TAX_METHOD } from '../../../../../../data/apollo/mutations';

const useStyles = makeStyles()((theme) => ({
  headerText: {
    marginBottom: theme.spacing(2),
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  selectField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  infoAlert: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const AlgorithmStep = ({ handleNext, handlePrevious }) => {
  const { classes } = useStyles();
  const { currentAccount } = useAccount();
  const {
    taxPayerDetails: { taxMethod },
  } = currentAccount;
  const [changeMethodError, clearError] = useApolloErrorState([
    'updateTaxMethod',
  ]);
  const [newMethod, setNewMethod] = useState(
    currentAccount.taxPayerDetails.taxMethod,
  );

  const [updateTaxMethod] = useMutation(UPDATE_TAX_METHOD, {
    variables: {
      accountId: currentAccount.id,
      newMethod,
    },
  });

  const handleContinue = () => {
    if (changeMethodError) {
      clearError();
    }
    if (taxMethod !== newMethod) {
      updateTaxMethod();
    }
    handleNext();
  };

  return (
    <>
      <CardContent>
        <Typography
          className={classes.headerText}
          color="secondary"
          gutterBottom
          variant="h6"
          align="center"
        >
          Tax Method Selection
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="subtitle1">
          The default tax methodology is FIFO (First In First Out). You can
          select a different method below to learn more about it, or click
          continue once you have made your selection.
        </Typography>
        <TextField
          fullWidth
          select
          value={newMethod}
          color="primary"
          size="small"
          label="Tax Method"
          onChange={(e) => setNewMethod(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!changeMethodError}
          variant="outlined"
          helperText={!!changeMethodError && 'Error setting tax methodology'}
          className={classes.selectField}
        >
          {Object.keys(taxMethodRef).map((method) => (
            <MenuItem key={method} value={method}>
              {taxMethodRef[method].title}
            </MenuItem>
          ))}
        </TextField>
        <Alert severity="info" className={classes.infoAlert}>
          <AlertTitle>{taxMethodRef[newMethod].subtitle}</AlertTitle>
          {taxMethodRef[newMethod].description}
        </Alert>
      </CardContent>
      <CardActions className={classes.actionRow}>
        <LedgibleButton
          variant="outlined"
          size="large"
          color="secondary"
          textTransformOff
          onClick={handlePrevious}
          startIcon={<ArrowLeftIcon />}
          id="tax-setup-flow-year-step-back"
        >
          Go Back
        </LedgibleButton>
        <LedgibleButton
          variant="contained"
          size="large"
          color="success"
          textTransformOff
          onClick={handleContinue}
          endIcon={<ArrowRightIcon />}
          id="tax-setup-flow-year-step-continue"
        >
          Continue
        </LedgibleButton>
      </CardActions>
    </>
  );
};

export default AlgorithmStep;

AlgorithmStep.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
};
