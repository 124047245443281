// absolute imports
import {
  createContext,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation, useReactiveVar } from '@apollo/client';

// relative imports
import {
  switchAppTypeToAccountType,
  getAccountDetailsForCreation,
} from '../../shared/utilities/appUtilities';
import {
  generateClientPermissions,
  disabledText,
} from '../../shared/permissions/PermissionRef';
import { useConfig } from './ConfigProvider';
import { useAuth0 } from './Auth0Provider';
import { useAppManagement } from './AppManagementProvider';
import {
  currentAccountIdVar,
  currentAccountTimezoneVar,
  currentAccountTaxYearVar,
  creatingAccountVar,
  persistedAccountIdsVar,
} from '../../../data/apollo/cache/reactiveVars';
import { guessTimezone } from '../../shared/utilities/dateUtilities';
import {
  ACCOUNT,
  ACCOUNTS,
  DEFAULT_ACCOUNT_ID,
  GET_LEDGIBLE_USER,
} from '../../../data/apollo/queries';
import { CREATE_TAX_PAYER_ACCOUNT } from '../../../data/apollo/mutations';
import useUpdatePolling from '../../shared/custom-hooks/useUpdatePolling';

export const AccountContext = createContext();
export const ClientAccountContext = createContext();
export const useAccount = () => useContext(AccountContext);
export const useClientAccounts = () => useContext(ClientAccountContext);

// MAIN COMPONENT
export const AccountProvider = ({ children }) => {
  const { appConfig, accountConfig, handleSetAccountConfigId } = useConfig();
  const { user, permittedApplications, allowedApplications } = useAuth0();
  const { appType, loadingApp } = useAppManagement() || {};
  const currentAccountTimezone = useReactiveVar(currentAccountTimezoneVar);
  const currentAccountId = useReactiveVar(currentAccountIdVar);
  const [hasNoAccount, setHasNoAccount] = useState(false);
  const addingAccount = useReactiveVar(creatingAccountVar);
  const [switchingAccounts, setSwitchingAccounts] = useState(false);

  /* FUNCTIONS & QUERIES & MUTATIONS */
  const {
    data: { getAccounts: accounts } = {},
    loading: loadingAccounts,
    refetch: refetchAccounts,
    networkStatus: accountsNetworkStatus,
  } = useQuery(ACCOUNTS, {
    variables: {
      type: switchAppTypeToAccountType(appType),
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    skip: !user || loadingApp || appType !== 'accounting',
  });

  // handle fetching default Account ID first if necessary
  const { loading: loadingDefaultAccountId } = useQuery(DEFAULT_ACCOUNT_ID, {
    variables: {
      type: switchAppTypeToAccountType(appType),
    },
    fetchPolicy: 'cache-and-network',
    onError(err) {
      if (err?.message === 'No account found') {
        setHasNoAccount(true);
      }
    },
    onCompleted({ getDefaultAccountId: defaultAccountId }) {
      if (defaultAccountId) {
        currentAccountIdVar(defaultAccountId);
      }
    },
    skip:
      !user ||
      loadingApp ||
      appType === 'none' ||
      loadingAccounts ||
      currentAccountId,
  });

  // populate account only after identifying proper account ID
  const {
    data: { getAccount: currentAccount = {} } = {},
    refetch: refetchCurrentAccount,
    networkStatus: currentAccountNetworkStatus,
  } = useQuery(ACCOUNT, {
    variables: {
      accountId: currentAccountId,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    onError(err) {
      if (err?.message === "User doesn't have access") {
        currentAccountIdVar(null);
      }
    },
    onCompleted({ getAccount: { subtype, taxPayerDetails } = {} }) {
      if (subtype?.id && subtype?.id !== accountConfig.id) {
        handleSetAccountConfigId(subtype.id);
      }
      if (!subtype?.id && accountConfig.id !== 'default') {
        handleSetAccountConfigId('default');
      }
      if (taxPayerDetails?.taxYear) {
        currentAccountTaxYearVar(taxPayerDetails.taxYear);
      }
      if (switchingAccounts) {
        setSwitchingAccounts(false);
      }
    },
    skip:
      !user ||
      loadingApp ||
      loadingAccounts ||
      loadingDefaultAccountId ||
      !currentAccountId,
  });

  // handle keeping peristed state up to date
  useEffect(() => {
    if (
      currentAccountId &&
      persistedAccountIdsVar()?.[appType] !== currentAccountId
    ) {
      persistedAccountIdsVar({
        ...persistedAccountIdsVar(),
        [appType]: currentAccountIdVar(),
      });
    }
  }, [appType, currentAccountId]);

  // Set list of available accounts ()
  const availableAccounts = useMemo(() => {
    if (accounts && accounts.length > 0 && permittedApplications.length > 0) {
      return accounts;
    }
    return [];
  }, [permittedApplications, accounts]);

  // Assign client permissions based for current account
  const clientPermissions = useMemo(() => {
    let permissions = { disabledText };
    if (user && currentAccount.id) {
      // check and designate if user has 'preparer' or 'tir-admin' type account, thereby having preparer permission
      // for all tax payer accounts
      const isPreparerViewingClient =
        currentAccount.type === 'TaxPayer' &&
        permittedApplications.includes('tax-preparer');
      const isTirAdminViewingClient =
        currentAccount.type === 'TaxPayer' &&
        permittedApplications.includes('tir-admin');
      // set permissions on provider
      const { role = 'read-only', userId } = currentAccount?.permission || {};
      const isOwner = currentAccount.owner === userId;

      permissions = generateClientPermissions(
        user.addAccount,
        isPreparerViewingClient,
        isTirAdminViewingClient,
        role,
        isOwner,
      );
    }
    return permissions;
  }, [currentAccount, user, permittedApplications]);

  useUpdatePolling({
    preset: 'account',
    updateCondition: currentAccount?.updating && currentAccountId,
    onUpdateComplete: refetchCurrentAccount,
  });

  // mutation for creating tax payer accounts
  const [
    createTaxPayerAccount,
    { loading: creatingTax, called: createTaxPayerCalled },
  ] = useMutation(CREATE_TAX_PAYER_ACCOUNT, {
    refetchQueries: [GET_LEDGIBLE_USER],
    awaitRefetchQueries: true,
    onCompleted(data) {
      const { createTaxPayerAccount: newTaxAccount = {} } = data;
      if (newTaxAccount.id) {
        setHasNoAccount(false);
        currentAccountIdVar(newTaxAccount.id);
      }
    },
  });

  // function to call tax payer account creation mutation
  const createAccountCallback = useCallback(
    (args) => createTaxPayerAccount(args),
    [createTaxPayerAccount],
  );

  // set app-wide ref for currentAccount from passed account ID
  const selectAccountCallback = useCallback(
    (selectedAccountId) => {
      let newAccountId = null;

      if (currentAccountId && currentAccountId !== selectedAccountId) {
        setSwitchingAccounts(true);
      }
      newAccountId = selectedAccountId;

      // set newAccountId if valid or set to null if not so useEffect can handle
      currentAccountIdVar(newAccountId);
    },
    [currentAccountId],
  );

  // creating account if flag(s) are triggered
  useEffect(() => {
    if (
      hasNoAccount &&
      !addingAccount &&
      user.profile.email_verified &&
      !createTaxPayerCalled
    ) {
      const subtypeRequired = appConfig.accountSubtypeRequired;
      const subtypeDefault = appConfig.accountSubtypeDefault;
      // handle (accounting only for now) account where subtype assignment is required and requires selection/input
      if (appType === 'accounting' && subtypeRequired && !subtypeDefault) {
        creatingAccountVar(true);
      }

      // else set account details and create automatically if tax account and no preparer account is associated
      if (
        appType === 'tax' &&
        !permittedApplications.includes('tax-preparer')
      ) {
        const accountDetails = getAccountDetailsForCreation({
          auth0Username: user.profile.username,
        });
        if (subtypeRequired && subtypeDefault) {
          accountDetails.subtype = subtypeDefault;
        }
        // create account without user action (current tax year or accounting)
        createAccountCallback({ variables: accountDetails });
      }
    }
  }, [
    addingAccount,
    createTaxPayerCalled,
    appType,
    selectAccountCallback,
    createAccountCallback,
    user,
    allowedApplications,
    appConfig.accountSubtypeRequired,
    appConfig.accountSubtypeDefault,
    permittedApplications,
    hasNoAccount,
  ]);

  // Keep tax year updated for tax accounts
  useEffect(() => {
    if (
      currentAccount.type === 'TaxPayer' &&
      currentAccount.taxPayerDetails &&
      currentAccount?.taxPayerDetails?.taxYear !== currentAccountTaxYearVar()
    ) {
      currentAccountTaxYearVar(currentAccount.taxPayerDetails.taxYear);
    }
  }, [currentAccount.taxPayerDetails, currentAccount?.type]);

  // Ensure timezone availabilty for account app-wide
  useEffect(() => {
    // timezone on account dictates timezone displayed in app
    if (
      currentAccount?.timezone &&
      currentAccount.timezone !== currentAccountTimezone
    ) {
      currentAccountTimezoneVar(currentAccount.timezone);
    }
    // default to timezone read from machine
    if (!currentAccount?.timezone && !currentAccountTimezone) {
      currentAccountTimezoneVar(guessTimezone());
    }
  }, [currentAccount?.timezone, currentAccountTimezone]);

  const loadingAccountInfo = useMemo(() => {
    if (switchingAccounts) {
      return true;
    }
    if (accountsNetworkStatus === 1 && accounts?.length === 0) {
      return true;
    }
    if (currentAccountNetworkStatus === 1 && !currentAccount?.id) {
      return true;
    }
    return false;
  }, [
    accounts?.length,
    accountsNetworkStatus,
    currentAccount?.id,
    currentAccountNetworkStatus,
    switchingAccounts,
  ]);

  const accountState = useMemo(
    () => ({
      currentAccount,
      accounts: availableAccounts,
      loadingAccountInfo,
      clientPermissions,
      selectAccount: selectAccountCallback,
      createAccount: createAccountCallback,
      refetchAccounts,
      creatingAccount: creatingTax,
      refetchCurrentAccount,
      setHasNoAccount,
    }),
    [
      currentAccount,
      availableAccounts,
      loadingAccountInfo,
      clientPermissions,
      selectAccountCallback,
      createAccountCallback,
      refetchAccounts,
      creatingTax,
      refetchCurrentAccount,
      setHasNoAccount,
    ],
  );

  return (
    <AccountContext.Provider value={accountState}>
      {children}
    </AccountContext.Provider>
  );
};

export const AccountConsumer = AccountContext.Consumer;

AccountProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
