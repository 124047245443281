// absolute imports
import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from 'tss-react/mui';
import { Typography } from '@mui/material';
// import Papa from 'papaparse';

// import icons
import {
  CloudUpload as UploadIcon,
  CheckCircleOutline as CheckCircleIcon,
  NotInterested as NotInterestedIcon,
} from '@mui/icons-material';

// relative imports
import { getExtensionFromFilename } from '../../../shared/utilities/stringUtilities';
import { getComponentTestKey } from '../../../shared/utilities/testUtilities';

const useStyles = makeStyles()((theme) => ({
  dropzoneInput: {
    background: 'rgba(95, 183, 209, .5)',
    border: '2px dashed #034c60',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'default',
    color: 'white',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  uploadIcon: {
    fontSize: theme.spacing(6),
  },
  dropzoneInputAccepted: {
    background: 'rgba(95, 183, 10, .5)',
  },
  dropzoneInputRejected: {
    background: theme.palette.error.light,
  },
  textMessage: {
    maxWidth: '325px',
  },
}));

const DropzoneInput = ({ acceptedExtArr, meta, onChange }) => {
  const { classes, cx } = useStyles();
  const [rejected, setRejected] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [fileName, setFileName] = useState('');

  const { error, pristine, dirty, submitFailed } = meta;
  const hasError =
    (!!pristine && !!dirty && !!error) || (error && submitFailed);

  const onDrop = useCallback(
    (droppedFiles) => {
      // function to get file(s) and do stuff with it
      const file = droppedFiles[0];
      const fileExt = getExtensionFromFilename(file.name);
      // let normHeaderRow = '';
      setFileName(file.name);
      if (acceptedExtArr.includes(fileExt)) {
        onChange([file]);
        setAccepted(true);
        setRejected(false);
        // Papa.parse(file, {
        //   header: true,
        //   encoding: 'ISO-8859-1',
        //   beforeFirstChunk: (chunk) => {
        //     const rows = chunk.split(/\r\n|\r|\n/);
        //     normHeaderRow = rows[0].toLowerCase().replace(/[^ -~]/g, '');
        //     if (normHeaderRow === matchHeader) {
        //       onChange([file]);
        //       setAccepted(true);
        //       setRejected(false);
        //     } else {
        //       onChange([]);
        //       setAccepted(false);
        //       setRejected(true);
        //     }
        //   },
        // });
      } else {
        onChange([]);
        setAccepted(false);
        setRejected(true);
      }
    },
    [acceptedExtArr, onChange],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: acceptedExtArr.map((item) => `.${item}`).join(', '),
  });

  return (
    <>
      <div
        {...getRootProps({
          className: cx(
            classes.dropzoneInput,
            (rejected || hasError) && classes.dropzoneInputRejected,
            accepted && !hasError && classes.dropzoneInputAccepted,
          ),
        })}
        data-cy={getComponentTestKey({
          componentName: 'DropzoneInput',
          elementType: 'dropzone',
          elementName: 'file',
        })}
      >
        <input {...getInputProps()} />
        {!accepted && !rejected && (
          <UploadIcon className={classes.uploadIcon} />
        )}
        {((!accepted && rejected) || (accepted && !rejected && hasError)) && (
          <NotInterestedIcon className={classes.uploadIcon} /> // rejected icon
        )}
        {accepted && !rejected && !hasError && (
          <CheckCircleIcon className={classes.uploadIcon} /> // accepted icon
        )}
        <span>Drag and drop or click to upload CSV file</span>
      </div>
      {rejected && !accepted && !isDragActive && (
        <Typography color="secondary" className={classes.textMessage}>
          <strong>{fileName}</strong>
          &nbsp;could not be processed! Please ensure it is correctly formatted.
        </Typography>
      )}
      {accepted && !rejected && !isDragActive && !hasError && (
        <Typography color="secondary" className={classes.textMessage}>
          <strong>{fileName}</strong>
          &nbsp;loaded successfully.
        </Typography>
      )}
      {accepted && !rejected && !isDragActive && hasError && (
        <Typography color="secondary" className={classes.textMessage}>
          {error}
          &nbsp;
          <strong>{fileName}</strong>
        </Typography>
      )}
    </>
  );
};

export default DropzoneInput;

DropzoneInput.propTypes = {
  input: PropTypes.shape().isRequired,
  acceptedExtArr: PropTypes.arrayOf(PropTypes.string).isRequired,
  // matchHeader: PropTypes.string.isRequired,
  meta: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};
