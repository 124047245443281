import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Paper, Typography } from '@mui/material';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';

// relative imports
import LedgibleButton from '../../../shared/custom-components/buttons/LedgibleButton';
import { creatingAccountVar } from '../../../../data/apollo/cache/reactiveVars';

const useStyles = makeStyles()((theme) => ({
  paperError: {
    width: 220,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  title: {
    fontSize: 14,
    marginTop: '.5rem',
    marginBottom: '.5rem',
  },
  errorIcon: {
    fontSize: '4rem',
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  applyFlex: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const NoAccountCard = ({ addAccount, toggleDrawer }) => {
  const { classes } = useStyles();

  return (
    <Paper className={classes.paperError}>
      <div className={classes.flexCenter}>
        <ErrorOutlineIcon
          titleAccess="Error Icon"
          className={classes.errorIcon}
          color="error"
        />
      </div>
      <Typography
        className={classes.title}
        color="textSecondary"
        align="center"
      >
        No accounts found!
      </Typography>
      {addAccount && (
        <>
          <Typography component="p">
            Don&apos;t worry, you just need to add one.
          </Typography>
          <div className={classes.applyFlex}>
            <LedgibleButton
              size="small"
              onClick={() => {
                creatingAccountVar(true);
                toggleDrawer(false);
              }}
              color="primary"
              id="create-account-button-no-accounts-found"
            >
              Create Account
            </LedgibleButton>
          </div>
        </>
      )}
      {!addAccount && (
        <Typography component="p">
          Please contact support if you are encountering issues.
        </Typography>
      )}
    </Paper>
  );
};

export default NoAccountCard;

NoAccountCard.propTypes = {
  addAccount: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
};

