// absolute imports
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles()((theme, { marginTop, paddingTop }) => ({
  pageBodyContainer: {
    width: '100%',
    minHeight: '100%',
    background: theme.whiteLabel.PageBody.color || theme.palette.grey[200],
  },
  pageBodyViewArea: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '5px 5px 0 0',
    paddingBottom: '160px',
    marginTop: theme.spacing(marginTop),
    paddingTop: theme.spacing(paddingTop),
    flexGrow: 1,
  },
}));

const PageBody = ({ children, marginTop, paddingTop, columnSizes }) => {
  const { classes } = useStyles({ marginTop, paddingTop });

  return (
    <Grid
      container
      direction="row"
      className={classes.pageBodyContainer}
      justifyContent="center"
    >
      <Grid
        item
        xs={columnSizes.xs || 12}
        sm={columnSizes.sm || 11}
        md={columnSizes.md || 11}
        lg={columnSizes.lg || 10}
        xl={columnSizes.xl || 9}
        className={classes.pageBodyViewArea}
        component={Paper}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default PageBody;

PageBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  marginTop: PropTypes.number,
  paddingTop: PropTypes.number,
  columnSizes: PropTypes.shape(),
};

PageBody.defaultProps = {
  marginTop: -29,
  paddingTop: 0,
  columnSizes: {},
};
