// absolute imports
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import {
  Alert,
  AlertTitle,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// icon imports
import {
  ArrowRightRounded as ArrowRightIcon,
  ArrowLeftRounded as ArrowLeftIcon,
} from '@mui/icons-material';

// relative imports
import LedgibleButton from '../../../../../shared/custom-components/buttons/LedgibleButton';
import { requiredField } from '../../../../../shared/utilities/formValidations';
import { FormTimezoneSelectField } from '../../../../../shared/final-form-field-wrappers';

const useStyles = makeStyles()((theme) => ({
  cardContent: {
    margin: theme.spacing(2, 1),
  },
  headerText: {
    marginBottom: theme.spacing(2),
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  selectField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

const TimezoneStep = ({ previousPage, createAccountError }) => {
  const { classes } = useStyles();

  return (
    <>
      <CardContent
        classes={{
          root: classes.cardContent,
        }}
      >
        <Typography
          className={classes.headerText}
          color="secondary"
          gutterBottom
          variant="h6"
          align="center"
        >
          Timezone Selection
        </Typography>
        {createAccountError && (
          <Grid item xs={11}>
            <Alert severity="error" style={{ marginBottom: 24 }}>
              <AlertTitle>Error</AlertTitle>
              {createAccountError.message}
            </Alert>
          </Grid>
        )}
        <Typography color="textSecondary" gutterBottom variant="subtitle1">
          Ledgible uses your computer&apos;s local time by default for
          displaying date/time.
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="subtitle1">
          If you want to see your data displayed with a different timezone, you
          can set it here.
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Field
              label="Account Timezone"
              name="timezone"
              data-cy="account-create-timezone"
              component={FormTimezoneSelectField}
              TextFieldProps={{
                helperText: 'Defaults to your current timezone.',
                margin: 'dense',
                variant: 'outlined',
              }}
              validate={requiredField.generic}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.actionRow}>
        <LedgibleButton
          variant="outlined"
          size="large"
          color="secondary"
          textTransformOff
          onClick={previousPage}
          startIcon={<ArrowLeftIcon />}
          id="create-account-timezone-step-previous"
        >
          Go Back
        </LedgibleButton>
        <LedgibleButton
          variant="contained"
          size="large"
          color="success"
          textTransformOff
          type="submit"
          endIcon={<ArrowRightIcon />}
          id="create-account-timezone-step-continue"
          data-cy="create-account-timezone-step-continue"
        >
          Continue
        </LedgibleButton>
      </CardActions>
    </>
  );
};

export default TimezoneStep;

TimezoneStep.propTypes = {
  previousPage: PropTypes.func,
  createAccountError: PropTypes.shape(),
};

TimezoneStep.defaultProps = {
  previousPage: () => null,
  createAccountError: null,
};