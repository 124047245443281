import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// icon imports
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles()((theme) => ({
  iconButton: {
    color: theme.palette.grey[200],
  },
}));

const CloseSnackbarButton = (id) => {
  const { classes } = useStyles();
  const { closeSnackbar } = useSnackbar();

  const handleCloseSnackbar = () => closeSnackbar(id);

  return (
    <IconButton onClick={handleCloseSnackbar}>
      <CloseIcon fontSize="small" className={classes.iconButton} />
    </IconButton>
  )
}

export default CloseSnackbarButton;