// high level app urls for defi protocols
const activeDefiProviders = {
  uniswap: {
    url: 'https://uniswap.org/',
    displayName: 'Uniswap',
  },
  sushi: {
    url: 'https://sushi.com/',
    displayName: 'SushiSwap',
  },
  vesper: {
    url: 'https://vesper.finance/',
    displayName: 'Vesper',
  },
  generic: {
    url: 'https://etherscan.io/directory/Smart_Contracts',
    displayName: 'Unknown DeFi Provider',
  },
  // CURRENTLY ONLY AVAILABLE FOR MANUAL DEFI
  pancakeswap: {
    url: 'https://pancakeswap.finance/',
    displayName: 'PancakeSwap',
  },
  aave: {
    url: 'https://aave.com/',
    displayName: 'AAVE',
  },
  '1inch': {
    url: 'https://app.1inch.io/',
    displayName: '1inch',
  },
  curve: {
    url: 'https://curve.fi/',
    displayName: 'Curve',
  },
  yearn: {
    url: 'https://yearn.finance/',
    displayName: 'yearn.finance',
  },
  maker: {
    url: 'https://makerdao.com/en/',
    displayName: 'Maker',
  },
  dydx: {
    url: 'https://dydx.exchange/',
    displayName: 'dYdX',
  },
};

export default activeDefiProviders;

export const getDefiProviderName = (provider) => {
  if (activeDefiProviders[provider]) return activeDefiProviders[provider].displayName;
  return activeDefiProviders.generic.displayName;
};
