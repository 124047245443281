/* Custom component to granularly control style and behavior of Zendesk support widget */

// absolute imports
import { useEffect, useState } from 'react';
import { Fab } from '@mui/material';
import { lighten } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { useReactiveVar } from '@apollo/client';

// icon imports
import { HelpOutline as HelpIcon } from '@mui/icons-material';

// relative imports
import {
  helpWidgetActiveVar,
  showHelpWidgetVar,
} from '../../../data/apollo/cache/reactiveVars';
import { useAuth0 } from '../../common/providers/Auth0Provider';
import { useAppManagement } from '../../common/providers/AppManagementProvider';
import { openZendeskWidget, closeZendeskWidget } from './zendesk-operations';
import useExternalScript from '../custom-hooks/useExternalScript';

const useStyles = makeStyles()((theme) => ({
  customLauncher: {
    position: 'fixed',
    bottom: 15,
    right: 22,
    backgroundColor: (theme.whiteLabel.Help.color || theme.palette.secondary.main),
    textTransform: 'none',
    textAlign: 'center',
    color: theme.whiteLabel.Help.textColor || theme.palette.common.white,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    '&:hover': {
      backgroundColor: lighten(theme.whiteLabel.Help.color || theme.palette.secondary.main, 0.1),
    },
    zIndex: 1000,
    height: 44,
  },
  label: {
    padding: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  helpText: {
    marginBottom: -1,
  },
  helpIcon: {
    marginRight: theme.spacing(0.8),
    fontSize: theme.typography.pxToRem(22),
  },
}));

const CustomZendeskLauncher = () => {
  const showHelpWidget = useReactiveVar(showHelpWidgetVar);
  const zdScriptStatus = useExternalScript(
    `https://static.zdassets.com/ekr/snippet.js?key=${
      import.meta.env.REACT_APP_ZENDESK_KEY
    }`,
    { id: 'ze-snippet' },
  );
  const { loadingAuth, isAuthenticated } = useAuth0();
  const { loadingApp } = useAppManagement();
  const [loaded, setLoaded] = useState(false);
  const { classes } = useStyles();
  const { zE } = window;

  useEffect(() => {
    if (zdScriptStatus === 'ready' && !loaded) {
      // ZENDESK -> register static widget settings
      window.zESettings = {
        webWidget: {
          helpCenter: {
            title: {
              '*': 'Knowledge Base',
            },
          },
          position: { horizontal: 'right', vertical: 'bottom' },
          zIndex: 2000, // will show above modal
        },
      };
      // hide default widget launcher initially to instead load custom Ledgible one
      zE('webWidget', 'hide');
      // set help center suggestions by default to rely on URL (only applies if activated in Zendesk)
      zE('webWidget', 'helpCenter:setSuggestions', { url: true });

      // set custom launcher active on open
      zE('webWidget:on', 'open', () => {
        helpWidgetActiveVar(true);
      });

      zE('webWidget:on', 'close', () => {
        closeZendeskWidget();
      });
      setLoaded(true);
    }
  }, [zE, zdScriptStatus, loaded]);

  if (showHelpWidget && !loadingAuth && !loadingApp && isAuthenticated) {
    return (
      <Fab
        id="ledgible-zendesk-launcher"
        onClick={() => openZendeskWidget()}
        className={classes.customLauncher}
        variant="extended"
        classes={{
          label: classes.label,
        }}
      >
        <HelpIcon size="small" className={classes.helpIcon} />
        <span className={classes.helpText}>Help</span>
      </Fab>
    );
  }
  return null;
};

export default CustomZendeskLauncher;
