// import external modules
import { makeStyles } from 'tss-react/mui';

import { ListItem, ListItemText, ListItemIcon } from '@mui/material';

// import svg icons
import { AccountBalance as AccountIcon } from '@mui/icons-material';

// relative imports
import { useAccount } from '../../common/providers/AccountProvider';
import { useConfig } from '../../common/providers/ConfigProvider';
import useAlternativeDisplay from '../../shared/custom-hooks/useAlternativeDisplay';

const useStyles = makeStyles()((theme, { isMobile }) => ({
  listItemRoot: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
    marginLeft: isMobile ? 0 : theme.spacing(3),
    paddingTop: 'inherit',
    paddingBottom: 'inherit',
    borderBottom: `${isMobile ? 1 : 0}px solid ${theme.palette.grey[200]}`,
  },
  accountButton: {
    maxWidth: 160,
  },
  leftBorder: {
    borderLeft: `${isMobile ? 0 : 1}px solid ${theme.palette.grey[200]}`,
  },
  greyText: {
    color: theme.palette.grey[isMobile ? 600 : 300],
    display: 'block',
  },
  textPadLeft: {
    color:
      theme.whiteLabel.HeaderText.color || isMobile
        ? undefined
        : theme.palette.common.white,
    fontSize: theme.typography.pxToRem(14),
    paddingLeft: '12px',
  },
  textPadLeftSecondary: {
    paddingLeft: '12px',
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[isMobile ? 700 : 400],
    marginBottom: theme.spacing(isMobile ? 0.5 : 0),
  },
  acctButtonIcon: {
    minWidth: 'auto',
    width: 'auto',
  },
  noPad: {
    padding: 0,
  },
}));

// FOR USE ON TAX-PREPARER APP ACCOUNTS ONLY

const PreparerAccountWindow = () => {
  const { isMobile } = useAlternativeDisplay();
  const { classes, cx } = useStyles({ isMobile });
  const { appConfig } = useConfig();
  const { currentAccount } = useAccount();

  return (
    <ListItem
      className={classes.leftBorder}
      classes={{
        root: classes.listItemRoot,
        gutters: classes.textPadLeft,
      }}
      alignItems="center"
    >
      <ListItemIcon
        classes={{
          root: classes.acctButtonIcon,
        }}
      >
        <AccountIcon fontSize="small" className={classes.greyText} />
      </ListItemIcon>
      <ListItemText
        primary={currentAccount.name}
        primaryTypographyProps={{
          classes: {
            root: classes.textPadLeft,
            colorPrimary: classes.greyText,
          },
          variant: 'body1',
          noWrap: true,
        }}
        secondary={appConfig?.taxProAppAccountWindowSecondaryText}
        secondaryTypographyProps={{
          classes: {
            root: classes.textPadLeftSecondary,
            colorPrimary: classes.greyText,
          },
          variant: 'body2',
          noWrap: true,
        }}
        className={cx(classes.noPad, classes.accountButton)}
      />
    </ListItem>
  );
};

export default PreparerAccountWindow;
