import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers';
import { Field } from 'react-final-form';

import { getDate } from '../utilities/dateUtilities';
import { currentAccountTimezoneVar } from '../../../data/apollo/cache/reactiveVars';

export const FormDatePickerFieldComponent = ({
  input,
  label,
  meta,
  DatePickerProps,
}) => {
  const { onChange, value, ...inputPropsRest } = input;
  const [error, setError] = useState(null);
  const { active, dirty, error: fieldError } = meta;

  const {
    format = 'MM/dd/yyyy',
    slotProps = {},
    inputProps,
    timezone,
    ...datePickerRest
  } = DatePickerProps;

  const { textField = {}, openPickerButton, ...slotPropsRest } = slotProps;
  const {
    FormHelperTextProps = {},
    InputProps = {},
    InputLabelProps = {},
    ...textFieldRest
  } = textField;

  useEffect(() => {
    if (fieldError) {
      setError(fieldError);
    }
  }, [fieldError]);

  const errorMessage = useMemo(() => {
    const errMsg = fieldError || error;

    switch (errMsg) {
      case 'maxDate':
        return 'Date is beyond maximum';
      case 'minDate': {
        return 'Date is before minimum';
      }
      case 'disableFuture':
        return 'Date cannot be in future';
      case 'disablePast':
        return 'Date cannot be in past';
      case 'invalidDate': {
        return 'Invalid date';
      }
      default: {
        return fieldError || null;
      }
    }
  }, [error, fieldError]);

  return (
    <DatePicker
      label={label}
      format={format}
      value={value || null}
      onChange={onChange}
      onError={(errMsg) => setError(errMsg)}
      disableFuture
      timezone={timezone || currentAccountTimezoneVar()}
      slotProps={{
        openPickerButton: {
          'aria-label': 'change date',
          ...slotProps?.openPickerButton,
        },
        textField: ({
          InputProps: InputPropsDefault,
          InputLabelProps: InputLabelPropsDefault,
        }) => ({
          variant: 'standard',
          fullWidth: true,
          inputProps: inputPropsRest,
          error: !active && dirty && !!error,
          helperText: !active && dirty && errorMessage,
          sx: {
            marginTop: '8px',
            marginBottom: '4px',
          },
          InputProps: {
            ...InputPropsDefault,
            ...InputProps,
          },
          InputLabelProps: {
            ...InputLabelPropsDefault,
            ...InputLabelProps,
          },
          FormHelperTextProps: {
            sx: {
              whiteSpace: 'pre-line',
            },
            ...FormHelperTextProps,
          },
          ...textFieldRest,
        }),
        ...slotPropsRest,
      }}
      {...datePickerRest}
    />
  );
};

FormDatePickerFieldComponent.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  label: PropTypes.string,
  DatePickerProps: PropTypes.shape(),
};

FormDatePickerFieldComponent.defaultProps = {
  label: null,
  DatePickerProps: {},
};

export const FormDatePickerField = (props) => {
  const { name, type, DatePickerProps, ...fieldProps } = props;
  const dates = {};
  const { timezone } = DatePickerProps;

  if (DatePickerProps.minDate) {
    dates.minDate = getDate(DatePickerProps.minDate);
  }

  if (DatePickerProps.maxDate) {
    dates.maxDate = getDate(DatePickerProps.maxDate);
  }

  const parseDateValueForFormData = (fieldValue) => {
    // fieldValue is DateTime object
    let value = fieldValue;

    if (value && !value.invalid) {
      if (Math.abs(value.offset) !== 0) {
        value = value.setZone(timezone);
      }
      value = value
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toUTC()
        .toString();
    }

    return value;
  };

  const formatFormDataForDisplay = (formValue) => {
    // form value is UTC string
    let value = formValue;

    if (getDate(value).isValid) {
      const options = {};
      const conversion = getDate(formValue, { zone: timezone });

      if (timezone && Math.abs(conversion.offset) === 0) {
        options.type = 'utc';
      } else {
        options.timezone = timezone;
      }
      value = getDate(value, options);
    }
    return value;
  };

  return (
    <Field
      name={name}
      type={type}
      component={FormDatePickerFieldComponent}
      parse={parseDateValueForFormData}
      format={formatFormDataForDisplay}
      DatePickerProps={{ ...DatePickerProps, ...dates }}
      {...fieldProps}
    />
  );
};

FormDatePickerField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  DatePickerProps: PropTypes.shape(),
};

FormDatePickerField.defaultProps = {
  type: null,
  DatePickerProps: {},
};

export default FormDatePickerField;
