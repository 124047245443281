import PropTypes from 'prop-types';

import TimezoneSelect from '../custom-components/TimezoneSelect';

const FormTimezoneSelectField = ({ input, meta, ...TimezoneSelectProps }) => {
  const { onChange, value, onFocus, onBlur, name, ...inputProps } = input;
  const { error, pristine, submitFailed, active, touched } = meta;
  const { TextFieldProps, ...TimezoneSelectPropsRest } = TimezoneSelectProps;
  const { helperText, InputLabelProps, ...textFieldPropsRest } = TextFieldProps;

  const isEmptyActiveField = (active && pristine) || !pristine || !!textFieldPropsRest?.placeholder

  return (
    <TimezoneSelect
      onChange={(tzObj) => onChange(tzObj?.name)}
      defaultTimezoneName={value}
      id={`${name}-timezone-select-field`}
      TextFieldProps={{
        error: (error && !pristine && touched) || (error && submitFailed),
        helperText: helperText || error,
        ...textFieldPropsRest,
        ...TimezoneSelectPropsRest,
        inputProps: {
          ...inputProps,
          ...textFieldPropsRest?.inputProps,
          onFocus,
          onBlur,
        },

        InputLabelProps: {
          focused: active,
          shrink: isEmptyActiveField,
          ...textFieldPropsRest?.InputLabelProps,
        },
      }}
    />
  );
};

export default FormTimezoneSelectField;

FormTimezoneSelectField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
};
