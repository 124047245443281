// absolute imports
import { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';

// relative imports
import SessionTimeoutModal from './session-timeout-modal/SessionTimeoutModal';
import { useAuth0 } from '../../common/providers/Auth0Provider';

const IdleTimer = () => {
  const { logout, isAuthenticated } = useAuth0();
  const [showPrompt, setShowPrompt] = useState(false);
  const [remaining, setRemaining] = useState(0);
  const [isExpired, setIsExpired] = useState(false);
  const envTimeout = import.meta.env.REACT_APP_REFRESH_TOKEN_LIFETIME; // in sec
  const timeout = (envTimeout || 1800) * 1000; // in ms -> 30 min default timeout
  const promptTimeout = 30000; // in ms -> 30 sec pre-timeout countdown

  // called after the timeout value is reached (30 min)
  const onPrompt = () => {
    if (isAuthenticated) {
      setRemaining(promptTimeout);
      setShowPrompt(true);
    }
  };

  // called after the promptTimeout (30 sec) is reached.
  const onIdle = () => {
    if (isAuthenticated) {
      setRemaining(0);
      setIsExpired(true);
      setTimeout(() => {
        logout();
      }, 3000);
    }
  };

  // only be called if `reset()` is called while `isPrompted()` is true
  const onActive = () => {
    if (showPrompt) {
      setShowPrompt(false);
    }
    setRemaining(0);
  };

  const { getRemainingTime, isPrompted, reset } = useIdleTimer({
    timeout,
    promptBeforeIdle: promptTimeout,
    onIdle,
    onPrompt,
    onActive,
    stopOnIdle: true,
    crossTab: true,
    eventsThrottle: 500,
  });

  const handleStillHere = () => {
    setShowPrompt(false);
    reset();
  };

  // prompt countdown
  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted && isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  if (showPrompt) {
    return (
      <SessionTimeoutModal
        initialValue={promptTimeout / 1000}
        timeRemaining={remaining}
        handleStillHere={handleStillHere}
        isExpired={isExpired}
      />
    );
  }
  return null;
};

export default IdleTimer;
