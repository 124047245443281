// absolute imports
import { createContext, useContext, useState, useMemo, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

// relative imports
import { GET_KYT_CHAINALYSIS_ALERTS } from '../../../data/apollo/queries';

import { useAccount } from './AccountProvider';
import { useWallet } from './WalletProvider';
import useUpdatePolling from '../../shared/custom-hooks/useUpdatePolling';

export const MonitoringAlertsContext = createContext();
export const MonitoringAlertsActionContext = createContext();
// Hooks
export const useMonitoringAlerts = () => useContext(MonitoringAlertsContext);
export const useMonitoringAlertsAction = () =>
  useContext(MonitoringAlertsActionContext);

export const MonitoringAlertsProvider = ({ children }) => {
  const [excludedAlerts, setExcludedAlerts] = useState(0);

  const { wallets } = useWallet();

  const countExcludedAlerts = useMemo(
    () => () => {
      let totalChainalysisAlertsCount = 0;

      wallets.forEach((wallet) => {
        if (
          !wallet.chainalysisWalletIntegrationActive &&
          wallet?.chainalysisAlertsCount
        ) {
          totalChainalysisAlertsCount += wallet.chainalysisAlertsCount;
        }
      });

      setExcludedAlerts(totalChainalysisAlertsCount);
    },
    [wallets],
  );

  useEffect(() => {
    if (wallets?.length) countExcludedAlerts();
  }, [countExcludedAlerts, wallets]);

  const { currentAccount } = useAccount();

  const {
    data: { getKytAlerts: alerts = [] } = {},
    loading: loadingAlerts,
    refetch: refetchAlerts,
  } = useQuery(GET_KYT_CHAINALYSIS_ALERTS, {
    variables: {
      accountId: currentAccount.id,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !currentAccount?.chainalysisIntegrationActive,
  });

  const totalUnreviewed = useMemo(() => {
    if (!alerts?.length) {
      return 0;
    }
    const unreviewed = alerts.filter((alert) => {
      const { alertStatus } = alert;
      return alertStatus === 'unreviewed';
    });
    return unreviewed.length;
  }, [alerts]);

  const kytWalletsUpdating = useMemo(() => {
    if (!currentAccount?.chainalysisIntegrationActive) {
      return [];
    }
    return wallets?.filter(
      (wallet) => wallet.chainalysisIntegrationActive && wallet.updating,
    );
  }, [currentAccount?.chainalysisIntegrationActive, wallets]);

  useUpdatePolling({
    preset: 'wallets',
    updateCondition: kytWalletsUpdating?.length > 0,
    onUpdateComplete: () => {
      refetchAlerts();
    },
  });

  const alertsValues = useMemo(
    () => ({
      alerts,
      totalNumberOfAlerts: alerts?.length,
      loadingAlerts,
      totalUnreviewed: totalUnreviewed - excludedAlerts,
    }),
    [alerts, loadingAlerts, excludedAlerts, totalUnreviewed],
  );

  const alertsActionsValues = useMemo(
    () => ({
      refetchAlerts,
    }),
    [refetchAlerts],
  );

  return (
    <MonitoringAlertsContext.Provider value={alertsValues}>
      <MonitoringAlertsActionContext.Provider value={alertsActionsValues}>
        {children}
      </MonitoringAlertsActionContext.Provider>
    </MonitoringAlertsContext.Provider>
  );
};

MonitoringAlertsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
