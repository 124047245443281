// import external modules
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Grid, Typography } from '@mui/material';

// relative imports
import useAlternativeDisplay from '../custom-hooks/useAlternativeDisplay';

const useStyles = makeStyles()(
  (theme, { headerContainerHeight, isMobile, headerContentHeight }) => ({
    headerRow: {
      backgroundColor:
        theme.whiteLabel.PageHeader.color || theme.palette.primary.main,
      minHeight: headerContainerHeight || 344,
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    headerRowContent: {
      height: headerContentHeight || 104,
    },
    headerText: {
      color: theme.whiteLabel.HeaderText.color || theme.palette.common.white,
      marginRight: isMobile ? '0rem' : '1rem',
      fontSize: theme.typography.pxToRem(28),
      cursor: 'default',
    },
    headerSubText: {
      color: theme.palette.grey[300],
      paddingTop: theme.spacing(1),
    },
    pageHeader: {
      marginTop: isMobile ? theme.spacing(3) : theme.spacing(0),
    },
    childrenGrid: {
      marginTop: isMobile ? theme.spacing(2) : theme.spacing(0),
    },
  }),
);

const PageHeader = ({
  children,
  pageTitle,
  flipTitles,
  customContent,
  buttonComponent,
  pageSubtitle,
  headerRowJustify,
  headerRowAlign,
  rightCol,
  leftCol,
  loading,
  loadingHeader,
  columnSizes,
  headerContainerHeight,
  headerContentHeight,
}) => {
  const { isMobile } = useAlternativeDisplay();
  const { classes } = useStyles({
    isMobile,
    headerContainerHeight,
    headerContentHeight,
  });

  return (
    <div className={classes.headerRow}>
      <Grid
        item
        xs={columnSizes.xs || 12}
        sm={columnSizes.sm || 11}
        md={columnSizes.md || 11}
        lg={columnSizes.lg || 10}
        xl={columnSizes.xl || 9}
        container
        direction="row"
        alignItems={headerRowAlign || 'center'}
        justifyContent={headerRowJustify || 'space-between'}
        className={classes.headerRowContent}
      >
        {customContent ? (
          children
        ) : (
          <Grid
            item
            xs={12}
            container
            direction="row"
            alignItems="center"
            spacing={1}
            className={classes.pageHeader}
          >
            {!loading && pageSubtitle && flipTitles && (
              <Grid
                item
                xs={12}
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
              >
                {pageSubtitle}
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={leftCol}
              container
              direction="column"
              alignItems={!isMobile ? 'flex-start' : 'center'}
              justifyContent={!isMobile ? 'flex-start' : 'center'}
            >
              <Grid
                item
                xs={10}
                sm={12}
                container
                direction="row"
                alignItems="center"
                justifyContent={isMobile ? 'center' : undefined}
                wrap="nowrap"
              >
                <Typography
                  variant="h4"
                  className={classes.headerText}
                  align={isMobile ? 'center' : 'left'}
                  noWrap
                  title={pageTitle}
                  data-cy="page-header-text"
                >
                  {loading && (loadingHeader || null)}
                  {!loading && pageTitle}
                </Typography>
                {!loading && buttonComponent && buttonComponent}
              </Grid>
              {!loading && pageSubtitle && !flipTitles && (
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  alignSelf="flex-end"
                >
                  {pageSubtitle}
                </Grid>
              )}
            </Grid>
            <Grid
              item
              xs={isMobile ? 12 : 10}
              sm={rightCol}
              container
              direction="row"
              alignItems="center"
              justifyContent={isMobile ? 'center' : 'flex-end'}
              className={classes.childrenGrid}
            >
              {!loading && children}
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default PageHeader;

PageHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  pageTitle: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  customContent: PropTypes.bool,
  buttonComponent: PropTypes.shape({}),
  pageSubtitle: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  headerRowJustify: PropTypes.string,
  headerRowAlign: PropTypes.string,
  flipTitles: PropTypes.bool,
  leftCol: PropTypes.number,
  rightCol: PropTypes.number,
  loading: PropTypes.bool,
  loadingHeader: PropTypes.string,
  columnSizes: PropTypes.shape(),
  headerContainerHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  headerContentHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

PageHeader.defaultProps = {
  children: null,
  pageTitle: null,
  pageSubtitle: null,
  customContent: false,
  buttonComponent: null,
  headerRowJustify: 'space-between',
  headerRowAlign: 'center',
  flipTitles: false,
  rightCol: 6,
  leftCol: 6,
  loading: false,
  loadingHeader: null,
  columnSizes: {},
  headerContainerHeight: null,
  headerContentHeight: null,
};
