import { goSystemLocator } from '../../components/shared/utilities/formValidations';

/* ----------GENERAL----------- */

/**
 * Master app list for general reference
 */
export const fullAppList = ['accounting', 'tax', 'tax-preparer', 'tir-admin'];

export const defaultCursorReturnSizes = {
  addresses: 60,
  nfts: 48,
};

/* ---------- ACCOUNTING ----------- */

export const appTitleMap = {
  xero: 'Xero',
  qbo: 'QuickBooks Online',
};

/* ---------- TAX ----------- */

/** TAX
 * Available tax years
 */
export const taxYears = [
  '2015',
  '2016',
  '2017',
  '2018',
  '2019',
  '2020',
  '2021',
  '2022',
  '2023',
  '2024',
];
export const currentTaxYear = '2023';
export const pendingTaxYear = '2024';

/** TAX
 * Supported tax methodologies and corresponding descriptions
 */
export const taxMethodRef = {
  fifo: {
    title: 'FIFO',
    subtitle: 'First-in First-out',
    description:
      'The first coin that was purchased (chronologically) is the first coin that is applied for a disposal.',
  },
  lifo: {
    title: 'LIFO',
    subtitle: 'Last-in First-out',
    description:
      'The last coin that was purchased (chronologically) is the first coin that is applied for a disposal.',
  },
  hifo: {
    title: 'HIFO',
    subtitle: 'Highest-in First-out',
    description: ` The coin with the highest cost-basis is
    the first coin that is applied for a disposal.`,
  },
};

/** TAX
 * Supported cost basis modes
 */
export const costBasisModeRef = {
  aggregate: {
    title: 'Aggregate',
    subtitle: 'Calculate by total available amounts',
    description: `The cost basis for an asset will be based on the entire available amount of an
    asset across all of your data sources.`,
  },
  bySource: {
    title: 'By Source',
    subtitle: 'Calculate by individual source',
    description: `The cost basis for an asset will be based only on the source in which the transaction originates and transfers
    between owned sources.`,
  },
};

/** TAX
 * Available tax prep details for specific prep types (tax preparer)
 */
export const taxPrepDetails = {
  goSystem: {
    displayName: 'GoSystem',
    reports: ['trtax'],
    fields: [
      {
        fieldId: 'goSystemLocator',
        fieldLabel: 'GoSystem Locator',
        fieldValidators: goSystemLocator,
        fieldNormalizer: (val) => val && val.toUpperCase(),
      },
    ],
  },
  ultraTax: {
    displayName: 'Ultra Tax',
    reports: [],
    fields: [],
  },
  proConnect: {
    displayName: 'Pro Connect',
    reports: [],
    fields: [],
  },
  lacerte: {
    displayName: 'Lacerte',
    reports: [],
    fields: [],
  },
  drakeTax: {
    displayName: 'Drake Tax',
    reports: [],
    fields: [],
  },
};

/** TAX
 * Reverse lookup to map tax system ID to field IDs
 */
export const prepTypeFromFieldId = {
  goSystemLocator: 'goSystem',
};

/** TAX
 * Reverse lookup to map tax system ID to report types
 */
export const prepTypeFromReportType = {
  trtax: 'goSystem',
};

/** TAX
 * Types of ownership for data entities in tax app
 */
export const taxOwnershipTypes = [
  { title: 'Individual', value: 'T' },
  { title: 'Joint', value: 'J' },
  { title: 'Spouse', value: 'S' },
];

/** TAX
 * ownership
 */
export const taxOwnershipRef = {
  T: 'Individual',
  J: 'Joint',
  S: 'Spouse',
};

/** TAX
 * tx transaction high-level sides of transaction (for given source)
 */
export const txSides = ['incoming', 'outgoing', 'fee'];

/** WALLET
 * for hardware wallets
 */
export const hardwareWalletConnectionMethods = ['default', 'custom', 'select'];

export const defaultDerivationPaths = {
  BTC: "m/44'/0'/0'/0/0",
  LTC: "m/44'/2'/0'/0/0",
  DOGE: "m/44'/3'/0'/0/0",
  DASH: "m/44'/5'/0'/0/0",
  ETH: "m/44'/60'/0'/0/0",
  BCH: "m/44'/145'/0'/0/0",
  BTG: "m/44'/156'/0'/0/0",
  ZEC: "m/44'/133'/0'/0/0",
};

/** WALLET
 * warnings for smart chains with limited support
 */
export const smartChainSupportMessage = {
  ETC: 'Certain tokens and DeFi functionality may not be supported on Ethereum Classic at this time.',
  ALGO: 'Certain tokens and DeFi functionality may not be supported on Algorand at this time.',
  CELO: 'Certain tokens and DeFi functionality may not be supported on Celo at this time.',
};

export const nftChains = ['AVAX', 'BNB', 'BSC', 'BTC', 'ETH', 'SOL'];

/*
 * LIMITS
 */
export const accountLimits = {
  reports: {
    genericTaxAccounting: 1000000,
  },
};

/* CURRENCIES */
export const g20CurrencyNames = {
  AED: 'UAE Dirham',
  ARS: 'Argentine Peso',
  AUD: 'Austrailian Dollar',
  BRL: 'Brazilian Real',
  CAD: 'Canadian Dollar',
  CNY: 'Chinese Yuan',
  EUR: 'Euro',
  INR: 'Indonesian Rupiah',
  IDR: 'Indian Rupee',
  JPY: 'Japanese Yen',
  MXN: 'Mexican Peso',
  RUB: 'Russian Ruble',
  SAR: 'Saudi Riyal',
  ZAR: 'South African Rand',
  KRW: 'Korean Won',
  TRY: 'Turkish Lira',
  GBP: 'British Pound Sterling',
  USD: 'US Dollar',
  SGD: 'Signapore Dollar',
  CHF: 'Swiss Franc',
  HKD: 'Hong Kong Dollar',
  NZD: 'New Zealand Dollar',
};

export const g20Currencies = Object.keys(g20CurrencyNames);

export const maximumAssetNumber = 99999999999;
export const minimumAssetNumber = 0.00000001;
export const maximumAssetDisplayNumber = '99,999,999,999';
export const minimumAssetDisplayNumber = '0.00000001';
/**
 * zero threshold corresponds with the defaultBTCPrice
 * in backend repo
 *
 * lib/commons/constants.js line 39
 */
export const zeroThresholdNumber = 0.00000000000000000000000000001134;
export const zeroThresholdDisplayNumber = '0.00';

/**
 * in the case where queries can potentially return null when a value should be displayed
 * somewhere in app, display will fallback to this value.
 *
 * Ex: should return count but null (0 doesn't make sense since that also is a valid count number)
 */
export const fallbackDisplayValue = '--'; // can possibly be changed to loading...
