// absolute imports
import { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

// relative imports
import LoadingPage from '../../../shared/page-components/LoadingPage';

// Wrap public route in layout provided from route config and apply any conditions required

const PublicRoute = ({ route }) => {
  const { main: Component, layout: Layout } = route;

  const render = (routeProps) => {
    const { LayoutProps, ...rest } = routeProps;

    return (
      <Suspense fallback={<LoadingPage loadingApplication />}>
        <Layout {...LayoutProps}>
          <Component {...rest} />
        </Layout>
      </Suspense>
    );
  };

  return <Route render={render} {...route} />;
};

export default PublicRoute;

PublicRoute.propTypes = {
  route: PropTypes.shape().isRequired,
};
