// import external modules
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

// relative imports
import ErrorBoundary from '../../error-handling/ErrorBoundary';

const useStyles = makeStyles()(() => ({
  mainContent: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    display: 'flex',
  },
}));

const FullScreenLayout = ({ children }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.mainContent}>
      <ErrorBoundary
        fallbackType="modal"
        errorMsg="Uncaught error in layout"
        context={{
          component: FullScreenLayout.displayName,
        }}
      >
        {children}
      </ErrorBoundary>
    </div>
  );
};

FullScreenLayout.displayName = 'FullScreenLayout';
export default FullScreenLayout;

FullScreenLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
