/* Adaptive page that displays loading based on state,
used in top-level providers during data hydration (before router renders) */

// absolute imports
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';
import { keyframes } from 'tss-react';

// relative imports
import { useConfig } from '../../common/providers/ConfigProvider';
import PrimaryLayout from '../layouts/PrimaryLayout';
import PageHeader from './PageHeader';
import PageBody from './PageBody';
import Loader from '../custom-components/Loader';
import LedgibleIconSVG from '../img-assets/LedgibleIcon.svg';
import { getAppTitle } from '../utilities/appUtilities';

const useStyles = makeStyles()((theme) => ({
  splashScreen: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageLoadContainer: {
    minheight: '60vh',
  },
  loadingDot: {
    marginTop: '40px',
    position: 'relative',
    height: '15px',
    width: '15px',
    borderRadius: '50%',
    display: 'inline-block',
    animation: `${keyframes`
      0% {
        left: -80px;
        background-color: #eee;
      }
      50% {
        background-color: ${theme.palette.primary.main};
      }
      100% {
        left: 80px;
        background-color: #eee;
      },
    `} 1.2s ${theme.transitions.easing.easeInOut} infinite alternate`,
  },
}));

const AnimatedLedgibleLoadingImage = () => {
  const { appConfig } = useConfig();
  const { classes: loadingClasses } = useStyles();

  return (
    <div
      className={loadingClasses.splashScreen}
      data-cy="ledgible-cylon-animation"
    >
      <img
        src={appConfig?.appLoaderIconImgSrc || LedgibleIconSVG}
        alt={`${getAppTitle()} Icon`}
        height="100px"
        data-cy="cylon-logo"
      />
      <div className={loadingClasses.loadingDot} />
    </div>
  );
};

const LoadingPage = ({
  loadingApplication,
  loadingLayout,
  loadingPage,
  hidePageHeader,
  hideSecondaryMenu,
}) => {
  const { classes } = useStyles();
  // render loading page based on passed booleans
  if (loadingApplication) {
    return <AnimatedLedgibleLoadingImage />;
  }

  if (loadingLayout) {
    return (
      <PrimaryLayout hideHeader>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Loader fillSpace />
        </Grid>
      </PrimaryLayout>
    );
  }
  if (loadingPage) {
    return (
      <PrimaryLayout
        hideSecondaryMenu={hideSecondaryMenu}
        hideHeader={hidePageHeader}
      >
        <PageHeader pageTitle="" />
        <PageBody>
          <Grid item className={classes.pageLoadContainer}>
            <Loader marginTop={10} />
          </Grid>
        </PageBody>
      </PrimaryLayout>
    );
  }
  return null;
};

export default LoadingPage;

LoadingPage.propTypes = {
  loadingApplication: PropTypes.bool,
  loadingLayout: PropTypes.bool,
  loadingPage: PropTypes.bool,
  hidePageHeader: PropTypes.bool,
  hideSecondaryMenu: PropTypes.bool,
};

LoadingPage.defaultProps = {
  loadingApplication: false,
  loadingLayout: false,
  loadingPage: false,
  hidePageHeader: false,
  hideSecondaryMenu: false,
};
