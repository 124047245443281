// absolute imports
import queryString from 'query-string';

// relative imports
import {
  currentTaxYear,
  fullAppList,
  fallbackDisplayValue,
} from '../../../data/app-config/appDetails';
import { guessTimezone } from './dateUtilities';
import { customLogoUrlVar } from '../../../data/apollo/cache/reactiveVars';
import { getAppConfig } from '../../../data/app-config/configs/configUtilities';

export const getAuth0DefaultClientOptions = () => {
  const { search } = window ? window.location : {};
  // eslint-disable-next-line camelcase
  const { org_id, organization } = queryString.parse(search);

  return {
    domain: import.meta.env.REACT_APP_AUTH0_DOMAIN,
    clientId: import.meta.env.REACT_APP_AUTH0_DEFAULT_CLIENT_ID,
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
    cacheLocation: 'localstorage',
    leeway: 60,
    authorizationParams: {
      audience: import.meta.env.REACT_APP_AUTH0_AUDIENCE,
      redirect_uri: import.meta.env.REACT_APP_AUTH0_CALLBACK,
      scope:
        'openid profile email read:current_user update:current_user_metadata create:current_user_metadata offline_access',
      organization:
        import.meta.env.REACT_APP_AUTH0_ORGANIZATION_ID ||
        // eslint-disable-next-line camelcase
        org_id ||
        organization,
    },
  };
};

/** Returns custom logo if set for the application in Auth0, else returns custom logo by subtype config or specified app logo
 * @param {String} appType - appId of the app logo requested
 * @param {String} subtype - options object for date formatting and output
 * @param {String} bgType - optional - the bg type of the logo, defaults to light bg, but can pass 'dark' here
 */
export const getAppLogoSrc = (appType, bgType) => {
  // pull custom logo if set on auth0 application
  const customLogo = customLogoUrlVar();

  if (customLogo) {
    return customLogo;
  }

  const appConfig = getAppConfig();

  // else determine logo from subType customization or default(s)
  const {
    taxLogoUrl,
    taxDarkLogoUrl,
    accountingLogoUrl,
    accountingDarkLogoUrl,
    taxProLogoUrl,
    taxProDarkLogoUrl,
    tirAdminLogoUrl,
    tirAdminDarkLogoUrl,
    defaultLogoUrl,
    defaultDarkLogoUrl,
  } = appConfig;

  let appLogo = defaultLogoUrl;

  switch (appType) {
    case 'accounting':
      if (bgType === 'dark') appLogo = accountingDarkLogoUrl;
      else appLogo = accountingLogoUrl;
      break;
    case 'tax':
      if (bgType === 'dark') appLogo = taxDarkLogoUrl;
      else appLogo = taxLogoUrl;
      break;
    case 'tax-preparer':
      if (bgType === 'dark') appLogo = taxProDarkLogoUrl;
      else appLogo = taxProLogoUrl;
      break;
    case 'tir-admin':
      if (bgType === 'dark') appLogo = tirAdminDarkLogoUrl;
      else appLogo = tirAdminLogoUrl;
      break;
    default:
      if (bgType === 'dark') appLogo = defaultDarkLogoUrl;
      break;
  }
  return appLogo;
};

export const getAppTitle = () => {
  const appConfig = getAppConfig();

  if (appConfig.appTitle) {
    return appConfig.appTitle;
  }

  return 'Ledgible Crypto';
};

export const getAuth0ClientIdFromAppId = (appId) =>
  ({
    tax: import.meta.env.REACT_APP_AUTH0_TAX_CLIENT_ID,
    'tax-preparer': import.meta.env.REACT_APP_AUTH0_TAX_PRO_CLIENT_ID,
    accounting: import.meta.env.REACT_APP_AUTH0_ACCOUNTING_CLIENT_ID,
    'tir-admin': import.meta.env.REACT_APP_AUTH0_TIR_ADMIN_CLIENT_ID,
    none: import.meta.env.REACT_APP_AUTH0_DEFAULT_CLIENT_ID,
  }[appId]);

export const getLedgibleAppIdFromClientId = (appId) =>
  ({
    [import.meta.env.REACT_APP_AUTH0_TAX_CLIENT_ID]: 'tax',
    [import.meta.env.REACT_APP_AUTH0_TAX_PRO_CLIENT_ID]: 'tax-preparer',
    [import.meta.env.REACT_APP_AUTH0_ACCOUNTING_CLIENT_ID]: 'accounting',
    [import.meta.env.REACT_APP_AUTH0_TIR_ADMIN_CLIENT_ID]: 'tir-admin',
    [import.meta.env.REACT_APP_AUTH0_DEFAULT_CLIENT_ID]: 'none',
  }[appId]);

/**
 * Get url pathname alias based on app id
 */
export const getAppUrlPathFromId = (appId) => {
  const appConfig = getAppConfig();

  return {
    accounting: appConfig.accountingAppUrlAlias,
    tax: appConfig.taxAppUrlAlias,
    'tax-preparer': appConfig.taxProAppUrlAlias,
    'tir-admin': appConfig.tirAdminAppUrlAlias,
  }[appId];
};

/**
 * Get app display name based on appId
 */
export const getAppDisplayName = (appId) => {
  const {
    taxAppDisplayName,
    taxProAppDisplayName,
    accountingAppDisplayName,
    tirAdminAppDisplayName,
  } = getAppConfig();

  return {
    tax: taxAppDisplayName,
    'tax-preparer': taxProAppDisplayName,
    accounting: accountingAppDisplayName,
    'tir-admin': tirAdminAppDisplayName,
  }[appId];
};

/**
 * Get base app name (transform from alias if passed)
 */
export const getAppIdFromUrlPath = (appUrlPath) => {
  const {
    accountingAppUrlAlias,
    taxAppUrlAlias,
    taxProAppUrlAlias,
    tirAdminAppUrlAlias,
  } = getAppConfig();

  return {
    [accountingAppUrlAlias]: 'accounting',
    [taxAppUrlAlias]: 'tax',
    [taxProAppUrlAlias]: 'tax-preparer',
    [tirAdminAppUrlAlias]: 'tir-admin',
  }[appUrlPath];
};

export const getClientIdFromUrl = (appUrlKey) => {
  const {
    tirAdminAppUrlAlias,
    accountingAppUrlAlias,
    taxAppUrlAlias,
    taxProAppUrlAlias,
  } = getAppConfig();
  // env-defined static hostname for instance of application
  const { host } = new URL(import.meta.env.REACT_APP_AUTH0_CALLBACK);

  return {
    [host]: import.meta.env.REACT_APP_AUTH0_DEFAULT_CLIENT_ID,
    [`${host}/${tirAdminAppUrlAlias}`]: import.meta.env
      .REACT_APP_AUTH0_TIR_ADMIN_CLIENT_ID,
    [`${host}/${accountingAppUrlAlias}`]: import.meta.env
      .REACT_APP_AUTH0_ACCOUNTING_CLIENT_ID,
    [`${host}/${taxAppUrlAlias}`]: import.meta.env
      .REACT_APP_AUTH0_TAX_CLIENT_ID,
    [`${host}/${taxProAppUrlAlias}`]: import.meta.env
      .REACT_APP_AUTH0_TAX_PRO_CLIENT_ID,
  }[appUrlKey];
};

/**
 * Get appropriate Auth0 Client ID value based on various conditions
 */
export const getAuth0ClientId = (appId) => {
  const appConfig = getAppConfig();
  // parse location info for potential reference
  const { host, pathname, search } = window.location;
  const parsedSearch = search ? queryString.parse(search) : {};

  // if env has static app set, return respective client id
  const { staticAppType } = appConfig;
  if (staticAppType) {
    return getAuth0ClientIdFromAppId(staticAppType);
  }
  // if client ID is available from query params, return that regardless of path or arg (eg from logout redirect)
  if (parsedSearch.authClientId) {
    return parsedSearch.authClientId;
  }
  // at this point, appId arg can be utilized if above conditions aren't met
  if (appId && appId !== 'none') {
    return getAuth0ClientIdFromAppId(appId);
  }
  // else just determine from location
  const firstPath = pathname.split('/')[1];
  let appUrlKey = host;

  // append pathname if valid internal app
  if (fullAppList.includes(getAppIdFromUrlPath(firstPath))) {
    appUrlKey += `/${firstPath}`;
  }

  // else return default from dictionary
  return getClientIdFromUrl(appUrlKey);
};

export const getAppAliasFromClientId = (clientId) => {
  const {
    tirAdminAppUrlAlias,
    accountingAppUrlAlias,
    taxAppUrlAlias,
    taxProAppUrlAlias,
  } = getAppConfig();

  return {
    [import.meta.env.REACT_APP_AUTH0_DEFAULT_CLIENT_ID]: '',
    [import.meta.env.REACT_APP_AUTH0_TIR_ADMIN_CLIENT_ID]: tirAdminAppUrlAlias,
    [import.meta.env.REACT_APP_AUTH0_ACCOUNTING_CLIENT_ID]:
      accountingAppUrlAlias,
    [import.meta.env.REACT_APP_AUTH0_TAX_CLIENT_ID]: taxAppUrlAlias,
    [import.meta.env.REACT_APP_AUTH0_TAX_PRO_CLIENT_ID]: taxProAppUrlAlias,
  }[clientId];
};

/**
 * Get appropriate Auth0 Redirect URI value based on various conditions
 */
export const getAuth0RedirectUri = (appId) => {
  const appConfig = getAppConfig();
  const { staticAppType } = appConfig;
  const { protocol, host, pathname, search } = window.location;
  const parsedSearch = search ? queryString.parse(search) : {};
  const firstPath = pathname.split('/')[1];
  let redirectUri = `${protocol}//${host}/`;

  if (staticAppType) {
    // if env has static app, return that
    const staticAppPath = getAppUrlPathFromId(staticAppType);
    redirectUri += staticAppPath;
  } else if (parsedSearch.authClientId) {
    // if client ID was sent in query params return that
    const searchAppPath = getAppAliasFromClientId(parsedSearch.authClientId);
    redirectUri += searchAppPath;
  } else if (appId && appId !== 'none') {
    // if appId is available as an arg, return that
    const passedAppPath = getAppUrlPathFromId(appId);
    redirectUri += passedAppPath;
  } else if (fullAppList.includes(getAppIdFromUrlPath(firstPath))) {
    // else append current pathname if valid internal app
    redirectUri += firstPath;
  }

  // return updated redirect or default to domain URI
  return redirectUri;
};

/** Returns an updated string after replacing instances of aliased appIDs with their respective alias
 * @param {String} path - string containing the react-router path portion of the url (may include tax, accounting, tax-preparer strings)
 * @param {Object} aliases - object containing aliases to map, defaults to tax|tax-preparer|accounting
 * @returns {String} - string with app IDs updated with their corresponding alias if applicable
 */
export const mapAliasToPath = (
  path = '',
  aliases = {
    taxAppUrlAlias: 'tax',
    taxProAppUrlAlias: 'tax-preparer',
    tirAdminAppUrlAlias: 'tir-admin',
    accountingAppUrlAlias: 'accounting',
  },
) => {
  let transform = path;
  const {
    taxAppUrlAlias,
    taxProAppUrlAlias,
    tirAdminAppUrlAlias,
    accountingAppUrlAlias,
  } = aliases;

  if (taxAppUrlAlias !== 'tax' && !path.includes('tax-preparer')) {
    transform = transform.replace('tax', taxAppUrlAlias);
  }
  if (taxProAppUrlAlias !== 'tax-preparer') {
    transform = transform.replace('tax-preparer', taxProAppUrlAlias);
  }
  if (tirAdminAppUrlAlias !== 'tir-admin') {
    transform = transform.replace('tir-admin', tirAdminAppUrlAlias);
  }
  if (accountingAppUrlAlias !== 'accounting') {
    transform = transform.replace('accounting', accountingAppUrlAlias);
  }
  return transform;
};

/**
 * Gets appType as param from current page url
 */
export const getAppIdFromUrl = () => {
  const pathArr = window.location.pathname.split('/');
  const appString = getAppIdFromUrlPath(pathArr[1]);
  return appString || 'none';
};

/**
 * Gets app alias as param from current page url
 */
export const getUrlAppParam = () => {
  const pathArr = window.location.pathname.split('/');
  const appString = getAppIdFromUrlPath(pathArr[1]);
  return appString ? pathArr[1] : 'none';
};

/**
 * converts input string (appType) to its corresponding backend / frontend string
 */
export const formatAppType = (appType, destination) => {
  const reference = {
    frontend: {
      tax: 'tax',
      'tax-preparer': 'tax-preparer',
      'tir-admin': 'tir-admin',
      accounting: 'accounting',
      TaxPayer: 'tax',
      TaxPreparer: 'tax-preparer',
      TirAdmin: 'tir-admin',
      Accounting: 'accounting',
    },
    backend: {
      TaxPayer: 'TaxPayer',
      TaxPreparer: 'TaxPreparer',
      TirAdmin: 'TirAdmin',
      Accounting: 'Accounting',
      tax: 'TaxPayer',
      'tax-preparer': 'TaxPreparer',
      'tir-admin': 'TirAdmin',
      accounting: 'Accounting',
    },
  };
  return destination && appType !== 'none'
    ? reference[destination][appType]
    : null;
};

/**
 * converts input string (appType) to its corresponding backend accountType string
 */
export const switchAppTypeToAccountType = (appType) => {
  const reference = {
    TaxPayer: 'TaxPayer',
    TaxPreparer: 'TaxPreparer',
    TirAdmin: 'TaxPreparer',
    Accounting: 'Accounting',
    tax: 'TaxPayer',
    'tax-preparer': 'TaxPreparer',
    'tir-admin': 'TaxPreparer',
    accounting: 'Accounting',
  };
  return appType !== 'none' ? reference[appType] : null;
};

export const getAccountDetailsForCreation = ({ auth0Username }) => {
  const { accountSubtypeDefault } = getAppConfig();
  const testRegex = /[^a-zA-Z0-9- ]/gi;
  const accountDetails = {
    timezone: guessTimezone(),
  };

  accountDetails.name = `${auth0Username.replace(testRegex, '')}`;
  accountDetails.taxPayerDetails = {
    taxYear: currentTaxYear,
  };
  if (accountSubtypeDefault) {
    accountDetails.accountSubtype = accountSubtypeDefault;
  }
  return accountDetails;
};

// check if node env is prod
export const isDev = () => !!import.meta.env.DEV;

export const unwrapSafely = (func, fallbackValue = fallbackDisplayValue) => {
  try {
    return func();
  } catch (e) {
    if (e instanceof TypeError) return fallbackValue;
    throw e;
  }
};

/**
 * will not continue with request validation if request contains certain params
 * (ie user email verification if user logs out)
 */
const skipRequestVerificationParams = [
  'supportSignup',
  'supportForgotPassword',
];

export const validateOAuthRequest = (request, expectedRequest) => {
  const errors = [];

  // prevent user not logged in from hitting this block during email verification
  const shouldSkipValidation =
    window.Cypress ||
    import.meta.env.MODE === 'ci' ||
    skipRequestVerificationParams.find((param) => request?.[param]);

  if (shouldSkipValidation) {
    return { valid: true, error: null };
  }

  if (!request?.grant_type) {
    return { valid: false, error: 'grant_type is required' };
  }

  if (!request?.client_id) {
    return { valid: false, error: 'client_id is required' };
  }

  if (!request?.redirect_uri) {
    return { valid: false, error: 'redirect_uri is required' };
  }

  if (request.grant_type !== 'authorization_code') {
    return {
      valid: false,
      error: 'grant_type of authorization_code is required',
    };
  }

  if (request.grant_type !== 'authorization_code') {
    return {
      valid: false,
      error: 'grant_type of authorization_code is required',
    };
  }

  if (
    expectedRequest &&
    request.redirect_uri !== expectedRequest.redirect_uri
  ) {
    return { valid: false, error: 'invalid redirect_uri' };
  }

  if (expectedRequest && request.client_id !== expectedRequest.client_id) {
    return { valid: false, error: 'invalid redirect_uri' };
  }

  return {
    valid: errors.length === 0,
    error:
      errors.length > 0
        ? errors.map((error) => error.message).join(', ')
        : null,
  };
};

export const parseAuth0RequestParams = (parsedSearch) => {
  const customParams = { ...parsedSearch };
  const oauthClientId = parsedSearch?.client_id;
  const oauthState = parsedSearch?.state;
  const oauthGrantType = parsedSearch?.grant_type;
  const oauthRedirectUri = parsedSearch?.redirect_uri;

  if (oauthRedirectUri) {
    customParams.authorizationParams = {
      redirect_uri: parsedSearch.redirect_uri,
    };
  }

  const isOAuth = oauthGrantType && oauthRedirectUri && oauthClientId; // state is optional

  // re-assign conflicting redirect_uri to custom param for oauth requests
  const oauthParams = {};
  if (isOAuth) {
    oauthParams.clientId = oauthClientId;
    oauthParams.appState = oauthState;
    oauthParams.grantType = oauthGrantType;
    oauthParams.authorizationParams = {
      redirect_uri: oauthRedirectUri,
    };
    delete customParams.redirect_uri;
    delete customParams.authorizationParams.redirect_uri;
    delete customParams.state;
    delete customParams.grant_type;
    delete customParams.client_id;
  }

  return { oauthParams, customParams };
};
