// absolute imports
import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { CardActions, CardContent, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';

// icon imports
import {
  ArrowRightRounded as ArrowRightIcon,
  ArrowLeftRounded as ArrowLeftIcon
} from '@mui/icons-material';

// relative imports
import { useAccount } from '../../../../providers/AccountProvider';
import LedgibleButton from '../../../../../shared/custom-components/buttons/LedgibleButton';
import TimezoneSelect from '../../../../../shared/custom-components/TimezoneSelect';
import useApolloErrorState from '../../../../../shared/custom-hooks/useApolloErrorState';
import { UPDATE_ACCOUNT } from '../../../../../../data/apollo/mutations';

const useStyles = makeStyles()((theme) => ({
  headerText: {
    marginBottom: theme.spacing(2),
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  selectField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

const TimezoneStep = ({ handleNext, handlePrevious }) => {
  const { classes } = useStyles();
  const { currentAccount } = useAccount();
  const [tz, setTz] = useState({
    name:
      currentAccount.timezone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const [changeTimezoneError, clearError] = useApolloErrorState([
    'updateAccount',
  ]);

  const [updateAccount] = useMutation(UPDATE_ACCOUNT);

  const handleContinue = () => {
    if (changeTimezoneError) {
      clearError();
    }

    if (tz.name !== currentAccount.timezone) {
      updateAccount({
        variables: {
          accountId: currentAccount.id,
          updateData: {
            timezone: tz.name,
          },
        },
      });
    }

    handleNext();
  };

  return (
    <>
      <CardContent>
        <Typography
          className={classes.headerText}
          color="secondary"
          gutterBottom
          variant="h6"
          align="center"
        >
          Timezone Selection
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="subtitle1">
          Ledgible uses your computer&apos;s local time by default for
          displaying date/time.
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="subtitle1">
          If you want to see your data displayed with a different timezone, you
          can set it here.
        </Typography>
        <TimezoneSelect
          onChange={setTz}
          TextFieldProps={{
            margin: 'dense',
          }}
        />
      </CardContent>
      <CardActions className={classes.actionRow}>
        <LedgibleButton
          variant="outlined"
          size="large"
          color="secondary"
          textTransformOff
          onClick={handlePrevious}
          startIcon={<ArrowLeftIcon />}
          id="tax-setup-flow-year-step-back"
        >
          Go Back
        </LedgibleButton>
        <LedgibleButton
          variant="contained"
          size="large"
          color="success"
          textTransformOff
          onClick={handleContinue}
          endIcon={<ArrowRightIcon />}
          id="tax-setup-flow-year-step-continue"
          disabled={!tz}
        >
          Continue
        </LedgibleButton>
      </CardActions>
    </>
  );
};

export default TimezoneStep;

TimezoneStep.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
};
