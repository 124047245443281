import PropTypes from 'prop-types';
import { Popper } from '@mui/material';

const LedgiblePopper = ({ modifiers, ...rest }) => (
  <Popper
    {...rest}
    popperOptions={{
      modifiers,
    }}
  />
);

export default LedgiblePopper;

LedgiblePopper.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.shape()),
}

LedgiblePopper.defaultProps = {
  modifiers: [{
    name: 'preventOverflow',
    enabled: true,
    options: {
      padding: 0,
    },
  }],
};
