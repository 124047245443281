/* COMPONENT DESCRIPTION
 *
 * Component takes in data in form of array of objects or array of strings and
 * outputs a new array of values consisting of a designated value key passed in for objects
 * or specific selected strings from string arrays.
 */

// absolute imports
import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import {
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

// icon imports
import { ArrowForward, ArrowBack } from '@mui/icons-material';

// relative imports
import LedgibleButton from './buttons/LedgibleButton';

// Component Styles
const useStyles = makeStyles()((theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 225,
    height: 200,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  titleText: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
}));

// Helper Functions
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function formatValueRef(displayKey, valueKey, list) {
  const refObj = {};
  if (displayKey && valueKey) {
    list.forEach((listItem) => {
      const val = listItem[valueKey];
      const label = listItem[displayKey];
      refObj[val] = label;
    });
  } else {
    list.forEach((listItem) => {
      refObj[listItem] = listItem;
    });
  }
  return refObj;
}

function initializeStateList(valueKey, list) {
  return list.map((listItem) => (valueKey ? listItem[valueKey] : listItem));
}

// List Card Compnent (internal)
const CustomList = ({
  title,
  items,
  classes,
  valueRef,
  handleToggle,
  handleToggleAll,
  numberOfChecked,
  checked,
}) => (
  <Card>
    <CardHeader
      className={classes.cardHeader}
      avatar={
        <Checkbox
          size="small"
          onClick={handleToggleAll(items)}
          checked={
            numberOfChecked(items) === items.length && items.length !== 0
          }
          indeterminate={
            numberOfChecked(items) !== items.length &&
            numberOfChecked(items) !== 0
          }
          disabled={items.length === 0}
          inputProps={{ 'aria-label': 'all items selected' }}
        />
      }
      title={title}
      titleTypographyProps={{
        className: classes.titleText,
      }}
      subheader={`${numberOfChecked(items)}/${items.length} selected`}
    />
    <Divider />
    <List className={classes.list} dense component="div" role="list">
      {items.map((item) => {
        const labelId = `transfer-list-item-${item}-label`;

        return (
          <ListItem
            key={item}
            role="listitem"
            button
            onClick={handleToggle(item)}
          >
            <ListItemIcon>
              <Checkbox
                size="small"
                checked={checked.indexOf(item) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={valueRef[item]} />
          </ListItem>
        );
      })}
      <ListItem />
    </List>
  </Card>
);

CustomList.propTypes = {
  classes: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  valueRef: PropTypes.shape().isRequired,
  handleToggle: PropTypes.func.isRequired,
  checked: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleToggleAll: PropTypes.func.isRequired,
  numberOfChecked: PropTypes.number.isRequired,
};

// Component
const TransferList = ({
  masterList = [], // list of all objects in both columns
  leftListDefault = [], // list of default items for left side, values must be in master list
  rightListDefault = [], // list of default items for right side, values must be in master list
  displayKey, // only for object lists, key for objects in master list to find display value
  valueKey, // only for object lists, key for objects in master list to find value value
  updateSelectedValues, // function to update parent component with new selected value
  leftTitle, // title of left side
  rightTitle, // title of right side
}) => {
  const { classes } = useStyles();
  const valueRef = formatValueRef(displayKey, valueKey, masterList);
  const [checked, setChecked] = useState([]);
  const [leftList, setLeftList] = useState(
    initializeStateList(valueKey, leftListDefault),
  );
  const [rightList, setRightList] = useState(
    initializeStateList(valueKey, rightListDefault),
  );

  const leftChecked = intersection(checked, leftList);
  const rightChecked = intersection(checked, rightList);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRightList(rightList.concat(leftChecked));
    updateSelectedValues(rightList.concat(leftChecked));
    setLeftList(not(leftList, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeftList(leftList.concat(rightChecked));
    setRightList(not(rightList, rightChecked));
    updateSelectedValues(not(rightList, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>
        <CustomList title={leftTitle} items={leftList} />
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <LedgibleButton
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
            marginBottom={4}
            marginTop={4}
            color="secondary"
            id={`transfer-list-${leftTitle}-transfer-forward`}
          >
            <ArrowForward />
          </LedgibleButton>
          <LedgibleButton
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
            marginBottom={4}
            marginTop={4}
            color="secondary"
            id={`transfer-list-${leftTitle}-transfer-back`}
          >
            <ArrowBack />
          </LedgibleButton>
        </Grid>
      </Grid>
      <Grid item>
        <CustomList
          title={rightTitle}
          items={rightList}
          classes={classes}
          valueRef={valueRef}
          handleToggleAll={handleToggleAll}
          numberOfChecked={numberOfChecked}
          handleToggle={handleToggle}
          checked={checked}
        />
      </Grid>
    </Grid>
  );
};

export default TransferList;

TransferList.propTypes = {
  masterList: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  ).isRequired,
  leftListDefault: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  ).isRequired,
  rightListDefault: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  ).isRequired,
  displayKey: PropTypes.string,
  valueKey: PropTypes.string,
  updateSelectedValues: PropTypes.func.isRequired,
  leftTitle: PropTypes.string.isRequired,
  rightTitle: PropTypes.string.isRequired,
};

TransferList.defaultProps = {
  displayKey: null,
  valueKey: null,
};
