// absolute imports
import PropTypes from 'prop-types';
import { Grid, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { marginTop }) => ({
  loaderContainer: {
    height: '100%',
    paddingTop: theme.spacing(marginTop),
  },
  maxSpaceContainer: {
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(153, 148, 148, 0.1)',
  },
  loaderBox: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  loaderFullScreen: {
    width: '100%',
    margin: 'auto',
  },
}));

const Loader = ({
  fillSpace,
  marginTop,
  LoaderContainerClasses,
  ...rest
}) => {
  const { classes } = useStyles({ marginTop });

  return fillSpace ? (
    <div className={classes.maxSpaceContainer}>
      <div className={classes.loaderBox}>
        <CircularProgress
          className={classes.loaderFullScreen}
          color={rest.color || 'primary'}
          size={rest.size || 50}
          thickness={rest.thickness || 3.6}
          variant={rest.variant || 'indeterminate'}
          data-cy="ledgible-loader"
          {...rest}
        />
      </div>
    </div>
  ) : (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.loaderContainer}
      classes={{
        ...LoaderContainerClasses,
      }}
    >
      <CircularProgress
        color={rest.color || 'primary'}
        size={rest.size || 40}
        thickness={rest.thickness || 3.6}
        variant={rest.variant || 'indeterminate'}
        data-cy="ledgible-loader"
        {...rest}
      />
    </Grid>
  );
};

export default Loader;

Loader.propTypes = {
  fillSpace: PropTypes.bool,
  LoaderContainerClasses: PropTypes.shape({}),
  marginTop: PropTypes.number,
};

Loader.defaultProps = {
  fillSpace: false,
  LoaderContainerClasses: {},
  marginTop: 0,
};
