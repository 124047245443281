// import external modules
import { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Link, Redirect, useLocation } from 'react-router-dom';
import {
  darken,
  AppBar,
  Button,
  Divider,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';

// import icons
import {
  ArrowLeft as LeftIcon,
  Notifications as NotificationsIcon,
} from '@mui/icons-material';

// relative imports
import { useConfig } from '../../common/providers/ConfigProvider';
import { useAuth0 } from '../../common/providers/Auth0Provider';
import { useAccount } from '../../common/providers/AccountProvider';
import { useAppManagement } from '../../common/providers/AppManagementProvider';
import ProfileNavbox from './ProfileNavbox';
import SecondaryToolbar from './SecondaryToolbar';
import HelpMenu from '../customer-support/HelpMenu';
import TrialUpgradeButton from '../../accounting/billing/TrialUpgradeButton';
import AccountWindow from '../../accounting/accounts/account-window/AccountWindow';
import TaxPayerAccountWindow from '../../tax/payer-components/TaxPayerAccountWindow';
import PreparerAccountWindow from '../../tax-preparer/preparer-components/PreparerAccountWindow';
import PreparerClientAccountWindow from '../../tax-preparer/preparer-components/PreparerClientAccountWindowVirtualized';
import UpdateDataSourcesButton from '../../common/account-updates/UpdateAccountDataButton';
import TirAdminAccountWindow from '../../tir-admin/tir-components/TirAdminAccountWindow';
import TirAdminClientAccountWindow from '../../tir-admin/tir-components/TirAdminClientAccountWindow';
import PendingCodeInviteButton from '../../tax/payer-components/PendingCodeInviteButton';
import { getAppUrlPathFromId, getAppLogoSrc } from '../utilities/appUtilities';
import useAlternativeDisplay from '../custom-hooks/useAlternativeDisplay';
import PreparerBar from './PreparerBar';

const useStyles = makeStyles()((theme, { isMobile }) => ({
  appBar: {
    backgroundColor:
      theme.whiteLabel.Header.color || theme.palette.secondary.main,
  },
  toolbarMain: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    justifyContent: 'space-between',
    alignItems: 'stretch',
    minHeight: '56px !important',
  },
  toolbarMainGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  backButton: {
    color: theme.whiteLabel.Navigation.color || theme.palette.grey[100],
    marginLeft: theme.spacing(2),
    '&:hover': {
      color: theme.whiteLabel.Navigation.color
        ? darken(theme.whiteLabel.Navigation.color, 1)
        : theme.palette.grey[300],
      backgroundColor: 'transparent',
    },
  },
  updatingText: {
    color: '#fff',
  },
  notificationsButton: {
    color: theme.whiteLabel.Navigation.color || theme.palette.common.white,
    padding: 5,
    marginRight: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.2)',
    },
    '& .beamer_icon.active, & #beamerIcon.active': {
      width: 15,
      height: 15,
      lineHeight: '15px',
      top: -2,
      right: -1,
    },
  },
  notificationsIcon: {
    margin: -2,
  },
  titleBox: {
    borderLeft: `${isMobile ? 0 : 1}px solid ${theme.palette.grey[200]}`,
  },
  productName: {
    width: '100%',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: '30px',
    fontWeight: 800,
    color: theme.whiteLabel.ProductName.color || theme.palette.common.white,
    whiteSpace: 'nowrap',
  },
  productNameDivider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: '2px',
    height: '30px',
    backgroundColor: theme.palette.grey[700],
  },
}));

const validTrialEndedUrls = [
  `/${getAppUrlPathFromId('accounting')}/trial-expired`,
  '/user-settings',
];

const Header = ({
  hideAccountMenu,
  hideSecondaryMenu,
  hideUpdateButton,
  accountOptional,
  setHeaderHeight,
}) => {
  const { isMobile } = useAlternativeDisplay();
  const { appConfig } = useConfig();
  const { classes, cx } = useStyles({ isMobile });
  const { state: locationState, pathname } = useLocation();
  const { user } = useAuth0();
  const { appType, displayAppType } = useAppManagement();
  const { clientPermissions: permissions, currentAccount } = useAccount();
  const headerRef = useRef();
  const isTaxPreparerViewingClient =
    appType === 'tax' && permissions.isPreparerViewingClient;
  const isTirAdminViewingClient =
    appType === 'tax' && permissions.isTirAdminViewingClient;
  const validDisabledUrls = [`/${appType}/disabled`, '/user-settings'];

  useLayoutEffect(() => {
    setHeaderHeight(headerRef?.current?.clientHeight);
  });

  // handle redirect condition for trial-expired in accounting
  if (
    currentAccount?.trialEnded &&
    !validTrialEndedUrls.includes(pathname) &&
    appType === 'accounting'
  ) {
    return (
      <Redirect to={`/${getAppUrlPathFromId('accounting')}/trial-expired`} />
    );
  }

  // else handle redirect condition for disabled account
  if (
    currentAccount?.disabled &&
    !currentAccount?.trialEnded &&
    !validDisabledUrls.includes(pathname)
  ) {
    return <Redirect to={`/${getAppUrlPathFromId(appType)}/disabled`} />;
  }

  return (
    <AppBar
      className={cx(classes.appBar, 'beamerActivePosition')}
      position="fixed"
      ref={headerRef}
    >
      {(isTaxPreparerViewingClient || isTirAdminViewingClient) && (
        <PreparerBar />
      )}
      <Toolbar className={classes.toolbarMain}>
        <div className={classes.toolbarMainGroup}>
          <img
            src={getAppLogoSrc(isTirAdminViewingClient ? 'tir-admin' : appType)}
            alt="primary logo"
            data-cy={`primary-logo-${appType}`}
            height="30px"
          />
          {appConfig.productName && (
            <>
              <Divider
                className={classes.productNameDivider}
                orientation="vertical"
              />
              <Typography className={classes.productName}>
                {appConfig.productName}
              </Typography>
            </>
          )}
          {(!hideAccountMenu || appType === 'accounting') &&
            currentAccount.id && (
              <>
                {appType === 'tax' &&
                  !permissions.isPreparerViewingClient &&
                  !permissions.isTirAdminViewingClient && (
                    <TaxPayerAccountWindow />
                  )}
                {appType === 'tax' && permissions.isPreparerViewingClient && (
                  <PreparerClientAccountWindow />
                )}
                {appType === 'tax' && permissions.isTirAdminViewingClient && (
                  <TirAdminClientAccountWindow />
                )}
                {appType === 'tax-preparer' && <PreparerAccountWindow />}
                {appType === 'tir-admin' && <TirAdminAccountWindow />}
                {appType === 'accounting' && <AccountWindow />}
              </>
            )}
          {hideAccountMenu &&
            accountOptional &&
            appType !== 'none' &&
            currentAccount.id && (
              <>
                {locationState && locationState.redirect && (
                  <Button
                    component={Link}
                    to={locationState.redirect}
                    className={classes.backButton}
                    variant="text"
                    id="TopNav-BackToDashboardWithRedirectBtn"
                  >
                    <LeftIcon />
                    {locationState.redirectLabel}
                  </Button>
                )}
                {((locationState && !locationState.redirect) ||
                  !locationState) &&
                  !currentAccount.disabled && (
                    <Button
                      component={Link}
                      to={
                        appConfig.alternateBaseAppPath || `/${displayAppType}`
                      }
                      className={classes.backButton}
                      variant="text"
                      id="TopNav-BackToDashboardBtn"
                    >
                      <LeftIcon />
                      {appType === 'accounting' &&
                      !appConfig.alternateBaseAppPath
                        ? 'Dashboard'
                        : 'Overview'}
                    </Button>
                  )}
              </>
            )}
          {!hideAccountMenu &&
            currentAccount.id &&
            !hideUpdateButton &&
            appType !== 'tax-preparer' &&
            appType !== 'tir-admin' &&
            !isTirAdminViewingClient && <UpdateDataSourcesButton />}
        </div>
        <div className={classes.toolbarMainGroup}>
          {appType === 'accounting' &&
            !hideAccountMenu &&
            !user?.achEnabled &&
            currentAccount.inTrial &&
            !currentAccount.trialEnded &&
            !currentAccount.subtype && (
              <TrialUpgradeButton
                variant="outlined"
                size="small"
                marginRight={8}
              />
            )}
          {appType === 'tax' &&
            appConfig.enableTaxProConnection &&
            !permissions.isPreparerViewingClient && (
              <PendingCodeInviteButton currentAccount={currentAccount} />
            )}
          {appConfig.enableBeamer && (
            <IconButton
              className={classes.notificationsButton}
              aria-label="toggle notifications menu"
              size="small"
              aria-controls="menu-list-grow"
              aria-haspopup="true"
              id="top-nav-notifications-menu-button"
            >
              <NotificationsIcon className={classes.notificationsIcon} />
            </IconButton>
          )}
          <HelpMenu />
          {user && <ProfileNavbox />}
        </div>
      </Toolbar>
      {currentAccount?.id && !hideSecondaryMenu && (
        <SecondaryToolbar
          permissions={permissions}
          appType={appType}
          currentAccount={currentAccount}
        />
      )}
    </AppBar>
  );
};

export default Header;

Header.propTypes = {
  hideAccountMenu: PropTypes.bool.isRequired,
  hideSecondaryMenu: PropTypes.bool.isRequired,
  hideUpdateButton: PropTypes.bool.isRequired,
  accountOptional: PropTypes.bool.isRequired,
  setHeaderHeight: PropTypes.func.isRequired,
};
