// require
import { detect } from 'detect-browser';

// relative imports
import { browserCheckVar, errorVar } from '../reactiveVars';

const checkBrowser = async () => {
  const error = errorVar();
  const existing = browserCheckVar();
  // clear error if it's displayed
  if (error) {
    errorVar(null);
  }

  try {
    const browser = await detect();

    if (browser) {
      browserCheckVar({
        ...existing,
        currentBrowser: browser.name,
        currentOS: browser.os,
      });
    }
  } catch (err) {
    browserCheckVar({
      ...existing,
      browser: 'unknown',
      os: 'unknown',
    });
  }
};

export default checkBrowser;
