/** Controlled/Composed component to properly provide timezone select list */
// absolute imports
import { useState } from 'react';
import PropTypes from 'prop-types';
import { getTimeZones } from '@vvo/tzdb';
import { useReactiveVar } from '@apollo/client';

// relative imports
import AutoCompleteTextField from './AutoCompleteTextField';
import { currentAccountTimezoneVar } from '../../../data/apollo/cache/reactiveVars';
import { getComponentTestKey } from '../utilities/testUtilities';

/** MUI TIMEZONE SELECT COMPONENT */
const TimezoneSelect = ({
  defaultTimezoneName,
  onChange,
  onInputChange,
  TextFieldProps = {},
  value,
  ...AutoCompleteProps
}) => {
  const currentTimezone = useReactiveVar(currentAccountTimezoneVar);
  // generate timezone options from tzdb
  const timeZones = getTimeZones({ includeUtc: true });
  const defaultValue =
    timeZones.find(
      (timeZone) =>
        timeZone.name === (defaultTimezoneName || currentTimezone) ||
        timeZone.group.includes(defaultTimezoneName || currentTimezone),
    ) || null;
  const [tzValue, setTzValue] = useState(value || defaultValue);

  const [tzInputValue, setTzInputValue] = useState('');

  const handleChange = (e, newValue) => {
    if (onChange) {
      onChange(newValue);
    }
    setTzValue(newValue);
  };

  const handleInputChange = (e, newInputValue) => {
    if (onInputChange) {
      onInputChange(newInputValue);
    }
    setTzInputValue(newInputValue);
  };

  return (
    <AutoCompleteTextField
      data-cy={getComponentTestKey({
        componentName: 'TimezoneSelect',
        elementType: 'input',
        elementName: 'timezone',
      })}
      value={tzValue}
      inputValue={tzInputValue}
      handleChange={handleChange}
      handleInputChange={handleInputChange}
      optionsList={timeZones}
      getOptionLabel={(option) => option.currentTimeFormat}
      isOptionEqualToValue={(option) => option.name === tzValue.name}
      TextFieldProps={{
        label: 'Timezone',
        variant: 'outlined',
        placeholder: 'Select timezone...',
        fullWidth: true,
        size: 'small',
        ...TextFieldProps,
      }}
      inputProps={{
        name: AutoCompleteProps.id
          ? `${AutoCompleteProps.id}-input`
          : 'timezone-select-input',
        id: AutoCompleteProps.id
          ? `${AutoCompleteProps.id}-input`
          : 'timezone-select-input',
      }}
      {...AutoCompleteProps}
    />
  );
};

export default TimezoneSelect;

TimezoneSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  defaultTimezoneName: PropTypes.string,
  TextFieldProps: PropTypes.shape(),
};

TimezoneSelect.defaultProps = {
  value: '',
  onInputChange: () => null,
  defaultTimezoneName: undefined,
  TextFieldProps: {},
};
