import * as Sentry from '@sentry/react';

export const captureSentryError = ({
  error,
  info,
  userId,
  accountId,
  appType,
  context,
}) => {
  if (
    // import.meta.env.PROD &&
    !window.Cypress &&
    import.meta.env.MODE !== 'ci' &&
    import.meta.env.REACT_APP_SENTRY_DSN
  ) {
    Sentry.withScope((scope) => {
      scope.setUser({
        id: userId || 'unavailable',
      });
      scope.setTag('accountId', accountId || 'unavailable');
      scope.setTag('appType', appType || 'unavailable');
      scope.setTag('pathname', window.location.pathname);
      scope.setTag('userAgent', navigator.userAgent);
      scope.setTag('context', JSON.stringify(context));

      Sentry.captureException(error);
    });
    // eslint-disable-next-line no-console
    console.log('Error log sent');
  } else {
    // eslint-disable-next-line no-console
    console.log('error is: ', error, info);
  }
};

export const captureSentryGenericError = (error, context) => {
  if (
    // import.meta.env.PROD &&
    !window.Cypress &&
    import.meta.env.MODE !== 'ci' &&
    import.meta.env.REACT_APP_SENTRY_DSN
  ) {
    Sentry.withScope((scope) => {
      scope.setTag('userAgent', navigator.userAgent);
      scope.setTag('context', JSON.stringify(context));

      Sentry.captureException(error);
    });
    // eslint-disable-next-line no-console
    console.log('Error log sent');
  } else {
    // eslint-disable-next-line no-console
    console.log('error is: ', error);
  }
};
