// absolute imports
import { useMemo, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

// relative imports
import {
  getAppUrlPathFromId,
  formatAppType,
  getAuth0ClientId,
  getAppIdFromUrl,
} from '../../../shared/utilities/appUtilities';
import { useConfig } from '../../providers/ConfigProvider';
import { useAuth0 } from '../../providers/Auth0Provider';
import { useAccount } from '../../providers/AccountProvider';
import { useAppManagement } from '../../providers/AppManagementProvider';
import useSetupFlow from '../../../shared/custom-hooks/useSetupFlow';
import LoadingPage from '../../../shared/page-components/LoadingPage';
import withAuthenticationRequired from '../../../shared/authentication/withAuthenticationRequired';
import SelfSelectFlow from '../../self-select/SelfSelectFlow';
import getDynamicRouteProps from '../routeUtilities';

// Wrap private route in layout provided from route config and apply any conditions required
const PrivateRoute = ({ route }) => {
  const {
    main: Component,
    layout: Layout,
    LayoutProps,
    accountOptional,
    // helpLabels, // can be used later when contextual help is enabled
    path,
  } = route;
  const { appConfig, accountConfig } = useConfig();
  // useContextualHelp({ labels: helpLabels }); // can be used later when contextual help is enabled
  const { appType, displayAppType } = useAppManagement();
  const { loadingAccountInfo, currentAccount } = useAccount();
  const setupFlow = useSetupFlow(currentAccount);
  const { user, permittedApplications } = useAuth0();
  const routeDisabled = useMemo(() => {
    const dynamicProps = getDynamicRouteProps(
      currentAccount,
      accountConfig,
      appConfig,
      path,
    );
    return dynamicProps.disabled;
  }, [accountConfig, appConfig, currentAccount, path]);

  const render = (routeProps) => {
    // check for route being disabled and redirect back to app main if so
    if (routeDisabled && permittedApplications.length > 0) {
      return (
        <Redirect
          to={
            appType !== 'none'
              ? appConfig.alternateBaseAppPath || `/${displayAppType}`
              : `/${getAppUrlPathFromId(permittedApplications[0])}`
          }
        />
      );
    }

    // check for verified email
    if (
      user &&
      user.profile &&
      !user.profile.email_verified &&
      route.path !== '/verify-email'
    ) {
      // pass through location state on referring route to email verify so user can come back to
      // requested route
      return (
        <Redirect
          to={{
            pathname: '/verify-email',
            state: { returnTo: route.path },
          }}
        />
      );
    }
    // if not loading and email verified, check for useSetupFlow indication
    if (setupFlow) {
      return <SelfSelectFlow flowId={setupFlow} />;
    }
    // if email verified, ensure account info is not loading if required
    if (
      !accountOptional &&
      (loadingAccountInfo || formatAppType(appType, 'backend') !== currentAccount.type) &&
      !currentAccount.accountPlan
    ) {
      return <LoadingPage loadingPage />;
    }
    // else return the requested route
    return (
      <Layout {...LayoutProps} accountOptional={accountOptional}>
        <Suspense fallback={<LoadingPage loadingPage hidePageHeader />}>
          <Component {...routeProps} />
        </Suspense>
      </Layout>
    );
  };

  // render route with render function
  return <Route render={render} path={route.path} />;
};

// ensure authentication on all Private routes with HOC
export default withAuthenticationRequired(PrivateRoute, {
  onRedirecting: () => <LoadingPage loadingApplication />,
  returnTo: `${window.location.pathname}${window.location.search}`,
  loginOptions: {
    appType: getAppIdFromUrl(),
    clientId: getAuth0ClientId(),
    appState: {},
  },
});

PrivateRoute.propTypes = {
  route: PropTypes.shape().isRequired,
};
