// absolute imports
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import {
  Box,
  Card,
  CardMedia,
  Grid,
  LinearProgress,
  Typography
} from '@mui/material';
import { useMutation } from '@apollo/client';


// relative imports
import { UPDATE_ACCOUNT } from '../../../../../data/apollo/mutations';
import LedgibleButton from '../../../../shared/custom-components/buttons/LedgibleButton';
import { getAppLogoSrc } from '../../../../shared/utilities/appUtilities';
import { useAccount } from '../../../providers/AccountProvider';
import { useAppManagement } from '../../../providers/AppManagementProvider';

const useStyles = makeStyles()((theme) => ({
  containerRoot: {
    backgroundColor: theme.palette.primary.main,
    overflowX: 'hidden',
  },
  mainGrid: {
    height: '100%',
  },
  logoImg: {
    margin: theme.spacing(0, 'auto', 2),
    width: 220,
  },
  stepCard: {
    width: 480,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 8,
  },
  progressRoot: {
    height: 8,
    borderRadius: 5,
  },
  progressBar: {
    borderRadius: 5,
  },
  textButton: {
    padding: 1,
    fontSize: theme.typography.body1,
  },
}));

const TaxSetupTemplate = ({
  progress,
  currentStepIndex,
  lastStepIndex,
  children,
}) => {
  const { classes } = useStyles();
  const { appType } = useAppManagement();
  const { currentAccount, refetchCurrentAccount } = useAccount();
  const displayOfframp = currentStepIndex !== lastStepIndex;

  const [updateAccount] = useMutation(UPDATE_ACCOUNT, {
    variables: {
      accountId: currentAccount.id,
      updateData: {
        setupRequired: false,
      },
    },
    onCompleted() {
      refetchCurrentAccount();
    },
  });

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.mainGrid}
      spacing={4}
      wrap="nowrap"
    >
      <Grid item>
        <CardMedia
          component="img"
          alt="Ledgible Tax Logo"
          image={getAppLogoSrc(appType, 'dark')}
          title="Ledgible Tax Logo"
          className={classes.logoImg}
        />
      </Grid>
      <Grid item>
        <Card className={classes.stepCard} raised>
          <Box width="60%">
            <LinearProgress
              variant="determinate"
              color="secondary"
              value={progress}
              classes={{
                root: classes.progressRoot,
                bar: classes.progressBar,
              }}
            />
          </Box>
          {children}
        </Card>
      </Grid>
      {displayOfframp && (
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Typography display="inline" color="textSecondary" variant="body1">
            Need to set up later?
          </Typography>
          <LedgibleButton
            variant="text"
            size="medium"
            color="white"
            textTransformOff
            onClick={updateAccount}
            id="tax-setup-flow-step-1-skip"
            marginLeft={8}
            className={classes.textButton}
          >
            Skip for Now
          </LedgibleButton>
        </Grid>
      )}
    </Grid>
  );
};

export default TaxSetupTemplate;

TaxSetupTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.shape()]).isRequired,
  currentStepIndex: PropTypes.number.isRequired,
  lastStepIndex: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
};
