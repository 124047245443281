// absolute imports
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { darken } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';

import { useQuery } from '@apollo/client';

// import icons
import { NotificationsActive as InvitedIcon } from '@mui/icons-material';

// relative imports
import LedgibleButton from '../../shared/custom-components/buttons/LedgibleButton';
import { useAuth0 } from '../../common/providers/Auth0Provider';
import { PENDING_LINK_QUERY } from '../../../data/apollo/queries';
import { getAppUrlPathFromId } from '../../shared/utilities/appUtilities';

const useStyles = makeStyles()((theme) => ({
  linkButton: {
    backgroundColor: theme.palette.success.dark,
    border: `1px solid ${theme.palette.success.dark}`,
    borderRadius: 2,
    boxShadow: theme.shadows[3],
    '&:hover': {
      backgroundColor: darken(theme.palette.success.dark, 0.1),
      border: `1px solid ${darken(theme.palette.success.dark)}`,
    },
  },
}));

const PendingCodeInviteButton = ({ currentAccount }) => {
  const { classes } = useStyles();
  const match =
    window.location.pathname === `/${getAppUrlPathFromId('tax')}/settings/general`;
  const { user } = useAuth0();

  // check for pending peparer link invite
  const { data: { checkPendingLinkCodeInvite: hasPendingCodeInvite } = {} } =
    useQuery(PENDING_LINK_QUERY, {
      variables: {
        email: user && user.profile.email,
      },
      fetchPolicy: 'cache-and-network',
      skip: !user || currentAccount?.preparerLink?.clientActive || match,
    });

  return !match && hasPendingCodeInvite ? (
    <Tooltip
      title="A Tax Pro has invited you to connect, click to view."
      placement="bottom-start"
      enterDelay={500}
      enterTouchDelay={500}
    >
      <span>
        <LedgibleButton
          // textTransformOff
          component={Link}
          to={`/${getAppUrlPathFromId('tax')}/settings/general`}
          size="small"
          color="success"
          variant="contained"
          startIcon={<InvitedIcon fontSize="large" />}
          id="tax-pro-view-invite-button"
          className={classes.linkButton}
          marginRight={20}
        >
          Tax Pro Invite
        </LedgibleButton>
      </span>
    </Tooltip>
  ) : null;
};

export default PendingCodeInviteButton;

PendingCodeInviteButton.propTypes = {
  currentAccount: PropTypes.shape().isRequired,
};
