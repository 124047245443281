import {
  useEffect,
  useState,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';
import { Dialog, Paper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useReactiveVar } from '@apollo/client';
import Draggable from 'react-draggable';
import clsx from 'clsx';

// relative imports
import ModalConfirmExit from './ModalConfirmExit';
import useAlternativeDisplay from '../custom-hooks/useAlternativeDisplay';
import ErrorBoundary from '../../error-handling/ErrorBoundary';
import { helpWidgetActiveVar } from '../../../data/apollo/cache/reactiveVars';
import useDialogState from '../custom-hooks/useDialogState';
import {
  useModal,
  useModalActions,
} from '../../common/providers/ModalProvider';

const useStyles = makeStyles()((theme, { isMobile, isDraggable }) => ({
  root: {
    marginLeft: theme.spacing(isMobile ? -3 : 0),
    marginRight: theme.spacing(isMobile ? -3 : 0),

    '& .MuiDialogTitle-root': {
      cursor: isDraggable ? 'move' : 'inherit',
    },
  },
}));

const DraggableComponent = (props) => (
  <Draggable
    handle="#draggable-dialog-title"
    cancel={'[class*="MuiDialogContent-root"]'}
  >
    <Paper {...props} />
  </Draggable>
);

const MuiDialog = ({
  children,
  isDraggable,
  onClose,
  ...muiDialogProps
}) => {
  const [hideBackdrop, setHideBackdrop] = useState(false);
  const { modals } = useModal();
  const { hideLastModal } = useModalActions();
  const { isMobile } = useAlternativeDisplay();
  const helpWidgetActive = useReactiveVar(helpWidgetActiveVar);
  const { classes } = useStyles({ isMobile, isDraggable });
  const { dialogState, updateDialogState, resetDialogState } = useDialogState();

  useEffect(() => {
    setHideBackdrop(modals.length > 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClose = useCallback((skipConfirmation) => {
    if (dialogState.pristine || skipConfirmation) {
      resetDialogState();
      if (onClose) onClose();
      hideLastModal();
    } else {
      updateDialogState('confirmExit', true);
    }
  }, [
    hideLastModal,
    onClose,
    dialogState,
    updateDialogState,
    resetDialogState,
  ]);

  const dialogClassName = clsx({
    [classes.root]: true,
    [muiDialogProps.className]: ![undefined, null].includes(
      muiDialogProps.className,
    ),
  });

  return (
    <Dialog
      className={dialogClassName}
      data-cy="dialog"
      disableEnforceFocus={helpWidgetActive}
      fullWidth={muiDialogProps.fullWidth || true}
      hideBackdrop={hideBackdrop}
      maxWidth={muiDialogProps.maxWidth || 'sm'}
      onClose={(skipConfirm) => handleOnClose(skipConfirm === true)}
      closeAfterTransition
      open
      {...(isDraggable && !isMobile
        ? { PaperComponent: DraggableComponent }
        : {})}
      {...muiDialogProps}
    >
      <ErrorBoundary
        fallbackType="modal"
        errorMsg="An error occurred while loading the modal."
        onReset={hideLastModal}
        errorMsgOnly
        context={{
          component: MuiDialog.displayName,
        }}
      >
        {children}
      </ErrorBoundary>
      {dialogState.confirmExit && <ModalConfirmExit onClose={onClose} />}
    </Dialog>
  );
};

// Intermediary variable in case we need to nest at some point
const LedgibleDialog = MuiDialog;

MuiDialog.displayName = 'LedgibleDialog';

export default LedgibleDialog;

MuiDialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.shape({}),
  ]).isRequired,
  isDraggable: PropTypes.bool,
  onClose: PropTypes.func,
};

MuiDialog.defaultProps = {
  isDraggable: true,
  onClose: () => null,
};
