import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import FormHelperText from './FormHelperText';

const FormSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  defaultVal,
  placeholder,
  ...passedProps
}) => {
  const availableOptions = useMemo(() => {
    const options = [];

    children.forEach((child) => {
      if (child?.props) {
        options.push(child.props.value);
      } else if (Array.isArray(child)) {
        const childArr = child;
        childArr.forEach((nested) => {
          if (nested?.props?.value) {
            options.push(nested.props.value);
          }
        });
      }
    });

    return options;
  }, [children]);

  const noOptions = availableOptions.every((option) => !option);
  const isOptionAvailable = availableOptions.find(
    (option) => input.value === option,
  )
    ? true
    : null;

  return (
    <FormControl
      error={!!(touched && error)}
      variant={passedProps.variant || 'standard'}
      margin={passedProps.margin || 'none'}
      fullWidth={passedProps.fullWidth}
      className={passedProps.className}
    >
      {label && (
        <InputLabel
          variant={passedProps.variant || 'standard'}
          shrink={
            passedProps.shrink ||
            !!placeholder ||
            !!defaultVal ||
            !!input.value ||
            isOptionAvailable
          }
          htmlFor={passedProps.id}
          id={label}
        >
          {label}
        </InputLabel>
      )}
      <Select
        {...input}
        inputProps={{
          name: passedProps.name,
          id: passedProps.id,
        }}
        {...passedProps}
        className={passedProps.className}
        value={
          !touched && defaultVal && !input.value && isOptionAvailable
            ? defaultVal
            : ((isOptionAvailable || noOptions) && input.value) || ''
        }
        displayEmpty
        labelId={label}
      >
        {placeholder && (
          <MenuItem value="">
            <em style={{ color: '#A2A2A2', fontStyle: 'normal' }}>
              {placeholder}
            </em>
          </MenuItem>
        )}
        {children}
      </Select>
      <FormHelperText touched={touched} error={error} />
    </FormControl>
  );
};

export default FormSelectField;

// Select Field prop types
FormSelectField.propTypes = {
  placeholder: PropTypes.node,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  defaultVal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FormSelectField.defaultProps = {
  children: null,
  label: null,
  defaultVal: null,
  placeholder: null,
};
