// absolute imports
/* eslint-disable no-unreachable */
import { useEffect, Suspense } from 'react';
import { Auth0Client } from '@auth0/auth0-spa-js';
import queryString from 'query-string';
// import { useHistory } from 'react-router-dom';
import { merge } from 'lodash';

// relative imports
import { useAuth0 } from '../../common/providers/Auth0Provider';
import { useAppManagement } from '../../common/providers/AppManagementProvider';
import LoadingPage from '../page-components/LoadingPage';
import {
  getAuth0DefaultClientOptions,
  getAuth0ClientId,
  getAuth0RedirectUri,
  parseAuth0RequestParams,
} from '../utilities/appUtilities';

// default to display on redirect
const defaultOnRedirecting = () => <LoadingPage loadingApplication />;

// default to return to after auth redirect
const defaultReturnTo = () =>
  `${window.location.pathname}${window.location.search}`;

const withAuthenticationRequired = (Component, options = {}) =>
  function WithAuthenticationRequired(props) {
    const { isAuthenticated, loadingAuth } = useAuth0();
    const { loadingApp, appType } = useAppManagement();

    // options passed to HOC from component
    const {
      returnTo = defaultReturnTo,
      onRedirecting = defaultOnRedirecting,
      loginOptions = {},
    } = options;

    useEffect(() => {
      if (loadingAuth || isAuthenticated || loadingApp) {
        // don't run effect if user is authed
        return;
      }

      const returnToRoute =
        typeof returnTo === 'function' ? returnTo() : returnTo;
      const queryParams = queryString.parse(returnToRoute);

      // re-assign conflicting redirect_uri to custom param for oauth requests
      const { oauthParams, customParams } =
        parseAuth0RequestParams(queryParams);

      const opts = merge(
        merge(loginOptions, {
          appState: {
            returnTo: returnToRoute,
          },
        }),
        {
          clientId: getAuth0ClientId(appType),
        },
        // non oauth params
        customParams,
        // oauth request params
        oauthParams,
      );

      // call logingWithRedirect passed/default options
      (async () => {
        // use new Auth0 client to ensure correct client app redirect
        const loginClient = new Auth0Client(
          merge(getAuth0DefaultClientOptions(), {
            clientId: getAuth0ClientId(opts.appType),
            authorizationParams: {
              redirect_uri: getAuth0RedirectUri(opts.appType),
            },
          }),
        );
        // send to login for chosen
        await loginClient.loginWithRedirect(opts);
      })();
    }, [
      loadingAuth,
      loadingApp,
      isAuthenticated,
      loginOptions,
      returnTo,
      appType,
    ]);

    return isAuthenticated ? (
      <Suspense fallback={<LoadingPage loadingPage hidePageHeader />}>
        <Component {...props} />
      </Suspense>
    ) : (
      onRedirecting()
    );
  };

export default withAuthenticationRequired;
