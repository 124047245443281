/* Provider that keeps track of the mounted Ledgible application */

// absolute imports
import {
  useEffect,
  useContext,
  createContext,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { useHistory } from 'react-router-dom';

// relative imports
import {
  getAppUrlPathFromId,
  getLedgibleAppIdFromClientId,
  getAppIdFromUrl,
  getAppDisplayName,
  getAppTitle,
} from '../../shared/utilities/appUtilities';
import { useAuth0 } from './Auth0Provider';
import { useConfig } from './ConfigProvider';
import { dispatchToDataLayer } from '../../shared/google-tag-manager/GTMContainer';
import {
  appTypeVar,
  requestedAppTypeVar,
  isTaxPreparerVar,
  switchingApplicationsVar,
  currentAccountIdVar,
  persistedAccountIdsVar,
} from '../../../data/apollo/cache/reactiveVars';

// ----- SET UP CONTEXT -----
export const AppManagementContext = createContext();
export const useAppManagement = () => useContext(AppManagementContext);

export const AppManagementProvider = ({ children }) => {
  const { appConfig } = useConfig();
  const { staticAppType } = appConfig;
  const { push } = useHistory();
  const {
    loadingAuth,
    isAuthenticated,
    allowedApplications,
    permittedApplications,
    tokenClientId,
  } = useAuth0();
  const appType = useReactiveVar(appTypeVar);
  const isTaxPreparer = useReactiveVar(isTaxPreparerVar);
  const appInPath = getAppIdFromUrl(); // current appId based on url path
  const [loadingApp, setLoadingApp] = useState(true);
  const appSetRef = useRef(null);
  const displayAppType = useMemo(() => getAppUrlPathFromId(appType), [appType]);

  const checkAppSwitchAllowed = useCallback(
    (requestedApp) => {
      // no unauthenticated users
      if (!isAuthenticated) return true;
      // bypass for static env (white-label/custom)
      if (staticAppType && staticAppType === requestedApp) {
        return true;
      }
      // handle tax preparer <> tax scenarios explicitly
      if (
        appType === 'tax-preparer' &&
        requestedApp === 'tax' &&
        permittedApplications.includes('tax')
      ) {
        return true;
      }
      if (
        appType === 'tax' &&
        requestedApp === 'tax' &&
        permittedApplications.includes('tax-preparer') &&
        !permittedApplications.includes('tax')
      ) {
        return false;
      }
      // otherwise allow switch for new user with no accounts
      if (
        allowedApplications.includes(requestedApp) &&
        !permittedApplications.includes(requestedApp) &&
        (appType === 'none' || appType === requestedApp)
      ) {
        return true;
      }
      // green light the following always (app and account exists)
      if (
        allowedApplications.includes(requestedApp) &&
        permittedApplications.includes(requestedApp)
      ) {
        return true;
      }
      if (permittedApplications.includes('tir-admin')) {
        if (requestedApp === 'tax' && appType === 'tir-admin') return true;
        if (appType === 'tax' && requestedApp === 'tir-admin') return true;
        if (appType === 'tax' && allowedApplications.includes('tir-admin'))
          return true;
        if (appType === 'none' && requestedApp === 'tir-admin') return true;
      }

      return false;
    },
    [
      appType,
      isAuthenticated,
      staticAppType,
      allowedApplications,
      permittedApplications,
    ],
  );

  // Function to set the appType, pass no args to leave as-is and stop app-load
  const setApplication = useCallback(
    async (selectedApp, redirectPath) => {
      const nextAppType = selectedApp;
      // prevent loading confusion on tax pro <> client transition
      if (isTaxPreparer && ['tax-preparer', 'tax'].includes(nextAppType)) {
        switchingApplicationsVar(true);
      }
      // set app loading to ensure proper UI transition if not already set
      if (!loadingApp) {
        setLoadingApp(true);
      }
      // set ref to prevent further useEffect processing after initial load
      if (!appSetRef.current) {
        appSetRef.current = true;
      }
      // push to new app base route or specified path, if passed
      if (redirectPath) {
        push(redirectPath);
      }

      // perform app switch if new appType is set
      if (checkAppSwitchAllowed(nextAppType)) {
        // await apolloClient.clearStore();
        if (appType !== nextAppType && appType !== 'none') {
          currentAccountIdVar(persistedAccountIdsVar()?.[nextAppType]);
        }
        // update to new app
        appTypeVar(nextAppType);
        // set GTM data tag for app type
        if (!window.Cypress) {
          dispatchToDataLayer({ dataLayer: { application: nextAppType } });
        }
      } else {
        // else set requestedAppType for persistence
        if (nextAppType !== 'none') {
          requestedAppTypeVar(nextAppType);
        }
        push('/app-select');
      }
      // }
      // stop app loading process
      setLoadingApp(false);
      switchingApplicationsVar(false);
    },
    [isTaxPreparer, loadingApp, checkAppSwitchAllowed, push, appType],
  );

  // handle page title assignment
  useEffect(() => {
    window.document.title =
      appType !== 'none' && getAppDisplayName(appType)
        ? `${getAppTitle()} | ${getAppDisplayName(appType)}`
        : getAppTitle();
  }, [appType, appConfig.appTitle]);

  // clear persisted accounts if user doesn't have permission for appType
  useEffect(() => {
    const persistedValues = Object.values(persistedAccountIdsVar());
    if (
      permittedApplications.length &&
      persistedValues.length > permittedApplications.length
    ) {
      Object.keys(persistedAccountIdsVar())
        .filter((app) => !permittedApplications.includes(app))
        .forEach((app) => {
          persistedAccountIdsVar({
            ...persistedAccountIdsVar(),
            [app]: null,
          });
        });
    }
  }, [appType, permittedApplications]);

  // Handle app initialization / side-effects
  useEffect(() => {
    if (loadingAuth) return;

    // run app selection process for INITIAL LOAD
    if (loadingApp && !appSetRef.current) {
      // check that auth info is populated
      if (isAuthenticated && allowedApplications.length) {
        const appFromClaim = getLedgibleAppIdFromClientId(tokenClientId);
        if (staticAppType && appType !== staticAppType) {
          setApplication(
            staticAppType,
            `/${getAppUrlPathFromId(staticAppType)}`,
          );
        }
        // Push to tax-preparer if they are trying to log in to tax but are a Tax Preparer
        else if (
          appType === 'none' &&
          appFromClaim === 'tax' &&
          allowedApplications.includes('tax-preparer')
        ) {
          setApplication(
            'tax-preparer',
            `/${getAppUrlPathFromId('tax-preparer')}`,
          );
        } else {
          // Otherwise, set application to app in url pathname if valid, else is passed as none
          const hasStoredApp = appType && appInPath === 'none';
          setApplication(hasStoredApp ? appType : appInPath || appFromClaim);
        }
      }
      if (!isAuthenticated) {
        // Otherwise should not be authenticated, so set to app from url path
        setApplication(staticAppType || appInPath);
      }
    }
  }, [
    appInPath,
    appType,
    allowedApplications,
    setApplication,
    loadingAuth,
    loadingApp,
    isAuthenticated,
    tokenClientId,
    staticAppType,
  ]);

  const appManagementValue = useMemo(
    () => ({
      appType,
      displayAppType,
      setApplication,
      loadingApp,
    }),
    [appType, displayAppType, loadingApp, setApplication],
  );

  return (
    <AppManagementContext.Provider value={appManagementValue}>
      {children}
    </AppManagementContext.Provider>
  );
};

export const AppManagementConsumer = AppManagementContext.Consumer;

AppManagementProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.shape()]).isRequired,
};
