// extended component to expand popper styles stolen from MUI and community code
// (mainly RichTooltip)
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, ClickAwayListener, Fade, Paper, Popper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, _params, classes) => ({
  popoverRoot: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: 1000,
    marginTop: 8,
  },
  content: {
    padding: theme.spacing(1),
  },
  popper: {
    zIndex: 2000,
    [`&[data-popper-placement*="bottom"] .${classes.arrow}`]: {
      top: 'auto',
      left: 0,
      marginTop: '-0.71em',
      '&::before': {
        transformOrigin: '0 100%',
      },
    },
    [`&[data-popper-placement*="top"] .${classes.arrow}`]: {
      bottom: 0,
      left: 0,
      marginBottom: '-0.71em',
      '&::before': {
        transformOrigin: '100% 0',
      },
    },
    [`&[data-popper-placement*="right"] .${classes.arrow}`]: {
      left: 0,
      marginLeft: '-0.71em',
      height: '1em',
      width: '0.71em',
      '&::before': {
        transformOrigin: '100% 100%',
      },
    },
    [`&[data-popper-placement*="left"] .${classes.arrow}`]: {
      right: 0,
      marginRight: '-0.71em',
      height: '1em',
      width: '0.71em',
      '&::before': {
        transformOrigin: '0 0',
      },
    },
  },
  arrow: {
    overflow: 'hidden',
    position: 'absolute',
    width: '1em',
    height: '0.71em' /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: 'border-box',
    color: theme.palette.background.paper,
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%',
      boxShadow: theme.shadows[1],
      backgroundColor: 'currentColor',
      transform: 'rotate(45deg)',
    },
  },
}));

const ExtendedPopper = ({
  placement,
  arrow,
  open,
  content,
  children,
  padContent,
  clickAwayHandler,
}) => {
  const { classes } = useStyles();
  const [arrowRef, setArrowRef] = useState(null);
  const [childNode, setChildNode] = useState(null);

  return (
    <div>
      {React.cloneElement(children, { ...children.props, ref: setChildNode })}
      <Popper
        open={open}
        anchorEl={childNode}
        placement={placement}
        transition
        className={classes.popper}
        modifiers={[
          {
            name: 'arrow',
            enabled: arrow,
            options: {
              element: arrowRef,
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              boundariesElement: 'window',
              // rootBoundary: 'viewport',
              // padding: 8,
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={clickAwayHandler}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.popoverRoot}>
                {arrow ? (
                  <span className={classes.arrow} ref={setArrowRef} />
                ) : null}
                <Box className={padContent ? classes.content : null}>
                  {content}
                </Box>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
};

export default ExtendedPopper;

ExtendedPopper.propTypes = {
  content: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  arrow: PropTypes.bool,
  placement: PropTypes.string,
  padContent: PropTypes.bool,
  clickAwayHandler: PropTypes.func.isRequired,
};

ExtendedPopper.defaultProps = {
  open: false,
  onClose: () => null,
  arrow: false,
  placement: 'bottom',
  padContent: true,
};
