// relative imports
import useExternalScript from '../custom-hooks/useExternalScript';
import { getAppIdFromUrl } from '../utilities/appUtilities';

const Beamer = () => {
  window.beamer_config = {
    product_id: 'eMERpsxb47436',
    selector: '#top-nav-notifications-menu-button',
    filter: getAppIdFromUrl(),
    filter_by_url: true,
  };

  useExternalScript('https://app.getbeamer.com/js/beamer-embed.js');

  return null;
};

export default Beamer;
