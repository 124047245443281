// absolute imports
import { createTheme } from '@mui/material/styles';
import Ajv from 'ajv';
import merge from 'ts-deepmerge';
// relative imports
import accountConfigSchema from './validation/account-config-schema.json';
import appConfigSchema from './validation/app-config-schema.json';
import defaultAppConfig from './defaultAppConfig';
import defaultAccountConfig from './defaultAccountConfig';
import ledgibleDefaultThemeOptions from './defaultThemeConfig';

// setup for JSON Schema validation
const ajv = new Ajv();
const validateApp = ajv.compile(appConfigSchema);
const validateAccount = ajv.compile(accountConfigSchema);

// set vars to limit fetch calls
let appConfig;
let accountConfig;
let appTheme;

export const getRemoteAppConfig = async () => {
  if (appConfig) return appConfig;

  const appConfigId = import.meta.env.REACT_APP_CONFIGURATION_SUBTYPE_ID;
  let customAppConfig = {};

  if (appConfigId) {
    try {
      const response = await fetch(`/config/${appConfigId}_app-config.json`);
      customAppConfig = await response.json();
    } catch (e) {
      throw new Error(
        `Error processing custom app configuration for id ${appConfigId} - ${
          e?.message || 'Unknown'
        }`,
      );
    }
  }

  const updatedConfig = merge(defaultAppConfig, customAppConfig);
  const valid = validateApp(updatedConfig);

  if (!valid) {
    throw new Error(
      `Error validating app config - ${JSON.stringify(validateApp.errors)}`,
    );
  }

  appConfig = updatedConfig;
  return appConfig;
};

export const getAppConfig = () =>
  appConfig || (async () => getRemoteAppConfig())();

export const getRemoteAccountConfig = async (configId) => {
  if (accountConfig && !configId) {
    return accountConfig;
  }
  const { accountSubtypeDefault } = await getAppConfig();

  const accountConfigId =
    configId ||
    accountSubtypeDefault ||
    import.meta.env.REACT_APP_ACCOUNT_SUBTYPE_DEFAULT;

  let customAccountConfig = {};

  if (accountConfigId && accountConfigId !== 'default') {
    try {
      const response = await fetch(
        `/config/${accountConfigId}_account-config.json`,
      );

      customAccountConfig = await response.json();
    } catch (e) {
      throw new Error(
        `Error processing custom account configuration for id ${accountConfigId} - ${
          e?.message || 'Unknown'
        }`,
      );
    }
  }

  const updatedConfig = merge(defaultAccountConfig, customAccountConfig);
  const valid = validateAccount(updatedConfig);

  if (!valid) {
    throw new Error(
      `Error validating account schema - ${JSON.stringify(
        validateAccount.errors,
      )}`,
    );
  }

  accountConfig = updatedConfig;
  return updatedConfig;
};

// function to recursively replace matched values in object
export function replaceClientColorValuesInThemeConfig(
  configObj = {},
  colorMap = {},
) {
  const keys = Object.keys(configObj);
  const colorMapArr = Object.keys(colorMap);

  for (let i = 0; i < keys.length; i += 1) {
    const currentVal = configObj[keys[i]];
    if (typeof currentVal === 'object') {
      configObj[keys[i]] = replaceClientColorValuesInThemeConfig(
        configObj[keys[i]],
        colorMap,
      );
    }
    if (typeof currentVal === 'string' && colorMapArr.includes(currentVal)) {
      const colorKeyValue = colorMap[currentVal];
      configObj[keys[i]] = colorKeyValue;
    }
  }

  return configObj;
}

// get white label theme config based on appConfig theme id value
export const getRemoteWhiteLabelThemeConfig = async (themeId) => {
  let customThemeConfig;

  try {
    const response = await fetch(`/config/${themeId}_theme.json`);
    customThemeConfig = await response.json();
  } catch (e) {
    throw new Error(
      `Not config found matching theme ID - ${e?.message || 'Unknown'}`,
    );
  }

  const { colorVars, ...overrides } = customThemeConfig;
  return replaceClientColorValuesInThemeConfig(overrides, colorVars);
};

export const getRemoteAppTheme = async () => {
  if (appTheme) return appTheme;

  let themeOptions = ledgibleDefaultThemeOptions;
  const { themeId } = await getAppConfig();

  if (themeId && themeId !== 'default') {
    try {
      const customThemeOptions = await getRemoteWhiteLabelThemeConfig(themeId);
      themeOptions = merge(themeOptions, customThemeOptions);
    } catch (e) {
      throw new Error(
        `Unable to load custom theme options for themeId: ${themeId} - ${
          e?.message || 'Unknown'
        }`,
      );
    }
  }
  // TODO -> validate if a JSON schema gets finalized for theme structure before returning
  appTheme = createTheme(themeOptions);
  return appTheme;
};

export const getAppTheme = () =>
  appTheme || (async () => getRemoteAppTheme())();

// used for chart colors
export const chartColors = [
  // '#332288',
  '#6699CC',
  '#88CCEE',
  '#44AA99',
  '#117733',
  '#999933',
  '#DDCC77',
  '#661100',
  '#CC6677',
  '#AA4466',
  '#5FBD71',
  '#143945',
];

export const chartColorsAlt = [
  '#A6CEE3',
  '#FB9A99',
  '#33A02C',
  '#B2DF8A',
  '#1F78B4',
  '#E1CAF1',
  '#AC6AD8',
  '#FFD86F',
  '#89F0FF',
  '#FFF19B',
];
