// import external modules
import {
  useState,
  useEffect,
  useMemo,
  useReducer,
  useCallback,
  useRef,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import {
  Avatar,
  Box,
  ButtonBase,
  Drawer,
  InputAdornment,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@mui/material';
import { FixedSizeList } from 'react-window';
import _isEqual from 'lodash/isEqual';

// import svg icons
import {
  Add as AddIcon,
  ArrowDropDown,
  ArrowDropUp,
  Close as CloseIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  FilterAlt as FilterIcon,
  Search as SearchIcon,
} from '@mui/icons-material';

// relative imports
import NoAccountCard from '../no-account-card/NoAccountCard';
import { createInitials } from '../../../shared/utilities/stringUtilities';
import { useAccount } from '../../../common/providers/AccountProvider';
import useAlternativeDisplay from '../../../shared/custom-hooks/useAlternativeDisplay';
import { useModalActions } from '../../../common/providers/ModalProvider';
import AutoCompleteTextField from '../../../shared/custom-components/AutoCompleteTextField';
import LedgibleButton from '../../../shared/custom-components/buttons/LedgibleButton';
import ActiveAccountIndicator from './ActiveAccountIndicator';
import { mapColor } from '../../../shared/utilities/styleUtilities';
import { creatingAccountVar } from '../../../../data/apollo/cache/reactiveVars';
import {
  getSubtypeFilterFieldFromConfig,
  getSecondaryAccountLabel,
} from '../../../../data/app-config/configs/subtypeField-utilities';
import { getAppUrlPathFromId } from '../../../shared/utilities/appUtilities';

const useStyles = makeStyles()((theme, { isMobile }) => ({
  windowWrapper: {
    display: 'flex',
    height: '100%',
    borderLeft: `${isMobile ? 0 : 1}px solid ${theme.palette.grey[200]}`,
    paddingLeft: isMobile ? 0 : theme.spacing(2),
    marginLeft: isMobile ? 0 : theme.spacing(3),
    alignItems: 'center',
  },
  accountListSecondary: {
    fontStyle: 'italic',
  },
  accountButton: {
    maxWidth: 180,
    minWidth: 100,
    maxHeight: 32,
    borderRadius: 4,
    backgroundColor:
      theme.whiteLabel.AccountBox.color || 'rgba(255, 255, 255, 0.1)',
    color: theme.whiteLabel.AccountBox.textColor || 'inherit',
    padding: theme.spacing(0.25, 1),
  },
  accountButtonListText: {
    color:
      theme.whiteLabel.HeaderText.color || isMobile
        ? undefined
        : theme.palette.common.white,
  },
  dropdownIcon: {
    marginLeft: theme.spacing(0.75),
    color:
      theme.whiteLabel.HeaderText.color || isMobile
        ? undefined
        : theme.palette.common.white,
  },
  headerText: {
    color:
      theme.whiteLabel.AccountWindow.headerTextColor ||
      theme.palette.common.white,
    fontSize: theme.typography.pxToRem(24),
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tealText: {
    color: theme.palette.primary.main,
  },
  drawerHeader: {
    padding: theme.spacing(2),
    background:
      theme.whiteLabel.AccountWindow.color || theme.palette.primary.main,
  },
  accountAvatar: {
    marginRight: 10,
    color: '#fff',
  },
  activeAccountButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  displaySet: {
    display: 'block',
  },
  searchBox: {
    flexGrow: 1,
  },
  selectField: {
    marginTop: 0,
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
    },
  },
  searchAdornment: {
    color: theme.palette.grey[400],
  },
  searchAdornmentActive: {
    color: theme.palette.secondary.light,
  },
  filterButton: {
    borderRadius: 25,
  },
  filterInputs: {
    backgroundColor: 'white',
  },
  filterDiv: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
}));

/* reducer default account state */
function heightsReducer(state, [element, value]) {
  switch (element) {
    case 'drawer':
      return { ...state, drawer: value };
    case 'search':
      return { ...state, search: value };
    case 'filters':
      return { ...state, filters: value };
    case 'header':
      return { ...state, header: value };
    default:
      return state;
  }
}

const filterCallback = (state, filterKey, filterValue) => ({
  ...state,
  [filterKey]: filterValue,
});

/* reducer account filter state */
function filtersReducer(state, [filter, value]) {
  if (filter) {
    const newState = filterCallback(state, filter, value);
    return newState;
  }
  return state;
}

const AccountWindow = () => {
  const { isMobile } = useAlternativeDisplay();
  const { classes, cx } = useStyles({ isMobile });
  const { showModal } = useModalActions();
  const {
    currentAccount,
    accounts,
    selectAccount,
    clientPermissions: permissions,
  } = useAccount();

  const {
    config: {
      accountSelection: {
        availableFilters,
        enableFiltering,
        secondaryLabelFieldId,
      } = {},
    } = {},
  } = currentAccount;

  const defaultFilters = useMemo(() => {
    // set defaults for subtype fields dynamically
    const filters = { accountSort: 'asc' };
    if (availableFilters && availableFilters.length) {
      availableFilters.forEach((filter) => {
        filters[filter.id] = filter.defaultValue;
      });
    }
    // return filter defaults objects
    return filters;
  }, [availableFilters]);

  const [filteredAccounts, setFilteredAccounts] = useState(accounts);
  const [accountName, setAccountName] = useState(null);
  const [accountNameInputValue, setAccountNameInputValue] = useState('');
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);

  // state for ref'ed heights
  const [heights, dispatchHeights] = useReducer(heightsReducer, {
    drawer: 0,
    header: 0,
    filters: 0,
    search: 0,
    addButton: 0,
  });
  // filter state for accounts with subtype enabled
  const titleRef = useRef(null);
  const [appliedFilters, setAppliedFilters] = useState(defaultFilters);
  const [selectedFilters, dispatchSelected] = useReducer(
    filtersReducer,
    defaultFilters,
  );
  const [showFilters, setShowFilters] = useState(false);
  // static heights
  const LIST_ITEM_HEIGHT = 75;
  const canCreateAccount = currentAccount.accountingAdd || false;

  // handle open/close of account dropdown
  const handleDropdownClick = (e) => {
    if (dropdownAnchorEl) {
      setAccountDropdownOpen(false);
      setDropdownAnchorEl(null);
    } else {
      setDropdownAnchorEl(e.currentTarget);
      setAccountDropdownOpen(true);
    }
  };

  // callback ref for height management
  const measuredRef = useCallback((node) => {
    if (node !== null) {
      let elementId;
      if (node.id === 'account-window-search') {
        elementId = 'search';
      }
      if (node.id === 'account-window-filters') {
        elementId = 'filters';
      }
      if (node.id === 'account-window-drawer') {
        elementId = 'drawer';
      }
      if (node.id === 'account-window-header') {
        elementId = 'header';
        // set ref for drawer for popper
        titleRef.current = node;
      }
      if (elementId) {
        // set height of element
        dispatchHeights([elementId, node.getBoundingClientRect().height]);
      }
    }
  }, []);

  const toggleDrawer = () => {
    setAccountMenuOpen(!accountMenuOpen);
    if (accountMenuOpen && showFilters) {
      setShowFilters(false);
    }
  };

  const handleApplyFilters = () => {
    setShowFilters(false);
    setAppliedFilters(selectedFilters);
  };

  const handleResetFilters = () => {
    setShowFilters(false);
    setAppliedFilters(defaultFilters);
    dispatchSelected(['default']);
  };

  const chooseAccount = (accountId) => {
    selectAccount(accountId);
    toggleDrawer();
  };

  const handleNameFilter = (e, value) => {
    setAccountName(value);
  };

  const isDefaultFilter = useMemo(() => {
    if (_isEqual(defaultFilters, appliedFilters)) return true;
    return false;
  }, [appliedFilters, defaultFilters]);

  // update displayed list based on selection
  useEffect(() => {
    let visibleAccounts = accounts
      .filter((account) => {
        if (accountName && accountName !== account.name) return false;
        return true;
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    if (appliedFilters.accountSort === 'desc') {
      visibleAccounts = visibleAccounts.reverse();
    }

    const subtypeFilters = Object.keys(appliedFilters).filter(
      (key) => key !== 'accountSort',
    );

    subtypeFilters.forEach((stFilter) => {
      if (appliedFilters[stFilter] !== defaultFilters[stFilter]) {
        visibleAccounts = visibleAccounts.filter(
          (account) =>
            account.subtypeFields?.[stFilter] === appliedFilters[stFilter],
        );
      }
    });

    setFilteredAccounts(visibleAccounts);
  }, [accounts, accountName, appliedFilters, defaultFilters]);

  // set height of list to properly size window for virtualization
  const listHeight = useMemo(() => {
    const nonListHeight =
      heights.search +
      heights.header +
      heights.addButton +
      (showFilters ? heights.filters : 0);
    const heightDifference = heights.drawer - nonListHeight - 1;
    return heightDifference || 0;
  }, [heights, showFilters]);

  // define wallet row render function for virtualization
  // eslint-disable-next-line react/no-unstable-nested-components
  const VirtualizedAccountRow = ({ index, style }) => {
    const account = filteredAccounts[index];
    return (
      <ListItemButton
        key={account.id}
        style={style}
        selected={account.id === currentAccount.id}
        onClick={() => chooseAccount(account.id)}
        component={Link}
        to={`/${getAppUrlPathFromId('accounting')}`}
        classes={{
          selected: classes.activeAccountButton,
        }}
        divider
      >
        <Avatar
          classes={{
            root: classes.accountAvatar,
          }}
          style={{
            backgroundColor: mapColor(account.name, 90, 40),
          }}
        >
          {createInitials(account.name)}
        </Avatar>
        <ListItemText
          primary={account.name}
          primaryTypographyProps={{
            noWrap: true,
            className: classes.displaySet,
          }}
          secondary={
            secondaryLabelFieldId
              ? getSecondaryAccountLabel(account, secondaryLabelFieldId)
              : null
          }
          secondaryTypographyProps={{
            noWrap: true,
            variant: 'body2',
            className: classes.accountListSecondary,
          }}
        />
        {account.id === currentAccount.id && (
          <ListItemIcon className={classes.tealText}>
            <ActiveAccountIndicator
              circleSize={10}
              marginRight={0.5}
              text="current"
            />
          </ListItemIcon>
        )}
      </ListItemButton>
    );
  };

  VirtualizedAccountRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.shape().isRequired,
  };

  // Account List return
  return (
    <div className={classes.windowWrapper}>
      <ButtonBase
        onClick={toggleDrawer}
        className={cx('accounts-window', classes.accountButton)}
      >
        <ActiveAccountIndicator circleSize={8} />
        <ListItemText
          primary={currentAccount.id && currentAccount.name}
          classes={{
            root: classes.accountButtonListText,
          }}
          primaryTypographyProps={{
            variant: 'body1',
            noWrap: true,
            color: 'inherit',
          }}
        />
        {accountMenuOpen && (
          <ArrowDropUp className={classes.dropdownIcon} fontSize="small" />
        )}
        {!accountMenuOpen && (
          <ArrowDropDown className={classes.dropdownIcon} fontSize="small" />
        )}
      </ButtonBase>
      <Drawer
        anchor="left"
        variant="temporary"
        open={accountMenuOpen}
        onClose={toggleDrawer}
        id="account-window-drawer"
        ref={measuredRef}
      >
        <Box
          className={classes.drawerHeader}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          id="account-window-header"
          ref={measuredRef}
        >
          <span
            className={classes.headerText}
            aria-label="toggle account menu"
            onClick={handleDropdownClick}
            role="button"
            id="account-window-actions-menu"
            tabIndex={0}
            onKeyDown={handleDropdownClick}
          >
            Accounts
            {accountDropdownOpen && (
              <ArrowDropUp className={classes.dropdownIcon} fontSize="medium" />
            )}
            {!accountDropdownOpen && (
              <ArrowDropDown
                className={classes.dropdownIcon}
                fontSize="medium"
              />
            )}
          </span>
          <Menu
            id="account-dropdown-menu"
            anchorEl={dropdownAnchorEl}
            open={accountDropdownOpen}
            onClose={handleDropdownClick}
          >
            {permissions && permissions.accountAdd && canCreateAccount && (
              <MenuItem
                onClick={() => {
                  handleDropdownClick();
                  creatingAccountVar(true);
                }}
                id="create-account-button-add"
              >
                <ListItemIcon>
                  <AddIcon fontSize="medium" />
                </ListItemIcon>
                <ListItemText
                  primary="Add New Account"
                  primaryTypographyProps={{
                    noWrap: true,
                    color: 'inherit',
                  }}
                />
              </MenuItem>
            )}
            {(!canCreateAccount ||
              (permissions && !permissions.accountAdd && canCreateAccount)) && (
              <MenuItem
                onClick={() => {
                  handleDropdownClick();
                  showModal('CREATE_ACCOUNTING_ACCOUNT', {
                    toggleDrawer,
                    canCreateAccount,
                  });
                }}
                id="create-account-button-contact"
              >
                <ListItemIcon>
                  <AddIcon fontSize="medium" />
                </ListItemIcon>
                <ListItemText
                  primary="Add New Account"
                  primaryTypographyProps={{
                    noWrap: true,
                    color: 'inherit',
                  }}
                />
              </MenuItem>
            )}
            {isMobile && (
              <MenuItem onClick={toggleDrawer}>
                <ListItemIcon>
                  <CloseIcon fontSize="medium" />
                </ListItemIcon>
                <ListItemText
                  primary="Close"
                  primaryTypographyProps={{
                    noWrap: true,
                    color: 'inherit',
                  }}
                />
              </MenuItem>
            )}
          </Menu>
          {enableFiltering && (
            <LedgibleButton
              id="search-accounts-type-filter-btn"
              variant="outlined"
              color="white"
              className={classes.filterButton}
              aria-label="Account Filters"
              onClick={() => setShowFilters((current) => !current)}
              textTransformOff
              startIcon={
                <FilterIcon
                  size="small"
                  className={
                    !isDefaultFilter ? classes.searchAdornmentActive : null
                  }
                />
              }
              endIcon={
                showFilters ? (
                  <ExpandLessIcon size="small" />
                ) : (
                  <ExpandMoreIcon size="small" />
                )
              }
            >
              Filter
            </LedgibleButton>
          )}
        </Box>
        {showFilters && (
          <div
            id="account-window-filters"
            className={classes.filterDiv}
            ref={measuredRef}
          >
            <TextField
              id="account-sort-input"
              select
              label="Sort By"
              value={selectedFilters.accountSort}
              onChange={(e) =>
                dispatchSelected(['accountSort', e.target.value])
              }
              name="accountSort"
              variant="outlined"
              fullWidth
              margin="dense"
              SelectProps={{
                className: classes.filterInputs,
              }}
            >
              <MenuItem value="asc">Account Name: A - Z</MenuItem>
              <MenuItem value="desc">Account Name: Z - A</MenuItem>
            </TextField>
            {availableFilters.map((filter) => (
              <Fragment key={filter.id}>
                {getSubtypeFilterFieldFromConfig(filter.id, {
                  id: `account-${filter.id}-filter`,
                  variant: 'outlined',
                  SelectProps: {
                    className: classes.filterInputs,
                  },
                  value: selectedFilters[filter.id],
                  onChange: (e) =>
                    dispatchSelected([filter.id, e.target.value]),
                })}
              </Fragment>
            ))}
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <LedgibleButton
                id="account-filter-clear-button"
                size="small"
                color="secondary"
                variant="outlined"
                textTransformOff
                onClick={handleResetFilters}
                marginTop={8}
                marginRight={16}
              >
                Clear
              </LedgibleButton>
              <LedgibleButton
                id="account-filter-apply-button"
                size="small"
                variant="contained"
                color="primary"
                textTransformOff
                onClick={handleApplyFilters}
                marginTop={8}
              >
                Apply
              </LedgibleButton>
            </Box>
          </div>
        )}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="stretch"
          id="account-window-search"
          ref={measuredRef}
        >
          <AutoCompleteTextField
            virtualize
            value={accountName}
            inputValue={accountNameInputValue}
            optionsList={accounts.map(({ name }) => name)}
            handleChange={handleNameFilter}
            handleInputChange={(event, newInputValue) => {
              setAccountNameInputValue(newInputValue);
            }}
            isOptionEqualToValue={(option, value) => option === value}
            TextFieldProps={{
              variant: 'outlined',
              placeholder: 'Search Accounts...',
              className: classes.selectField,
              margin: 'none',
              InputProps: {
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={classes.searchAdornment}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              },
            }}
            className={classes.searchBox}
            inputProps={{
              name: 'accountName',
              id: 'accountName',
            }}
          />
        </Box>
        {accounts.length > 0 && (
          <div>
            <FixedSizeList
              height={listHeight}
              width={window.innerWidth > 450 ? 450 : window.innerWidth}
              itemSize={LIST_ITEM_HEIGHT}
              itemCount={filteredAccounts.length}
            >
              {VirtualizedAccountRow}
            </FixedSizeList>
          </div>
        )}
        {accounts.length === 0 && (
          <NoAccountCard
            addAccount={permissions && permissions.accountAdd}
            toggleDrawer={toggleDrawer}
          />
        )}
      </Drawer>
    </div>
  );
};

export default AccountWindow;
