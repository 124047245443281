// absolute imports
import {
  useContext,
  createContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

// relative imports
import { getRemoteAccountConfig } from '../../../data/app-config/configs/configUtilities';

/* ENVs related to App Config:
  REACT_APP_CONFIGURATION_SUBTYPE_ID -> determines app style configuration
  REACT_APP_ACCOUNT_SUBTYPE_DEFAULT -> default setting for account subtype configuration, used if no app config present
*/

export const ConfigContext = createContext();
export const useConfig = () => useContext(ConfigContext);

export const ConfigProvider = ({ children, appConfig, accountConfig }) => {
  const [accountConfigId, setAccountConfigId] = useState();
  const [accountConfiguration, setAccountConfiguration] =
    useState(accountConfig);

  const handleSetAccountConfigId = useCallback((newId) => {
    setAccountConfigId(newId);
  }, []);

  useEffect(() => {
    const getAccountConfig = async () => {
      const config = await getRemoteAccountConfig(accountConfigId);
      setAccountConfiguration(config);
    };
    if (accountConfigId && accountConfigId !== accountConfiguration.id) {
      getAccountConfig();
    }
  }, [accountConfigId, accountConfiguration?.id]);

  const configValue = useMemo(
    () => ({
      appConfig,
      accountConfig: accountConfiguration,
      handleSetAccountConfigId,
    }),
    [appConfig, accountConfiguration, handleSetAccountConfigId],
  );

  return (
    <ConfigContext.Provider value={configValue}>
      {children}
    </ConfigContext.Provider>
  );
};

ConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
  appConfig: PropTypes.shape().isRequired,
  accountConfig: PropTypes.shape().isRequired,
};
