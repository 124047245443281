import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

// relative imports
import AddTirAdminModal from './AddTirAdminModal';
import { ALL_USERS_QUERY } from '../../../../data/apollo/queries';
import { useAccount } from '../../../common/providers/AccountProvider';
import { ADD_PREPARER_TEAM_MEMBER } from '../../../../data/apollo/mutations';

const AddTirAdminModalContainer = ({ currentNumber, hideModal }) => {
  const [isOver, setIsOver] = useState(false);
  const { currentAccount } = useAccount();

  useEffect(() => {
    if (currentNumber >= currentAccount.plan.numberOfUsers) {
      setIsOver(true);
    }
  }, [currentNumber, currentAccount.plan.numberOfUsers]);

  const [addTaxPreparer, { data, loading }] = useMutation(
    ADD_PREPARER_TEAM_MEMBER,
    {
      refetchQueries: () => [
        {
          query: ALL_USERS_QUERY,
          variables: {
            accountId: currentAccount.id,
          },
        },
      ],
      awaitRefetchQueries: true,
    },
  );

  return (
    <AddTirAdminModal
      submitUser={(values) => {
        addTaxPreparer({
          variables: {
            email: values.email,
            accountId: currentAccount.id,
            permission: 'admin', // values.permission,
            tirAdmin: true,
          },
        });
      }}
      loading={loading}
      newUserAdded={
        data && data.createTaxPreparer ? data.createTaxPreparer.type : null
      }
      newUserToken={
        data && data.createTaxPreparer ? data.createTaxPreparer.token : null
      }
      hideModal={hideModal}
      goesOver={isOver}
    />
  );
};

export default AddTirAdminModalContainer;

AddTirAdminModalContainer.propTypes = {
  currentNumber: PropTypes.number.isRequired,
  hideModal: PropTypes.func.isRequired,
};
