// absolute imports
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Field } from 'react-final-form';
import {
  CardActions,
  CardContent,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// icon imports
import {
  ArrowRightRounded as ArrowRightIcon,
  ArrowLeftRounded as ArrowLeftIcon,
} from '@mui/icons-material';

// relative imports
import Loader from '../../../../../shared/custom-components/Loader';
import { FormTextField } from '../../../../../shared/final-form-field-wrappers';
import {
  requiredField,
  composeValidators,
  invalidSelection,
} from '../../../../../shared/utilities/formValidations';
import { ACCOUNT_SUBTYPES } from '../../../../../../data/apollo/queries';
import LedgibleButton from '../../../../../shared/custom-components/buttons/LedgibleButton';
import { useConfig } from '../../../../providers/ConfigProvider';

const useStyles = makeStyles()((theme) => ({
  cardContent: {
    margin: theme.spacing(2, 1),
  },
  headerText: {
    marginBottom: theme.spacing(2),
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const SubtypeSelectStep = ({ previousPage }) => {
  const { appConfig } = useConfig();
  const { classes } = useStyles();

  // query for subtype options based on ENV var
  const {
    data: { getSubtypes: availableSubtypes = [] } = {},
    loading: loadingSubtypes,
  } = useQuery(ACCOUNT_SUBTYPES, {
    variables: {
      tag: appConfig.accountSubtypeTag,
    },
    fetchPolicy: 'cache-first',
  });

  return (
    <>
      <CardContent
        classes={{
          root: classes.cardContent,
        }}
      >
        <Typography
          className={classes.headerText}
          color="secondary"
          gutterBottom
          variant="h6"
          align="center"
        >
          Account Type
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="subtitle1">
          Please choose from the following account types based on your needs for
          this account.
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={12}>
            {loadingSubtypes && <Loader />}
            {!loadingSubtypes && (
              <Field
                select
                label="Account Type"
                name="subtype"
                id="add-account-subtype"
                data-cy="add-account-subtype"
                component={FormTextField}
                InputLabelProps={{ shrink: true }}
                fullWidth
                margin="dense"
                variant="outlined"
                validate={composeValidators(
                  requiredField.generic,
                  invalidSelection,
                )}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (value) => {
                    if (value === '')
                      return (
                        <span style={{ color: 'rgba(0, 0, 0, 0.35)' }}>
                          Select account type...
                        </span>
                      );
                    return availableSubtypes?.find((st) => st.id === value)
                      .displayName;
                  },
                }}
              >
                {availableSubtypes.map((subtype) => (
                  <MenuItem key={subtype.id} value={subtype.id}>
                    {subtype.displayName}
                  </MenuItem>
                ))}
              </Field>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.actionRow} disableSpacing>
        <LedgibleButton
          variant="outlined"
          size="large"
          color="secondary"
          textTransformOff
          onClick={previousPage}
          startIcon={<ArrowLeftIcon />}
          id="create-account-subtype-select-step-previous"
        >
          Go Back
        </LedgibleButton>
        <LedgibleButton
          variant="contained"
          size="large"
          color="success"
          textTransformOff
          endIcon={<ArrowRightIcon />}
          id="create-account-subtype-select-step-continue"
          data-cy="create-account-subtype-select-step-continue"
          type="submit"
        >
          Continue
        </LedgibleButton>
      </CardActions>
    </>
  );
};

export default SubtypeSelectStep;

SubtypeSelectStep.propTypes = {
  previousPage: PropTypes.func,
};

SubtypeSelectStep.defaultProps = {
  previousPage: () => null,
};
