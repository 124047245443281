import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Field } from 'react-final-form';
import { getDate } from '../utilities/dateUtilities';

export const FormTimeFieldComponent = ({
  input,
  meta,
  TimePickerProps = {},
}) => {
  const { onChange, value, ...inputPropsRest } = input;
  const [error, setError] = useState(null);
  const { dirty, active, error: fieldError } = meta;

  const { label, slotProps = {}, ...timePickerPropsRest } = TimePickerProps;
  const { textField = {}, ...slotPropsRest } = slotProps;
  const {
    FormHelperTextProps = {},
    InputProps = {},
    InputLabelProps = {},
    ...textFieldRest
  } = textField;

  useEffect(() => {
    if (fieldError) {
      setError(fieldError);
    }
  }, [fieldError]);

  const errorMessage = useMemo(() => {
    const errMsg = fieldError || error;

    switch (errMsg) {
      case 'maxDate':
        return 'Time is beyond maximum';
      case 'minDate': {
        return 'Time is before minimum';
      }
      case 'disableFuture':
        return 'Time cannot be in future';
      case 'disablePast':
        return 'Time cannot be in past';
      case 'invalidDate': {
        return 'Invalid time';
      }
      default: {
        return fieldError || null;
      }
    }
  }, [error, fieldError]);

  return (
    <TimePicker
      label={label}
      id={`${label}-form-field`}
      value={value || null}
      onChange={onChange}
      onError={(errorMsg) => setError(errorMsg)}
      slotProps={{
        openPickerButton: {
          'aria-label': 'change time',
          ...slotProps?.openPickerButton,
        },
        textField: ({
          InputProps: InputPropsDefault,
          InputLabelProps: InputLabelPropsDefault,
        }) => ({
          variant: 'standard',
          fullWidth: true,
          FormHelperTextProps: {
            style: {
              whiteSpace: 'pre-line',
            },
            ...FormHelperTextProps,
          },
          sx: {
            marginTop: '8px',
            marginBottom: '4px',
          },
          inputProps: inputPropsRest,
          InputProps: {
            ...InputPropsDefault,
            ...InputProps,
          },
          InputLabelProps: {
            ...InputLabelPropsDefault,
            ...InputLabelProps,
          },
          error: !active && dirty && !!error,
          helperText: !active && dirty && errorMessage,
          ...textFieldRest,
        }),
        ...slotPropsRest,
      }}
      {...timePickerPropsRest}
    />
  );
};

FormTimeFieldComponent.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  TimePickerProps: PropTypes.shape(),
};

FormTimeFieldComponent.defaultProps = {
  TimePickerProps: {},
};

export const FormTimeField = (props) => {
  const { name, type, TimePickerProps, ...fieldProps } = props;

  return (
    <Field
      name={name}
      type={type}
      component={FormTimeFieldComponent}
      // arg is datetime value from input
      parse={(value) => value?.toUTC().toString() || null}
      // arg is string value from form fields
      format={(value) => (value ? getDate(value) : value)}
      TimePickerProps={TimePickerProps}
      {...fieldProps}
    />
  );
};

FormTimeField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  TimePickerProps: PropTypes.shape(),
};

FormTimeField.defaultProps = {
  type: null,
  TimePickerProps: {},
};

export default FormTimeField;
