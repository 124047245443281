import { SvgIcon } from '@mui/material';

const ErrorFoundIcon = (props) => (
  <SvgIcon {...props}>
    <g
      id="App-Assets"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons"
        transform="translate(-824.000000, -52.000000)"
        fillRule="nonzero"
      >
        <g id="no-results" transform="translate(824.371075, 52.000000)">
          <path
            d="M21.9548585,2.60141806 L21.9548585,19.531612 L21.9548585,19.8824709 C21.9548585,20.4540169 21.4916873,20.9171881 20.9201412,20.9171881 L1.5510446,20.4572044 C0.979498565,20.4572044 0.516327369,19.9940332 0.516327369,19.4224872 L0.411233773,2.5751681 L11.2570148,1.88357537 L21.9548585,2.60141806 Z"
            id="Path"
            fill="#FECE85"
          />
          <path
            d="M21.9548585,1.03471723 L21.9548585,2.60141806 L0.156187271,2.60141806 L0.138702922,1.18152952 C0.138702922,0.609983481 0.510280503,0.147609159 1.08182654,0.147609159 L1.48452908,0 L20.9201412,0 C21.4916873,0 21.9548585,0.463171197 21.9548585,1.03471723 Z"
            id="Path"
            fill="#918291"
          />
          <path
            d="M1.97938773,19.531612 C1.70610688,19.531612 1.48452908,19.3100811 1.48452908,19.0367534 L1.48452908,2.60141806 L0.80587382,2.24690296 L-1.33226568e-15,2.60141806 L-1.33226568e-15,19.8824709 C-1.33226568e-15,20.4539232 0.463264946,20.9171881 1.03471723,20.9171881 L20.4436576,20.9171881 C20.3861889,20.4376107 20.2647359,19.9639395 20.0626112,19.531612 L1.97938773,19.531612 Z"
            id="Path"
            fill="#FDB97E"
          />
          <path
            d="M1.48452908,-1.6653321e-16 L1.48452908,2.60141806 L-1.33226568e-15,2.60141806 L-1.33226568e-15,1.03471723 C-1.33226568e-15,0.463171197 0.463171197,-1.6653321e-16 1.03471723,-1.6653321e-16 L1.48452908,-1.6653321e-16 Z"
            id="Path"
            fill="#7A6D79"
          />
          <g id="Group" transform="translate(2.687152, 0.929530)">
            <path
              d="M0.371390081,1 C0.166452881,1 6.66132839e-16,0.776142965 6.66132839e-16,0.5 C6.66132839e-16,0.223857035 0.165937257,0 0.370874457,0 L0.371343206,0 C0.576327281,0 0.742498912,0.223857035 0.742498912,0.5 C0.742498912,0.776142965 0.576374156,1 0.371390081,1 L0.371390081,1 Z"
              id="Path"
              fill="#FE99A0"
            />
            <path
              d="M1.92393468,1 C1.71899748,1 1.5525446,0.776142965 1.5525446,0.5 C1.5525446,0.223857035 1.71848186,0 1.92341906,0 L1.92388781,0 C2.12887188,0 2.29504351,0.223857035 2.29504351,0.5 C2.29504351,0.776142965 2.12891876,1 1.92393468,1 L1.92393468,1 Z"
              id="Path"
              fill="#FECE85"
            />
            <path
              d="M3.47647928,1 C3.27154208,1 3.1050892,0.776142965 3.1050892,0.5 C3.1050892,0.223857035 3.27102646,0 3.47596366,0 L3.47643241,0 C3.68141648,0 3.84758811,0.223857035 3.84758811,0.5 C3.84758811,0.776142965 3.68146336,1 3.47647928,1 Z"
              id="Path"
              fill="#BBF49B"
            />
          </g>
          <circle
            id="Oval"
            fill="#DFEEF4"
            cx="10.9775464"
            cy="11.7515453"
            r="5.71527288"
          />
          <ellipse
            id="Oval"
            fill="#F2FBFF"
            transform="translate(10.976846, 11.749819) rotate(-79.105817) translate(-10.976846, -11.749819) "
            cx="10.9768459"
            cy="11.749819"
            rx="4.33087235"
            ry="4.33082547"
          />
          <polygon
            id="Path"
            fill="#7A6D79"
            transform="translate(17.857012, 18.577658) rotate(-45.000000) translate(-17.857012, -18.577658) "
            points="16.9259115 17.0647173 18.7881119 17.0647173 18.7881119 20.0905996 16.9259115 20.0905996"
          />
          <path
            d="M17.8029583,20.0421269 L18.2761607,19.4395965 L19.3390029,18.3774106 C19.540237,18.1763171 19.8665334,18.1763171 20.0677675,18.3774106 L22.7597948,21.0883754 C23.2492159,21.5775153 23.3779345,22.2906236 23.1459505,22.8975602 C23.1459505,22.8975602 22.8432322,23.2542316 22.6685762,23.4287938 L22.6582637,23.4391063 C21.9941865,24.1028084 21.0064849,23.9281524 20.3423608,23.2644503 L17.8029114,20.7704227 C17.6017242,20.5693292 17.6017242,20.2432203 17.8029583,20.0421269 L17.8029583,20.0421269 Z"
            id="Path"
            fill="#918291"
          />
          <path
            d="M13.6853237,17.3828652 L16.7573661,14.3126978 L17.0948187,15.1270091 L17.9178488,15.4724773 L17.9980986,15.5526803 C18.4577073,16.0120078 18.4577073,16.7569911 17.9980986,17.2163185 L16.5873976,18.6261758 L16.2660699,18.6641445 C15.8068831,19.1230501 15.2512277,19.0043159 14.791994,18.5453635 L13.6853237,17.3828652 Z"
            id="Path"
            fill="#F2FBFF"
          />
          <path
            d="M23.1459505,22.8975602 C23.06331,23.1143099 22.9346383,23.3171846 22.7599823,23.4918875 L22.7495761,23.5022937 C22.0854989,24.1659021 21.008688,24.1659021 20.3446108,23.5022937 L17.6526304,20.7910477 C17.4512557,20.5901417 17.4512557,20.2640328 17.6526304,20.0626112 L18.2761607,19.4395965 L21.3343281,22.5125764 C21.824218,23.0019976 22.5383107,23.1306224 23.1459505,22.8975602 L23.1459505,22.8975602 Z"
            id="Path"
            fill="#7A6D79"
          />
          <path
            d="M15.9859922,18.0359267 L16.587257,18.6260352 L16.449679,18.7636131 C15.9905391,19.2227531 15.2461183,19.2227531 14.7870252,18.7636131 L14.7078535,18.6844414 L13.5454489,17.5230212 L14.4950413,16.5734288 L16.7575067,14.3124634 L17.9179425,15.4723836 C17.432787,16.3731791 16.7769129,17.1692561 15.9942891,17.816552 C15.926836,17.8722863 15.9235548,17.9746612 15.9859922,18.0359267 L15.9859922,18.0359267 Z"
            id="Path"
            fill="#DFEEF4"
          />
          <path
            d="M10.9775464,4.76933676 C9.27401767,4.76933676 7.71289495,5.378664 6.50047485,6.39111564 C6.21275652,6.66191212 5.96999125,6.93317734 5.71705413,7.22797379 C4.66911816,8.44911262 4.25347814,10.0166572 4.25347814,11.7515922 C4.25347814,15.6078521 7.11898957,18.2266139 10.9775464,18.2266139 C13.1347308,18.2266139 14.8941344,17.737099 16.3447729,16.2211169 C17.3555371,15.010103 17.9639737,13.4519334 17.9639737,11.7515922 C17.9639737,7.89528531 14.8361501,4.76933676 10.9775464,4.76933676 Z M10.9775464,16.9235377 C8.11930061,16.9235377 5.80241338,14.6080099 5.80241338,11.7515453 C5.80241338,8.89508072 8.11930061,6.57955286 10.9775464,6.57955286 C13.8357922,6.57955286 16.1526795,8.89503385 16.1526795,11.7515453 C16.1526795,14.6080567 13.8357922,16.9235377 10.9775464,16.9235377 Z"
            id="Shape"
            fill="#918291"
          />
          <path
            d="M16.3447729,16.22107 C15.0636342,17.7570677 13.1346839,18.7338944 10.9776402,18.7338944 C7.11880207,18.7338944 3.99088478,15.6079459 3.99088478,11.7515922 C3.99088478,9.59750157 4.9667271,7.67159814 6.5007561,6.39139689 C5.48975758,7.60227011 4.88160222,9.16058033 4.88160222,10.8608747 C4.88160222,14.7172284 8.00951952,17.843177 11.8683576,17.843177 C13.5711832,17.8432239 15.1323997,17.2340373 16.3447729,16.22107 L16.3447729,16.22107 Z"
            id="Path"
            fill="#7A6D79"
          />
          <path
            d="M12.1453885,11.681561 L13.8413235,9.98665725 C13.962542,9.86548555 13.962542,9.66898584 13.8413235,9.54781414 L13.1826369,8.88954948 C13.0613715,8.76833091 12.864778,8.76833091 12.7435126,8.88954948 L11.0474838,10.584547 C11.0088589,10.6231719 10.9461871,10.6231719 10.9075621,10.584547 L9.21153338,8.88954948 C9.09026793,8.76833091 8.89367447,8.76833091 8.77240902,8.88954948 L8.11372249,9.54781414 C7.99250392,9.66898584 7.99250392,9.86548555 8.11372249,9.98665725 L9.80965751,11.681561 C9.84832932,11.7202328 9.84832932,11.7829046 9.80965751,11.8215764 L8.11372249,13.5164802 C7.99250392,13.6376519 7.99250392,13.8341516 8.11372249,13.9553233 L8.77240902,14.613588 C8.89367447,14.7348065 9.09026793,14.7348065 9.21153338,14.613588 L10.9075621,12.9185905 C10.9461871,12.8799655 11.0088589,12.8799655 11.0474838,12.9185905 L12.7435126,14.613588 C12.864778,14.7348065 13.0613715,14.7348065 13.1826369,14.613588 L13.8413235,13.9553233 C13.962542,13.8341516 13.962542,13.6376519 13.8413235,13.5164802 L12.1453885,11.8215764 C12.1067166,11.7829046 12.1067166,11.720186 12.1453885,11.681561 L12.1453885,11.681561 Z"
            id="Path"
            fill="#FE99A0"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default ErrorFoundIcon;
