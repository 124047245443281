// absolute imports
import { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { useReactiveVar } from '@apollo/client';

// relative imports
import { errorVar } from '../../data/apollo/cache/reactiveVars';
import MODALS from '../shared/modals/ModalLibrary';

const useStyles = makeStyles()(() => ({
  splashScreen: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
  },
}));

const TimeoutScreen = ({ children }) => {
  const { classes: timeoutClasses } = useStyles();

  return <div className={timeoutClasses.splashScreen}>{children}</div>;
};

const topLevelErrorModalKeys = ['BAD_CONNECTION', 'GENERIC_ERROR'];

const GQLErrorBoundary = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [hasTimeoutErr, setHasTimeoutErr] = useState(false);

  const error = useReactiveVar(errorVar);

  const modalKey = useMemo(() => error && error.modalKey, [error]);

  const hideModal = useCallback(() => {
    setModalOpen(false);
    setHasTimeoutErr(false);
    errorVar(null);
  }, [setModalOpen, setHasTimeoutErr]);

  useEffect(() => {
    if (error && error.modalKey === 'TOKEN_EXPIRED') {
      setHasTimeoutErr(true);
    }
    if (
      error &&
      topLevelErrorModalKeys.includes(error.modalKey) &&
      !modalOpen &&
      modalKey
    ) {
      setModalOpen(true);
    }
  }, [error, modalOpen, modalKey]);

  const CurrentModal = MODALS[modalKey];

  // if user's session has timed out
  if (hasTimeoutErr) {
    return (
      <TimeoutScreen>
        <CurrentModal hideModal={hideModal} />
      </TimeoutScreen>
    );
  }

  return (
    <>
      {modalOpen && modalKey && (
        <CurrentModal hideModal={hideModal} error={error} />
      )}
      {children}
    </>
  );
};

export default GQLErrorBoundary;

TimeoutScreen.propTypes = {
  children: PropTypes.node.isRequired,
};

GQLErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};
