import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

export const FormTextField = ({
  input,
  meta,
  noPlaceholderShrink,
  formSubmitError,
  ...textFieldProps
}) => {
  const {
    onChange: passedOnChange,
    helperText,
    label,
    InputProps,
    InputLabelProps,
    ...textFieldPropsRest
  } = textFieldProps;
  const { touched, error, active, pristine, submitFailed } = meta;

  const getHelperText = () => {
    if ((touched && error && !pristine) || (error && submitFailed))
      return error;
    if (formSubmitError) return formSubmitError;
    if (helperText) return helperText;
    return null;
  };

  return (
    <TextField
      variant="standard"
      label={label}
      error={
        !!((touched && error && !pristine) || (error && submitFailed)) ||
        !!formSubmitError
      }
      helperText={getHelperText()}
      FormHelperTextProps={{
        style: {
          whiteSpace: 'pre-line',
        },
      }}
      onChange={(event) => {
        const { value } = event.target;
        input.onChange(value);
        if (passedOnChange) passedOnChange(value);
      }}
      value={input.value}
      InputLabelProps={{
        focused: active,
        shrink:
          (active && pristine) ||
          !pristine ||
          (!!textFieldProps.placeholder && !noPlaceholderShrink) ||
          (!!input.value && input.value !== ''),
        ...InputLabelProps,
      }}
      InputProps={{
        inputProps: {
          onFocus: input.onFocus,
          onBlur: input.onBlur,
        },
        ...InputProps,
      }}
      {...textFieldPropsRest}
    />
  );
};

export default FormTextField;

FormTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  noPlaceholderShrink: PropTypes.bool,
  formSubmitError: PropTypes.string,
};

FormTextField.defaultProps = {
  noPlaceholderShrink: false,
  formSubmitError: null,
};
