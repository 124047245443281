// absolute imports
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Alert, AlertTitle, Grid, Typography } from '@mui/material';

// relative imports
import ErrorFoundIcon from '../../shared/custom-icons/ErrorFoundIcon';
import LedgibleButton from '../../shared/custom-components/buttons/LedgibleButton';
import { openZendeskWidget } from '../../shared/customer-support/zendesk-operations';
import useAlternativeDisplay from '../../shared/custom-hooks/useAlternativeDisplay';

import PageBody from '../../shared/page-components/PageBody';
import PageHeader from '../../shared/page-components/PageHeader';
import { useAuth0 } from '../../common/providers/Auth0Provider';

const useStyles = makeStyles()((theme) => ({
  alert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  supportLink: {
    color: theme.whiteLabel.Link.color || '#007bff',
    display: 'inline',
    padding: 0,
    border: 0,
    backgroundColor: 'transparent',
    '&:focus': {
      outline: 0,
    },
    cursor: 'pointer',
  },
  secondaryText: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 8px',
  },
  resetButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  alertTitle: {
    marginBottom: 0,
  },
  alertSvg: {
    fontSize: theme.typography.pxToRem(72),
  },
  errorMessage: {
    fontWeight: 400,
  },
  firstGridItem: {
    marginTop: theme.spacing(3),
  },
}));

const PageErrorFallback = ({
  resetError,
  fallbackProps,
  errorMsg = 'An error occurred loading this page',
}) => {
  const { classes } = useStyles();
  const { isMobile } = useAlternativeDisplay();
  const { pageHeaderProps = {}, pageBodyProps = {} } = fallbackProps;
  const { logout } = useAuth0();

  const handleBackToLogin = async () => {
    logout();
  };

  return (
    <>
      <PageHeader {...pageHeaderProps} />
      <PageBody paddingTop={isMobile ? 2 : 4} {...pageBodyProps}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item className={classes.firstGridItem}>
            <ErrorFoundIcon className={classes.alertSvg} />
          </Grid>
          <Grid item xs={12} container direction="row" justifyContent="center">
            <Grid item xs={11} md="auto">
              <Alert severity="error" className={classes.alert}>
                <AlertTitle className={classes.alertTitle}>
                  <span className={classes.errorMessage}>{errorMsg}</span>
                </AlertTitle>
              </Alert>
              <div className={classes.secondaryText}>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  align="center"
                >
                  We&apos;ll do our best to fix this, but in the meantime,
                  please &nbsp;
                  <span
                    role="button"
                    tabIndex={0}
                    className={classes.supportLink}
                    onClick={() => openZendeskWidget()}
                    onKeyUp={() => openZendeskWidget()}
                  >
                    contact our support team
                  </span>
                  &nbsp; to let them know, or try reloading the page.
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid item container direction="column">
            <Grid item container direction="row" justifyContent="center">
              <div className={classes.resetButton}>
                <LedgibleButton
                  size="medium"
                  variant="contained"
                  onClick={resetError}
                  color="primary"
                  id="error-page-try-again-button"
                >
                  Try Again
                </LedgibleButton>
              </div>
            </Grid>
            <Grid item container direction="row" justifyContent="center">
              <LedgibleButton
                size="medium"
                variant="contained"
                onClick={handleBackToLogin}
                color="secondary"
                id="error-page-back-login-button"
                marginBottom={16}
              >
                Back To Login
              </LedgibleButton>
            </Grid>
          </Grid>
        </Grid>
      </PageBody>
    </>
  );
};

export default PageErrorFallback;

PageErrorFallback.propTypes = {
  error: PropTypes.shape().isRequired,
  resetError: PropTypes.func.isRequired,
  fallbackProps: PropTypes.shape(),
  errorMsg: PropTypes.string,
};

PageErrorFallback.defaultProps = {
  fallbackProps: {},
  errorMsg: undefined,
};
