export default {
  accountingAppDisplayName: 'Business Accounting',
  accountingAppUrlAlias: 'accounting',
  accountingDarkLogoUrl:
    'https://ledgible.io/wp-content/uploads/2022/05/Accounting-Logo-Dark-BG.webp',
  accountingLogoUrl:
    'https://ledgible.io/wp-content/uploads/2022/05/Accounting-Logo-Light-BG.webp',
  accountSubtypeDefault: null,
  accountSubtypeRequired: false,
  accountSubtypeTag: null,
  alternateBaseAppPath: null,
  appLoaderIconImgSrc: null,
  appTitle: 'Ledgible',
  defaultDarkLogoUrl:
    'https://ledgible.io/wp-content/uploads/2022/05/Ledgible-Logo-Dark-Default.webp',
  defaultDocumentTitle: 'Ledgible',
  defaultLogoUrl:
    'https://ledgible.io/wp-content/uploads/2022/01/Ledgible-Logo-Original.webp',
  enableBeamer: true,
  enableOAuthRedirectValidation: false,
  favicon16Href: '/favicon-16x16.png',
  favicon32Href: '/favicon-32x32.png',
  favicon96Href: '/favicon-96x96.png',
  productName: null,
  signupCodeRequired: false,
  staticAppType: null,
  taxAppDisplayName: 'Tax',
  taxAppUrlAlias: 'tax',
  taxDarkLogoUrl:
    'https://ledgible.io/wp-content/uploads/2022/05/Tax-Logo-Dark-BG.webp',
  taxLogoUrl:
    'https://ledgible.io/wp-content/uploads/2022/05/Tax-Logo-Light-BG.webp',
  taxProAppAccountWindowSecondaryText: 'Tax Pro Account',
  taxProAppDisplayName: 'Tax Pro',
  taxProAppUrlAlias: 'tax-preparer',
  taxProDarkLogoUrl:
    'https://ledgible.io/wp-content/uploads/2022/05/TaxPro-Logo-Dark-BG.webp',
  taxProLogoUrl:
    'https://ledgible.io/wp-content/uploads/2022/05/TaxPro-Logo-Light-BG.webp',
  themeId: 'default',
  tirAdminAppAccountWindowSecondaryText: 'TIR Admin Account',
  tirAdminAppDisplayName: 'TIR Admin',
  tirAdminAppUrlAlias: 'tir-admin',
  tirAdminDarkLogoUrl:
    'https://ledgible.io/wp-content/uploads/2024/05/Ledgible-TIR-Logo-Dark-BG.webp',
  tirAdminLogoUrl:
    'https://ledgible.io/wp-content/uploads/2024/05/Ledgible-TIR-Logo-Primary.webp',
  verificationEmailSetup: null,
};
