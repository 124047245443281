// absolute imports
import { RetryLink } from '@apollo/client/link/retry';

// relative imports
import { errorVar } from '../cache/reactiveVars';

// max 5 retries on connection error, space randomly between .5 and 2.5 sec
const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: 2500,
    jitter: true,
  },
  attempts: (count, operation, error) => {
    if (
      !!error
      && error.message === 'Failed to fetch'
      && operation.query.definitions[0].operation !== 'mutation'
    ) {
      if (count <= 5) return true;
      // show network error if retry failed 5 times
      if (count > 5) {
        errorVar({
          code: error.statusCode || 502,
          message: error.message,
          modalKey: 'BAD_CONNECTION',
          type: 'network',
        });
      }
      return false;
    }
    return false;
  },
});

export default retryLink;
