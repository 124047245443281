import { useEffect, useState } from 'react';

// custom hook to load third party scripts from components
const useExternalScript = (src, attributes = {}) => {
  // track status in state
  const [status, setStatus] = useState(src ? 'loading' : 'idle');
  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus('idle');
        return undefined;
      }
      // check for existing in dom
      let script = document.querySelector(`script[src="${src}"]`);
      if (!script) {
        // add script if not found by appending to body
        script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.setAttribute('data-status', 'loading');
        if (Object.keys(attributes).length) {
          Object.keys(attributes).forEach((key) => {
            script.setAttribute(key, attributes[key]);
          });
        }
        document.body.appendChild(script);
        // Store status in attribute on script to be read by other instances of this hook
        const setAttributeFromEvent = (event) => {
          script.setAttribute(
            'data-status',
            event.type === 'load' ? 'ready' : 'error',
          );
        };
        script.addEventListener('load', setAttributeFromEvent);
        script.addEventListener('error', setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute('data-status'));
      }
      // manage event handlers to update the state for particular hook instance.
      const setStateFromEvent = (event) => {
        setStatus(event.type === 'load' ? 'ready' : 'error');
      };
      // Add event listeners
      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);
      // cleanup by removing active listeners
      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent);
          script.removeEventListener('error', setStateFromEvent);
        }
      };
    },
    [attributes, src], // re-run effect if script src changes
  );
  return status;
};

export default useExternalScript;