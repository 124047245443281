// absolute imports
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { makeStyles } from 'tss-react/mui';
import {
  Alert,
  AlertTitle,
  AppBar,
  Box,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';

// relative imports
import ErrorManImg from '../shared/img-assets/pages/ComputerError.svg';
import LedgibleButton from '../shared/custom-components/buttons/LedgibleButton';
import { unSnakeCase, properCase } from '../shared/utilities/stringUtilities';
import {
  getAuth0ClientId,
  getAppIdFromUrl,
  getAppLogoSrc,
} from '../shared/utilities/appUtilities';
import { primaryStoreKey } from '../../data/apollo/cache/utilities/browserStorage';
import useAlternativeDisplay from '../shared/custom-hooks/useAlternativeDisplay';

const useStyles = makeStyles()((theme) => ({
  appBar: {
    backgroundColor:
      theme.whiteLabel.Header.color || theme.palette.secondary.main,
  },
  container: {
    background: '#fff',
    height: '100%',
    width: '100%',
  },
  gridContainer: {
    minHeight: '80%',
    width: '100%',
  },
  errorMan: {
    maxWidth: 340,
    maxHeight: 340,
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2),
    },
  },
  imgItem: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  logo: {
    margin: theme.spacing(1.5),
    height: 50,
    width: 'auto',
  },
  headerText: {
    color: '#343434',
    margin: theme.spacing(2, 0),
    fontWeight: 500,
    textAlign: 'left',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(-4),
      textAlign: 'center',
    },
  },
  divider: {
    color: theme.palette.text.secondary,
    height: '1.2px',
    width: '90%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(-2),
  },
  linkText: {
    color: theme.whiteLabel.Link.color || '#3791af',
    cursor: 'pointer',
    '&:hover': {
      color: theme.whiteLabel.Link.hoverColor || '#143945',
      textDecoration: 'underline',
    },
  },
  bottomText: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  errorBox: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  offrampButton: {
    maxWidth: 200,
  },
}));

const ErrorPage = ({
  error,
  errorMsg,
  fallbackProps: { auth0Client, persistor },
  resetError,
}) => {
  // use window since providers aren't available at all mounting instances
  const { location } = window;
  const auth0Error = queryString.parse(location.search);
  const hasError = Object.keys(auth0Error).length > 0 || !!error;
  const { classes } = useStyles({ hasError });
  const urlAppId = getAppIdFromUrl();
  const [isAuthed, setIsAuthed] = useState(false);
  const checked = useRef(false);
  const { isMobile } = useAlternativeDisplay(960);

  useEffect(() => {
    const checkAuth = async () => {
      const authed = await auth0Client.isAuthenticated();
      setIsAuthed(authed);
      checked.current = true;
    };

    if (auth0Client && !checked.current) {
      checkAuth();
    }
  }, [auth0Client]);

  const handleBackToLogin = async () => {
    sessionStorage.removeItem(primaryStoreKey);
    if (persistor) {
      await persistor.purge();
      await persistor.pause();
    }

    if (isAuthed) {
      await auth0Client.logout({
        clientId: getAuth0ClientId(),
        logoutParams: {
          returnTo: queryString.stringifyUrl({
            url: `${import.meta.env.REACT_APP_AUTH0_CALLBACK}/logout`,
            query: { authClientId: getAuth0ClientId() },
          }),
        },
      });
    } else {
      await auth0Client.loginWithRedirect({
        appType: urlAppId,
      });
    }
  };

  return (
    <div className={classes.container}>
      <AppBar className={classes.appBar} position="sticky">
        <Box display="flex" flexDirection="row" justifyContent="center">
          <CardMedia
            component="img"
            title="Ledgible logo"
            image={getAppLogoSrc()}
            alt="Ledgible logo"
            className={classes.logo}
          />
        </Box>
      </AppBar>
      <Grid
        container
        direction="row"
        alignItems={isMobile ? 'flex-start' : 'center'}
        justifyContent="center"
        className={classes.gridContainer}
      >
        <Grid item className={classes.imgItem}>
          <img src={ErrorManImg} alt="Error-Man" className={classes.errorMan} />
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="flex-start"
          xs={12}
          md={6}
        >
          {hasError && (
            <>
              <Typography
                gutterBottom
                variant="h6"
                className={classes.headerText}
              >
                Sorry, it looks like something went wrong:
              </Typography>
              <Alert severity="error" className={classes.errorBox}>
                <AlertTitle>
                  {auth0Error.error
                    ? properCase(unSnakeCase(auth0Error.error))
                    : 'Error Details'}
                </AlertTitle>
                {auth0Error.error
                  ? auth0Error.error_description
                  : errorMsg || error?.message || 'Error found'}
              </Alert>
            </>
          )}
          {!hasError && (
            <Typography
              gutterBottom
              variant="body1"
              align="center"
              className={classes.headerText}
            >
              Sorry, it looks like something went wrong...
            </Typography>
          )}
          <Grid item container direction="column">
            {resetError && (
              <Grid
                item
                container
                direction="row"
                justifyContent={isMobile ? 'center' : 'flex-start'}
              >
                <LedgibleButton
                  fullWidth
                  size="medium"
                  variant="contained"
                  onClick={resetError}
                  color="primary"
                  id="error-page-try-again-button"
                  className={classes.offrampButton}
                  marginBottom={16}
                >
                  Try Again
                </LedgibleButton>
              </Grid>
            )}
            <Grid
              item
              container
              direction="row"
              justifyContent={isMobile ? 'center' : 'flex-start'}
            >
              <LedgibleButton
                fullWidth
                size="medium"
                variant="contained"
                onClick={handleBackToLogin}
                color="secondary"
                id="error-page-back-login-button"
                className={classes.offrampButton}
                marginBottom={16}
              >
                {auth0Error ? 'Back ' : 'Go '} To Login
              </LedgibleButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ErrorPage;

ErrorPage.propTypes = {
  error: PropTypes.shape(),
  fallbackProps: PropTypes.shape(),
  errorMsg: PropTypes.string,
  resetError: PropTypes.func,
};

ErrorPage.defaultProps = {
  error: undefined,
  errorMsg: undefined,
  fallbackProps: {},
  resetError: undefined,
};
