// import external modules
import { makeStyles } from 'tss-react/mui';

import { useHistory } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

// import svg icons
import { AccountBalance as AccountIcon } from '@mui/icons-material';

// relative imports
import { useAccount } from '../../common/providers/AccountProvider';
import useAlternativeDisplay from '../../shared/custom-hooks/useAlternativeDisplay';

import { getAppUrlPathFromId } from '../../shared/utilities/appUtilities';
import { useConfig } from '../../common/providers/ConfigProvider';

const useStyles = makeStyles()((theme, { isMobile }) => ({
  listItemRoot: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
    marginLeft: isMobile ? 0 : theme.spacing(3),
    paddingTop: 0,
    paddingBottom: 0,
    borderBottom: `${isMobile ? 1 : 0}px solid ${theme.palette.grey[200]}`,
  },
  leftBorder: {
    borderLeft: `${isMobile ? 0 : 1}px solid ${theme.palette.grey[200]}`,
  },
  accountButton: {
    maxWidth: 160,
    color:
      theme.whiteLabel.AccountBox.textColor ||
      theme.palette.grey[isMobile ? 700 : 200],
  },
  greyText: {
    color: theme.palette.grey[isMobile ? 700 : 200],
    display: 'block',
  },
  textPadLeft: {
    color:
      theme.whiteLabel.HeaderText.color || isMobile
        ? undefined
        : theme.palette.common.white,
    fontSize: theme.typography.pxToRem(14),
    paddingLeft: '12px',
  },
  textPadLeftSecondary: {
    paddingLeft: '12px',
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[400],
  },
  acctButtonIcon: {
    minWidth: 'auto',
    width: 'auto',
    color: theme.whiteLabel.AccountBox.textColor || 'inherit',
  },
  noPad: {
    padding: 0,
  },
  link: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[isMobile ? 600 : 400],
    display: 'block',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.grey[isMobile ? 700 : 500],
      textDecoration: 'underline',
    },
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
    marginBottom: theme.spacing(0.5),
  },
}));

const TaxPayerAccountWindow = () => {
  const { isMobile } = useAlternativeDisplay();
  const { accountConfig } = useConfig();
  const { classes, cx } = useStyles({ isMobile });
  const { currentAccount } = useAccount();
  const taxPayerDetails = currentAccount.taxPayerDetails || {};
  const history = useHistory();

  const goToSettings = () => {
    history.push(`/${getAppUrlPathFromId('tax')}/settings/general`);
  };

  return (
    <ListItem
      className={classes.leftBorder}
      classes={{
        root: classes.listItemRoot,
        gutters: classes.textPadLeft,
      }}
      alignItems="center"
    >
      <ListItemIcon
        classes={{
          root: classes.acctButtonIcon,
        }}
      >
        <AccountIcon fontSize="small" className={classes.greyText} />
      </ListItemIcon>
      <ListItemText
        primary={currentAccount.name}
        primaryTypographyProps={{
          classes: {
            root: classes.textPadLeft,
            colorPrimary: classes.greyText,
          },
          variant: 'body1',
          noWrap: true,
        }}
        secondary={
          !accountConfig.hideTaxLabels && (
            <span
              role="button"
              className={classes.link}
              onKeyDown={goToSettings}
              onClick={goToSettings}
              tabIndex={0}
            >
              {`Tax Year ${taxPayerDetails.taxYear}`}
            </span>
          )
        }
        secondaryTypographyProps={{
          classes: {
            root: classes.textPadLeftSecondary,
            colorPrimary: classes.greyText,
          },
          variant: 'body2',
          noWrap: true,
        }}
        className={cx(classes.noPad, classes.accountButton)}
      />
    </ListItem>
  );
};

export default TaxPayerAccountWindow;
