// absolute imports
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

// relative imports
import LedgibleDialogActions from './LedgibleDialogActions';
import LedgibleDialogContent from './LedgibleDialogContent';
import LedgibleDialogContentText from './LedgibleDialogContentText';
import LedgibleButton from '../custom-components/buttons/LedgibleButton';
import { useModalActions } from '../../common/providers/ModalProvider'
import useDialogState from '../custom-hooks/useDialogState';

const useStyles = makeStyles()((theme) => ({
    title: {
        color: theme.palette.secondary.main,
    },
    content: {
        color: theme.palette.text.secondary,
    }
}));

const ModalConfirmExit = ({
  onClose,
}) => {
    const { classes } = useStyles();
    const { hideLastModal } = useModalActions();
    const { dialogState, ignoreDialogState, resetDialogState } =
      useDialogState();

    const handleConfirmExitYes = () => {
      if (onClose) {
        onClose();
      }
      hideLastModal();
      resetDialogState();
    };

    const handleConfirmExitNo = () => {
      ignoreDialogState();
    };

    return (
      <Dialog
        open={!dialogState.pristine}
        onClose={handleConfirmExitNo}
        aria-labelledby="confirm-exit-dialog-title"
        aria-describedby="confirm-exit-dialog-description"
      >
        <DialogTitle className={classes.title} id="confirm-exit-dialog-title">
          Are you sure you want to exit?
        </DialogTitle>
        <LedgibleDialogContent className={classes.content}>
          <LedgibleDialogContentText>
            <b>Yes</b> - Exit and lose progress <br /> <b>No</b> - Continue
            editing
          </LedgibleDialogContentText>
        </LedgibleDialogContent>
        <LedgibleDialogActions>
          <LedgibleButton
            color="cancel"
            id="confirm-exit-cancel-button"
            onClick={handleConfirmExitNo}
          >
            No
          </LedgibleButton>
          <LedgibleButton
            autoFocus
            color="primary"
            id="confirm-exit-agree-button"
            onClick={handleConfirmExitYes}
          >
            Yes
          </LedgibleButton>
        </LedgibleDialogActions>
      </Dialog>
    );
}

ModalConfirmExit.propTypes = {
  onClose: PropTypes.func,
}

ModalConfirmExit.defaultProps = {
  onClose: () => {},
}

export default ModalConfirmExit;
