import { captureSentryGenericError } from '../../../../components/error-handling/sentry';

export const primaryStoreKey = `ledgible_session_store::${encodeURI(
  import.meta.env.REACT_APP_AUTH0_CALLBACK,
)}`;

const storageTypes = {
  local: localStorage,
  session: sessionStorage,
};

export const getLocalStore = (storageType) => {
  try {
    if (storageTypes[storageType]) {
      const serializedState =
        storageTypes[storageType].getItem(primaryStoreKey);
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    }
  } catch (err) {
    captureSentryGenericError(err, { function: 'getLocalStore' });
  }
  return undefined;
};

export const saveLocalStore = (state, storageType) => {
  try {
    if (storageTypes[storageType]) {
      const serializedState = JSON.stringify(state);
      storageTypes[storageType].setItem(primaryStoreKey, serializedState);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    captureSentryGenericError(err, { function: 'saveLocalStore' });
  }
};